import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './noticeBanner.css';
import CSSModules from 'react-css-modules';
import Button from "../button/Button";
import {setNoticeBannerContent} from "../../services/actions/paragraphActions";

const NoticeBanner = () => {
    const {noticeBanner} = useSelector(state => state.paragraphReducer);
    const dispatch = useDispatch();
    
    return !!noticeBanner &&
            
        <div styleName="wrapper">
            <div className='close-cross' style={{top: '16px', right: "18px"}} 
                onClick={()=>dispatch(setNoticeBannerContent(null))}>+</div>
            {noticeBanner.text}
            <br />
            {noticeBanner.text1}
            
            <Button text={noticeBanner.button} mod='fill blue margin0' 
              style={{marginTop: '20px'}} 
              callback={()=>dispatch(setNoticeBannerContent(null))} />
        </div>       
}

export default CSSModules(NoticeBanner, styles, {allowMultiple: true});
import React, { useState, useEffect, useRef } from 'react'
import CSSModules from 'react-css-modules'
import styles from './TemplatesView.css'
import Button from '../../../../../components/button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { TemplatesActionCreators } from '../../../../../redux/template/action'
import api from '../../../../../services/api/api'
import cn from 'classnames'
import UserLogo from '../../../../../components/userLogo/UserLogo'
import { getPopUpAction } from '../../../../../services/actions/dataActions'
import { saveNewDocumentSubVersionAction } from '../../../../../redux/document/actions';
import { popUpReset } from '../../../../../services/helpers'
import { transL, transS } from '../../../../../services/helpers/lang'
import { use } from 'chai'

const TemplatesView = () => {
    const [isPublishOpen, setIsPublishOpen] = useState(false)
    const [isVersionOpen, setIsVersionOpen] = useState(false)
    const {varlist, actualDocument, currentDocument} = useSelector(state => state.document),
      {user} = useSelector(state => state.userReducer)
    const id = actualDocument._id;
    const core = actualDocument.coreDocument;
    const orig = actualDocument.orig;
    const title = currentDocument.title;
    const dispatch = useDispatch()
    const editHandler = () => {
        dispatch(TemplatesActionCreators.setEditPopup(true, core))
    }
    const versionsAvailable = false,
      isAuthorOrCompanyAdmin = user._id === actualDocument.addedBy ||
        user.company?.knownCompanies[0].members._general.members.find(
        m => user._id === m._id)?.accessStatus > 0;

    const displayVersion = '' // 2.0
    
    const clickOutsideHandler = () => {
        setIsPublishOpen(false)
        setIsVersionOpen(false)
    }
    const newTemplateBtn = () => {
        setIsPublishOpen(false)
        setIsVersionOpen(false)
        dispatch(TemplatesActionCreators.cloneTemplate(id, core, title, title +' (Copy)'));
    }
    const publishDraft = () => {
        dispatch(saveNewDocumentSubVersionAction(core));
        dispatch(getPopUpAction(popUpReset));
    }
    const publishBtnClickHandler = () => {
        setIsPublishOpen(false);
        if (orig === 302) {
            let canPublish = !varlist.find(v => !v.value && v.required);
            
            dispatch(getPopUpAction(canPublish ? popups.createFromDraft : popups.noCreateFromDraft))
        } else if (orig !== 300) {
            dispatch(TemplatesActionCreators.publishTemplate(id, core, displayVersion))
        } else {
            dispatch(TemplatesActionCreators.unpublishTemplate(id))
        }
    }
    const popups = {
        noCreateFromDraft : {
            name: 'confirm',
            header: transS('Not all the required fields were filled out'),
            text: transL('Not all'),
            swap: true,
            confirm: {
                name: transS('Create anyway'),
                mod: 'red arch',
                event: () => publishDraft()
            },
            cancel: {
                    name: transS('Fill out'),
                    event: () => dispatch(getPopUpAction(popUpReset))
                },    
        },
            createFromDraft: {
                name: 'confirm',
                header: transS('Create Document'),
                text: transL('Create Document'),
                confirm: {
                    name: transS('Create'),
                    event: () => publishDraft()
                },
                cancel: {
                    name: transS('Cancel'),
                    event: () => dispatch(getPopUpAction(popUpReset))
                }
            },
    }
    
    return (
        <>
            {(isPublishOpen || isVersionOpen) && <div styleName='screen' onClick={clickOutsideHandler} />}
            <div styleName='wrapper'>
                <div className="scaffold"></div>
                {orig === 302 ? <div styleName='status'>{transS('Unpublished Draft')}</div>
                : <div styleName={cn('status', {'_green': orig === 300})}>{
                    orig === 300 ? transS('Published') :  transS('Unpublished')}</div> }
                <div styleName={cn('publish', {'_open': isPublishOpen})}>
                    <div styleName='publish_btnBlock'>
                        <div onClick={publishBtnClickHandler} 
                          styleName={isAuthorOrCompanyAdmin ? '' : 'BtnsOpaque'}>
                            <img src='/images/send.svg' height='21' alt='' style={{marginRight: '10px'}} />
                            <Button text={orig === 302 ? transS('Create\xA0Document') : (orig !== 300 ? transS('Publish') : transS('Unpublish'))}
                                    mod='blue margin0' />
                        </div>

                        {orig !== 302 && <Button text='&nbsp;'
                                callback={() => setIsPublishOpen(prev => !prev)}
                                mod={`leftArrow${isPublishOpen ? ' leftArrow_open' : ''}`} />}
                    </div>
                    <div styleName='publish_popup'>
                        <div className='close-cross' onClick={() => setIsPublishOpen(false)}>+</div>
                        <div styleName='smallText'>
                            {orig === 300
                                ? <span dangerouslySetInnerHTML={{__html:transL('templateHide')}} />
                                : <span dangerouslySetInnerHTML={{__html:transL('templateAvailable')}} />                                    
                            }
                        </div>
                        {versionsAvailable && <div styleName='publish_popup_btnBlock'>
                            <Button text={transS('New Version')} mod='blue margin0' />
                            <div styleName='smallText'>
                                Create a new version of this template.
                            </div>
                        </div>}
                        <div styleName='publish_popup_btnBlock'>
                            <Button text={transS('New Template')} mod='blue margin0' 
                              callback={newTemplateBtn}/>
                            <div styleName='smallText'>
                               {transS('Create a new template based on this version of this template')}
                            </div>
                        </div>
                    </div>
                </div>
                
                {orig !== 302 && <>

                <div className="borderHeader" />
                <div styleName='border' />
                <Button text={transS('Settings')} mod='blue' style={{marginRight: '40px'}} callback={editHandler} />

                {versionsAvailable && <div styleName={cn('versions', {'_open': isVersionOpen})}>
                    <Button text={transS('Version')}
                            mod={`blue leftArrow${isVersionOpen ? ' leftArrow_open' : ''}`}
                            callback={() => setIsVersionOpen(prev => !prev)}
                    />
                    <div styleName='versions_popup'>
                        <Button text={transS('New Version')} mod='blue arch' style={{marginBottom: '40px'}} />
                        <div styleName='versions_popup_version'>
                            <div styleName='versions_popup_version_title _current'>
                                {transS('Current Version')}
                            </div>
                            <div styleName='versions_popup_version_desc'>
                                <div>Last changes <span styleName='gray'>on Jan 29, 2020</span></div>
                                <div>Created <span styleName='gray'>on Jan 29, 2020</span></div>
                            </div>
                        </div>

                        <div styleName='versions_popup_version'>
                            <div styleName='versions_popup_version_title _current _prev'>
                                Showing
                                Version 3.1
                            </div>
                            <div styleName='versions_popup_version_desc'>
                                <div>Last changes <span styleName='gray'>on Jan 29, 2020</span></div>
                                <div>Created <span styleName='gray'>on Jan 29, 2020</span></div>
                            </div>
                        </div>

                        <div styleName='versions_popup_version'>
                            <div styleName='versions_popup_version_title'>
                                Version 3.0
                            </div>
                            <div styleName='versions_popup_version_desc'>
                                <div>Last changes <span styleName='gray'>on Jan 29, 2020</span></div>
                                <div>Created <span styleName='gray'>on Jan 29, 2020</span></div>
                            </div>
                        </div>

                        <div styleName='versions_popup_version'>
                            <div styleName='versions_popup_version_title'>
                                Version 2.0
                            </div>
                            <div styleName='versions_popup_version_desc'>
                                <div>
                                    <span styleName='green bold'>{transS('Published')}</span>
                                    <span styleName='gray'> on Jan 29, 2020 </span>
                                    by
                                    <div styleName='userBlock'>
                                        <UserLogo firstname='Mock' lastname='User' email='test@tt.tt' avatar='' mod='_small' />
                                        <div>
                                            <div styleName='userBlock_name'>Mock User</div>
                                            <div styleName='userBlock_email'>test@tt.tt</div>
                                        </div>
                                    </div>
                                </div>
                                <Button text={transS('Unpublish')} mod='red arch' style={{margin: '16px 0 10px'}} />
                                <div>Created <span styleName='gray'>on Jan 29, 2020</span></div>
                            </div>
                        </div>
                    </div>
                </div>} </>}
            </div>
        </>
    )
}

export default CSSModules(TemplatesView, styles, {allowMultiple: true})
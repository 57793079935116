import React, { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './approveInfo.css';
import UserLogo from '../../../../components/userLogo/UserLogo';
import Button from "../../../../components/button/Button"
import {remindUserDocumentApproveAction} from "../../../../services/actions/documentActions"
@connect(state => ({
  approverUserList: state.userReducer.approverUserList,
  approved: state.document.approved
}), {remindUserDocumentApproveAction} )
@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })

export default class ApproversList extends Component {

  render() {
    const { approved, approverUserList, remindUserDocumentApproveAction} = this.props;
    if (!approved || !approverUserList) return;

    return (
      <div styleName='listWrapper'>
        <div styleName='progress'>
          {approverUserList
              .filter(u => !approved.find(id => id === u._id))
              .map(u => {
                    return <div styleName='right' key={'app' + u._id}>
                      <UserLogo mod='_user-list' firstname={u.firstname} lastname={u.lastname} avatar={u.avatar}/>
                      <div styleName='user-data'>
                        <div styleName='user-name'>{u.firstname} {u.lastname}</div>
                        <div>{u.email}</div>
                      </div>
                      <div styleName='remindBtn'>
                        <Button text='Remind' mod='blue' callback={remindUserDocumentApproveAction}/>
                      </div>
                    </div>
                  }
              )
          }
        </div>
      </div>
    )
  }
}

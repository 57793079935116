import CSSModules from 'react-css-modules'
import React from 'react'
import styles from './finalize.css'
import Button from '../../../../../components/button/Button'
import Approvals from '../elements/Approvals/Approvals'
import cx from "classnames"
import Changes from "../elements/Changes/Changes"
import Spinner from "../../../../../components/spinner/Spinner";
import { transL, transS } from '../../../../../services/helpers/lang'

@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
export class FinalizedExternal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isScrollNeed: false,
            isScrolled: false
        }
    }

    componentDidMount() {
        this._calcBlockWidth()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this._calcBlockWidth()
    }

    _calcBlockWidth = () => {
        const headerWidth = this.refWrapper.parentElement.offsetWidth + 80 // 80 = 40padding left + 40padding right
        const clientWidth = document.documentElement.clientWidth

        if (headerWidth > clientWidth && !this.state.isScrollNeed) {
            this.setState({isScrollNeed: true})
        } else if (headerWidth < clientWidth && this.state.isScrollNeed) {
            this.setState({isScrollNeed: false, isScrolled: false})
        }
    }

    render() {
        const {approveButton, approverUserList, approvers, approved, isProjectAdmin, isExternal, approveBtnCallback,
            getPopUpAction, popup, setMenuAction, stateMenu, frameProgress, jumpChanges, openInvitePopup, actualDocument} = this.props
        const {isScrollNeed, isScrolled} = this.state
        const isApprovedAll = approverUserList && (approved.length === approverUserList.length),
            approvalInfoMessage = !approverUserList || isApprovedAll ? '' : '1';

        return (
            <div styleName="wrapper" ref={e => this.refWrapper = e}>
                <div styleName='text' dangerouslySetInnerHTML={{__html: transL('to finalise')}} />

                <Approvals approverUserList={approverUserList} approved={approved} isExternal={isExternal}
                           actualDocument={actualDocument} approveBtnCallback={approveBtnCallback} isProjectAdmin={isProjectAdmin}/>

                <div style={isScrollNeed ? null : {width: '233px'}}
                     styleName={cx('scrollBlock', {'scrollBlock_open': isScrolled || !isScrollNeed})}>

                    <div styleName='scrollBlockButtons'>
                        <Button text={transS('Finalise')} mod='green fill' style={{marginRight: '40px'}} callback={() => {
                            this.setState({toCollaborate: false})
                            getPopUpAction(popup.finalize);
                        }}/>
                        <Button text={<span dangerouslySetInnerHTML={{__html: 
                          transS('Refuse to Finalise')}} />} 
                          mod='blue nowrap' 
                          callback={() => getPopUpAction(popup.refuseFinalize)}/>
                    </div>
                    {isScrollNeed && <div styleName='scrollBlockButtons-grad' />}
                    {isScrollNeed &&
                    <>
                      <div className="borderHeader" styleName={cx('borderH', {'borderH-left': isScrolled})}/>
                      <div styleName={cx('arrow-right', {'arrow-left': isScrolled})}
                         onClick={() => this.setState({isScrolled: !isScrolled})} />
                    </>}
                </div>               

                <Changes setStateMenu={arg => setMenuAction(arg)} jumpChanges={jumpChanges} stateMenu={stateMenu} frameProgress={frameProgress}/>

                <Button mod='blue margin0' callback={openInvitePopup} text={transS('Teams')} />
                {/*<Share openInvitePopup={openInvitePopup}/>*/}

            </div>
        )
    }
}

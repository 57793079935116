import React, { Component } from 'react';
import { connect } from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './progressAndTasks.css';
import moment from 'moment';
import api from '../../../../services/api/api';
import Radio from '../../../../components/radio/Radio';
import Button from '../../../../components/button/Button';
import Version from './Version';
import { getPopUpAction } from '../../../../services/actions/dataActions';
import {
  saveNewDocumentSubVersionAction, getHistoryDocumentAction, returnSubVersionAction,
} from '../../../../redux/document/actions';
import { popUpReset } from '../../../../services/helpers';
import { hasDownloadDocumentPermission } from '../../../../../common/validation';
import { getDateString } from "../../../../../common/getDateString";
import { transF, transS } from '../../../../services/helpers/lang';
import Tip from '../../../../components/tip/Tip';
const noReply = "no reply from server";


@connect(state => ({
  user: state.userReducer.user,
  dateData: state.userReducer.dateData,
  currentDocument: state.document.currentDocument,
  actualDocument: state.document.actualDocument,
  displayVersion: state.document.displayVersion,
  currentProject: state.projectReducer.currentProject
}), {saveNewDocumentSubVersionAction, returnSubVersionAction, getHistoryDocumentAction, getPopUpAction} )
@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
export default class Progress extends Component {
  state = {
      isExpanded: 2,
      rightsChecked: false
  };
  clickCount = 0;
  
  constructor (props) {
    super(props);
    this._message = this._message.bind(this);
    this._collapse = this._collapse.bind(this);
    this._unknown = this._unknown.bind(this);
    this.message = noReply;
  }
  
  _collapse = e => {
      let button = parseInt(e.currentTarget.value, 10);
      this.setState({ isExpanded: this.clickCount*4 + button });
      this.clickCount++;
  };
  
  _unknown = () => '';
  
  componentDidMount() {
    const { user, currentProject } = this.props;
    let isFreeUser = (user.ticks <= 0);
    let isExportForbidden = !hasDownloadDocumentPermission() && (!user.totalPaying || isFreeUser);
    // this.refs.listVersions.style.height = window.innerHeight - 300 + 'px';
    /*if (isFreeUser) this.message = 'Export rights are unavailable for FREE plan users';
    else */
    if (isExportForbidden) {      
      api.getProjectExport(currentProject._id)
         .then(res => {
             const data = res.data.data;
             this.message = '';
         })
         .catch(resp => {
            this.message = (resp.response.status == 403) ?
               'Export rights are available for PAID plan users only' : 
                'something wrong with server. Try again';           
         })
         .finally(() => {
             this.setState({ rightsChecked: true });
         });
    } else { this.message = '';
      this.setState({ rightsChecked: true });
    }
  }
  
  _message = () => {return this.message;};
  
  _origination = (no, first) => {
      const { user } = this.props,       
        shift = user.userGroup ? 20 : 0;
      return transF('_origination')(no, shift, first)
  }

  render() {
    const { actualDocument, getHistoryDocumentAction, displayVersion, 
        prohibitNewVersion, dragHistory, jumpDoc, toggleBar, freezeFunc,
        getPopUpAction, user, saveNewDocumentSubVersionAction, returnSubVersionAction, 
        currentDocument, download, dateData } = this.props;
    const {isExpanded, rightsChecked} = this.state;

    const lastUpdateDiff = moment(actualDocument.updatedAt).fromNow(); // moment().diff(moment(actualDocument.createdAt), 'hours')
    const isFrozen = actualDocument.orig === 21,
      justImported =  actualDocument.orig === 50
    const updaterEditor = !actualDocument.blocked && actualDocument.editingBy ? actualDocument.editingBy : actualDocument.editor;
    const editorName = updaterEditor._id === user._id ?  transS('You')
      : (updaterEditor.firstname + ' ' + updaterEditor.lastname);
    let stamp = null, prevVersion = '', 
      allowNew = !(actualDocument.history[0].orig === 6 
        || actualDocument.history[0].orig === 7 || prohibitNewVersion || justImported);
    return ( rightsChecked &&
      <div styleName='partCommon bottomed'>
          <div styleName='title'>{transS('Progress')}</div>
          <div styleName="radioField">
            <Radio text0={transS('Collapse All')} text={transS('Expand All')} radioBtn={ isExpanded < 0 ? 1 : isExpanded % 2}
                   name='progress' callback={this._collapse} />
          </div>
          { (allowNew || isFrozen) && <div styleName="h2">{transS('Versions & Export')}</div> }
          {isFrozen && <div styleName="buttonField">
            <Button text={transS('Import as New Version')} mod='blue arch top10' 
              callback={() => freezeFunc(true)}/>
            <div className='space20'></div>
            <Button text={transS('Unfreeze without Import')} mod='blue arch top10' 
              style={{margin:0}}
              callback={() => freezeFunc(false)}/>              
          </div>}
          {justImported && <div styleName="buttonField">
            <Button text={transS('Continue with This Version')} mod='blue arch top10' 
              callback={() => freezeFunc(true, true)}/>
            <div className='space20'></div>
            <Button text={transS('Return to Previous Version')} mod='blue arch top10' 
              style={{margin:0}}
              callback={() => freezeFunc(false, true)}/>              
          </div>}
          { allowNew && <div styleName="buttonField">
            <Button text={transS('Create Subversion')} mod="blue arch top10"
               callback={() => {
                 toggleBar(false);
                 getPopUpAction({
                    name: 'confirm',
                    text: transS('Do you want to create a new version? ') +
                      transS('Note that you\'re not able to change the previous versions.'),
                    confirm: {
                      name: transS('Confirm'),
                      event: () => { saveNewDocumentSubVersionAction(currentDocument.coreDocument);
                          toggleBar(false); }
                    },
                    cancel: {
                      name: transS('Cancel'),
                      event: () => { getPopUpAction(popUpReset); }
                    }
                 });                 
              }
            } />  
            {!user.continuity ? false :
            actualDocument.orig !== 50 ? <div styleName='tip'><Button text='Export & Freeze' mod='blue arch top10' 
              callback={() => {
                 toggleBar(false);
                 freezeFunc(null)
                }
              } /> 
              <Tip text='Export this document in docx and <br>freeze it in Contract.one until a newer <br>docx version is imported.' 
                arrow='top left'/>
            </div>: <Button text='Return Version' mod='blue arch top10' 
              callback={() => { //todo - may be unreachable branch
                 toggleBar(false);
                 getPopUpAction({
                    header: transS('Return to Previous Version'),
                    name: 'confirm',
                    text: 'Do you want to delete this version and return to the previous one?',
                    swap: true,
                    confirm: {
                      name: 'Return',
                      danger: true,
                      event: () => { returnSubVersionAction(currentDocument._id); }
                    },
                    cancel: {
                      name: 'Cancel',
                      event: () => { getPopUpAction(popUpReset); }
                    }
                 });  
                }
              } />}
          </div> }
          
          <Version statusText={this._origination(actualDocument.history[0].orig, true)}
            changedText={`Changed by ${editorName} ${lastUpdateDiff}`}
            history={dragHistory}
            last={stamp}
            first={stamp = +moment(actualDocument.createdAt)}
            extExpand={isExpanded === -2 ? !displayVersion*1 : isExpanded}
            isCurrent={!displayVersion}
            download={download}
            downloadVersion={{
                  download,
                  version: actualDocument.version,
                  subVersion: actualDocument.subVersion,
                  isHistory: false
            }}
            jumpDoc={id => { jumpDoc(id, actualDocument._id, actualDocument.coreDocument, actualDocument.blocked, '');toggleBar(false) }}
            goToVersion={() => 
                { getHistoryDocumentAction(actualDocument._id, actualDocument.coreDocument, actualDocument.blocked, ''); 
                  toggleBar(false); 
                } }
            />
          
          <div styleName="h2 mt-30">{transS('Previous Versions')}</div>
          { actualDocument.history.map(item => {
             const version = `${item.version}.${item.subVersion}`;
             let jumping = prevVersion;
             prevVersion = version;
             return (
              <Version versionName={`${transS(item.subVersion ? 'Subversion' : 'Version')} ${item.version-1}.${item.subVersion}` }
                key={item._id}
                statusText={this._origination(item.orig, false)}
                changedText={`${item.editor.firstname} ${item.editor.lastname}, ${getDateString(item.createdAt, 4, dateData)}`}
                history={dragHistory}
                last={stamp}
                first={stamp = +moment(item.createdAt)}
                extExpand={isExpanded === -2 ? 1*(displayVersion === version) : isExpanded}
                isCurrent={displayVersion === version}
                 downloadVersion={{
                     _message: this.message,
                     download,
                     version: item.version,
                     subVersion: item.subVersion,
                     isHistory: true
                 }}
                jumpDoc={id => { jumpDoc(id, item._id, item.coreDocument || item._id, item.blocked, jumping); }}
                goToVersion={()=> {
                  getHistoryDocumentAction(item._id, item.coreDocument || item._id, item.blocked, version); 
                  toggleBar(false); }}
                />
             )})
          }
      </div>

    );
  }
  
 
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import SignUp from './auth/SignUp';
import SignIn from './auth/SignIn';
import Schedule from './auth/Schedule';
import Scheduled from './auth/Scheduled';
import LogIn from './auth/LogIn';
import Promo from './static/Promo';
import Privacy from './static/Privacy';
import Terms from './static/Terms';
import Contacts from './static/Contacts';
import Header from '../../components/header/Header';
import Activation from './auth/Activation';
import ActivationComplete from './auth/activationComplete/ActivationComplete';
import ActivationError from './auth/activationError/ActivationError';
import PageNotFound from '../pageNotFound/PageNotFound';
import ResetPassword from './auth/ResetPassword/ResetPassword';
import EnterNewPassword from './auth/EnterNewPassword/EnterNewPassword';
import Mission from "./static/Mission";
import PromoBam from "./static/PromoBam";
import DataProtection from "./legal/DataProtection"
import DataProtectionEU from "./legal/DataProtectionEU"
import Legal from "./legal/Legal"
import DataProtectionGlobal from "./legal/DataProtectionGlobal"
import TermsUse from "./legal/TermsUse"
import Security from "./static/Security"
import Cookies from "../../components/cookies/Cookies"
import UnregisterNew from "./UnregisterNew"
import EventBanner from "../../components/eventBanner/EventBanner"
import PromoFuture from './static/PromoFuture'
import { allocSimplawyer, setLanguage } from '../../services/helpers/lang';
import appConfig from '../../../configs/appConfig';

class PublicArea extends Component {
  constructor (props) {
    super(props);
    if (allocSimplawyer)
      setLanguage('ru-Ru')
  }
  
  render() {
    let { activationInProgress, location } = this.props;


    const isWithoutHeader = location.pathname.includes('/sign-up') ||
        location.pathname.includes('/login') ||
        location.pathname.includes('/schedule') ||
        location.pathname.includes('/sign-in') ||
        location.pathname.includes('/documents/')
    const isHideBanner = location.pathname === '/new' || location.pathname === '/new/'

    return (
      <div className='wrapper'>
        {!isWithoutHeader && !activationInProgress && <Header />}
        {!isWithoutHeader && <Cookies />}
        {/*{!isWithoutHeader && !isHideBanner && <EventBanner />}*/}
        {
          activationInProgress
            ? <div>
              <Activation />
            </div>
            : <Switch>
              <Route exact path='/remote-work' component={Promo} />
              <Route exact path='/login' component={LogIn} />
              <Route exact path='/login/:token' component={LogIn} />
              <Route exact path='/:projectId/documents/:documentId' component={LogIn} />
              <Route exact path='/:projectId/documents/:documentId/history/:history' component={LogIn} />
              <Route exact path='/privacy' component={Privacy} />
              <Route exact path='/terms' component={Terms} />
              <Route exact path='/contacts' component={Contacts} />
              <Route exact path='/security' component={Security} />
              <Route exact path='/promo' component={Promo} />
              <Route exact path='/bamlegal' component={PromoBam} />
              <Route exact path='/mission' component={Mission} />
              <Route exact path='/legal' component={Legal} />
              <Route exact path='/legal/terms-of-use' component={TermsUse} />
              <Route exact path='/legal/data-protection' component={DataProtection} />
              <Route exact path='/legal/data-protection/eu' component={DataProtectionEU} />
              <Route exact path='/legal/data-protection/global' component={DataProtectionGlobal} />
              <Route exact path='/sign-up' component={SignUp} />
              <Route exact path='/sign-in' component={SignIn} />
              <Route exact path='/schedule' component={Schedule} />
              <Route exact path='/scheduled' component={Scheduled} />
              <Route exact path='/activated' component={ActivationComplete} />
              <Route exact path='/activationError' component={ActivationError} />
              <Route exact path='/reset-password' component={ResetPassword} />
              <Route exact path='/new-password/:token' component={EnterNewPassword} />
              <Route exact path='/not-found/:error' component={PageNotFound} />
              <Route exact path='/new' component={UnregisterNew} />
              <Route exact path='/future' component={PromoFuture} />
              {/*<Redirect exact from="/" to="/remote-work" />*/}
              <Redirect exact from="/" to={allocSimplawyer ? "/login" : "/future"} />
              <Redirect exact from="/company" to="/login" />
              <Route component={PageNotFound} />
            </Switch>
        }
      </div>
    );
  }
}

PublicArea.propTypes = {
  activationInProgress: PropTypes.bool
};

export default withRouter(connect(state => ({
  activationInProgress: state.userReducer.activationInProgress
}))(PublicArea));

import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import styles from './promo.css';

const Slogan = props => {
  let { message } = props;
  return (
    <p styleName='slogan'>{message}</p>
  );
};


Slogan.propTypes = {
  message: PropTypes.string
};

export default new CSSModules(Slogan, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });

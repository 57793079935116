import React from 'react'
import {Avatar} from './Avatar';

export const ShowAvatars = ({arr}) => {
    const sty = {display: 'flex',
      flexWrap: 'wrap', gap: 5}
    return <div style={sty}>
      {arr.map(userId => <Avatar  userId={userId} key={userId} />)}
    </div>
}

import React, { Component } from 'react';
import cssModules from 'react-css-modules';
import styles from './addUserByEmail.css';

@cssModules(styles, {
  allowMultiple: true,
  handleNotFoundStyleName: 'throw' })

export default class EditableLabel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: this.props.isEditing || false,
      text: this.props.text || ''
    };

    this._handleFocus = this._handleFocus.bind(this);
    this._handleChange = this._handleChange.bind(this);
  }

  /**
   * Method to start editing on click
   * @private
   */
  _handleFocus() {
    if (this.state.isEditing) {
      if (typeof this.props.onFocusOut === 'function') {
        this.props.onFocusOut(this.state.text);
      }
    } else {
      if (typeof this.props.onFocus === 'function') { this.props.onFocus(this.state.text); }
    }
    this.setState({ isEditing: !this.state.isEditing, text: this.props.text
    });
  }

  /**
   * usual input handler for react
   * @private
   */
  _handleChange() {
    this.setState({ text: this.textInput.value });
  }

  render() {
    if (this.state.isEditing) {
      return (
        <div>
          <input type='text'
            styleName="inputStyle"
            ref={input => { this.textInput = input; }}
            value={this.state.text}
            onChange={this._handleChange}
            onBlur={this._handleFocus}
            style={{ width: this.props.inputWidth,
              height: this.props.inputHeight,
              padding: 0,
              fontSize: this.props.inputFontSize,
              fontWeight: this.props.inputFontWeight,
              borderWidth: this.props.inputBorderWidth,
              border: this.props.inputBorder
            }}
            maxLength={this.props.inputMaxLength}
            placeholder={this.props.inputPlaceHolder}
            tabIndex={this.props.inputTabIndex}
            autoFocus />
        </div>);
    }

    return (<div>
      <label styleName="inputStyle"
        onClick={this._handleFocus}
        style={{ fontSize: this.props.labelFontSize,
          height: this.props.inputHeight,
          width: this.props.inputWidth,
          fontWeight: this.props.labelFontWeight
        }}>
        {this.props.text ? this.props.text : this.props.inputPlaceHolder}
      </label>
      {this.props.onHoverFunc && <span styleName="hoverStyle"
        onClick={() => this.props.onHoverFunc(false)}>{this.props.onHoverFunc(true)}</span>}
    </div>);
  }
}

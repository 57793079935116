import React, {Component} from 'react'
import styles from './companySettings.css'
import cssModules from "react-css-modules"
import cx from "classnames"
import UserListItem from "../../components/createProjectDropDown/UserListItem"
import { transS } from '../../../../services/helpers/lang'

@cssModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
class TeamLine extends Component {
    state = {
        isOpen: true,
        isNameEdit: false,
        name: '',
        oldName: null,
        members: [],
    }

    componentDidMount() {
        const {team} = this.props
        let members = this.props.members.map(m => ( {...m, teams: [...m.teams]} ) )
        this.setState({name: team, members})
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.members !== this.props.members) {
            let members = this.props.members.map(m => ( {...m, teams: [...m.teams]} ) )
            this.setState({members})
        }
    }

    inputChangeHandler = (e) => {
        let {oldName, name} = this.state

        if (e.target.value.startsWith('_')) return

        oldName = oldName ? oldName : name

        this.setState({name: e.target.value, oldName})
    }

    rename = () => {
        const {teams} = this.props
        const {oldName, name, members} = this.state

        if (name === '') return this.setState({isNameEdit: false, name: oldName})
        if (!oldName || name === oldName) return this.setState({isNameEdit: false})
        if (!!teams.find(t => t === name) && (oldName && oldName !== name) ) return this.setState({isNameEdit: false, name: oldName})

        if (oldName) {
            members.forEach(m => m.teams = m.teams.filter(t => t !== oldName))
            members.forEach(m => {
                if (!m.teams.find(t => t === name)) m.teams.push(name)
            })
        }


        this.props.addTeam({oldName, name, members})
        this.setState({isNameEdit: false})
    }

    addSubTeam = () => {
        const {oldName, name} = this.state
        const {teams} = this.props

        let newTeamName = 'New Team', count = 0
        teams.forEach(i => {
            if (i === newTeamName) count += 1
            newTeamName = `New Team ${count ? count : ''}`
        })

        let team = {name: newTeamName, members: [], parentTeams: [name]}
        this.props.addTeam(team)
    }

    render() {
        const {team, members, openEdit, openAddMembers, subTeamsList, isSubTeam} = this.props
        const {isOpen, isNameEdit, name} = this.state

        const membersList = members.map(m => <UserListItem maxNameWidth={114} noCheck={true} user={m} key={m._id || m._localId}/>)

        return (
            <div styleName='TL-wrapper'>
                <div styleName='TL-head'>
                    <span styleName='TL-title'>
                        {isNameEdit ?
                            <input styleName='TL-inputName' placeholder='Enter Team Name' autoFocus type='text' onBlur={this.rename} value={name} onChange={this.inputChangeHandler}/>
                        :
                            <React.Fragment>
                                <span styleName='TL-titleS' onClick={() => this.setState({isNameEdit: true})}>{team}</span>
                                <div styleName='TL-hint'>{transS('Click to rename')}</div>
                                <div onClick={() => this.setState({isOpen: !isOpen})} styleName={cx('arrow', {'_open': isOpen})}></div>
                            </React.Fragment>
                        }
                    </span>
                    {!isNameEdit &&
                    <div styleName='TL-head-controls'>
                        <div styleName='TL-btnEdit' onClick={openEdit}><img src='../../../../images/icon-settings.png' alt=''/></div>
                        <div styleName='TL-btnAdd' onClick={openAddMembers}>{transS('Add Member')}</div>
                        {!isSubTeam && <div styleName='TL-btnAdd' onClick={this.addSubTeam}>Add Team</div>}
                    </div>}
                </div>
                {isOpen && <React.Fragment>
                    <div styleName='TL-userList'>
                        {membersList}
                    </div>
                    <div styleName='TL-subTeamsList'>
                        {subTeamsList}
                    </div>
                </React.Fragment>}
            </div>
        )
    }
}

export default TeamLine



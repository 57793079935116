import cx from 'classnames'
import moment from 'moment'
import CSSModules from 'react-css-modules'
import React from 'react'
import styles from './ReviewedInternal.css'
import Button from '../../../../../components/button/Button'
import Changes from '../elements/Changes/Changes'
import Approvals from '../elements/Approvals/Approvals'
import SendMenu from '../elements/SendMenu/SendMenu'
import { getDateString } from "../../../../../../common/getDateString"
import {connect} from "react-redux"
import Share from "../elements/Share/Share"
import api from '../../../../../services/api/api';
import { transS } from '../../../../../services/helpers/lang'

@connect(state => ({  
    frameProgress: state.paragraphReducer.frameProgress,
    dateData: state.userReducer.dateData
}), {})
@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
export class ReviewedInternal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSendMenuOpen: false,
            isScrollNeed: false,
            isScrolled: false,
        }
    }

    componentDidMount() {
        window.addEventListener('mousedown', this.handleClickOutside)
        window.addEventListener('resize', this.handleResizeWindow)
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.handleClickOutside)
        window.removeEventListener('resize', this.handleResizeWindow)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.frameProgress) {
            this._sendMenuSetPosition()
            this._setScroll()
        }
    }

    handleClickOutside = (e) => {
        if (this.state.isSendMenuOpen && e.target !== this.refSendMenu &&
            !this.refSendMenu.contains(e.target) && !this.refSendMenuButton.contains(e.target)) {
            this.setState({isSendMenuOpen: false})
        }
    }
    handleResizeWindow = () => {
        this._sendMenuSetPosition()
    }

    _sendMenuSetPosition = () => {
        const {hasExternals, approverUserList, approved} = this.props
        if (!this.refSendMenu) return

        if (hasExternals && approverUserList && approved && (approverUserList.length !== approved.length)) {
            let btn = this.refSendMenuButton.parentElement, btnBlock = btn.parentElement.parentElement,
                leftOffsetBtn = btn.offsetLeft + btnBlock.offsetLeft + 40

            let width = document.documentElement.clientWidth // ширина видимой области окна браузера (без учёта скроллбара)

            if (window.innerWidth <= 1366) {
                let scrollBarWidth = window.innerWidth - width
                width = 1366 - scrollBarWidth
            }
            let rightOffsetBtn = width - leftOffsetBtn // отступ от левой границы кнопки до правого края окна браузера
            let sendMenuPosition = rightOffsetBtn - 840 // 840 это 800 ширина sendMenu + 40 отступ от правого края окна браузера

            if (sendMenuPosition > -40) sendMenuPosition = -40

            this.refSendMenu.style.left = `${sendMenuPosition}px`
        } else {
            this.refSendMenu.style.left = '-40px'
        }
    }

    _setScroll = () => {
        const isExternalReqEdit = !this.props.actualDocument.blocked && this.props.actualDocument.editorsRequest !== 0
            || this.props.isCollaborative && this.props.actualDocument.blocked && !!this.props.actualDocument.editorsContRequest
        if (this.props.isApproveAgain && isExternalReqEdit) {
            const clientWidth = document.documentElement.clientWidth
            if (clientWidth < 1487 && !this.state.isScrollNeed) {
                this.setState({isScrollNeed: true})
            } else if (clientWidth >= 1487 && this.state.isScrollNeed) {
                this.setState({isScrollNeed: false, isScrolled: false})
            }
        } else if (this.state.isScrollNeed && (!this.props.isApproveAgain || !isExternalReqEdit)) {
            this.setState({isScrollNeed: false, isScrolled: false})
        }
    }

    sendBtnClickHandler = () => {
        if (this.props.user.temporary) return this.props.getPopUpAction({name: 'auth'})
        if (!this.state.isSendMenuOpen && this.props.frameProgress?.sendToFrame) 
            this.props.frameProgress.sendToFrame({c1:'clearSelection'});
        this.setState({isSendMenuOpen: !this.state.isSendMenuOpen})
    }

    teamBtnClickHandler = () => {
        if (this.props.user.temporary) return this.props.getPopUpAction({name: 'auth'})

        this.props.openInvitePopup()
    }
    
    render() {
        const {
            actualDocument, isCollaborative, approveButton, approverUserList, getPopUpAction, popup, frameProgress, setMenuAction, jumpChanges,
            isProjectAdmin, isExternal, approved, approvers, setToCollaborate, stateMenu, isApprovedAll, 
            hasExternals, isApproveAgain, openInvitePopup, approveBtnCallback, dateData
        } = this.props
        const {isSendMenuOpen, isScrollNeed, isScrolled} = this.state

        const actRequest = actualDocument.blocked ? actualDocument.editorsContRequest : actualDocument.editorsRequest
        const isExternalReqEdit = !actualDocument.blocked && actualDocument.editorsRequest !== 0
            || isCollaborative && actualDocument.blocked && !!actualDocument.editorsContRequest
        const hint = !hasExternals ? 
            transS('Cannot send the document. There is nobody on the other team.') : null,
            approvalInfoMessage = !approverUserList || isApprovedAll ? '' : '1';
        const isDoc = !actualDocument.contentType;
        const isShared = actualDocument.shared;

        return (
            <div styleName='wrapper'>
                <div className="scaffold"></div>
                { isSendMenuOpen && <div styleName='screen' />}
                <div styleName='buttonBlock'>

                    {( isExternalReqEdit || !!isApproveAgain || (isCollaborative && !isExternalReqEdit) || isShared ) 
                        && <div styleName={cx('scroll-button-block', {'scroll-button-block_open': isScrolled || !isScrollNeed, 'scroll-button-block_noScroll': !isScrollNeed})}>
                        <div styleName='scroll-buttons'>
                            {isShared &&<div styleName='alert non_alert'>{transS('Shared with Counterparty')}
                                <span styleName='alert_time'>
                            {getDateString(actualDocument.createdAt, 4, dateData)}</span>
                            </div>}
                            {isCollaborative && !isExternalReqEdit && !isApproveAgain &&
                            <div styleName='alert'>{transS('Collaboration mode')}</div>}
                            {isExternalReqEdit &&
                            <div styleName='alert'>{transS('The External Team')}<br/>
                                {transS('requested editing')}
                                <span styleName='alert_time'>
                            {getDateString(actRequest, 4, dateData)}</span>
                            </div>}
                            {!!isApproveAgain &&
                            <div styleName='alert' style={{top: '-16px', height: '45px'}} 
                                dangerouslySetInnerHTML={{__html:
                                transS('Your Approval <br/> was cancelled <br/> by new changes')}}
                            />}
                        </div>                        

                        {isScrollNeed && <div styleName='scroll-buttons-grad' />}
                        {isScrollNeed &&
                        <div styleName={cx('arrow-right', {'arrow-left': isScrolled})}
                             onClick={() => this.setState({isScrolled: !isScrolled})} />}
                        <div styleName='scroll-button-block_mask' />
                    </div>}

                    {isDoc && <Approvals approverUserList={approverUserList} approved={approved} isExternal={isExternal}
                               actualDocument={actualDocument} approveBtnCallback={approveBtnCallback} isProjectAdmin={isProjectAdmin}/>
                               }
                    <div styleName='sendButtonBlock'>
                        <div ref={e => this.refSendMenuButton = e}>
                            {!isDoc && isShared ?
                            <Button text={transS('Revoke Access')} mod="shared"
                                    callback={() => getPopUpAction(popup.share)}/>
                            :<Button text={isDoc ? transS('Send') : transS('Share')} mod={'send ' + (isSendMenuOpen ? 'send-open ' : '')}
                                    callback={this.sendBtnClickHandler}
                            />}
                        </div>
                        <div styleName={cx('sendMenu-wrapper', {'sendMenu-wrapper_vis': isSendMenuOpen})} ref={e => this.refSendMenu = e}>
                            <SendMenu
                                takeOverEdit={() => getPopUpAction(isCollaborative ? popup.collaborateCancel : popup.overtake)}
                                requestBack={() => getPopUpAction(popup.request)}
                                close={() => this.setState({isSendMenuOpen: false})}
                                send={ !isDoc ? () => getPopUpAction(popup.share)
                                    : () => {
                                    setToCollaborate(false)
                                    if (approvalInfoMessage) {
                                        popup.approve.question = transS('Are you sure you want to send?')
                                        getPopUpAction(popup.approve);
                                    } else getPopUpAction(popup.transfer);
                                }}
                                finalize={() => {
                                    setToCollaborate(false)
                                    if (approvalInfoMessage) {
                                        isProjectAdmin ? getPopUpAction(popup.finalizeNotProveAdmin) : getPopUpAction(popup.finalizeNotProve)
                                    } else {
                                        getPopUpAction(popup.finalize);
                                    }
                                }}
                                collaborate={isCollaborative
                                    ? () => getPopUpAction(popup.collaborateCancel)
                                    : () => {
                                        setToCollaborate(true)
                                        popup.approve.question = transS('Are you sure you want to start Collaboration Mode?')
                                        getPopUpAction(popup.collaborate);
                                    }}
                                approvers={approvers}
                                approverUserList={approverUserList}
                                approved={approved}
                                isExternal={isExternal}
                                isCollaborative={isCollaborative}
                                isProjectAdmin={isProjectAdmin}
                                freezeFunc={this.props.freezeFunc}
                                hint={hint}
                            />
                        </div>
                    </div>
                </div>
                {isDoc && <div className="borderHeader" /> }
                {isDoc && <Changes setStateMenu={arg => setMenuAction(arg)} jumpChanges={jumpChanges} stateMenu={stateMenu} frameProgress={frameProgress} isInternal="1"/>
                }
                {!isDoc && <a href={`/api/documents/getpure/${actualDocument.projectId}/${actualDocument.coreDocument}/${actualDocument.title}`} download> 
                  <Button mod='blue margin0' text={transS('Download')} style={{marginRight: '40px'}} /> 
                      </a>}
                <Button mod='blue margin0' callback={this.teamBtnClickHandler} text={transS('Teams')} style={{marginTop: '15px', marginRight: '40px'}} />
                {/*<Share openInvitePopup={openInvitePopup} />*/}

            </div>
        )
    }
}

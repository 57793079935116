import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {CardElement, injectStripe} from 'react-stripe-elements';
import CSSModules from 'react-css-modules';
import styles from './paymentComponent-OLD.css';
import cx from 'classnames';
import api from '../../../../services//api/api';
import Spinner from '../../../../components/spinner/Spinner'

@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this); 
    this.submit1 = this.submit1.bind(this);
    this.process = this.process.bind(this);
    this.doc_url = '';
    this.state = {      
      errMessage: '',      
      isProcessing: false
    };    
  }

  async submit(ev) {
    ev.preventDefault();    
    let atoken = null;
    let result = null;
    if (this.props.stripe) {
       this.setState({isProcessing: true});
       try {
          let {token} = await this.props.stripe.createToken({name: "Name"});
          atoken = token;
          if (token && token.error && token.error.message) {
              this.setState({errMessage: token.error.message});
          }
       }
       catch(err) {
          this.setState({errMessage: "Error with Stripe tokenization ",
                         isProcessing: false});
          return;
       }
       
       if (!atoken || !atoken.id) {
           this.setState({isProcessing: false});
           return;
       }
       
       api.renewSubscription(atoken.id, this.props.clicked() - 1, 21)
               .then( (resp)=> { 
                   this.setState({isProcessing: false});
                   this.props.returnFunc(resp.data.data);
               } )
               .catch( (resp) => { //response.data.err.message response.data.code=401
                   let msg = 'connection error. Try later';
                   this.doc_url = '';
                   if ( (resp) && (resp.response) )
                       if ( (resp.response.data) &&  (resp.response.data.code) ) {
                           if (resp.response.data.code == 401) {
                               msg = resp.response.data.err.message;
                               this.doc_url = resp.response.data.err.doc_url;
                           } else if (resp.response.data.code == 402)
                               msg = resp.response.data.err.message;
                           else msg = 'unknown.error';                           
                       } else msg = 'Response structure error'; 
                   this.setState({errMessage: msg,
                                  isProcessing: false});
               } )
      
       
    } else {
      this.setState({errMessage: "Stripe.js hasn't loaded yet."});
    }
    if (atoken) {        
      // console.log(atoken);
      // console.log(result);
    }
  }
  
  async process(result, err, description = '') {
      if (result) {
          api.renewSubscription(result, 3, this.props.clicked())
               .then( (resp)=> { 
                   this.setState({isProcessing: false});
                   this.props.returnFunc(resp.data.data);
               } )
               .catch( (resp) => { //response.data.err.message response.data.code=401
                   let msg = 'connection error. Try later';
                   this.doc_url = '';
                   if ( (resp) && (resp.response) )
                       if ( (resp.response.data) &&  (resp.response.data.code) ) {
                           if (resp.response.data.code == 401) {
                               msg = resp.response.data.err.message;
                               this.doc_url = resp.response.data.err.doc_url;
                           } else if (resp.response.data.code == 402)
                               msg = resp.response.data.err.message;
                           else msg = 'unknown.error';                           
                       } else msg = 'Response structure error'; 
                   this.setState({errMessage: msg,
                                  isProcessing: false});
               } )
      } else {
          this.setState({errMessage: description ? description : err.message,
                         isProcessing: false});
      }      
  }
  
  async submit1(ev) {
    ev.preventDefault();
    if (this.state.isProcessing) return;
    this.setState({isProcessing: true});
    this.setState({errMessage: ''});
    this.props.stripe.createSource({type: 'card'})
       .then( result => {
          if (result.error) this.process(null, result.error, "");
          else if (result.source.card.three_d_secure != 'not_supported') {
              if (result.source.card.three_d_secure !== 'required') {
                  this.process(result.source.id,null);
                  return;
              }
              this.props.stripe.createSource(
                {type: 'three_d_secure',
                amount: this.props.clicked(),
                currency: 'usd',
                three_d_secure: { card: result.source.id },
                redirect: { return_url: window.location.href }
                })
              .then(source3D => {
                  if (source3D.error)
                    this.process(null, source3D.error, "Error with Stripe 3D Secure check");
                  else {
                    this.process(source3D.source.id, null);
                  }
              })
              .catch(err => {
                  this.process(null, err, "Error with Stripe 3D Secure check");
              })

          }
          else this.process(result.source.id,null);
      })
      .catch(err => {
          this.process(null, err, "Error with Stripe tokenization");
      })
  }
  
  render() {
    return (
      <div>
        { this.state.isProcessing && <Spinner /> }
        <div styleName="checkout">
          Card details
          <CardElement            
            {...createOptions()}
          />
          { (this.doc_url) ?
            <div styleName="errMessage"><a href={this.doc_url} target='_blank'>
              {this.state.errMessage}</a></div>            
            :
            <div styleName="errMessage">
              {this.state.errMessage}</div>
          }
        </div>
        <button type="button" styleName={cx('calc-btn', {'_disable':this.state.isProcessing})}
          onClick={this.submit1}>Checkout</button>
        <button type="button" styleName={cx('calc-btn', {'_disable':this.state.isProcessing}, {'_decline':true})}
          onClick={()=>{this.props.returnFunc(false)}}>Cancel</button>
      </div>
    );
  }
}

CheckoutForm.propTypes = {
  returnFunc: PropTypes.func,
  clicked: PropTypes.func
};

export default injectStripe(CheckoutForm);

const createOptions = (padding) => {
  return {
    style: {
      base: {
        fontSize: '22px',
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
        padding,
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

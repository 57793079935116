import React, { Component } from 'react';
import cssModules from 'react-css-modules';
import styles from './activationComplete.css';
import { transLoc } from '../../../../services/helpers/lang';

@cssModules(styles, {
  'allowMultiple': true,
  'handleNotFoundStyleName': 'throw'
})

class ActivationComplete extends Component {
  render() {
    return (
      <div styleName='wrapper'>
        <h1 styleName='title'>{transLoc('Thank you for registration')}</h1>
        <a styleName='log-in-button' href='/login'>{transLoc('Sign in')}</a>
      </div>
    );
  }
}

export default ActivationComplete;

import React from 'react'
import CSSModules from 'react-css-modules';
import styles from './unitList.css'
import Avatar from './Avatar';

const ShowAvatars = ({arr}) => {
    return <div styleName='avatars'>
      {arr.map(userId => <Avatar  userId={userId} key={userId} />)}
    </div>
}

export default CSSModules(ShowAvatars, styles, {allowMultiple: true});
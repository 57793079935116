import React from 'react';
import {connect} from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './header.css';
import cx from 'classnames';
import Logo from '../logo/Logo';
import {NavLink} from 'react-router-dom';
import Button from '../button/Button';
import { transLoc } from '../../services/helpers/lang';

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      whiteLogo: false,
      whiteButtons: false,
      isMobilePopupOpen: false
    }
    this.isScrollHandler = false
  }

  componentDidMount() {
    const pathname = location ? location.pathname : '/'
    const isPromoFuture = (pathname.toLowerCase() === ('/future') || pathname.toLowerCase() === ('/future/'))

    if (isPromoFuture) {
      this.setState({whiteLogo: true, whiteButtons: true})
      window.addEventListener('scroll', this.scrollHandler)
      this.isScrollHandler = true
    }

    window.addEventListener('click', this.clickOutsideHandler)
  }

  clickOutsideHandler = e => {
    if (this.refPopup && this.state.isMobilePopupOpen && e.target !== this.refPopup && !this.refPopup.contains(e.target)) {
      this.setState({isMobilePopupOpen: false})
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const pathname = location ? location.pathname : '/'
    const isPromoFuture = (pathname.toLowerCase() === ('/future') || pathname.toLowerCase() === ('/future/'))

    if (!isPromoFuture) {
      window.removeEventListener('scroll', this.scrollHandler)
      this.isScrollHandler = false
      if (this.state.whiteLogo || this.state.whiteButtons) {
        this.setState({whiteLogo: false, whiteButtons: false})
      }
    } else if (isPromoFuture && !this.isScrollHandler) {
      this.setState({whiteLogo: true, whiteButtons: true})
      window.addEventListener('scroll', this.scrollHandler)
      this.isScrollHandler = true
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollHandler)
    window.removeEventListener('click', this.clickOutsideHandler)
    this.isScrollHandler = false
  }

  scrollHandler = () => {
    const needWhiteButtons = () => {
      const currentScrollY = window.scrollY
      return currentScrollY < 900 ||
          (currentScrollY > 1578 && currentScrollY < 2150) ||
          (currentScrollY > 7380 && currentScrollY < 8190) ||
          (currentScrollY > 9050 && currentScrollY < 9750)
    }
    const needWhiteLogo = () => {
      const currentScrollY = window.scrollY
      return currentScrollY < 2150 ||
          (currentScrollY > 7380 && currentScrollY < 10520) ||
          (currentScrollY > 8823 && currentScrollY < 9540)
    }

    if (needWhiteLogo()) {
      this.setState({whiteLogo: true})
    } else if (this.state.whiteLogo) {
      this.setState({whiteLogo: false})
    }

    if (needWhiteButtons()) {
      this.setState({whiteButtons: true})
    } else if (this.state.whiteButtons) {
      this.setState({whiteButtons: false})
    }
  }

  render() {
    const {location, showStarted} = this.props;
    const {isMobilePopupOpen} = this.state
    const pathname = location ? location.pathname : '/', notFound = pathname.includes('not-found');

    const isBamLegal = (pathname === ('/bamlegal') || pathname === ('/bamlegal/'))
    const isLegal = (pathname === ('/legal') || pathname === ('/legal/'))
    const isTermsUse = (pathname === ('/legal/terms-of-use') || pathname === ('/legal/terms-of-use/'))
    const isDataProtection = (pathname === ('/legal/data-protection') || pathname === ('/legal/data-protection/'))
    const isDataProtectionEU = (pathname === ('/legal/data-protection/eu') || pathname === ('/legal/data-protection/eu/'))
    const isDataProtectionGlobal = (pathname === ('/legal/data-protection/global') || pathname === ('/legal/data-protection/global/'))
    const isSecurity = (pathname === ('/security') || pathname === ('/security/'))
    const isNew = (pathname === ('/new') || pathname === ('/new/'))
    const isMission = (pathname === '/mission' || pathname === '/mission/')
    const isPromoFuture = (pathname.toLowerCase() === ('/future') || pathname.toLowerCase() === ('/future/'))
    const isPromo = (pathname.toLowerCase() === ('/') || pathname.toLowerCase() === ('/remote-work') || pathname.toLowerCase() === ('/remote-work/'))

    return (
      <header styleName={cx('header', {'_bg': !isPromoFuture, '_fixed': isPromoFuture || isPromo, '_future': isPromoFuture})}>
        <div styleName={cx('mask', {'_visible': false})}/>
        <div styleName={cx('wrapper _fixed')}>

          <div styleName='left'>
            <Logo activeLink={(pathname !== '/' && pathname !== '/remote-work')} white={this.state.whiteLogo} />
            {isMission && <div styleName='header-mission'>Clarity and Love: Mission Statement</div>}

            {isLegal && <div styleName='header-dataProtection'>Legal</div>}
            {isTermsUse &&
            <div styleName='header-dataProtection'><NavLink to='/legal'>Legal</NavLink> / Terms of Use</div>}
            {isDataProtection &&
            <div styleName='header-dataProtection'><NavLink to='/legal'>Legal</NavLink> / Data Protection Policy
            </div>}
            {isDataProtectionEU &&
            <div styleName='header-dataProtection'><NavLink to='/legal'>Legal</NavLink> / <NavLink
                to='/legal/data-protection'>Data Protection Policy</NavLink> / Europe</div>}
            {isDataProtectionGlobal &&
            <div styleName='header-dataProtection'><NavLink to='/legal'>Legal</NavLink> / <NavLink
                to='/legal/data-protection'>Data Protection Policy</NavLink> / Global</div>}
            {isSecurity && <div styleName='header-dataProtection'>Security</div>}
            {isNew && <div styleName='header-dataProtection'>Negotiate .new document</div>}
          </div>

          <div styleName="right">
            {showStarted && <NavLink to='/schedule'>
              <Button text='Schedule Demo' mod='blue fill big'/>
            </NavLink>}

            {isBamLegal &&
            <NavLink style={{textDecoration: 'none'}} to='/mission'>
              <div styleName='mission'>
                <span>Clarity</span> and <span>Love</span>:
                <span>Our Mission Statement</span>
              </div>
            </NavLink>}

            {isPromoFuture &&
            <React.Fragment>
              {/*<button styleName={cx('promoButton', {'_dark': !this.state.whiteLogo})} style={{marginRight: '40px'}}>Product</button>*/}
              {/*<button styleName={cx('promoButton', {'_dark': !this.state.whiteLogo})} style={{marginRight: '40px'}}>Company</button>*/}
              <NavLink to='/schedule'>
                <button styleName={cx('promoButtonArch', {'_dark': !this.state.whiteButtons})}
                        style={{marginRight: '40px'}}>Request Trial</button>
              </NavLink>
              <NavLink to='/login'><button styleName={cx('promoButtonFill', {'_dark': !this.state.whiteButtons})}>{transLoc('Sign in')}</button></NavLink>
            </React.Fragment>}

            {!isPromoFuture && <NavLink to='/login'>
              <Button text={transLoc('Sign in')} mod={isNew ? 'arch blue margin0' : 'signIn'}
                      style={isNew ? {transform: 'translateY(1px)'} : null}/>
            </NavLink>}

          </div>
          {isPromoFuture && <div styleName="rightMobile" ref={e => this.refPopup = e}>
            <img src={`/images/burgerMenu${isMobilePopupOpen ? 'Open' : ''}.png`}
                 alt="" width='15' height='14'
                 onClick={() => this.setState({isMobilePopupOpen: !isMobilePopupOpen})} />
            <div styleName={cx('rightMobile_popup', {'_open': isMobilePopupOpen})}>
              <React.Fragment>
                <NavLink to='/login'>
                  <button styleName='promoButtonFill _dark' 
                    style={{marginBottom: '20px'}}>{transLoc('Sign in')}</button>
                </NavLink>
                <NavLink to='/schedule'>
                  <button styleName='promoButtonArch _dark'
                          style={{marginRight: '40px'}}>Request Trial</button>
                </NavLink>
              </React.Fragment>
            </div>
          </div>}


        </div>
      </header>
    );
  }
}

export default connect(state => ({
  showStarted: state.dataReducer.showStarted,
  location: state.router.location
}),{})(new CSSModules(Header, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' }))

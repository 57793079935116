import React, {Fragment} from 'react'
import CSSModules from 'react-css-modules'
import cx from 'classnames'
import styles from "./navigationContent.css"
import { transS } from '../../../../services/helpers/lang';

class NavigationContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentHoveredPara: {
                id: null,
                vis: false,
                content: null,
                top: 0
            }
        }
        this.timeout = null
    }

    componentDidMount() {
        window.addEventListener('wheel', this.wheelHandler, {passive: false})
    }

    componentWillUnmount() {
        window.removeEventListener('wheel', this.wheelHandler)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.navigation.current !== this.props.navigation.current) {
            if (this.refCurrent) this.refCurrent.scrollIntoView({behavior: "auto", inline: "nearest", block: "nearest"})
        }
    }

    wheelHandler = e => {
        if (this.props.wrapper && (e.target === this.props.wrapper || this.props.wrapper.contains(e.target) && !this.refParaList.contains(e.target)))
            e.preventDefault()

        if (this.props.isFullMode || e.target !== this.refPopupPara && !this.refPopupPara.contains(e.target))
            return

        this.refParaList.scrollTo({top: this.refParaList.scrollTop + e.deltaY, behavior: "smooth"})
        if (this.state.currentHoveredPara.vis) {
            this.setState({currentHoveredPara: {vis: false, content: null, id: null, top: 0}})
        }
        clearTimeout(this.timeout)
    }

    paraListScrollHandler = () => clearTimeout(this.timeout)

    paraMouseEnterHandler = (e, para, num) => {
        this.setState({currentHoveredPara: {vis: false, content: null, id: null, top: 0}})
        const top = e.target.offsetHeight * num - this.refParaList.scrollTop - 8
        this.timeout = setTimeout(() => this.setState({
            currentHoveredPara: {
                vis: true,
                id: para.id,
                top: top,
                content: <div>{para.enum && <span>{para.enum}</span>}{para.textContent}</div>
            }
        }), 500)
    }

    paraMouseLeaveHandler = () => {
        this.setState({currentHoveredPara: {vis: false, content: null, id: null, top: 0}})
    }

    makeSmallParaList = () => {
        const {navigation, jump} = this.props
        return navigation.paras
            .filter(el => !!el.textContent.trim()) // фильтр пустых параграфов
            .map((el, index) => {
            return <div styleName={cx('paraItem_wrapper', {'_current': navigation.current.id === el.id})}
                        key={'CSP' + el.id}
                        ref={e => navigation.current.id === el.id ? this.refCurrent = e : null}
                        onMouseEnter={e => this.paraMouseEnterHandler(e, el, index + 1)}
                        onMouseLeave={() => clearTimeout(this.timeout)}
                        onClick={() => jump(el.id)}>
                <div styleName='paraItem'>
                    {el.enum || el.textContent}
                </div>
            </div>
        })
    }

    makeFullParaList = () => {
        const {navigation, paraFilter} = this.props
        return navigation.paras
            .filter(el => !!el.textContent.trim()) // фильтр пустых параграфов
            .filter(el => {
                if (!paraFilter) return 1
                return paraFilter === 1 ? el.changed : el.changed || el.commented
            })
            .map(el => this.createFullPara(el))
    }


    createFullPara = (para) => {
        const {navigation, jump} = this.props
        return !para || !para.textContent.trim() ? ''
        : <div styleName={cx('paraItem_wrapper', {'_current': navigation.current.id === para.id, '_chg': para.changed || para.commented})}
                    style={para.nestingLevel > 3 ? {paddingLeft: (para.nestingLevel - 1) * 5} : null}
                    key={'CFP' + para.id}
                    ref={e => navigation.current.id === para.id ? this.refCurrent = e : null}
                    onClick={() => jump(para.id)}>
            <div styleName='paraItem'>
                <div>{para.enum && <span>{para.enum}</span>}{para.textContent}</div>
            </div>
        </div>
    }

    render() {
        const {currentHoveredPara} = this.state
        const {isOpen, isFullMode, navigation, jump} = this.props

        return <div styleName={cx('wrapper', {'_open': isOpen, 'fullMode': isFullMode, 'b': navigation.back})}>

            {isFullMode && !!navigation.recent.length && <Fragment>
                <div className='h3' styleName='title'>{transS('Recent')}</div>
                <div styleName='paraList recentParaList'>
                    {navigation.recent.map( (el, i) => this.createFullPara(el, i))}
                </div>
            </Fragment>}

            {isFullMode && <div className='h3' styleName='title'>{transS('All')}</div>}

            <div styleName='paraList'
                 style={isFullMode && !!navigation.recent.length ? {maxHeight: 'calc(100vh - 410px - ' + (69 + 23 * navigation.recent.length) + 'px)'} : null}
                 onScroll={this.paraListScrollHandler}
                 ref={e => this.refParaList = e}>
                    {isFullMode ? this.makeFullParaList() : this.makeSmallParaList()}
            </div>

            {!isFullMode &&
            <div styleName={cx('popupPara', {'_vis': currentHoveredPara.vis, '_current': navigation.current.id === currentHoveredPara.id})}
                   onMouseLeave={this.paraMouseLeaveHandler}
                   onClick={() => jump(currentHoveredPara.id)}
                   ref={e => this.refPopupPara = e}
                   style={{top: currentHoveredPara.top}}>
                {currentHoveredPara.content}
            </div>}

        </div>
    }
}

export default new CSSModules(NavigationContent, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})

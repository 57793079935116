import React, {Component} from 'react'
import styles from './companySettings.css'
import cx from 'classnames'
import cssModules from "react-css-modules"
import Button from "../../../../components/button/Button"
import Checkbox from "../../../../components/checkbox/Checkbox"
import {isEmail} from "../../../../../common/validation"
import { transL, transS } from '../../../../services/helpers/lang'

@cssModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
class AddMember extends Component {
    state = {
        isAccMenuOpen: false,
        isTeamMenuOpen: false,
        user: {
            firstname: '',
            lastname: '',
            email: '',
            newEmail: '',
            title: '',
            accessStatus: 0,
            deletedTeams: [],
            addedTeams: ['_general'],
            teams: ['_general'],
            billingPlan: null,
        },
        savedTeams: [],
        emailErrorMessage: null
    }

    componentDidMount() {
        const {editableMember} = this.props
        if (editableMember && !editableMember.new) {
            let savedTeams = editableMember.teams.slice()
            this.setState({user: {...editableMember, deletedTeams: [], addedTeams: [], newEmail: editableMember.notConfirmedEmail || editableMember.email}, savedTeams})
        }
        document.addEventListener('mousedown', this.handleClickOutside)
        this.bodyStyle = document.body.style.overflowY;
        document.body.style.overflowY = 'hidden'
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
        document.body.style.overflowY = this.bodyStyle
    }

    handleClickOutside = (e) => {
        if (this.accPopup && e.target !== this.accPopup && !this.accPopup.contains(e.target)
            && this.refArrow && e.target !== this.refArrow && !this.refArrow.contains(e.target)) this.setState({isAccMenuOpen: false})
    }

    inputChangeHandler = e => {
        const {user} = this.state
        const isNewUser = !!this.props.editableMember.new
        const isLocalUser = !this.props.editableMember._id
        if (!isNewUser && !isLocalUser && ( e.target.name === 'firstname' || e.target.name === 'lastname')) return
        if (!isNewUser && !isLocalUser && !user.isActivated && e.target.name === 'email') return

        if (!isNewUser && !isLocalUser && e.target.name === 'email') {
            return this.setState({user: {...user, newEmail: e.target.value}})
        } else if (e.target.name === 'email') {
            return this.setState({user: {...user, email: e.target.value, newEmail: e.target.value}})
        }

        this.setState({user: {...user, [e.target.name]: e.target.value}} )
    }

    inviteMember = () => {
        const {user} = this.state
        if (user.email === '' || isEmail(user.email)) return this.checkEmail(user.email)
        if (user.email === user.newEmail) delete user.newEmail
        this.props.inviteMember({...user, _localId: Date.now()})
    }

    editMember = () => {
        const {user} = this.state
        const {editMember} = this.props
        editMember({...user, isActivated: true})
    }

    editTeams = (t) => {
        let {user} = this.state
        let {editableMember} = this.props
        let userTeams = user.teams.slice()
        const isInvite = !userTeams.find(i => i === t)

        if (isInvite) {
            userTeams.push(t)
            if (!editableMember.teams || !editableMember.teams.find(i => i === t)) user.addedTeams = [...user.addedTeams, t]
            user.deletedTeams = user.deletedTeams.filter(i => i !== t)
        } else {
            userTeams = userTeams.filter(i => i !== t)
            user.deletedTeams = [...user.deletedTeams, t]
            user.addedTeams = user.addedTeams.filter(i => i !== t)
        }

        this.setState({user: {...user, teams: userTeams}})
    }

    teamsPopupSubmit = () => {
        const {user} = this.state

        this.setState({ isTeamMenuOpen: false, savedTeams: user.teams.slice()})
    }

    teamsPopupClose = () => {
        const {editableMember} = this.props
        const {user, savedTeams} = this.state

        savedTeams.forEach(t => {
            let isDeleted = user.deletedTeams.find(i => i === t)
            if (isDeleted) {
                user.deletedTeams = user.deletedTeams.filter(i => i !== t)
                user.addedTeams = user.addedTeams.filter(i => i === t)
            }

            let isAdded = user.addedTeams.find(i => i === t)
            if (!isAdded) user.addedTeams = user.addedTeams.filter(i => i === t)
        })


        user.teams = editableMember.new ? ['_general'] : savedTeams

        this.setState({isTeamMenuOpen: false, user})
    }

    accessStatusChangeHandler = (accessStatus) => {
        const {user} = this.state
        this.setState({user: {...user, accessStatus}})
        this.setState({isAccMenuOpen: false})
    }

    checkEmail = (email) => {
        if (email === '') {
            return this.setState({emailErrorMessage: transL('requiredEmail')})
        } else if (isEmail(email)) {
            return this.setState({emailErrorMessage: transL('errorEmail')})
        } else {
            this.setState({emailErrorMessage: null})
        }
    }

    render() {
        const {user, isAccMenuOpen, isTeamMenuOpen, emailErrorMessage} = this.state
        const {teams} = this.props
        const isNewUser = !!this.props.editableMember.new
        let isNoTeams = true

        const teamsLine = user.teams.map((t, index) => {
            if (t === '_general') return user.teams.length === 1 ? <div styleName={'addMember-addTeamBtn'} key={t}>{transS('Add Member to Team')}</div> : ''

            return <span key={t}>{`${t}${ ((index !== 0) && (index + 1 !== user.teams.length)) ? ' / ' : ''}`}</span>
        })

        const teamsPopup = teams.map(t => {
            if (t.startsWith('_')) return
            if (!t.startsWith('_')) isNoTeams = false

            return <div styleName='addMember-team-popup-line' key={t} onClick={() => this.editTeams(t)}>
                <div styleName='addMember-team-popup-line-checkbox'><Checkbox checked={!!user.teams.find(i => i === t)}/></div>
                <div styleName='addMember-team-popup-line-teamName'>{t}</div>
            </div>
        })

       return (
           <div styleName='addMember-wrapper'>
               <div styleName='addMember'>
                   <div onClick={this.props.close} className='close-cross'>+</div>
                   <div className='h1' style={{marginBottom: '50px'}}>{isNewUser ? transS('Add Member') : 'Edit Member'}</div>
                   <div styleName='addMember-inputs'>
                       <input styleName={'addMembers_input'} style={{width: '155px', marginRight: '10px'}}
                              name='firstname'
                              placeholder='First Name'
                              onChange={e => this.inputChangeHandler(e) }
                              value={user.firstname} type='text' />
                       <input styleName={'addMembers_input'} style={{width: '155px'}}
                              name='lastname'
                              placeholder='Last Name'
                              onChange={e => this.inputChangeHandler(e) }
                              value={user.lastname} type='text' />
                           <input styleName={'addMembers_input'}
                                  name='email'
                                  placeholder='Email'
                                  onBlur={e => this.checkEmail(e.target.value)}
                                  onChange={e => this.inputChangeHandler(e)}
                                  value={user.newEmail} type='text'/>
                           {emailErrorMessage && <div styleName='emailError' style={{marginBottom: '10px'}}>{emailErrorMessage}</div>}
                           {user.notConfirmedEmail && 
                             <div styleName='emailError' 
                                style={{marginBottom: '10px', marginTop: '-3px'}}
                                dangerouslySetInnerHTML={{__html: transL('Still') + user.email}} />}
                       <input styleName={'addMembers_input'}
                              name='title'
                              placeholder='Title'
                              onChange={e => this.inputChangeHandler(e) }
                              value={user.title} type='text' />
                   </div>
                   <div styleName='addMember-access'>
                       <div className='h2' style={{marginBotton: '20px'}}>Access Level</div>
                       <div styleName='addMember-access-active'>
                           <div styleName='addMember-access-current'
                                onClick={() => this.setState({isAccMenuOpen: true})}>
                               {user.accessStatus === 2 ? 'Super Admin' : user.accessStatus === 1 ? transS('Admin') : 'Member'}</div>
                           <div styleName={cx('arrow', {'_open': isAccMenuOpen})}
                                ref={e => this.refArrow = e}
                                onClick={() => this.setState({isAccMenuOpen: !isAccMenuOpen})}>{''}</div>
                           {isAccMenuOpen &&
                           <div styleName={cx('addMember-access-popup', {'superAdmin': user.accessStatus === 2, 'admin': user.accessStatus === 1})}
                                ref={e => this.accPopup = e}>
                               <div styleName='addMember-access-select'>
                                   <div onClick={() => this.accessStatusChangeHandler(0)}>Member</div>
                                   <span>Can create and modify projects and documents.</span>
                               </div>
                               <div styleName='addMember-access-select'>
                                   <div onClick={() => this.accessStatusChangeHandler(1)}>Admin</div>
                                   <span>Can manage users, company, billing, trash bin.  Can add admins.  Cannot remove admins.</span>
                               </div>
                               <div styleName='addMember-access-select'>
                                   <div styleName='superAdmin' onClick={() => this.accessStatusChangeHandler(2)}>Super Admin</div>
                                   <span>Can do everything admins can.  Can also remove admins, <b>including you</b>.</span>
                               </div>
                           </div>}
                       </div>
                   </div>

                   {!isNoTeams &&
                   <div styleName='addMember-team'>
                       <div className='h2' style={{marginBottom: '20px', lineHeight: 'normal'}}>Team</div>
                       <div styleName='addMember-team-current'
                            onClick={() => this.setState({isTeamMenuOpen: true})}>{teamsLine}</div>
                       {isTeamMenuOpen && <div styleName='addMember-team-popup'>
                           <div onClick={this.teamsPopupClose} className='close-cross'>+</div>
                           <div className='h3' style={{paddingBottom: '40px'}}>{transS('Add Member to Team')}</div>
                           {teamsPopup}
                           <div style={{marginTop: '40px'}}>
                            <Button text={transS('Save Changes')} mod='blue fill' callback={this.teamsPopupSubmit}/></div>
                       </div>}
                   </div>}

                   <div styleName='addMember-billing'>
                       <div className='h2' style={{marginBotton: '20px'}}>Billing Plan</div>
                       <div>{transS('Basic')}</div>
                   </div>
                   {isNewUser ? <Button text={transS('Add Member')} mod='blue fill' callback={() => this.inviteMember()}/>
                   :
                       <Button text={transS('Save Changes')} mod='blue fill' callback={() => this.editMember()}/>}
               </div>
           </div>
       )
    }
}

export default AddMember
import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import styles from './projectPage.css';
import cx from 'classnames';
import EditableLabel from '../../../../components/input/Editable';
import Hint from "../../../../components/button/Hint";
import { transS } from '../../../../services/helpers/lang';

class SubTeam extends React.Component {
    constructor(props) {
        super(props);
        this.state = {showAll: false}
    }

    render() {
        let {func, number, companyNumber, editable, external, selectedFlow, isExt, locked} = this.props;

        let isYourFirm = companyNumber === 0 || number === 0

        const members = func(number, 'getMembers', {type: 'smallUser', isYourFirm})

        if (number === 0 && external === true) return;

        return (
            <div styleName='subteam-wrapper'>
                {(selectedFlow !== 0) && !isExt &&
                <div styleName='editTeam'>{editable ?
                    <EditableLabel text={func(number, 'getName')}
                                   labelClassName='doc-label'
                                   inputClassName='doc-input'
                                   inputWidth='100%'
                                   inputFontSize='16px'
                                   inputFontWeight='700'
                                   inputMaxLength='56'
                                   inputBorder='none'
                                   inputPlaceHolder='Team name'
                                   onFocusOut={(data) => func(number, "teamChangeName", data.trim())}
                                   hoverClassName='removeTeam'
                                   onHoverFunc={isGet => (isGet ? "Remove Team" : func(number, 'removeTeam'))}
                    />
                    : (external ? 'Representatives' : 'Team')
                }
                    {editable && <div styleName="hintPlace-team"><Hint
                        text={transS('Click to rename')}
                    /></div>}
                </div>
                }
                <div styleName="subteam">
                    {members.length <= 10 || this.state.showAll ? members :
                        <div>
                            <div>{members[0]}</div>
                            <div>{members[1]}</div>
                            <div>{members[2]}</div>
                            <div>{members[3]}</div>
                            <div>{members[4]}</div>
                            <div>{members[5]}</div>
                            <div>{members[6]}</div>
                            <div>{members[7]}</div>
                            <div>{members[8]}</div>
                            <div>{members[9]}</div>
                        </div>
                    }

                    {members.length > 10 && !this.state.showAll && <span
                        styleName='teamCommand'
                        onClick={() => this.setState({showAll: true})}>Show all</span>}
                    {this.state.showAll && <span
                        styleName='teamCommand'
                        onClick={() => this.setState({showAll: false})}>Hide</span>}
                    {/*{!isExt && !locked && <span styleName='teamCommand' onClick={}>Add member</span>}*/}
                </div>
            </div>
  );
    }
}


SubTeam.propTypes = {
  func: PropTypes.func,
  number: PropTypes.number,
  editable: PropTypes.bool,
  external: PropTypes.bool
};

export default new CSSModules(SubTeam, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});



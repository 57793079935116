import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import styles from './projectPage.css';
import Checkbox from '../../../../components/checkbox/Checkbox';

const Workspace = props => {
  let { func, number, flow } = props;
  let i, counter = 0, s= '', current = flow.workspaces[number];
  const checkboxes = () => {
    let result = [], key = 0;
    for (let i = 0; i<flow.abstractTeams.length; i++)
    if (!((flow.deletedTeams | flow.internals) & (1 << i)) )
      result.push(
        <div key={key++} styleName="ws_item" onClick={() => func(number,'checkworkspace', i)}>
            <Checkbox checked={!!(current & (1 << i))} />
            { current & (1 << i) ? s : ''}
        </div>
      );
    return result;
  };
  
  for (i = 0; i<number; i++) 
    if (current === flow.workspaces[i]) {
       s = 'Duplicated'; break;
    }
  if (!s)
  for (i = 0; i<flow.abstractTeams.length; i++) {
    if ((current & (1 << i)) && !(flow.deletedTeams & (1 << i)) ) {
       counter++; s += !i ? 'Include in'
         : `${s?' ':''} ${(!s || s === 'Include in') ? '' : '+ '} ${flow.abstractTeams[i]}`;       
    }
  }
  if (counter === 1) s = 'No Mean';
  
  return (
    <div styleName="ws_wrapper">
      { 
        checkboxes()
      }      
    </div>
  );
};

Workspace.propTypes = {
  func: PropTypes.func,
  number: PropTypes.number,
  flow: PropTypes.object
};

export default new CSSModules(Workspace, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });



import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './cookies.css';
import Button from "../button/Button"

export function setCookies() {
    const today = new Date()
    const expires = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate()).toGMTString()

    document.cookie = `cookiesEnabled=true; expires=${expires}`
}

class Cookies extends React.Component {
    state = {
        showCookies: true
    }

    componentDidMount() {
        const results = document.cookie.match('(^|;) ?' + 'cookiesEnabled' + '=([^;]*)(;|$)');
        const checkSession = window.sessionStorage.getItem('cookiesEnabled')
        if (results || checkSession) this.setState({showCookies: false})
    }

    accept = () => {
        setCookies()
        this.setState({showCookies: false})
    }

    decline = () => {
        window.sessionStorage.setItem('cookiesEnabled', 'false')
        this.setState({showCookies: false})
    }

    render() {
        if (!this.state.showCookies) return

        return (
            <div styleName="wrapper">
                <div styleName='text'>
                    We use minimum cookies to make our platform work better for you. <br/>
                    <span>Without cookies the internet would work like in the 1990s.</span>
                </div>
                <Button text={'That\'s fine'} mod='blue arch' callback={this.accept}/>
                <div onClick={this.decline} styleName='btn_no'>No Cookies, even if everything breaks</div>
            </div>
        )
    }
}

export default new CSSModules(Cookies, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});

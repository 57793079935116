import CSSModules from 'react-css-modules'
import useInput from '../../../../services/hooks/useInput'
import Button from '../../../../components/button/Button'
import styles from './templatesList.css'
import React from 'react'
import { transS } from '../../../../services/helpers/lang'
import FolderControl from './FolderControl'

export const RenamePopup = CSSModules(props => {
    const title = useInput(props.title)
    const folderParams = props.folderParams,
      isNewFolder = folderParams && !folderParams._id
    const saveHandler = () => {
        if (!title.value) return
        props.saveHandler(title.value, folderParams)
        props.closeHandler()
    }
    return (
        <>
            <div styleName='screen' onClick={() => props.closeHandler()} />
            <div styleName='renamePopup' style={props.style}>
                <div className='close-cross' style={{top: '10px', right: '20px'}} onClick={props.closeHandler}>+</div>
                <div className='h3' style={{lineHeight: 'normal', marginBottom: '20px'}}>
                    {transS(isNewFolder? 'New Folder': 'Rename')}
                </div>
                <input type="text" styleName='renamePopup_input' {...title} />
                <div styleName='popupBottom'>
                    <Button text={transS('Done')}
                            mod={'fill blue' + (title.value ? '' : ' disabled')}
                            style={{marginRight: '10px'}}
                            callback={saveHandler} />
                    <Button text={transS('Cancel')} mod='arch blue' callback={props.closeHandler} />
                    {!!folderParams && <FolderControl
                        folderParams={folderParams}
                        templates={props.templates}
                    />}
                </div>
            </div>
        </>
    )
}, styles, {allowMultiple: true})
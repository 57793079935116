import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './lock.css';

const Lock = () => {
  return (
    <svg styleName='lock' version="1.1" width="16" height="16" viewBox="0 0 16 16">
                <path fill="#444444" d="M12 8v-3.1c0-2.2-1.6-3.9-3.8-3.9h-0.3c-2.1 0-3.9 1.7-3.9 3.9v3.1h-1l0.1 5c0 0-0.1 3 4.9 3s5-3 5-3v-5h-1zM9 14h-1v-2c-0.6 0-1-0.4-1-1s0.4-1 1-1 1 0.4 1 1v3zM10 8h-4v-3.1c0-1.1 0.9-1.9 1.9-1.9h0.3c1 0 1.8 0.8 1.8 1.9v3.1z"></path>
    </svg>
  );
};

export default new CSSModules(Lock, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });

import io from 'socket.io-client';
import { push } from 'connected-react-router';


import store from '../store';
import {socketConstants} from '../../../configs/appConfig';
import projectConstants from '../../../src/services/constants/projectConstants';

import {
    calculateUnreadTasksAction,
    setMenuAction,    
    updateParagraphListIO
} from '../actions/paragraphActions';
import {
    addNewSubVersionSocket,
    addNewVersionSocket,
    chgApprovals, clearWriterCoverMessageAction,
    getDocumentByForceSocket,
    getDocumentByRequestSocket, setDocumentEnvelope,
    setActualDocumentAction, setWriterCoverMessageAction,
    transferDocumentEditingAction,
    setVarList,
} from '../../redux/document/actions';
import {getPopUpAction} from '../actions/dataActions';
import {getExternalUserList, 
    updateUnknown,
    updateApproverList} from '../actions/userActions';
import {setMessage, userExpelledAction} from '../actions/projectActions';
import {documentRenamedAction, documentChangedAction} from '../actions/documentActions';
import {popUpReset} from '../helpers';
import {pubSub} from '../../views/privateArea/PrivateArea';
import {hasPermissionApprove} from '../../../common/validation';
import api from "../api/api"
import {eventSubtypes, eventTypes, saveStats} from "../helpers/logger"
import {cloneDeep} from "lodash"
import { transL, transS } from '../helpers/lang';
import userConstants from '../constants/userConstants';
import { addUnit, changeUnit, deleteUnit } from './directStore';

let socketId = null
window.socketDisconnect = () => {
    const user = store.getState().userReducer.user
    if (user) window.socket.emit(socketConstants.CONCLUSION, {userId: user._id})

    window.socket.disconnect()
    window.socket = undefined
    // console.log("SOCKET: DISCONNECT")
}

window.socketConnect = async (userId) => new Promise ((resolve, reject) => {
    window.socket = io()
    window.addEventListener('beforeunload', window.socketDisconnect)

    window.socket.on('reconnect', ()  => {
        // console.log('SOCKET: RECONNECT')
        let user = store.getState().userReducer.user;
        if (!user || !user._id) return;
        let project = store.getState().projectReducer.currentProject;
        let document = store.getState().document.currentDocument;

        let userGroup = user.userGroup;

        if (project && document && project._id && document.coreDocument) {
            let projectId = project._id;
            let documentId = document.coreDocument;

            let data = {userGroup, projectId, documentId};

            window.socket.emit(socketConstants.ENTER_THE_DOCUMENT, data);
            window.socket.emit(socketConstants.JOIN_TO_GROUP, 0);
        } else if (project && project._id) {
            let projectId = project._id;
            let data = {userGroup, projectId};

            window.socket.emit(socketConstants.ENTER_THE_PROJECT, data);
        }
        window.socket.emit(socketConstants.CONCLUSION, {userId: store.getState().userReducer.user._id, socketId})
    })

    window.socket.on('connect', () => {
        // console.log("SOCKET: CONNECT")
        window.socket.emit('INTRODUCTION', userId)
        socketId = window.socket.id
        resolve('connected')
    })

    window.socket.on(socketConstants.S_GATHER_ALL, data => 
      store.dispatch((data => ({
        type: socketConstants.S_GATHER_ALL,
        data }))(data))
    )

    window.socket.on(socketConstants.S_CHANGES_UNREAD, data => 
      store.dispatch((data => ({
        type: socketConstants.S_CHANGES_UNREAD,
        data }))(data))
    )
  
    window.socket.on(socketConstants.S_UNIT_CHANGE, data => 
        changeUnit(data)
    )
  
    window.socket.on(socketConstants.S_UNIT_DELETED, data => 
        deleteUnit(data)
    )
  
    window.socket.on(socketConstants.S_UNIT_ADDED, data => 
        addUnit(data)
    )

      //window.socket.on('reconnecting', () => {
    //    console.log('RECONNECTING ...');
    //    if (!window.navigator.onLine) {
    //        console.log('No Internet connection')
    //    }
    //});

    window.socket.on(socketConstants.STOP_PUBLICATION_TIMER, data => {
        let sendFrame = store.getState().paragraphReducer.frameCallback;
        if (sendFrame) sendFrame(data);
        if (data.isPrivate) store.dispatch(updateParagraphListIO(null, data));
        else store.dispatch(updateParagraphListIO(data));
    });

    window.socket.on(socketConstants.START_EDITING_PARAGRAPH, data => {
        let sendFrame = store.getState().paragraphReducer.frameCallback;
        if (sendFrame) sendFrame(data);
    });
  
    window.socket.on(socketConstants.UPDATE_TEMPLATE, data => {
      let sendFrame = store.getState().paragraphReducer.frameCallback,
        me = store.getState().userReducer.user._id, list;
      let  {set, changeDoc, userId} = data;
      if (userId === me) return;
      if (set && set.variable) {
         try {
            list = JSON.parse(set.variable);
         } catch (_) {return;}
         store.dispatch(setVarList(list));
         if (changeDoc)  sendFrame({c1:'variable', action: 'list', varlist: list});
      }
    });

    window.socket.on(socketConstants.UPDATE_EDITING_PARAGRAPH, data => {
        let sendFrame = store.getState().paragraphReducer.frameCallback;
        if (sendFrame) sendFrame(data);
        if (!data.action)
            store.dispatch(updateParagraphListIO(null, null,
                `{"time": ${data.when}, "userId": "${data.who}", "id": "${data.id}"}`, data));

        if (data.mark) return // quote comment
        if (data.action === 1) return // accept/undo accept changes

        const document = store.getState().document;
        if (!document.approved || !document.approved.length) return;
        const delApproved = [...store.getState().document.approved, ...store.getState().document.actualDocument.delApproved]
        store.dispatch(chgApprovals({approved: [], delApproved}));
        let approverUsers = store.getState().userReducer.approverUserList.slice();
        approverUsers = approverUsers.map(el => {
            if (!delApproved.find(userId => userId === el._id)) return el

            el.approveEvents.push({
                subtype: eventSubtypes.DISAPPROVE_DOCUMENT,
                when: Date.now(),
                user: el._id,
                project: store.getState().document.actualDocument.projectId,
                document: store.getState().document.actualDocument.coreDocument,
                attributes: {whoDueChanges: data.who},
            })
            return el
        })
        store.dispatch(updateApproverList(approverUsers))
    });


window.socket.on(socketConstants.NEW_DOCUMENT_SUBVERSION, data => {
  let uid = store.getState().userReducer.user._id, subversion = data.subVersion;
  if ( uid !== data.editor && uid !== data.editor?._id) {
    store.dispatch(getPopUpAction({
      name: 'confirm',
      text: `New Document ${ subversion ? 'Subversion' : 'Version'}`,
      confirm: {
        name: transS('Got it'),
        event: () => {
          store.dispatch(addNewSubVersionSocket(data));
        }
      }
    }));
  } else if (!subversion) store.dispatch(addNewSubVersionSocket(data));
});

    window.socket.on(socketConstants.NEW_DOCUMENT_VERSION, data => {
        let document = data.document || data, 
          isDoc = !document.contentType, shared = document.shared,
          user = store.getState().userReducer.user, isInternal = user.userGroup == document.editorsGroup;
        if (user._id !== document.editor) {
            store.dispatch(getPopUpAction({
                name: 'confirm',
                text: isDoc ?  transS('New Document Version') :
                    shared ?  transS('Shared with the counterparty')
                      :  transS('Sharing revoked'),
                coverMessage: {
                    message: data.coverMessage
                },
                confirm: {
                    name: transS('Got it'),
                    event: () => store.dispatch(getPopUpAction(popUpReset))
                }
            }));
            if (isDoc)
                store.dispatch(addNewVersionSocket(document))            
        }
        if (!isDoc) {
                let actual = store.getState().document.actualDocument;
                if (isInternal)
                    store.dispatch(setActualDocumentAction({...actual, shared: shared, createdAt: new Date().toISOString()}));
                else 
                    store.dispatch(push(`/#/${actual.projectId}`));
            }
    });

    window.socket.on(socketConstants.ENVELOPE_CHANGED, data => {
      store.dispatch(setDocumentEnvelope(data));
    });

    window.socket.on(socketConstants.GET_DOCUMENT_BY_FINALIZE, data => {
        let group = store.getState().userReducer.user.userGroup,
            userId = store.getState().userReducer.user._id,
            approvals = store.getState().userReducer.approverUserList,
            document = data.document,
            isDirectedToFinalize = group ? document.status === 9 : document.status === 10,
            isSendToFinalize = group ? document.status === 10 : document.status === 9,
            isRequestCancellingFinalize = document.status === 13 || document.status === 14,
            isRefuseFinalize = document.status === 11,
            isFinalizeSuccess =  document.status === 12;
        const popup = {
            directedToFinalize: {
                name: 'confirm',
                header: transL('reqFinHdr'),
                text: transL('reqFinTxt'),
                coverMessage: {
                    positionTop: true,
                    message: data.coverMessage
                },
                closable: true,
                cancel: {
                    name: transS('Review First'),
                    mod: 'blue arch',
                    event: () => store.dispatch(getPopUpAction(popUpReset))
                },
                confirm: {
                    name: transS('Finalise')+'!',
                    mod: 'green arch',
                    event: () => {
                        store.dispatch(transferDocumentEditingAction(document.coreDocument, false, false, true))
                    }
                },
            },
            confirm: {
                name: transS('Finalise')+'!',
                mod: 'green arch',
                event: () => {
                    store.dispatch(transferDocumentEditingAction(data.coreDocument, false, false, true))
                }
            },
            directedToFinalizeWithApprovals: {
                name: 'confirm',
                header: transL('reqFinHdr'),
                ads: 'approve',
                closable: true,
                text: `${transL('reqFinTxt')}
                <div class='h3' style="margin-top: 50px;margin-bottom: 20px">${transS('Approvals Needed')} 
                        (${ (approvals) ? ` ${approvals.length}/${approvals.length}` : ''})</div> 
                        ${transS('Before you can send you need the document to be approved by:')}`,
                coverMessage: {
                    message: data.coverMessage
                },
                cancel: {
                    name: transS('Got it'),
                    event: () => store.dispatch(getPopUpAction(popUpReset))
                }
            },
            finalizedByEveryone: {
                name: 'confirm',
                header: `<div style="font-size: var(--fontSize_h1); font-weight: bold; color: #00ab26; display: inline-block">
                ${transS('The Document <br> is finalised by <br> everyone!')}
                <img src="../../images/approve.png" alt="" style="margin-left: 10px" />
            </div>`,
                text: `${transS('Now it really is final!')}<br><br>`,
                closable: true,
                confirm: {
                    name: transS('Export in .docx'),
                    mod: 'green arch',
                    event: () => {
                        api.downloadDocument(document._id, document.projectId, document.version + 1, document.subVersion, 'docx', false)
                            .then(res => {
                                const url = res.data;
                                const ifr = window.document.createElement('iframe');
                                window.document.body.appendChild(ifr)
                                ifr.src = url;
                                setTimeout(() => window.document.body.removeChild(ifr), 10000)
                                store.dispatch(getPopUpAction(popUpReset))
                            })
                    }
                },
                cancel: {
                    name: transS('Perfect'),
                    mod: 'green arch',
                    event: () => store.dispatch(getPopUpAction(popUpReset))
                },
            },
            finalizedByOneSide: {
                name: 'confirm',
                closable: true,
                header: `<div style="font-size: var(--fontSize_h1); font-weight: bold; color: #00ab26; display: inline-block">
                            ${transS('The Document <br> is finalised on <br> your side!')}
                            <img src="../../images/approve.png" alt="" style="margin-left: 10px" />
                            </div>`,
                question: '',
                text: transL('We sent'),
                cancel: {
                    name: transS('Good'),
                    event: () => store.dispatch(getPopUpAction(popUpReset))
                },
            },
            requestCancellingFinalVersion: {
                name: 'confirm',
                closable: true,
                question: '',
                text: transS('Another team sent a request to cancel Final Version.'),
                coverMessage: {
                    message: data.coverMessage
                },
                cancel: {
                    name: transS('Ok'),
                    event: () => store.dispatch(getPopUpAction(popUpReset))
                },
            }
        }

        if (isDirectedToFinalize) {
            (approvals.length > 0 && !group) ? store.dispatch(getPopUpAction(popup.directedToFinalizeWithApprovals)) : store.dispatch(getPopUpAction(popup.directedToFinalize))
        } else if (isRefuseFinalize) {
            store.dispatch(addNewVersionSocket(document))
        } else if (document.isCancelRequest) {
            store.dispatch(addNewVersionSocket(document))
        } else if (isFinalizeSuccess) {
            store.dispatch(getPopUpAction(popup.finalizedByEveryone))
        } else if (isRequestCancellingFinalize) {
            (document.status === 13 && !group || document.status === 14 && group) ?
                store.dispatch(getPopUpAction(popup.requestCancellingFinalVersion)) : store.dispatch(addNewVersionSocket(document))
        } else if (userId === document.editor && isSendToFinalize) {
            store.dispatch(getPopUpAction(popup.finalizedByOneSide))
        } else {
            store.dispatch(addNewVersionSocket(document))
        }
        store.dispatch(addNewVersionSocket(document))
    });

    window.socket.on(socketConstants.GET_DOCUMENT_BY_REQUEST, data => {
        let actual = store.getState().document.actualDocument,
            group = store.getState().userReducer.user.userGroup,
            isCont = actual && actual.editorsGroup !== group,
            askRequest = isCont ? data.document.editorsContRequest: data.document.editorsRequest;
        if (actual && !actual.displayVersion && actual.coreDocument === data.document.coreDocument
            && askRequest !== 0 ) {
            let approverUserList = store.getState().userReducer.approverUserList,
                approved = store.getState().document.approved,
                isExternal = group;

            if (!isExternal && approved && approverUserList && approverUserList.length
                && approved.length < approverUserList.length && actual.orig != 9 )
                store.dispatch(getPopUpAction({
                    name: 'confirm',
                    text: transL('cowReq') + transL('cowApprove'),
                    coverMessage: {
                        message: data.coverMessage
                    },
                    confirm: {
                        name: transS('Got it'),
                        event: () => {
                            if (isCont)
                                store.dispatch(setActualDocumentAction({...actual, editorsContRequest: Date.now()}));
                            else
                                store.dispatch(setActualDocumentAction({...actual, editorsRequest: Date.now()}));
                            store.dispatch(getPopUpAction(popUpReset));
                        }
                    }
                }));
            else
                store.dispatch(getPopUpAction({
                    name: 'confirm',
                    text: transL('cowReq') + transL('cowWhen'),
                    coverMessage: {
                        message: data.coverMessage
                    },
                    question: transS('Do you want to grant the right to edit?'),
                    confirm: {
                        name: transS('Confirm'),
                        event: () => {
                            store.dispatch(getDocumentByRequestSocket(data.document, true));
                        }
                    },
                    cancel: {
                        name: transS('Cancel'),
                        event: () => {
                            if (isCont)
                                store.dispatch(setActualDocumentAction({...actual, editorsContRequest: Date.now()}));
                            else
                                store.dispatch(setActualDocumentAction({...actual, editorsRequest: Date.now()}));
                            store.dispatch(getPopUpAction(popUpReset));
                        }
                    }
                }));
        } else {
            store.dispatch(getPopUpAction(popUpReset));
        }
    });

    window.socket.on(socketConstants.GET_DOCUMENT_BY_FORCE, data => {
        if (data.document.orig === 9 || data.document.orig === 10) {
            store.dispatch(getPopUpAction({
                name: 'confirm',
                header: 'The document is now being <br> edited by the other Team',
                text: `The other Team cancelled finalisation of the document. Now they are editing it.<br>`,
                coverMessage: {
                    message: data.coverMessage
                },
                confirm: {
                    name: transS('Confirm'),
                    event: () => store.dispatch(getPopUpAction(popUpReset()))
                }
            }))
        } else {
            store.dispatch(getPopUpAction({
                name: 'confirm',
                header: 'The document is now being <br> edited by the other Team',
                text: `The other Team withdrew the document for editing.<br>The changes and External Comments that you made but haven't sent, are saved as a separate version. The other Team doesn't see them.`,
                coverMessage: {
                    message: data.coverMessage
                },
                confirm: {
                    name: transS('Confirm'),
                    event: () => store.dispatch(getPopUpAction(popUpReset()))
                }
            }));
        }
        store.dispatch(getDocumentByForceSocket(data.document))
    });

    window.socket.on(socketConstants.PROJECT_DELETED, data => {
        // store.dispatch(getPopUpAction({
        //     name: 'confirm',
        //     text: 'The project was deleted!',
        //     confirm: {
        //         name: 'Confirm',
        //         event: () => {
        //             store.dispatch(projectDeletedAction());
        //         }
        //     }
        //
        // }));
    });

    window.socket.on(socketConstants.APPROVE_DOCUMENT, data => {
        store.dispatch(chgApprovals(data));

        let approverUsers = store.getState().userReducer.approverUserList.slice();
        approverUsers = approverUsers.map(el => {
            if (el._id !== data.who) return el

            const isApprove = !!data.approved.find(el => el === data.who)
            el.approveEvents.push({
                subtype:isApprove ? eventSubtypes.APPROVE_DOCUMENT : eventSubtypes.DISAPPROVE_DOCUMENT,
                when: Date.now(),
                user: el._id,
                project: store.getState().document.actualDocument.projectId,
                document: store.getState().document.actualDocument.coreDocument,
                attributes: null
            })
            return el
        })
        store.dispatch(updateApproverList(approverUsers))
    });

    window.socket.on(socketConstants.PROJECT_NAME_UPDATE, data => {
        store.dispatch({
            type: projectConstants.RENAME_PROJECT,
            projectId: data.projectId,
            newName: data.newName
        });
        let state = store.getState();
        let list = state.projectReducer.projectList;
        let newCurrentProject = null;
        list.forEach(item => {
            if (item._id === data.projectId) {
                newCurrentProject = Object.assign({}, item);
            }
        });

        const prevCurrentProject = store.getState().projectReducer.currentProject
        if (prevCurrentProject._id !== data.projectId && !!data.projectId) {
            store.dispatch(saveStats({type: eventTypes.PROJECT, subtype: eventSubtypes.OPEN_PROJECT, project: data.projectId, when: Date.now()}))
        }

        /**
         * Updating current project wjth a new name
         */
        store.dispatch({
            type: projectConstants.SET_CURRENT_PROJECT,
            currentProject: newCurrentProject
        });
    });

    window.socket.on(socketConstants.UPDATE_CLAUSE, data => {
      store.dispatch({
        type: userConstants.CHANGE_CLAUSE,
        clause: data.clause
      })
    });

    window.socket.on(socketConstants.USER_EXPELLED, data => {
        let me = store.getState().userReducer.user;
        if (data.expelledUsers.indexOf(me._id) !== -1) {
            pubSub.notifySubscribers(socketConstants.USER_EXPELLED);
            setTimeout(function() {
                store.dispatch(getPopUpAction({
                    name: 'confirm',
                    text: 'You\'ve been removed from the project.',
                    confirm: {
                        name: transS('Confirm'),
                        event: () => {
                            store.dispatch(userExpelledAction());
                        }
                    }
                }));
            }, 5000);
        }
    });

    window.socket.on(socketConstants.USER_TRANSFERRED_TO_COUNTER_PARTY, data => {
        let uReducer = store.getState().userReducer;
        let me = uReducer.user;
        if (data.transferredUsers.indexOf(me._id) !== -1) {
            setTimeout(function() {
                store.dispatch(getPopUpAction({
                    name: 'confirm',
                    text: 'You\'ve been transferred to the Counterparty',
                    confirm: {
                        name: transS('Confirm'),
                        event: () => {
                            store.dispatch(() => {
                                getPopUpAction(popUpReset());
                                window.location.reload();
                            });
                        }
                    }
                }));
            }, 1500);
        } else {
            let doc = store.getState().document;
            if (doc && doc.actualDocument && doc.actualDocument._id && !doc.displayOnly) {
                let i = -1;
                let changed = false;
                let achanged = false;
                let newlist = doc.approved.slice();
                let externalUsers = uReducer.externalUserList.slice();
                let internalUsers = uReducer.internalUserList.slice();
                let approverUsers = uReducer.approverUserList.slice();
                /* input is data.transfer[[ids],[ids]], data.clear[[ids],[ids]], data.existingUsers[[users],[users]] */
                /* me.userGroup = 0 => internals - is my internals else internals is my externals */
                data.transfer[1-me.userGroup].forEach( usr => {
                    i = internalUsers.findIndex((item)=>{return item._id == usr});
                    if (i != -1) {
                        externalUsers.push(internalUsers[i]);
                        internalUsers.splice(i, 1);
                        changed = true;
                    }
                    i = approverUsers.findIndex((item)=>{return item._id == usr});
                    if (i != -1) {
                        approverUsers.splice(i, 1);
                        changed = true;
                        i = newlist.indexOf(usr);
                        if (i != -1) {
                            newlist.splice(i, 1);
                            achanged = true;
                        }
                    }
                });

                data.transfer[me.userGroup].forEach( usr => {
                    i = externalUsers.findIndex((item)=>{return item._id == usr});
                    if (i != -1) {
                        internalUsers.push(externalUsers[i]);
                        if (hasPermissionApprove() || externalUsers[i].ticks > 0) approverUsers.push(externalUsers[i]);
                        externalUsers.splice(i, 1);
                        changed = true;
                    }
                });

                data.clear[me.userGroup].forEach( usr => {
                    i = internalUsers.findIndex((item)=>{return item._id == usr});
                    if (i != -1) {
                        internalUsers.splice(i, 1);
                        changed = true;
                    }
                    i = approverUsers.findIndex((item)=>{return item._id == usr});
                    if (i != -1) {
                        approverUsers.splice(i, 1);
                        changed = true;
                        i = newlist.indexOf(usr);
                        if (i != -1) {
                            newlist.splice(i, 1);
                            achanged = true;
                        }
                    }
                });

                data.clear[1-me.userGroup].forEach( usr => {
                    i = externalUsers.findIndex((item)=>{return item._id == usr});
                    if (i != -1) {
                        externalUsers.splice(i, 1);
                        changed = true;
                    }
                });

                data.existingUsers[me.userGroup].forEach( usr => {
                    internalUsers.push(usr);
                    changed = true;
                    if (hasPermissionApprove() || usr.ticks > 0) approverUsers.push(usr);
                });

                data.existingUsers[1-me.userGroup].forEach( usr => {
                    externalUsers.push(usr);
                    changed = true;
                });

                if (data.proved) {
                    let newApprover = [];
                    let provingChanged = false;
                    approverUsers.forEach(item => {
                        if ( data.proved.includes(item._id) ) newApprover.push(item);
                        else {
                            provingChanged = true;
                            i = newlist.indexOf(item._id);
                            if (i != -1) {
                                newlist.splice(i, 1);
                                achanged = true;
                            }
                        }
                    });
                    data.proved.forEach(usr => {
                        i = newApprover.findIndex((item)=>{return item._id == usr});
                        if (i == -1) {
                            i = internalUsers.findIndex((item)=>{return item._id == usr});
                            provingChanged = true;
                            if ( (i != -1) && (hasPermissionApprove() || internalUsers[i].ticks > 0) )
                                newApprover.push(internalUsers[i]);
                        }
                    });
                    if (provingChanged) {
                        changed = true;
                        approverUsers = newApprover;
                    }
                }
                if (achanged) store.dispatch(chgApprovals({approved: newlist, delApproved: doc.actualDocument.delApproved}));
                if (changed) store.dispatch(getExternalUserList(externalUsers, internalUsers, approverUsers));
            }
        }
    });

    window.socket.on(socketConstants.UPDATE_APPROVE_MESSAGE, data => {
        const proj = store.getState().projectReducer.projectList.find(p => p._id === data.projectId)
        const messages = cloneDeep(proj.messages)
        const currentMessage = messages.find(el => el._id === data.message._id) || data.message
        currentMessage.content = data.message.content
        currentMessage.edit = data.message.edit
        currentMessage.delete = data.message.delete
        currentMessage.resolve = data.message.resolve
        currentMessage.readers = data.message.readers
        currentMessage.title = data.message.title
        if (currentMessage.freeze && !data.message.freeze) delete currentMessage.freeze

        const userId = store.getState().userReducer.user._id

        const isAuthor = currentMessage.user._id === userId || currentMessage.user === userId
        const isRecipient = !currentMessage.recipients || !currentMessage.recipients.length || currentMessage.recipients.find(el => el._id === userId)
        const isCorrectPerm = currentMessage.group === proj.group ? true : (currentMessage.isPublic && !currentMessage.freeze)
        if ( (isAuthor || isRecipient) && isCorrectPerm ) {
            store.dispatch(setMessage(currentMessage))
        }

        if (currentMessage.type === 1 && currentMessage.recipients && currentMessage.recipients.length) {
            let approverUsers = store.getState().userReducer.approverUserList.slice()

            approverUsers = approverUsers.map(el => {
                if (currentMessage.recipients.find(r => r._id === el._id)) {
                    el.approveEvents.push({
                        action: 'request',
                        when: currentMessage.when,
                        user: currentMessage.user
                    })
                }
                return el
            })
            store.dispatch(updateApproverList(approverUsers))
        }
        store.dispatch(calculateUnreadTasksAction())
    })

    window.socket.on(socketConstants.UPDATE_REPLY_APPROVE_MESSAGE, data => {
        const proj = store.getState().projectReducer.projectList.find(p => p._id === data.projectId)
        const messages = cloneDeep(proj.messages)
        const currentMessage = messages.find(el => el._id === data.messageId)
        currentMessage.replies = currentMessage.replies || []
        const currentReply = currentMessage.replies.find(el => el.when === data.reply.when) || {...data.reply}
        currentReply.content = data.reply.content
        currentReply.edit = data.reply.edit
        currentReply.delete = data.reply.delete
        currentReply.readers = data.reply.readers
        if (currentReply.freeze && !data.reply.freeze) delete currentReply.freeze
        currentMessage.replies = currentMessage.replies.filter(el => data.reply.when !== el.when)
        currentMessage.replies.push(currentReply)
        currentMessage.replies.sort((a,b) => a.when > b.when ? 1 : -1)

        const userId = store.getState().userReducer.user._id

        const isAuthor = currentMessage.user._id === userId || currentMessage.user === userId
        const isRecipient = !currentMessage.recipients || !currentMessage.recipients.length || currentMessage.recipients.find(el => el._id === userId)
        const isCorrectPerm = currentMessage.group === proj.group
            ? (!currentReply.freeze || currentReply.group === proj.group)
            : (currentMessage.isPublic && !currentMessage.freeze && (!currentReply.freeze || currentReply.group === proj.group))
        if ( (isAuthor || isRecipient) && isCorrectPerm ) {
            store.dispatch(setMessage(currentMessage))
        }
        store.dispatch(calculateUnreadTasksAction())
    })

    window.socket.on(socketConstants.UPDATE_DOCUMENT_MESSAGES, data => {
        const userId = store.getState().userReducer.user._id

        data.messages.filter(message => {
            const proj = store.getState().projectReducer.projectList.find(p => p._id === message.project)
            const isAuthor = message.user._id === userId || message.user === userId
            const isRecipient = !message.recipients || !message.recipients.length || message.recipients.find(el => el._id === userId)
            const isCorrectPerm = message.group === proj.group ? true : (message.isPublic && !message.freeze)
            if (message.replies) {
                message.replies = message.replies.filter(reply => message.group === proj.group
                    ? (!reply.freeze || reply.group === proj.group)
                    : (message.isPublic && !message.freeze && (!reply.freeze || reply.group === proj.group))
                )
            }
            return (isAuthor || isRecipient) && isCorrectPerm
        }).forEach(el => {
            store.dispatch(setMessage(el))
        })
    })

    window.socket.on(socketConstants.WRITING_COVER_MESSAGE, data => {
        const {action, projectId, documentId, userId} = data
        const actualDocument = store.getState().document.actualDocument

        if (actualDocument._id !== documentId) return

        if (action) {
            store.dispatch(setWriterCoverMessageAction(userId))
        } else {
            store.dispatch(clearWriterCoverMessageAction())
        }
    })

    window.socket.on(socketConstants.GET_UNKNOWNS, data => 
        store.dispatch(updateUnknown(data)))

    window.socket.on(socketConstants.UPDATE_USER_INTERFACE_DATA, data => {
        const actualDocument = store.getState().document.actualDocument
        if (data.commentDrafts) {
            localStorage.setItem('commentDrafts', data.commentDrafts)
        }
        if (data.paragraphStateMenu) {
            localStorage.setItem('paragraphStateMenu', data.paragraphStateMenu)
            const docStateMenu = JSON.parse(data.paragraphStateMenu)[actualDocument.coreDocument]
            if (docStateMenu && !window.processSaveUID) {
                store.dispatch(setMenuAction(docStateMenu))
            }
            window.processSaveUID = undefined
        }
    })

    window.socket.on(socketConstants.DOCUMENT_CHANGE, data => {
        store.dispatch(documentChangedAction(data));        
    })

    window.socket.on(socketConstants.UPDATE_PROJECT, data => {
        const proj = data.project
        store.dispatch({
            type: projectConstants.UPDATE_PROJECT,
            projectId: proj._id,
            newName: proj.title,
            admins: proj.admins,
            signee: proj.signee
        })
    })
})

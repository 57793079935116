import React, {Component} from 'react';
import {connect} from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './approveInfo.css';
import UserLogo from '../../../../components/userLogo/UserLogo';
import Button from "../../../../components/button/Button"
import { transS } from '../../../../services/helpers/lang';

@connect(state => ({
    externalUserList: state.userReducer.externalUserList,
}), {})
@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
export default class RecipientsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        }
    }

    makeUserList = () => {
        const {externalUserList} = this.props;

        return externalUserList
            .filter((u, i) => !!(this.state.isOpen || i < 2))
            .map(u => {
                return <div styleName='right' key={'rec' + u._id}>
                    <UserLogo mod='_user-list' firstname={u.firstname} lastname={u.lastname} avatar={u.avatar}/>
                    <div styleName='user-data'>
                        <div styleName='user-name'>{u.firstname} {u.lastname}</div>
                        <div>{u.email}</div>
                    </div>
                </div>
            })
    }

    render() {
        const {externalUserList, title} = this.props;
        const {isOpen} = this.state

        return (
            <div styleName='progress'>
                <div styleName='title'>{title ? title : transS('Who is going to receive')}</div>
                {this.makeUserList()}
                {externalUserList.length > 2 &&
                <Button text={isOpen ? transS('Hide') : transS('Show Everyone')} mod='blue'
                        callback={() => this.setState({isOpen: !isOpen})}/>}
            </div>
        )
    }
}

import React from "react"
import CSSModules from 'react-css-modules'
import styles from './SendPopup.css'
import {connect} from "react-redux"
import {getPopUpAction} from "../../services/actions/dataActions"
import Button from "../button/Button"
import cx from 'classnames'
import RecipientsList from "../../views/privateArea/components/approveInfo/RecipientsList"
import ApproversList from "../../views/privateArea/components/approveInfo/ApproversList"
import {isEqual} from 'lodash'
import api from '../../services/api/api'
import Comment from '../comment/Comment'
import UserLogo from '../userLogo/UserLogo'
import { getLanguage, transF, transS } from "../../services/helpers/lang"

@connect(state => ({
    popUp: state.dataReducer.popUp,
    approverUserList: state.userReducer.approverUserList,
    approved: state.document.approved,
    user: state.userReducer.user,
    admins: state.projectReducer.currentProject.admins,
    actualDocument: state.document.actualDocument,
    messages: state.projectReducer.currentProject.messages,
    externalUserList: state.userReducer.externalUserList,
    internalUserList: state.userReducer.internalUserList,
}), {
    getPopUpAction
})
@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
export default class SendPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alignTop: 0,
            coverMessage: null,

        }
    }

    componentDidMount() {
        const {actualDocument, messages} = this.props
        document.body.style.overflowY = 'hidden'

        if (this.props.popUp.coverMessage && this.props.popUp.coverMessage.event) {
            const coverMessage = messages
                .filter(m => m.document === actualDocument.coreDocument)
                .filter(m => m.when > Date.parse(actualDocument.createdAt))
                .filter(m => m.type === 3)
                .filter(m => m.event === this.props.popUp.coverMessage.event)
                .filter(m => !m.delete)
                .filter(m => !!m.freeze)
                .filter((m, _, arr) => !arr.find(i => i._id !== m._id && i.when > m.when))[0]
            this.setState({coverMessage})
        }
        if (this.refPopup && !this.state.alignTop) {
            const alignTop = (document.documentElement.clientHeight - this.refPopup.offsetHeight) / 2
            this.setState({alignTop: alignTop <= 40 ? 40 : alignTop})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {actualDocument, messages} = this.props
        if (messages && this.props.popUp.coverMessage && this.props.popUp.coverMessage.event) {
            const coverMessage = this.props.messages
                .filter(m => m.document === actualDocument.coreDocument)
                .filter(m => m.when > Date.parse(actualDocument.createdAt))
                .filter(m => m.type === 3)
                .filter(m => m.event === this.props.popUp.coverMessage.event)
                .filter(m => !m.delete)
                .filter(m => !!m.freeze)
                .filter((m, _, arr) => !arr.find(i => i._id !== m._id && i.when > m.when))[0]
            if (!isEqual(this.state.coverMessage, coverMessage)) {
                this.setState({coverMessage})
            }
        } else if (this.state.coverMessage && this.state.coverMessage.length) {
            this.setState({coverMessage: null})
        }

        if (this.refPopup && !this.state.alignTop) {
            const alignTop = (document.documentElement.clientHeight - this.refPopup.offsetHeight) / 2
            this.setState({alignTop: alignTop <= 40 ? 40 : alignTop})
        }
    }

    componentWillUnmount() {
        document.body.style.overflowY = 'auto'
    }

    messagesFunc = (action, params) => {
        const {actualDocument, user, coverMessage} = this.props
        switch (action) {
            case 'addMessage':
                api.addMessage(3, actualDocument.projectId, params.content, actualDocument.coreDocument, [], user.userGroup, true, coverMessage.event)
                break
            case 'editMessage':
                api.editMessage(params.messageId, params.content, params.reply)
                break
            case 'deleteMessage':
                api.deleteMessage(params.messageId, params.reply)
                break
            case 'resolveMessage':
                api.resolveMessage(params.messageId, params.value)
                break
            case 'unreadCheck':
                api.unreadCheckMessage(params.messageId, params.reply)
                break
            case 'changeTitle':
                api.changeTitle(params.messageId, params.title)
                break
        }
    }

    render() {
        const {alignTop} = this.state
        const {popUp: {header, question, text, confirm, cancel, close, swap, coverMessage, lockRecepients}, approverUserList, 
            actualDocument, approved, user, externalUserList, internalUserList, admins} = this.props;

        const changesIds = []
        const isDoc = !actualDocument.contentType;
        const isRus = !getLanguage() ? ' rus' : ''
        const isShared = actualDocument.shared;
        
        const changes = !isDoc ? [] : Array.from(window.frames['iframe'].document.body.querySelectorAll('mod.new0:not(.accepted):not(.declined)'))
            .filter(e => {
                if (e.dataset.color && !e.dataset.chgd) return false // стили игнорируются
                if (e.dataset.h) {
                    const data = e.dataset.h.split(' ')[0].split('-')
                    const userId = data[data.length - 1]
                    if (userId === user._id) return false // свои правки игнорируются
                }
                if (changesIds.find(el => el === e.dataset.h)) return false // одинаковые не задваиваются

                changesIds.push(e.dataset.h)
                return true
            })
        const changesCount = changesIds.length

        const notApprovedUsersCount = !user.userGroup && 
            approverUserList.filter(u => !approved.find(id => id === u._id)).length,
            isProjectAdmin = !!admins.find(el => el === user._id),
            canSend = !notApprovedUsersCount || !isDoc || isProjectAdmin

        const coverMessageBlock = !!coverMessage && (coverMessage.editable ?
            (this.state.coverMessage &&
                <div styleName='coverMessage'>
                    <Comment {...this.state.coverMessage} externalUserList={externalUserList} internalUserList={internalUserList}
                             isProjectAdmin={isProjectAdmin} me={user}
                             recipients={this.state.coverMessage.recipients.length ? this.state.coverMessage.recipients : null}
                             isNoResolve={true} isNoSendNow={true} isEditCreator={true} isNoReply={true} func={this.messagesFunc} />
                </div>)
            : '')

        return (
            <div styleName='wrapper'>
                <div styleName='popup_wrapper' ref={e => this.refPopup = e} style={{top: alignTop}}>
                    <div styleName='popup'>
                    <span className='close-cross' onClick={() => {
                        if (close) close.event()
                        else if (cancel) cancel.event()
                        else confirm.event()
                    }}>+</span>

                        <div styleName='header'>{header ? header : transS('Sending Document')}</div>
                        {text && <div styleName='text'>{text}</div>}

                        {!!notApprovedUsersCount && isDoc && <div>
                            <div className='h3' style={{lineHeight: 'normal', marginBottom: '16px'}}>{transS('Approvals Needed')}</div>
                            <div style={{marginBottom: '16px'}}>{transS('Before you can send you need the document to be approved by:')}</div>
                            <ApproversList />
                        </div>}

                        {!!changesCount && <div style={{marginBottom: '20px'}}>
                            <div className='h3' style={{lineHeight: 'normal', marginBottom: '16px'}}>{transS('Changes without attention')}</div>
                            <div>{transF('changesCount')(changesCount)}</div>
                        </div>}

                        {!lockRecepients && <RecipientsList title={isDoc ? null : transS('Who is going to have access')} />}

                        {coverMessageBlock}

                        {question && <div styleName='question'>{question}</div>}

                        <div styleName={cx('btn-row', {'_swap': swap})}>
                            {cancel &&
                            <Button text={cancel.name} mod={'big margin0' + (cancel.mod ? ` ${cancel.mod}` : ' blue arch')}
                                    callback={cancel.event}/>}
                            {confirm && canSend && <Button text={confirm.name}
                                                mod={(confirm.danger ? 'red ' : '') + 'big margin0' + (confirm.mod ? ` ${confirm.mod + isRus}` : ' blue fill')}
                                                callback={confirm.event}/>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
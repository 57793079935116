import React from 'react';
import { ButtonStd } from '../../../../components/button/Buttons';
import { colors } from '../../../../components/colors';
import { transS } from '../../../../services/helpers/lang';

export const SelectParams = ({unit, action}) => {

  const styleCont = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 20,    
  }
  
  return <div style={styleCont}>
    <div className='h2'>{transS('Parameters')}</div>
    <div>
      <ButtonStd 
        text={transS('Add Parameter')}
        style = {{          
          backgroundColor: colors.orange,
          borderColor: colors.orange,
        }}
        action={()=>null}
      />
    </div>    
  </div>
}
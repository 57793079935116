import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CSSModules from 'react-css-modules';
import styles from './radio.css';

const Radio = props => {
  let { text0, text2 = '', text, mod = '', radioBtn, name, callback = () => {} } = props; 

  return (
    <div styleName={cx('wrapper', { [mod]: mod, '_s': !text2 })} >
      <label styleName={cx('label',  {'current': radioBtn === 0})}>
          <input type='radio' name={name ? name : 'group'} checked={radioBtn === 0} onClick={callback} onChange = {()=>{return;} } value='0' />
          <span>{text0}</span>
      </label>
      {!!text2 &&  <label styleName={cx('label',  {'current': radioBtn === 2})}>
          <input type='radio' name={name ? name : 'group'} checked={radioBtn === 2} onClick={callback} onChange = {()=>{return;} } value='2' />
          <span>{text2}</span>
      </label>}
      
      <label styleName={cx('label',  {'current': radioBtn === 1})}>
          <input type='radio' name={name ? name : 'group'} checked={radioBtn === 1} onClick={callback} onChange = {()=>{return;} } value='1' />
          <span>{text}</span>
      </label>
    </div>
  );
};

Radio.propTypes = {
  text0: PropTypes.string.isRequired,
  text2: PropTypes.string,
  text: PropTypes.string.isRequired,
  radioBtn: PropTypes.number,
  mod: PropTypes.string,
  name: PropTypes.string,
  callback: PropTypes.func
};

export default new CSSModules(Radio, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });

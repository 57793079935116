import React from 'react';
import { useSelector } from 'react-redux';
import { StdList } from '../../../../components/StdList';

export const MoveFolder = ({unit, action}) => {
  const {unitsList} = 
    useSelector(state => state.unitReducer),
    list = [],
    listAdd = id => {
      const u = unitsList.get(id)
      if (!u.externals || u === unit) return
      list.push(u)
      u.folders?.forEach(element => listAdd(element))
    }
  
  listAdd('_externals')  

  return <StdList 
    items={list}
    current={unit.ancestor}
    onSelect={action}
  />
}
import React, { useState, useRef, useEffect } from 'react';
import CSSModules from 'react-css-modules';
import { showContext} from './Units';
import styles from './unitList.css'
import { transS } from '../../../../services/helpers/lang';
import { StdRadio } from '../../../../components/StdRadio';
import { Icon_x } from '../../../../components/svglib/svglib';


const radioStyle = {
  display: 'flex',
  alignItems: 'baseline'
},
linkStyle = {
  position: 'relative',
  left: -20,  
}, 
inputStyle = {
  paddingRight: 10, 
  marginLeft: 20,
},
crossStyle = {
  position: 'relative',
  left: -20,
  top: 3,
  width: 0,
  cursor: 'pointer',
}


const FilterDocs = ({callback}) => {
  const {firstColumnStyle} =  showContext(),
    [finFilter, setFinFilter] = useState(0),
    [approvalFilter, setApprovalFilter] = useState(0),
    inputRef = useRef()
  const filterByFin = [
      transS('All'),
      transS('In Progress'),
      transS('Finalized')
    ], 
    filterByApproval = [
      transS('All'),
      transS('Only Where My Approval Required')
    ]

  useEffect(() => {
    const newApp = finFilter === 2 ? 0 : approvalFilter
    if (finFilter === 2)
      setApprovalFilter(0)
    callback({test: inputRef.current.value, finFilter, approvalFilter: newApp})
  }, [finFilter])

  useEffect(() => {
    const newFin = approvalFilter && finFilter === 2 ? 0 : finFilter
    if (newFin !== finFilter)
      setFinFilter(0)
    callback({test: inputRef.current.value, finFilter: newFin, approvalFilter})
  }, [approvalFilter])

  const handleInput = (toRead) => {
    let test = ''
    if (toRead) 
      test = inputRef.current.value
    else {
      if (!inputRef.current.value)
        return
      inputRef.current.value = test
    }
    callback({test, inputChanged: true, finFilter, approvalFilter})
  }


  const combined = {...firstColumnStyle, ...radioStyle}
  return <div styleName='unit unit-filter'>
    <div styleName='unit-child' 
      style={combined}>
      <span>{transS('Find:')}</span>
      <input id='filterDocsInput' className='dev-std-input'
        style={inputStyle}
        onInput={handleInput}
        ref={inputRef} defaultValue="" />
      <span style={crossStyle}
        onClick={() => handleInput(false)}>
        <Icon_x />
      </span>
    </div>  
    <div styleName='unit-child'>
      <span className='dev-link' style={linkStyle}>
        {transS('Search Fields')}
      </span>
    </div>
    <div styleName='unit-child'>
      <div style={{...radioStyle, gap: 20}}>
        <StdRadio 
          selections={filterByFin} 
          selected={finFilter}
          callback={setFinFilter}
        />        
        <StdRadio 
          selections={filterByApproval} 
          selected={approvalFilter}
          callback={setApprovalFilter}
        />
      </div>
    </div>
  </div>
}

export default CSSModules(FilterDocs, styles, {allowMultiple: true});
import { projectConstants } from '../constants';
import { setCurrentProject } from '../actions/projectActions';
import {cloneDeep} from "lodash"

const initialState = {
  projectList: null,
  currentProject: {},
  invitePopup: {
    show: false,
    projectId: null,
    transfer:null,
  }
};

const findProject = (state, doc) => {
  let p = state.projectList.find(p => p.docs && !!p.docs.find(d => d._id === doc || d.coreDocument === doc));
  if (!p) return null;
  return {...p};
};

const retState = (state, p) => {
  const projectList = state.projectList.map(proj => proj._id === p._id ? p : proj);
  return {
    ...state,
    projectList,
    currentProject: state.currentProject._id === p._id ? p : state.currentProject
  };
};

const folderMoving = (state, id, parentDoc) => {
  let p = findProject(state, id);
  if (!p) return state;  
  let oldDoc = p.docs.find(d => d._id === id || d.coreDocument === id), 
    oldParent = oldDoc.parent, findCore = oldDoc.coreDocument || oldDoc.id;
  id = oldDoc.id;
  p.docs = p.docs.map(item =>
      (item._id === id) ? {...item, parent: parentDoc} : 
           item.parent === findCore ? {...item, parent: oldParent} :item );
  return retState(state,p);
}
  
const modifyDocInProject = (state, id, modification, appDoc, delDoc) => {
  let p = findProject(state, id);
  if (!p) return state;
  if (delDoc) 
      p.docs = p.docs.filter(doc => {
        if (doc.coreDocument === id || doc._id === id) return false;
        if (doc.parent === id) doc.parent = null;
        return true;
      });
  else
    p.docs = p.docs.map(item =>
      (item._id === id || item.coreDocument === id) ? {...item, ...modification} : item );
  if (appDoc) p.docs.push(appDoc);
  return retState(state, p);
};

export default (state = initialState, action) => {
  let p, id;
  switch (action.type) {      
  case "@@redux/INIT":
  case "@@INIT":
    if (!state.currentProject) state.currentProject = {};
    return {
      ...state,
      invitePopup: {
        show: false,
        projectId: null
      }
    };
  case projectConstants.GET_PROJECT_LIST:
    return {
      ...state,
      projectList: action.projectList
    };
  case projectConstants.RENAME_DOCUMENT: 
    return modifyDocInProject(state, action.documentId, {title: action.newName});
    
  case projectConstants.SHARE_DOCUMENT: 
    return modifyDocInProject(state, action.document._id, {shared: action.shared, createdAt: new Date().toISOString()});
    
  case projectConstants.DOC_TO_FOLDER:
    //data = {document, parentDoc, userId, isNew, action: "DOC_TO_FOLDER"} 
    if (action.data.isNew)
      return modifyDocInProject(state, action.data.document._id, 
        {parent: action.data.document.parent},
          action.data.parentDoc);
    return folderMoving(state, action.data.document._id, action.data.parentDoc);
    
  case projectConstants.DELETE_DOCUMENT:
    return modifyDocInProject(state, action.documentId, null, null, true);

  case projectConstants.GET_PROJECT_DOCUMENTS_LIST:
    p = state.currentProject;
    if (p._id) {
        p = action.projectList.find(el => el._id === p._id);
        if (!p) p = {};
    }
    return {
      ...state,
      projectDocList: action.projectDocList,
      projectList: action.projectList,
      currentProject: p
    };
  case projectConstants.SET_CURRENT_PROJECT:
    if (action.projects) return {
      ...state,
      currentProject: action.currentProject,
      projectList: action.projects        
    }
    return {
      ...state,
      currentProject: action.currentProject
    };
  case projectConstants.ADD_PROJECT:
    return {
      ...state,
      projectList: [action.project, ...state.projectList],
      currentProject: action.currentProject
    };

    /**
     * We rename the project in the current projectList to see changes without page reload
     */
  case projectConstants.RENAME_PROJECT:
    return {
      ...state,
      projectList: state.projectList.map(item => item._id === action.projectId
        ? { ...item, title: action.newName }
        : item
      )
    };
    case projectConstants.UPDATE_PROJECT:
      let changedProject;
      return {
        ...state,
        projectList: state.projectList.map(item => item._id === action.projectId
            ? changedProject = { ...item, title: action.newName, admins: action.admins, signee: action.signee }
            : item),
        currentProject: action.projectId === state.currentProject._id
            ? changedProject : state.currentProject
      };
    /**
     * We delete from state the deleted project
     */
  case projectConstants.DELETE_PROJECT:
    return {
      ...state,
      projectList: state.projectList.filter(project => project._id !== action.projectId)
    };

    /**
     * it opens or closes the invite popup depending on action.show value
     */
    case projectConstants.GET_INVITE_PROJECT_POPUP:
    return {
      ...state,
      invitePopup: action.payload
    };
    case projectConstants.SET_MESSAGE:
      const proj = cloneDeep(state.projectList.find(p => p._id === action.message.project))
      proj.messages = proj.messages.filter(m => m._id !==  action.message._id)
      proj.messages.push(action.message)

      return {...state,
        projectList: state.projectList.map(el => el._id === proj._id ? proj : el),
        currentProject: state.currentProject._id === proj._id ? proj : state.currentProject}
  default:
    return state;
  }
};

import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './projectList.css';
import Button from "../../../../components/button/Button"
import { transF, transS } from '../../../../services/helpers/lang';
import api from '../../../../services/api/api';

const SearchFields = ({clear, result, check}) => {
    const {formatting, previousColor, frameProgress} = useSelector(state => state.paragraphReducer)
    const [open, setOpen] = useState(true),
      [showResults, setShowResults] = useState(''),
      [noResults, setNoResults] = useState(''),
      [isDisabled, setIsDisabled] = useState(' disabled'),
      dispatch = useDispatch(),
      docName = useRef(null),
      partyName = useRef(null)  ,   // not used
      projectName = useRef(null)  ,
      contentRef = useRef(null)  ,
      contactRef = useRef(null),    // not used
      dateRef = useRef(null),       // not used
      expiryRef = useRef(null)      // not used

    const showing = (sett, score) => 
      (transF('ShowSearch')(sett.size, score))

    const searchIt = async () => {
      setNoResults(transS('...searching'))
      const preCheck = check(docName.current.value, projectName.current.value)
      const notFound = () => {
        setShowResults(transS('No documents found.'))
        setNoResults('')
        result(check('',''))
      }
      const intersect = a => {
        if (!a.length) return null
        return new Set(a.filter(x => preCheck.has(x)))
      }

      if (!preCheck.size) return notFound()
      
      if (!contentRef.current.value) {
        setNoResults('')
        setShowResults(showing(preCheck))
        result(preCheck)        
        return
      }
      const res = await api.fts(contentRef.current.value, [...preCheck])
      if (!res || res.status !== 200) return notFound();
      const {found, score} = res.data.data
      if (!found || !found.length) return notFound()
      const total = new Set(found)
      setShowResults(showing(total, score))
      setNoResults('')
      result(total)
    }

    const clearSimple = () => {
      docName.current.value = ''
      partyName.current.value = ''
      projectName.current.value = ''
      contentRef.current.value = ''
      contactRef.current.value = ''
      dateRef.current.value = ''
      expiryRef.current.value = ''
      setShowResults('')
      setNoResults('')
      setIsDisabled(' disabled')
      result(check('',''))
    }

    const checkDisabled = () => setIsDisabled((docName.current?.value ||
      partyName.current?.value || projectName.current?.value ||
      contentRef.current?.value || contactRef.current?.value ||
      dateRef.current?.value || expiryRef.current?.value) 
      ? '' : ' disabled')
 
    return <div styleName="marg20">
      <div className='h2 at'>{transS('Search Fields')} 
        <span styleName="arrowShift" 
         onClick={() => setOpen(!open)}
         className={`arrow${open ? ' arrowOpen' : ''}`}>
        </span></div>
      {showResults && <div styleName='showResults'>{showResults}</div>}
      {open && <div>
         <div styleName='searchBlock'>
            <div styleName='search-header'>{transS('General Info')}</div>
            <textarea placeholder={transS('Document Name')} 
              onInput={checkDisabled} ref={docName} />
            <textarea styleName="tempHidden" placeholder='Party' readOnly 
              onInput={checkDisabled} ref={partyName} />
            <textarea placeholder={transS('Project Name')} 
              onInput={checkDisabled} ref={projectName} />
            <div className='space20'></div>
            <Button text={transS('Search Fields ')}
                mod={'blue fill' + isDisabled}
                callback={!isDisabled && searchIt}/>
            <Button text={transS('Clear Search')}
                mod={'blue arch' + isDisabled}
                callback={!isDisabled && clearSimple}/>
            <span styleName='searching'><span>{noResults}</span></span>
         </div>

         <div styleName='searchBlock'>
            <div styleName='search-header'>{transS('Contents')}</div>
            <textarea placeholder={transS('Document Text' )}
              onInput={checkDisabled} 
              styleName='bigSearch' ref={contentRef} />
            <textarea styleName="tempHidden" placeholder='Contract Value' readOnly 
              onInput={checkDisabled} ref={contactRef} />
        </div>

        <div styleName='searchBlock tempHidden'>
            <div styleName='search-header'>Other Parameters</div>
            <textarea placeholder='Document Date' readOnly
              onInput={checkDisabled} 
              styleName='bigSearch' ref={dateRef} />
            <textarea placeholder='Expiry Date' readOnly 
              onInput={checkDisabled} ref={expiryRef} />
        </div>

      </div>}      
    </div>
  }
  export default new CSSModules(SearchFields, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});
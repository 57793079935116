import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { render } from 'react-dom';
import moment from 'moment';
import history from '../services/store/history';
import store from '../services/store';
import AppRoot from './AppRoot';

const APP_ROOT_ELEMENT = document.querySelector('#app-root');

if (APP_ROOT_ELEMENT) {
  moment.locale('en');

  require('../services/sockets');

  render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
          <Router history={history}>
              <AppRoot />
          </Router>
      </ConnectedRouter>
    </Provider>,
    APP_ROOT_ELEMENT
  );
}

import { dataConstants } from '../constants';

// get pop up action
export const getPopUpAction = popUp => ({
  type: dataConstants.GET_POP_UP,
  popUp
});

export const getpopUpWithInputAction = popUpWithInput => {
  return {
    type: dataConstants.GET_POP_UP_WITH_INPUT,
    popUpWithInput
  };
};

export const toggleLoaderAction = loader => ({
  type: dataConstants.TOGGLE_LOADER,
  loader
});

export const headerShowStartedAction = showStarted => ({
  type: dataConstants.SHOW_STARTED,
  showStarted
});


import cx from 'classnames'
import CSSModules from 'react-css-modules'
import React from 'react'
//import {NavLink} from 'react-router-dom';
import styles from './finalize.css'
import Button from '../../../../../components/button/Button'
import UserLogo from "../../../../../components/userLogo/UserLogo"
import { getDateString } from "../../../../../../common/getDateString"
import Tip from "../../../../../components/tip/Tip"
import {connect} from "react-redux"
import { signingTool, transS } from '../../../../../services/helpers/lang'

@connect(state => ({    
    dateData: state.userReducer.dateData
}), {})
@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})

export class Finalized extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowPopupNew: false,
            isShowPopupDocuSign: false,
            isShowDownloadPopup: false,

            isShowSignPopup: false,
            isSignPopupFull: false
        }
    }

    componentDidMount() {
        const { envelope } = this.props;
        window.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        const {refPopupNew, refPopupBtnNew, refDocuSignBlock, refPopupDownload} = this
        const {isShowPopupNew, isShowPopupDocuSign, isShowDownloadPopup} = this.state

        if (isShowPopupNew && refPopupNew && refPopupBtnNew &&
            refPopupBtnNew !== e.target && !refPopupBtnNew.contains(e.target) &&
            e.target !== refPopupNew && !refPopupNew.contains(e.target)) {
            this.setState({isShowPopupNew: false});
        }

        if (isShowDownloadPopup && refPopupDownload && e.target !== refPopupDownload && !refPopupDownload.contains(e.target))
            this.setState({isShowDownloadPopup: false});

        if (refDocuSignBlock && isShowPopupDocuSign && refDocuSignBlock !== e.target && !refDocuSignBlock.contains(e.target))
            this.setState({isShowPopupDocuSign: false});
    }

    makeSignersList = (arr) => {
        const {envelope, internalUserList, externalUserList, dateData} = this.props

        return arr.map(s => {
            let avatar = null
            if (internalUserList.find(el => el._id === s.userId)) avatar = internalUserList.find(el => el._id === s.userId).avatar
            if (externalUserList.find(el => el._id === s.userId)) avatar = externalUserList.find(el => el._id === s.userId).avatar

            return <div styleName='signer' key={s.userId + 'sl'}>
                <div styleName='signer-left'>
                    <UserLogo avatar={avatar} firstname={s.name && s.name.split(' ')[0]}
                              lastname={s.name && s.name.split(' ')[1]} mod='_small'/>
                    <div styleName='signer-left_nameBlock'>
                        {s.name && <div styleName='signer-left_nameBlock_name'>{s.name}</div>}
                        <div
                            styleName={s.name ? 'signer-left_nameBlock_mail' : 'signer-left_nameBlock_name'}>{s.email}</div>
                    </div>
                </div>
                <div styleName='signer-right'>
                    <div styleName={cx('signer-right_info', {'green checkMark': s.signed > 0}, {'red': s.signed < 0} )}>
                        {(s.signed > 0) ? transS('Signed') :
                            ((s.signed < 0) ? transS('Declined to sign') :
                                s.userId === envelope.user ? transS('Initiated signing') : transS('Has not signed yet'))}
                    </div>
                    {s.signed && <div styleName='signer-right_date'>on {getDateString(Math.abs(s.signed), 1, dateData)}</div>}
                </div>
            </div>
        })
    }

    render() {
        const {download, copyDocument, copyDocumentBlocked, envelope, envelopeObj, isProjectAdmin, getPopUpAction, popup, currentProject, user, internalUserList, externalUserList} = this.props
        const {isShowPopupNew, isShowPopupDocuSign, isShowDownloadPopup, isShowSignPopup, isSignPopupFull} = this.state

        const {isProcessSigning, isMySign, isMySignNeed, isSignedVersion, isSignDeclined} = envelopeObj;
        let initiateSignCondition = isProjectAdmin && !!signingTool() 
          && (!envelope || !envelope.status || envelope.status === 'created');
        let otherUsers = envelope && envelope.signee && envelope.signee.filter(s => !internalUserList.find(el => el._id === s.userId) && !externalUserList.find(el => el._id === s.userId));

        const docuSignPopup = <React.Fragment>
            <Button mod={`leftArrow${isShowPopupDocuSign ? ' leftArrow_open' : ''}`}
            text='&nbsp;' callback={() => this.setState({isShowPopupDocuSign: !isShowPopupDocuSign})}
                    style={{height: '14px'}}/>
            <div styleName='docuSign_popup'>
                {isProjectAdmin && <a href={(envelopeObj.link || envelopeObj.adminLink)+'&admin=1'}><Button text={isSignDeclined ? 'Check in DocuSign' : transS('Go to DocuSign')} mod='green arch' style={{marginBottom: '36px'}} callback={() => null} /></a>}
                {isProjectAdmin && isSignDeclined && <a href={envelopeObj.adminLink +'&admin=2'}><Button text="Initiate Docusign Again" mod='green fill' style={{marginBottom: '36px'}} callback={() => null} /></a>}
                {!!envelope && !!envelope.signee && <React.Fragment>
                    <div className='h4' style={{marginBottom: '20px'}}>Internal Team</div>
                    {this.makeSignersList(envelope.signee.filter(s => internalUserList.find(el => el._id === s.userId)))}
                    <div className='h4' style={{marginBottom: '20px', marginTop: '20px'}}>External Team</div>
                    {this.makeSignersList(envelope.signee.filter(s => externalUserList.find(el => el._id === s.userId)))}
                    {!!otherUsers && !!otherUsers.length && <React.Fragment>
                       <div className='h4' style={{marginBottom: '20px', marginTop: '20px'}}>Other DocuSign Users</div>
                       {this.makeSignersList(otherUsers)}
                    </React.Fragment>}
                </React.Fragment>}
            </div>
        </React.Fragment>

        return (
          <>
            <div styleName="wrapper">
                { (isShowPopupNew || isShowPopupDocuSign || isShowDownloadPopup) && <div styleName='screen' />}
                {isShowSignPopup && <div styleName='signPopupWrapper'><div styleName='signPopup'>
                    <div className='close-cross' onClick={() => this.setState({isShowSignPopup: false})}>+</div>
                    <div className='h2' style={{marginBottom: '35px', lineHeight: 'normal'}}>Sign document with <br/> DocuSign</div>
                    <div>We sent a link to sign the document to <b>{user.email}</b>.  Please sign it with that link.</div>
                    <div styleName='signPopup_blueText' onClick={() => this.setState({isSignPopupFull: !isSignPopupFull})}>
                        <div>Do you have a DocuSign account for <br/> <i>{user.email}</i>?</div>
                        <Button text='&nbsp;' mod={`leftArrow${isSignPopupFull ? ' leftArrow_open' : ''}`} callback={() => null} />
                    </div>
                    {isSignPopupFull && <div>
                        <div className='h3' style={{margin: '35px 0 20px', lineHeight: 'normal'}}>Login to DocuSign account for {user.email}</div>
                        <div>
                            It’s important you had already had your DocuSign account registered for this particular email at the moment the signing was initiated. <br/> <br/>
                            If you had, go to your DocuSign account to sign.
                        </div>
                        <a href={envelopeObj.link}><Button text='DocuSign' mod='green fill margin0' style={{margin: '20px 0'}} callback={() => null} /></a>
                        <div>If not, check your email and follow the DocuSign link.</div>
                    </div>}
                </div></div>}

                <div styleName='statusBlock'>
                    { !isSignedVersion && <div styleName='text checkMark'>{transS('Final Version')}</div>}

                    {initiateSignCondition && <React.Fragment>
                        {!currentProject.signee.length ?
                            <div styleName='initiateDisabled'>
                                <Button mod='green fill margin0 nowrap disabled' text={transS('Initiate DocuSign')} callback={() => null}/>
                                <Tip text='Please go to Teams to appoint signers' arrow='top left' />
                            </div> :
                            (!envelope ?
                                <Button mod='green fill margin0 nowrap' text={transS('Initiate DocuSign')} callback={() => getPopUpAction(popup.initiateDocSign)}/>
                                :
                                <a href={envelopeObj.link || envelopeObj.adminLink}>
                                  <Button mod='green fill margin0 nowrap' text={transS('Initiate DocuSign')} callback={() => null}/>
                                </a>
                            )}
                        <span styleName="margin40"/>
                    </React.Fragment>
                    }

                    {isProcessSigning && isMySignNeed && !isMySign && <div styleName={cx('docusign_btn', {'_open': isShowPopupDocuSign})} ref={e => this.refDocuSignBlock = e}>
                        <Button mod='green fill margin0 nowrap' text='DocuSign' callback={() => this.setState({isShowSignPopup: true})} />
                        {docuSignPopup}
                    </div>}

                    {isProcessSigning && isMySignNeed && isMySign && <div styleName={cx('docusign_btn', {'_open': isShowPopupDocuSign})} ref={e => this.refDocuSignBlock = e}>
                        <div styleName='text checkMark docuSign_text '>You Signed</div>
                        {docuSignPopup}
                    </div>}

                    {isProcessSigning && !initiateSignCondition && !isMySignNeed && 
                      <div styleName={cx('docusign_btn', {'_open': isShowPopupDocuSign})} ref={e => this.refDocuSignBlock = e}>
                        <div styleName='text docuSign_text gray'>Signing&nbsp;in&nbsp;Progress</div>
                        {docuSignPopup}
                      </div>}

                    { (!isProcessSigning && isSignedVersion) &&
                    <div styleName={cx('docusign_btn', {'_open': isShowPopupDocuSign})} ref={e => this.refDocuSignBlock = e}>
                        <div styleName='text bold checkMark docuSign_text' style={{lineHeight: '14px'}}>{transS('Signed Version')}</div>
                        {docuSignPopup}
                    </div>}
                    { (!isProcessSigning && isSignDeclined) &&
                    <div styleName={cx('docusign_btn', {'_open': isShowPopupDocuSign})} ref={e => this.refDocuSignBlock = e}>
                        <div styleName='text docuSign_text red' style={{lineHeight: '14px'}}>Declined&nbsp;to&nbsp;sign</div>
                        {docuSignPopup}
                    </div>}
                </div>
                

                {isSignedVersion ?
                    <div styleName={cx('downloadPopup', {'_open': isShowDownloadPopup})}
                         ref={e => this.refPopupDownload = e}>
                        <div styleName='downloadPopup_btn'>
                            <Button text={transS('Download pdf')} mod='blue nowrap margin0'
                                    callback={() => download('signed')}/>
                            <div styleName='arrowBtn'
                                 onClick={() => this.setState({isShowDownloadPopup: !isShowDownloadPopup})}>
                                 <Button text='&nbsp;' mod={`leftArrow${isShowDownloadPopup ? ' leftArrow_open' : ''}`}/>
                            </div>
                        </div>

                        <div styleName={cx('downloadPopup_popup', {'_vis': isShowDownloadPopup})}>
                            <Button text={transS('Export in .docx')} mod='blue nowrap margin0'
                                    callback={() => download('docx')}/>
                        </div>
                    </div>
                    :
                    <Button text={transS('Export in .docx')} mod='blue nowrap margin0'
                             callback={() => download('docx')}/>
                }
            </div>

               <span styleName="margin40"/>
               <div className="borderHeader"></div>
               <div styleName='btnNewBlock'>
                    <div ref={e => this.refPopupBtnNew = e}>
                        <Button text={transS('New')} mod={`changes leftArrow${isShowPopupNew ? ' leftArrow_open' : ''}`}
                                style={{zIndex: '6'}}
                                callback={() => this.setState({isShowPopupNew: !isShowPopupNew})}/>
                    </div>
                    <div ref={e => this.refPopupNew = e}
                         styleName={cx('btnNewPopup', {'btnNewPopup_visible': isShowPopupNew})}>
                        {copyDocumentBlocked ? <Button text='Copy as New Document' mod='blue disabled'/>
                            : <Button text={transS('Copy as New Document')} mod='blue' callback={copyDocument}/>}
                        <Button text={transS('Request Cancelling Final Version')} mod='blue' style={{marginTop: '15px'}}
                                callback={() => getPopUpAction(popup.cancellingFinalVersion)}/>
                    </div>
                </div>
          </>
        )
    }
}

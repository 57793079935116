import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './userManual.css'

const UserManual = () => {
    return (
        <div styleName='wrapper'>
            <div styleName="page_1">
                <div styleName="p1dimg1">
                    <img src="/images/userManual_image_001.png" styleName="p1img1" alt='image'/>
                </div>


                <div styleName="id1_1">
                    <table cellPadding='0' cellSpacing='0' styleName="t0">
                        <tbody>
                        <tr>
                            <td styleName="tr0 td0"><p styleName="p0 ft0">Contract</p></td>
                            <td styleName="tr0 td1"><p styleName="p0 ft1">User Manual</p></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div styleName="id1_2">
                    <p styleName="p1 ft2">Getting the most of Contract.one</p>
                </div>
            </div>

            <div styleName="page_2">
                <div styleName="p2dimg1">
                    <img src="/images/userManual_Image_002.jpg" styleName="p2img1" alt='image'/>
                </div>


                <div styleName="id2_1">
                    <div styleName="id2_1_1">
                        <p styleName="p1 ft5"><span styleName="ft3">1</span><span styleName="ft4">projects</span></p>
                        <p styleName="p2 ft6">These are your projects.</p>
                        <p styleName="p3 ft9"><span styleName="ft7">1.1</span><span styleName="ft8">Create a new project.</span>
                        </p>
                        <div styleName="p2dimg2">
                            <img src="/images/userManual_image_003.jpg" styleName="p2img2" alt='image'/>
                        </div>
                        <p styleName="p4 ft5"><span styleName="ft10">2</span><span styleName="ft11">Teams</span></p>
                        <p styleName="p5 ft12">Invite people to work on the contract.</p>
                        <div styleName="p2dimg6">
                            <img src="/images/userManual_image_006.jpg" styleName="p2img6" alt='image'/>
                        </div>
                        <p styleName="p6 ft6">Add your colleagues to the <span styleName="ft13">Internal Team</span>.
                        </p>
                        <p styleName="p7 ft12">Make sure you require approvals from the key <nobr>decision-makers</nobr>
                        </p>
                        <div styleName="p2dimg7">
                            <img src="/images/userManual_image_007.jpg" styleName="p2img7" alt='image'/>
                        </div>
                        <p styleName="p8 ft6">Add your client or the counterpart to the <span styleName="ft13">External
                            Team</span>.</p>
                        <p styleName="p9 ft6">Save Changes.</p>
                        <div styleName="p2dimg5">
                            <img src="/images/userManual_image_005.jpg" styleName="p2img5" alt='image'/>
                        </div>
                    </div>
                    <div styleName="id2_1_2">
                        <p styleName="p10 ft16"><span styleName="ft14">1.2</span>
                            <span styleName="ft15">Upload a Word document or just </span>
                            <nobr>drag-n-drop</nobr>
                            into a project
                        </p>
                        <div styleName="p2dimg3">
                            <img src="/images/userManual_image_004.jpg" styleName="p2img3" alt='image'/>
                        </div>
                    </div>
                </div>
                <div styleName="id2_2">
                    <p styleName="p1 ft6">2</p>
                </div>

                <div styleName="page_3">

                    <div styleName="dclr"/>
                    <div styleName="id3_1">
                        <div styleName="id3_1_1">
                            <p styleName="p11 ft5"><span styleName="ft10">3</span><span styleName="ft11">Document</span>
                            </p>
                            <div styleName="p3dimg1">
                                <img src="/images/userManual_image_008.png" styleName="p3img1"/>
                            </div>
                            <p styleName="p12 ft9"><span styleName="ft6">3.1</span><span
                                styleName="ft17">Communication</span></p>
                            <p styleName="p13 ft12">Internal Comments (on gray background) are for your team only. The
                                other
                                party doesn’t
                                see them.</p>
                            <p styleName="p14 ft6">External Comments (blue) can be seen by both teams.</p>
                            <p styleName="p12 ft9"><span styleName="ft18">3.2</span><span
                                styleName="ft19">Editing</span></p>
                            <p styleName="p15 ft12">Edit right on the screen when the document is on your side. Teams
                                edit
                                in turns.</p>
                            <p styleName="p16 ft5"><span styleName="ft10">4</span><span
                                styleName="ft11">Communication</span></p>
                            <p styleName="p12 ft9"><span styleName="ft6">4.1</span><span styleName="ft17">Start an Internal
                                Thread</span></p>
                            <p styleName="p17 ft12">Next to any paragraph you can start an Internal Thread to discuss
                                the
                                clause within your
                                team.</p>
                            <div styleName="p4dimg1">
                                <img src="/images/userManual_image_009.jpg" styleName="p4img1"/>
                            </div>
                            <p styleName="p18 ft9"><span styleName="ft18">4.2</span><span styleName="ft19">Start an External
                                Thread</span></p>
                            <p styleName="p19 ft6">After you start typing tick <span
                                styleName="ft13">Public Comment</span>. The
                                comment box
                                will become blue.</p>
                            <div styleName="p4dimg2">
                                <img src="/images/userManual_image_010.jpg" styleName="p4img2"/>
                            </div>
                            <p styleName="p20 ft9"><span styleName="ft18">4.3</span><span styleName="ft19">Add a title to a
                                thread</span></p>
                            <p styleName="p21 ft6">Bring a mouse over the thread to add a title.</p>
                            <div styleName="p4dimg3">
                                <img src="/images/userManual_image_011.jpg" styleName="p4img3"/>
                            </div>
                        </div>
                        <div styleName="id3_1_2">
                            <p styleName="p22 ft12">A fresh External Comment will be seen by the external team only
                                after
                                you send the
                                document to them.</p>
                            <div styleName="p4dimg4">
                                <img src="/images/userManual_image_012.jpg" styleName="p4img4"/>
                            </div>
                            <p styleName="p23 ft9"><span styleName="ft18">4.4</span><span styleName="ft20">Edit, Delete an External
                                Comment</span>
                            </p>
                            <p styleName="p24 ft21">You can do that before an External Comment is sent to the External
                                Team.</p>
                            <div styleName="p4dimg5">
                                <img src="/images/userManual_image_013.jpg" styleName="p4img5"/>
                            </div>
                        </div>
                    </div>
                    <div styleName="id3_2">
                        <p styleName="p1 ft6">3</p>
                    </div>
                </div>

                <div styleName="page_4">
                    {/*<div styleName="p4dimg1">*/}
                    {/*    <img src="/images/userManual_image_004.jpg" styleName="p4img1"/>*/}
                    {/*</div>*/}


                    <div styleName="id4_1">
                        <div styleName="id4_1_1">
                            <p styleName="p1 ft5"><span styleName="ft10">5</span><span styleName="ft11">Changes</span>
                            </p>
                            <p styleName="p25 ft9"><span styleName="ft6">5.1</span><span
                                styleName="ft17">Change text</span></p>
                            <p styleName="p26 ft12">Just click on any paragraph and edit, if it is your Team’s turn to
                                make
                                changes or when
                                you are in Collaboration Mode with the other Team.</p>
                            <p styleName="p27 ft9"><span styleName="ft18">5.2</span><span styleName="ft20">Accept and Decline</span>
                            </p>
                            <p styleName="p28 ft21">Click on a tracked change. <span
                                styleName="ft22">Accept </span>or <span
                                styleName="ft22">Decline </span>the
                                change from a menu next to it.</p>
                            <div styleName="p5dimg1">
                                <img src="/images/userManual_image_014.jpg" styleName="p5img1"/>
                            </div>
                            <p styleName="p29 ft6">The accepted change will become green and the
                                <nobr>declined—red.</nobr>
                            </p>
                            <div styleName="p5dimg2">
                                <img src="/images/userManual_image_015.jpg" styleName="p5img2"/>
                            </div>
                            <p styleName="p30 ft9"><span styleName="ft18">5.3</span><span styleName="ft19">Mark a change for
                                yourself</span></p>
                            <p styleName="p31 ft12">Click <img src="/images/userManual_image_016.jpg" styleName="p5img3"/> if you want to mark that you dealt with it or prefer the
                                changes
                                markup don’t
                                distract you.</p>
                            <p styleName="p32 ft12">The text will become gray, but only for your account:</p>
                            <div styleName="p5dimg3">
                                <img src="/images/userManual_image_017.jpg" styleName="p5img3"/>
                            </div>
                        </div>
                        <div styleName="id4_1_2">
                            <p styleName="p1 ft9"><span styleName="ft18">5.4</span><span styleName="ft20">History of a change</span>
                            </p>
                            <p styleName="p33 ft12">Click on a change a see the list of actions.</p>
                            <div styleName="p5dimg4">
                                <img src="/images/userManual_image_018.jpg" styleName="p5img4"/>
                            </div>
                            <p styleName="p34 ft9"><span styleName="ft23">5.5</span><span styleName="ft24">History of a
                                paragraph</span></p>
                            <p styleName="p35 ft26">Click on the paragraph and choose <span styleName="ft25">Show Paragraph
                                History</span>. Find
                                time or a version</p>
                            <div styleName="p5dimg5">
                                <img src="/images/userManual_image_019.jpg" styleName="p5img5"/>
                            </div>
                            <p styleName="p36 ft9"><span styleName="ft23">5.6</span><span styleName="ft24">Request or Overtake
                                editing</span></p>
                            <p styleName="p37 ft12">Request editing, if you need to change the document, but the other
                                party
                                is working on
                                it. If they confirm, the document gets back to you with all their new changes.</p>
                            <div styleName="p5dimg6">
                                <img src="/images/userManual_image_020.jpg" styleName="p5img6"/>
                            </div>
                            <p styleName="p38 ft12">The project manager of the Internal Team can overtake editing if the
                                other Team is not
                                able to transfer it to you.</p>
                            <p styleName="p33 ft12">This would create a new version based on the latest version
                                available to
                                you. But this
                                will not show you the changes the other Team is working on right now.</p>
                            <div styleName="p5dimg7">
                                <img src="/images/userManual_image_021.jpg" styleName="p5img7"/>
                            </div>
                        </div>
                    </div>
                    <div styleName="id4_2">
                        <p styleName="p1 ft6">4</p>
                    </div>

                    <div styleName="page_5">
                        {/*<div styleName="p5dimg1">*/}
                        {/*    <img src="/images/userManual_image_005.jpg" styleName="p5img1"/>*/}
                        {/*</div>*/}


                        <div styleName="id5_1">
                            <div styleName="id5_1_1">
                                <p styleName="p1 ft5"><span styleName="ft10">6</span><span
                                    styleName="ft11">Approve</span></p>
                                <p styleName="p39 ft12">Before the document can be sent, it must be approved all team
                                    members, whose approval is
                                    required.</p>
                                <div styleName="p6dimg1">
                                    <img src="/images/userManual_image_022.jpg" styleName="p6img1"/>
                                </div>
                                <p styleName="p40 ft12">The only user who can send the document without approvals is the
                                    team member who created
                                    the project.</p>
                                <p styleName="p41 ft5"><span styleName="ft27">7</span><span styleName="ft28">Send</span>
                                </p>
                                <p styleName="p39 ft12">If the document is approved, you can send it to the
                                    counterpart</p>
                                <div styleName="p6dimg2">
                                    <img src="/images/userManual_image_024.jpg" styleName="p6img2"/>
                                </div>
                                <p styleName="p42 ft12">After you send the document, the counterpart would see the new
                                    changes and External
                                    Comments.</p>
                            </div>
                            <div styleName="id5_1_2">
                                <p styleName="p1 ft9"><span styleName="ft14">6.1</span><span styleName="ft29">Require
                                    Approval</span></p>
                                <p styleName="p43 ft12">The team member who created the project can select users whose
                                    approval for sending the
                                    document is required.</p>
                                <p styleName="p44 ft6">Go to the project and then click <span
                                    styleName="ft13">Invite</span>.</p>
                                <div styleName="p6dimg3">
                                    <img src="/images/userManual_image_023.jpg" styleName="p6img3"/>
                                </div>
                                <p styleName="p45 ft5"><span styleName="ft10">8</span><span
                                    styleName="ft30">Collaborate</span></p>
                                <p styleName="p46 ft12">You can collaborate not only within your team but also with the
                                    <nobr>counterpart—for</nobr>
                                    example when you’re both on the phone and need to make changes online.
                                </p>
                                <div styleName="p6dimg4">
                                    <img src="/images/userManual_image_025.jpg" styleName="p6img4"/>
                                </div>
                            </div>
                        </div>
                        <div styleName="id5_2">
                            <p styleName="p1 ft6">5</p>
                        </div>
                    </div>
                </div>

                <div styleName="page_6">
                    {/*<div styleName="p6dimg1">*/}
                    {/*    <img src="/images/userManual_image_006.jpg" styleName="p6img1"/>*/}
                    {/*</div>*/}


                    <div styleName="id6_1">
                        <p styleName="p1 ft5"><span styleName="ft10">9</span><span
                            styleName="ft11">Progress & Tasks</span></p>
                        <p styleName="p25 ft9"><span styleName="ft6">9.1</span><span styleName="ft17">See history, versions in
                            Progress</span></p>
                        <div styleName="p7dimg2">
                            <img src="/images/userManual_image_026.jpg" styleName="p7img2"/>
                        </div>
                        <div styleName="p7dimg1">
                            <img src="/images/userManual_image_027.jpg" styleName="p7img1"/>
                        </div>
                        <p styleName="p47 ft9"><span styleName="ft18">9.2</span><span styleName="ft20">Versions</span>
                        </p>
                        <p styleName="p9 ft6">If you can edit, you’re in current version.</p>
                        <p styleName="p48 ft26">Scroll to previous versions, see their history, download them, explore
                            what
                            happened.</p>
                        <div styleName="p7dimg3">
                            <img src="/images/userManual_image_028.jpg" styleName="p7img3"/>
                        </div>
                        <p styleName="p49 ft9"><span styleName="ft18">9.3</span><span
                            styleName="ft19">Create a Task</span></p>
                        <p styleName="p50 ft6">Type <span styleName="ft13">@ </span>in a comment. Choose a person from
                            the
                            Internal Team and set
                            up the deadline, if you need one.</p>
                        <div styleName="p7dimg4">
                            <img src="/images/userManual_image_029.jpg" styleName="p7img4"/>
                        </div>
                        <p styleName="p51 ft9"><span styleName="ft18">9.4</span><span
                            styleName="ft19">Follow a Task</span></p>
                        <p styleName="p52 ft12">If you have new tasks there’ll be a red badge on the Progress & Tasks
                            button. Click it.</p>
                        <div styleName="p7dimg5">
                            <img src="/images/userManual_image_031.jpg" styleName="p7img5"/>
                        </div>
                        <p styleName="p53 ft12">In the Tasks section you’ll see your tasks. Click Jump to Item and reply
                            to
                            your
                            colleagues.</p>
                        <div styleName="p7dimg6">
                            <img src="/images/userManual_image_030.jpg" styleName="p7img6"/>
                        </div>
                    </div>
                    <div styleName="id6_2">
                        <p styleName="p1 ft6">6</p>
                    </div>
                </div>

                <div styleName="page_7">
                    {/*<div styleName="p7dimg1">*/}
                    {/*    <img src="/images/userManual_image_007.jpg" styleName="p7img1"/>*/}
                    {/*</div>*/}


                    <p styleName="p54 ft5">Questions?</p>
                    <p styleName="p55 ft31">Email or call us</p>
                    <p styleName="p56 ft31"><a styleName='mail' href="mailto:global@contract.one">global@contract.one</a></p>
                    <p styleName="p57 ft18">+1 929 999 1891</p>
                    <p styleName="p58 ft6">7</p>
                    <div styleName="p8dimg1">
                        <img src="/images/userManual_image_032.jpg" styleName="p8img1"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default new CSSModules(UserManual, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
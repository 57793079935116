import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './Tip.css'
import Button from '../button/Button'
import cx from 'classnames'

const Tip = props => {
    const {text, header, style, btn, arrow} = props

    return (
        <div styleName={cx('wrapper', {[arrow]: arrow, 'bottom right': !arrow})} style={style}>
            {header && <div styleName='header' dangerouslySetInnerHTML={{__html: header}} />}
            <div dangerouslySetInnerHTML={{__html: text}} />
            {btn && <Button text={btn.text} callback={btn.callback} mod='small arch gray' style={{marginTop: '10px'}}/>}
        </div>
    )
}

export default new CSSModules(Tip, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
import React from 'react';
import { rights } from '../../../../../configs/appConfig';
import { transS, transF } from '../../../../services/helpers/lang';
import { Icon_ArrowDown, Icon_Cog, Icon_Doc, Icon_Doc_Auto, Icon_Download, Icon_Folder, Icon_Pin, Icon_Pinned } from '../../../../components/svglib/svglib';
import { colors } from '../../../../components/colors';
import { ButtonStd } from '../../../../components/button/Buttons';
import { addFolder, renameUnit, unitHelper } from './unitsFuncs';
import { addUnit, expandUnit, pinUnit } from '../../../../services/sockets/directStore';
import api from '../../../../services/api/api';

const icon_arrow = () => <Icon_ArrowDown color={colors.primary} />,
  icon_doc_auto = () => <Icon_Doc_Auto color={colors.primary}/>,
  icon_doc = () => <Icon_Doc color={colors.primary}/>,
   icon_folder = () => <Icon_Folder color={colors.primary}/>


export const getContextItems = (unit, x, y, setState, isUnitPinned) => {
  const disabled = !(unit.integrated & rights.CAN_REMOVE),
    {iAmInternal, inGroup, isDoc, isFolder, checkApprove} = unitHelper(unit,''),
    downloadable = isDoc & unit.orig != 302 || unit.contentType

  return [
    {text: transS("Rename"),
      action: (event) => !disabled && renameUnitDialog(unit, {x, y}, setState), disabled},
    {text: transS("Parameters & Settings"), Picture: Icon_Cog, 
      action: () => editParams(unit) },
    {text: '1', line: true},
    !isUnitPinned && unit.folders?.length ? {text: transS("Show All and Expand"), 
      Picture: icon_arrow,
      action: () => expandUnit(unit.loc_id, !!unit.mark)} : null,
    {text: transS(isUnitPinned ? "Unpin" : "Pin"), 
      Picture: isUnitPinned ? Icon_Pinned : Icon_Pin, 
      action: () => pinUnit(unit) },
    downloadable ? {text: transS("Download"), Picture: Icon_Download, 
      action: () => api.downloadUnit(unit.loc_id) } : null,
    {text: '2', line: true},
    isUnitPinned ? null : 
      {text: transS(!isFolder ? "For this document" : "For this folder"), 
      style: {color: colors.darkGray}},
    isUnitPinned ? null : 
      {text: transS("New Document from Template"), Picture: icon_doc_auto,  
      action: () => newTemplate(unit)},
    isUnitPinned ? null : 
      {text: transS("New Document"), Picture: icon_doc, 
      action: () => newDoc(unit)},
    isUnitPinned ? null : 
      {text: transS("New Folder"), Picture: icon_folder, 
      action: () => newFolderDialog(unit, {x, y}, setState)},
    {text: transS(!isFolder ? "Delete Document" : "Delete Folder"), 
      style: {color: colors.darkRed},
      action: () => !disabled && deleteUnitDialog(unit, isFolder, setState), disabled},
  ]
}

const renameUnitDialog = (unit, position, setState) => setState({
  name: 'uni',
  setState,
  position, crossed: true,
  items: [
    ()=><div className='h3'>{transS('Rename')}</div>,
    ()=><input id='renameUnitInput' className='dev-std-input'
       defaultValue={unit.title} />,
    ()=><div className='dev-std-buttons-block'>
      <ButtonStd text={transS('Ok')} 
        action={()=>{
          renameUnit(document.getElementById('renameUnitInput').value.trim(), unit)
          setState(null)
      }}
      />
      <ButtonStd text={transS('Cancel')} 
        action={() => setState(null)}
        style={{
          backgroundColor: colors.white,
          color: colors.primary,
      }}/>
    </div>
  ]
})
  

  const editParams = unit => {

  }

export const newFolderDialog = (unit, position, setState) => setState({
    name: 'uni',
    setState,
    position, crossed: true,
    items: [
      ()=><div className='h3'>{
        unit ?
        transS('New Folder at ') + unit.title
        : transS('New Folder')
      }</div>,
      ()=><input id='renameUnitInput' className='dev-std-input'
         defaultValue="" />,
      ()=><div className='dev-std-buttons-block'>
        <ButtonStd text={transS('Ok')} 
          action={()=>{
            const name = document.getElementById('renameUnitInput').value.trim()
            if (unit?.externals)
              addUnit({loc_id: 'e'+(Date.now()), integrated:0, folder: name, 
                parentId: unit.loc_id, externals: true})
            else
              addFolder(name, unit?.loc_id || null)
            setState(null)
        }}
        />
        <ButtonStd text={transS('Cancel')} 
          action={() => setState(null)}
          style={{
            backgroundColor: colors.white,
            color: colors.primary,
        }}/>
      </div>
    ]
  })

  const newTemplate = unit => {

  }

  export const newDoc = unit => {

  }

  export const deleteUnitDialog = (unit, isFolder, setState) => setState({
    name: 'uni',
    setState,
    crossed: true,
    items: [
      ()=><div className='h3'>{transS(
        isFolder ? 'Do you want to remove the folder?' 
        : 'Do you want to remove the document?')
        .replace('?', unit.title ? ` (${unit.title})?` : '?')}
      </div>,
      ()=><div className='dev-std-buttons-block'>
        <ButtonStd text={transS('Cancel')} 
          action={()=>setState(null)}
          style={{
            backgroundColor: colors.white,
            color: colors.primary,
        }}
        />
        <ButtonStd text={transS('Ok')} 
          action={() => {            
            api.deleteUnit(unit.loc_id)
            setState(null)}
          }
          style={{
            backgroundColor: colors.white,
            color: colors.darkRed,
            borderColor: colors.darkRed,
        }}/>
      </div>
    ]
  })
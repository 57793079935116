import checkForMac from '../../common/macCheck'

export const iframeSetContent = (content, isTemplate) => frames['iframe'].onload = () => {
    let contentHtmlEl = document.createElement('html')
    contentHtmlEl.innerHTML = content
    applyCSSandJS(contentHtmlEl)
    content = contentHtmlEl.querySelector('body').innerHTML
    frames['iframe'].document.querySelector('body').innerHTML = content
    if (isTemplate) frames['iframe'].templateMode = true

    const c1LastItem = frames['iframe'].document.getElementById('c1-last-item')
    const iframeSize = c1LastItem.offsetTop
    window.postMessage(JSON.stringify({c1: 'frame', winLength: iframeSize}), window.origin)

    frames['iframe'].onload = null
}


function applyCSSandJS(htmlElement) {
    const links = Array.from(htmlElement.querySelectorAll('link'))
        .filter(link => !link.href.includes('/images/frame.css'))

    const styles = Array.from(htmlElement.querySelectorAll('style'))

    const scripts = Array.from(htmlElement.querySelectorAll('script'))
        .filter(script => !script.src.includes('/images/frame.js'))

    if (checkForMac()) {
        const macCSS = '<link rel="stylesheet" type="text/css" href="/images/stylesMAC.css">'
        frames['iframe'].document.head.insertAdjacentHTML('beforeEnd', macCSS)
    }

    return [...links, ...styles, ...scripts].forEach(el => frames['iframe'].document.head.appendChild(el))
}

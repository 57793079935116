import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import styles from '../static/promo.css';

const Pane = props => {
  let {image, name, paragraphs, longo = false, callback = () => {} } = props;    

  return (
                      <div styleName='pane'>
                        <img src={image} />
                        <div>{name}</div>
                        <article styleName='clink' onClick={() => {callback()}}>
                              {longo ? 'Hide text' : 'See more'}
                        </article>
                        {longo && paragraphs && paragraphs.map(item => {
                            return <p>{item}</p>;
                        })}                           
                      </div>
  );
};
Pane.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  paragraphs: PropTypes.array,
  callback: PropTypes.function,
  longo: PropTypes.bool
};
export default new CSSModules(Pane, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });

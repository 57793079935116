import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import styles from './projectPage.css';
import cx from 'classnames';
import SubTeam from './SubTeam';
import Button from "../../../../components/button/Button"
import Spinner from "../../../../components/spinner/Spinner"
import { getLanguage, transS } from '../../../../services/helpers/lang';
import Tippp from '../../../../components/tip/Tippp';

const Team = props => {
  let { internalTeams, func, number, simplest, count, selectedFlow, locked, 
    canTeam, justAddedUsers, link,
    sendInvitationProject, isSendInvitesProcess, isNewProject, sendInvitesPermission } = props,
    companyName = func(number, 'getCompany'), counter = 0, 
    canAddSubTeams = func(number, 'canAddSubTeams'),
    heightStyle = {height: (count * 60 + (simplest ? 0 : 35))+'px'};

    let isExt = (selectedFlow !== 0 && number !== 0)
    const link2 = link ? link.replace('member', 'approval') : null

    const sendInvitesNowCallback = () => isSendInvitesProcess ? null : sendInvitationProject(number)

    let timer, target
    const copyInvite = async (e, text) => {
      if (target) clearTipp()
      try {
        await navigator.clipboard.writeText(text)
      }
      catch {
        const textarea = document.createElement("textarea");
        textarea.textContent = text;
            textarea.style.position = "fixed";
            textarea.style.width = '2em';
            textarea.style.height = '2em';
            textarea.style.padding = 0;
            textarea.style.border = 'none';
            textarea.style.outline = 'none';
            textarea.style.boxShadow = 'none';
            textarea.style.background = 'transparent';
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            document.execCommand("copy");
            document.body.removeChild(textarea);
      }
      target = e.target
      target.dataset.tip = 1
      timer = setTimeout(clearTipp, 3000)
    }

    const clearTipp = () => {
      if (timer) clearTimeout(timer)
      timer = null
      if (target) 
        delete target.dataset.tip
      target = null
    }
    const tipBtn = {text: transS("Got it"), 
      arrow:'bottom left',
      callback: clearTipp
    }, tipTxt = transS('Link copied to clipboard')

  return (
    <div styleName={cx("team", {'int': number === 0, 'ext': number !== 0})}>
      <div className='h2' style={{lineHeight: 'normal', marginBottom: '40px'}}>
        {companyName || func(number,'getName')}</div>
        { (!locked || !!canTeam ) &&
        <Button text={transS('Add Members')} mod='blue arch' callback={() => func(number, 'addMembers', number)} /> }
        {!!link && <div styleName='teamLink'
          onClick={e => copyInvite(e, link)}>{transS('Link to Invite to Project')}
          <Tippp text={tipTxt} btn={tipBtn} />
        </div>}
        {!!link && <div styleName='teamLink'
          onClick={e => copyInvite(e, link2)}>{transS('Link to Invite to Project as Approver')}
          <Tippp text={tipTxt} btn={tipBtn} />
        </div>}

        {justAddedUsers && <div styleName={cx('justAddedUsers', {'ext': number !== 0})}>
            <div className='h4' style={{marginBottom: '20px'}}>{transS('Just Added')}</div>
            {sendInvitesPermission && <div styleName='justAddedUsers-sendBtn'
              data-lang={getLanguage()}>
                {isSendInvitesProcess ?
                    <div styleName='justAddedUsers_spinner'><Spinner withoutLogo={1} />{transS('Sending invites...')}</div>
                    :
                    <Button text={transS('Send Invites Now')} mod={`${number !== 0 ? 'arch' : 'fill'} blue nowrap`} callback={sendInvitesNowCallback} />}

                <div styleName='justAddedUsers-sendBtn-desc'>{transS('If you don’t send, the invites will be sent')} {number !== 0 ?
                    transS('with the first version') : transS('automatically within an hour')}
                </div>
            </div>}
            {justAddedUsers}
        </div>}
      {internalTeams.length 
          ? internalTeams.map(i => <SubTeam companyNumber={number} number={i} key={counter++} selectedFlow={selectedFlow} func={func} editable={true} />) : ''}
      { (!number || simplest || companyName) &&
      <SubTeam number={number} func={func} key={counter++} isExt={isExt} selectedFlow={selectedFlow} 
         external={!!internalTeams.length} locked={locked} /> }
      <div styleName='teamCommandLine'>
         {canAddSubTeams &&  <div styleName='teamCommand' 
            onClick={() => func(-1, 'editteam')}>Add a New Team</div> }
         {!number && !simplest && <div styleName='teamCommand' 
            onClick={() => func(number, 'editcompany') }>Edit the Company</div> }
         {!!number && !simplest && <div styleName='teamCommand'
           onClick={() => func(number, 'editcompany') } >{ companyName ? 
          'Edit the Company and Add Members' : 'Add New Company'}</div> }
          {!!number && !simplest && <div styleName='teamCommand'>{ companyName && 'Invite options'}</div> }
         {!!number && !simplest && !companyName && <div styleName='teamCommand'
           onClick={() => func(number, 'selectCompany')} >Select From Existing</div> }
      </div>
      {canAddSubTeams && !internalTeams.length && <div styleName='teamRemark'>
          Each Team can work internally and then collaborate with all the Teams.<br /><br />
          They can send the document to other parties.  You can set up Approval Rules for that.
       </div> }   
       {/*<div style={heightStyle}></div>*/}
    </div>
  );
};


Team.propTypes = {
  internalTeams: PropTypes.array,
  func: PropTypes.func,
  number: PropTypes.number,
  simplest: PropTypes.bool
};

export default new CSSModules(Team, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });



import api from '../api/api';
import { paragraphConstants } from '../constants';
import { toggleLoaderAction } from './dataActions';
import store from "../store"

// get paragraph list action
export const getParagraphList = (documentId, enterTheDocument = true, historyDocumentId = null, itemId = null) => ({
  type: paragraphConstants.GET_PARAGRAPH_LIST,
  documentId,
  enterTheDocument,
  historyDocumentId,
  itemId
});

export const setNoticeBannerContent = data => ({
    type: paragraphConstants.SET_NOTICE_BANNER_CONTENT,
    data
});

export const getParagraphListSuccess = (data) => ({
  type: paragraphConstants.GET_PARAGRAPH_LIST_SUCCESS,
  data
});

export const updateParagraphListIO = (paragraph  = null, comment = null, change = null, data = null) => ({
  type: paragraphConstants.UPDATE_PARAGRAPH_LIST_IO,
  paragraph,
  comment,
  change,
  data
});

export const resetPragraphListAction = () => ({
  type: paragraphConstants.RESET_PARAGRAPHS
});

export const setFrameAction = (data, struc) => ({
  type: paragraphConstants.SET_FRAME,
  func: data,
  struc: struc
});

export const setMenuAction = (changedItem) => {
  const actualDocument = store.getState().document.actualDocument
  if (!actualDocument._id) return

  const stateMenu = {...store.getState().paragraphReducer.stateMenu, ...changedItem}
  const coreDocumentId = actualDocument.coreDocument
  const paragraphStateMenu = localStorage.getItem('paragraphStateMenu')
      ? JSON.parse(localStorage.getItem('paragraphStateMenu'))
      : {}

  if (stateMenu.showChanges === 3) {
    stateMenu.redline = false
    changedItem.redline = false
  } else if (stateMenu.showChanges === 4) {
    stateMenu.showChanges = 3
    stateMenu.redline = false
    stateMenu.filterParagraphs = false
  }
  if (changedItem.filterParagraphs === 0) {
    stateMenu.filterParagraphs = false
  }
  delete stateMenu.hadChanges
  delete stateMenu.changesCount
  delete stateMenu.showNewer
  paragraphStateMenu[coreDocumentId] = stateMenu
  localStorage.setItem('paragraphStateMenu', JSON.stringify(paragraphStateMenu))

  let psm
  if (!window.initUserInterfaceData) {
    window.initUserInterfaceData = {}
    psm = {[coreDocumentId]: stateMenu}
    window.initUserInterfaceData.paragraphStateMenu = JSON.stringify(psm)
  } else {
    psm = JSON.parse(window.initUserInterfaceData.paragraphStateMenu) || {[coreDocumentId]: stateMenu}
    if (!psm[coreDocumentId]) psm[coreDocumentId] = stateMenu
    window.initUserInterfaceData.paragraphStateMenu = JSON.stringify(psm)
  }

  return {
    type: paragraphConstants.SET_MENU,
    changedItem: changedItem
  }
};

export const setPopupMode = (popupMode) => ({
  type: paragraphConstants.SET_POPUP_MODE,
  popupMode
})
export const setJumpChanges = (payload) => ({
  type: paragraphConstants.SET_JUMP_CHANGES_END,
  payload
})

export const unreadTasksAction = (id, commentId, group) => async dispatch => {
  let commentList
  try {
    const res = await api.unreadTasks(id, commentId, group)
    commentList = res.data.data.list
  } catch (e) {
    console.log(e)
  }

  let tasksCount = 0;

  if (commentList && commentList.length) {
    const id = `data-due-id=\\"${store.getState().userReducer.user._id}\\"`;
    tasksCount = commentList.split('\n').filter(el => el.includes(id)).length
  }

  dispatch({
    type: paragraphConstants.UPDATE_COMMENT_LIST,
    commentList
  })
}

export const calculateUnreadTasksAction = (markList) => async dispatch => {
  if (!markList) {
    markList = await api.unreadMarks(store.getState().document.actualDocument._id, '')
    markList = markList.data.data.list.split(' ')
  }
  const userId = store.getState().userReducer.user._id
  const reg = new RegExp(`data-due-id="(-?)${userId}"`)
  const actualDocumentCoreId = store.getState().document.actualDocument.coreDocument
  let tasksCount

  const commentList = store.getState().paragraphReducer.commentList.toString()
      .split('\n')
      .filter(comment => !!comment)
      .map(comment => JSON.parse(comment))
      .filter(comment => comment.content.match(reg))
      .filter(comment => comment.userId !== userId)
      .filter(comment => !markList.find(mark => mark === `${comment.comment}-${comment.time}`))
  tasksCount = commentList.length
  store.getState().projectReducer.currentProject?.messages?.filter(
    message => message.document === actualDocumentCoreId)
      .forEach(message => {
        const matchReplies = (message.replies || [])
            .filter(reply => reply.user._id !== userId)
            .filter(reply => reply.content.match(reg))
            .filter(reply => !reply.readers.includes(userId))
        tasksCount += matchReplies.length
        if (message.content.match(reg) && message.user._id !== userId && !message.readers.includes(userId)) tasksCount++
      })

  dispatch({
    type: paragraphConstants.SET_TASKS_COUNT,
    tasksCount
  })
}



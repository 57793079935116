import React from 'react';

export const PlusSign = ({margin}) => {
  const pluStyle = {width:'25%', display: 'block', margin: '20px auto 16px'};
  if (margin) pluStyle.marginTop = margin;

  return (
    <svg style={pluStyle} 
        xmlns='http://www.w3.org/2000/svg' width='81' height='81' viewBox='0 0 81 81'>
        <path fill='none' fillRule='evenodd' stroke='#DEDEDE'
           d='M47.29.5H33.71v33.21H.5v13.58h33.21V80.5h13.58V47.29H80.5V33.71H47.29V.5z' />                            
    </svg>
  );
};

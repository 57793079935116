import React from "react"
import Button from "../button/Button"
import {logout} from "../../services/actions/userActions"
import {connect} from "react-redux"
import { transS } from "../../services/helpers/lang"

@connect(state => ({}), {logout})
export default class DeactivatedNotice extends React.Component {
    render() {
        return (
            <div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                <div style={{marginBottom: '10px'}}>Your account has been deactivated by the company admin.</div>
                <Button text={transS('Log Out')} mod='blue arch' callback={this.props.logout} />
            </div>
        )
    }
}
import React, {Component} from 'react'
import styles from './companySettings.css'
import cssModules from "react-css-modules"
import cx from "classnames"
import UserListItem from "../../components/createProjectDropDown/UserListItem"
import Button from "../../../../components/button/Button"
import {popUpWithInputReset} from "../../../../services/helpers"
import { transS } from '../../../../services/helpers/lang'

@cssModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
class UserLine extends Component {
    state = {
        isOpen: false,
        showPasswordPopup: false,
        newPassword: '',
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (e) => {
        if (this.refBlock && e.target !== this.refBlock && !this.refBlock.contains(e.target)) {
            this.setState({isOpen: false})
        }
    }

    deactivateMember = () => {
        const {user} = this.props
        this.props.deactivateMember(user)
    }

    deleteMember = () => {
        const {user} = this.props
        this.props.deleteMember(user)
    }

    changeMemberPassword = () => {
        const {user} = this.props
        const {newPassword} = this.state

        if (newPassword.length < 2) return

        this.props.changeMemberPassword(user._id, newPassword)
        this.setState({newPassword: '', showPasswordPopup: false})
    }

    render() {
        const {user, popUpReset, getPopUpAction, isRequested} = this.props
        const {isOpen, showPasswordPopup, newPassword} = this.state

        const teams = user.teams && user.teams.map((t, index) => {
            if (t === '_general') return

            const length = user.teams.length
            return <span key={t}>{`${t}${ ((index !== 0) && (index + 1 !== length)) ? ' / ' : ''}`}</span>
        })

        const plan = user.isActivated ? (user.totalPaying ? (user.ticks >= 0 ? transS('Basic') : transS('Basic (Expired)')) : (user.ticks >= 0 ? transS('Demo') : transS('Free'))) : ''

        const popup = {
            deactivateMember: {
                name: 'confirm',
                text: `<div>
                          <div style="margin-bottom: 20px;font-size: var(--fontSize_h2); font-weight: bold">Deactivate Member?</div>
                          Are you sure you want to deactive the user?<br> 
                          His account will be blocked.</div>`,
                confirm: {
                    name: 'Deactivate Member',
                    danger: true,
                    event: () => {
                        this.deactivateMember();
                    }
                },
                cancel: {
                    name: transS('No'),
                    event: () => {
                        getPopUpAction(popUpReset);
                    }
                }
            },
            declineRequest: {
                name: 'confirm',
                text: `<div>
                          <div style="margin-bottom: 20px;font-size: var(--fontSize_h2); font-weight: bold">${transS('Decline Request')}?</div>
                          Are you sure you want to decline the request to join the company?</div>`,
                confirm: {
                    name: transS('Decline Request'),
                    danger: true,
                    event: () => {
                        this.deleteMember();
                    }
                },
                cancel: {
                    name: transS('No'),
                    event: () => {
                        getPopUpAction(popUpReset);
                    }
                }
            },
        }

        return (
            <div styleName={'userLine-wrapper'}>
                <div styleName='col1'><UserListItem maxNameWidth={180} noCheck={true} user={user}/></div>
                <div styleName='col2'>{user.title || ''}</div>
                <div styleName='col3'>{teams}</div>
                <div styleName='col4'>{isRequested ? 'Requested' :
                    user.accessStatus === 2 ? 'Super Admin' : user.accessStatus === 1 ? transS('Admin') : 'Member'}</div>
                <div styleName='col5'>{plan}</div>
                <div styleName='col6' ref={e => this.refBlock = e}>
                    <div onClick={() => this.setState({isOpen: !isOpen})} styleName={cx('userLine-edit_btn', {'userLine-edit_btn_open': isOpen})}>
                        <span></span><span></span><span></span>
                    </div>
                    {isOpen && <div styleName='userLine-settings'>
                        {isRequested ? <React.Fragment>
                                <Button text='Add as Member' mod='blue margin0' callback={this.props.acceptRequest}/>
                                <Button text={transS('Decline Request')} mod='red margin0' callback={() => getPopUpAction(popup.declineRequest)}/>
                            </React.Fragment>
                            :
                            user.isActivated ?
                                <React.Fragment>
                                    <Button text='Edit Member' mod='blue margin0' callback={() => this.props.openEdit()}/>
                                    <Button text='Change Password' mod='blue margin0'
                                            callback={() => this.setState({showPasswordPopup: true})}/>
                                    <Button text='Deactivate Member' mod='red margin0'
                                            callback={() => getPopUpAction(popup.deactivateMember)}/>
                                    {/*<Button text='Delete Member' mod='_ghostBlue _red' callback={this.deleteMember}/>*/}
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Button text='Edit Member' mod='blue margin0' callback={() => this.props.openEdit()}/>
                                    <Button text='Delete Member' mod='red margin0' callback={this.deleteMember}/>
                                </React.Fragment>}
                    </div>}
                </div>

                {showPasswordPopup && <div styleName='passwordPopup-wrapper'>
                    <div styleName='passwordPopup-card'>
                        <span className='close-cross' onClick={() => this.setState({showPasswordPopup: false})}>+</span>
                        <div styleName='passwordPopup-title'>Change Password</div>
                        <div styleName='passwordPopup-text'>Enter new password for {`${user.firstname} ${user.lastname}`}.  This will log out Member from their account.</div>
                        <input styleName='passwordPopup-input' type="password" value={newPassword}
                               placeholder={transS('New Password')} autoFocus
                               onChange={e => this.setState({newPassword: e.target.value})}/>
                         <div styleName='passwordPopup-btnRow'>
                             <Button text={transS('Cancel')} mod='blue arch big' callback={() => this.setState({showPasswordPopup: false})}/>
                             <Button text='Change Password' mod='blue fill big margin0' callback={this.changeMemberPassword}/>
                         </div>
                    </div>
                </div>}
            </div>
        )
    }
}

export default UserLine
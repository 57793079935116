import React from 'react'
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import styles from './SmallUser.css';
import cx from 'classnames';
import Checkbox from '../../../../components/checkbox/Checkbox';
import Hint from '../../../../components/button/Hint';
import UserLogo from "../../../../components/userLogo/UserLogo";
import InviteUserOptions from "./InviteUserOptions";
import Button from "../../../../components/button/Button"
import Tip from "../../../../components/tip/Tip"
import {eventSubtypes} from "../../../../services/helpers/logger"
import { getDateString } from "../../../../../common/getDateString"
import {isEqual} from "lodash"
import Spinner from "../../../../components/spinner/Spinner"
import {connect} from "react-redux"
import { signingTool, transF, transS } from '../../../../services/helpers/lang';

@connect(state => ({    
    dateData: state.userReducer.dateData
}), {})
@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})

class SmallUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isRemovePopupShow: false,
            isFullInfoMode: false,
            isEditUserPopup: false,
            editUserData: {
                email: '',
                firstname: '',
                lastname: '',
            },
            isNameCut: false,
            resendInviteProcess: false
        }
    }

    componentDidMount() {
        const {user, maxNameWidth} = this.props
        window.addEventListener('mousedown', this.clickOutsideHandler)

        if (user && !user.isActivated)
            this.setState({editUserData: {email: user.email, firstname: user.firstname, lastname: user.lastname}})

        if (this.refNameBlock && this.refNameBlock.offsetWidth >= (maxNameWidth - 10 || (this.props.isAddListItem && 240) || 140))
            this.setState({isNameCut: true})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(prevProps.user, this.props.user)) {
            if (this.refNameBlock && this.refNameBlock.offsetWidth >= (this.props.maxNameWidth - 20 || (this.props.isAddListItem && 240) || 140) && !this.state.isNameCut) this.setState({isNameCut: true})
            else if (this.state.isNameCut) this.setState({isNameCut: false})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.clickOutsideHandler)
    }

    clickOutsideHandler = e => {
        if (this.refRemovePopup && e.target !== this.refRemovePopup && !this.refRemovePopup.contains(e.target))
            this.setState({isRemovePopupShow: false})

        if (this.refWrapper && e.target !== this.refWrapper && !this.refWrapper.contains(e.target))
            this.setState({isFullInfoMode: false, isEditUserPopup: false})
    }

    crossClickHandler = e => {
        e.preventDefault()
        e.stopPropagation()

        if (this.props.isJustAdded)
            return this.removeUser()

        this.setState({isRemovePopupShow: true})
    }

    removeUser = () => {
        this.props.func(this.props.number, 'removeUser', {user: this.props.user, isNotUpdate: this.props.isJustAdded && this.props.isInPopup})
        this.setState({isRemovePopupShow: false})
    }

    editUserHandler = () => {
        this.props.func(this.props.number, 'updateUser', {user: this.props.user, newData: this.state.editUserData})
        this.setState({isEditUserPopup: false})
    }

    makeUserStats = () => {
        const {user, project, userId, myUserGroup, canEdit, isJustAdded, dateData} = this.props
        const {resendInviteProcess} = this.state
        const stats = user.projStats
        if (!stats) return

        const isCreator = user._id === project.creator
        const inviteE = stats.find(i => i.subtype === eventSubtypes.INVITE_USER)
        const joinedE = stats.find(i => i.subtype === eventSubtypes.OPEN_PROJECT) // first open project
        const rightsE = []

        stats.filter(i => i.subtype.includes('ADD_'))
            .map(i => {
                let events
                if (i.subtype === eventSubtypes.ADD_APPROVE_RIGHTS) events = ['Approver']
                else if (i.subtype === eventSubtypes.ADD_SIGNEE_RIGHTS) events = ['Signee']
                else if (i.subtype === eventSubtypes.ADD_ADMIN_RIGHTS) events = ['Admin']
                return {when: i.when, user: i.user, events}
            })
            .forEach((i, ind, arr) => {
                const test = rightsE.find(el => el.when - i.when < 60000 && el.when - i.when > -60000 && el.user._id === i.user._id)
                if (test) test.events = [...test.events, ...i.events]
                else rightsE.push(i)
            })

        return <div styleName='userStats'>
            {inviteE && !isCreator && <div>
                 {transF('invidedBy')(inviteE.user.firstname, inviteE.user.lastname, 
                    getDateString(inviteE.when, 0, dateData))} 
                </div>}

            {user._id !== userId && !isJustAdded && 
              (myUserGroup ? userId === user.invitedBy : canEdit) && 
              (!joinedE && resendInviteProcess ?
                <div styleName='resendInviteSpinner' onClick={this.resendInvitation}>
                    <Spinner withoutLogo={1} />Re-sending invite...
                </div>
                :
                <Button text={transS('Re-send Invitation')} mod='blue margin0' 
                    style={{marginBottom: '20px', 
                      whiteSpace: 'nowrap', height: '12px'}} 
                    callback={this.resendInvitation}/>)}

            {rightsE.map((el, ind) => {
                return <div key={el.events.join('') + ind}> 
                  {transF('MadeRights')(el.events.join(', '), 
                        el.user.firstname, el.user.lastname, 
                        getDateString(el.when, 0, dateData))} 
                </div>                   
            })}

            {joinedE && <div>
                {transF('Joined')(getDateString(joinedE.when, 0, dateData))}
            </div>}
        </div>
    }

    resendInvitation = () => {
        const {resendInviteProcess} = this.state
        const {resendInvite} = this.props

        this.setState({resendInviteProcess: true})

        resendInvite().then(() => this.setState({resendInviteProcess: false}))
    }

    nameClickHandler = e => {
        const {isFullInfoMode} = this.state
        const {isAddListItem, func, number, user} = this.props

        if (isAddListItem) return func(number, 'addMember', user)

        this.setState({isFullInfoMode: !isFullInfoMode})
    }

    render() {
        const {func, number, user, prove, admin, isAddListItem, noCheck, isInPopup, signee, userId, isJustAdded, canEdit, maxNameWidth, myUserGroup, noTip} = this.props;
        const {isRemovePopupShow, isFullInfoMode, isEditUserPopup, editUserData, isNameCut} = this.state

        const isExt = number !== 0
        const isName = !!`${user.firstname}${user.lastname}`
        const isMe = user._id === userId, canSign = !!signingTool()
        
        return (
            <React.Fragment>
                <div styleName={cx('wrapper', {'addListItem': isAddListItem, 'fullInfo': isFullInfoMode, 'ext': isExt, 'b': !isInPopup && isJustAdded, 'ja': isJustAdded, 'nc':noCheck})}
                     ref={e => this.refWrapper = e}>
                    <div styleName='left' style={maxNameWidth ? {width: maxNameWidth + 30} : null}>
                        <UserLogo {...{...user, email: user.temporary ? 'New User' : user.email}} mod='_small' />
                        <div styleName={cx('nameBlock', {'_email': !user.firstname && !user.lastname})} ref={e => this.refNameBlock = e} style={maxNameWidth ? {maxWidth: maxNameWidth - 10} : null}>

                            {isName && <div styleName='name' onClick={this.nameClickHandler} data-close={1}>{user.firstname} {user.lastname}</div>}
                            <div styleName='email' onClick={() => isName ? null : this.nameClickHandler()} data-close={1}>{user.temporary ? 'New User' : user.email}</div>

                            {!user.isActivated && isJustAdded && <React.Fragment>
                                <Button text={isName ? transS('Edit Name') : transS('Add Name')} mod='blue' 
                                  style={{height: '14px', top: '-3px', whiteSpace: 'nowrap'}}
                                  callback={() => this.setState({isEditUserPopup: !isEditUserPopup})}/>
                            </React.Fragment>}
                            {!user.isActivated && <div styleName='notRegistered'>
                                {transS('Not registered yet')}</div>}
                            {isNameCut && !noTip && <div styleName='nameBlock_tip'>
                                <Tip text={`${user.firstname ? `${user.firstname} ${user.lastname}<br />` : ''}${user.email}`} arrow='bottom left' />
                            </div>}
                        </div>

                    </div>

                    {isEditUserPopup && <div styleName='addNamePopup'>
                        {!user._id &&  <input type="text" placeholder='Email' style={{width: '320px'}} value={editUserData.email}
                                              onChange={e => this.setState({editUserData: {...editUserData, email: e.target.value}})}/>}
                        <input type="text" placeholder={transS('First Name')} style={{marginRight: '20px'}} value={editUserData.firstname}
                               onChange={e => this.setState({editUserData: {...editUserData, firstname: e.target.value}})}/>
                        <input type="text" placeholder={transS('Last Name')} value={editUserData.lastname}
                               onChange={e => this.setState({editUserData: {...editUserData, lastname: e.target.value}})}/>
                        <Button text={transS('Done')} mod='blue fill' style={{marginRight: '10px'}} callback={this.editUserHandler}/>
                        <Button text={transS('Cancel')} mod='blue arch' callback={() => this.setState({isEditUserPopup: false})}/>
                    </div>}

                    {!isAddListItem && <React.Fragment>

                        {canEdit && <div styleName='controls'>
                            {!isExt && <div styleName='control prove'>
                                <Checkbox checked={prove} callback={() => func(number, 'proveUser', user)}
                                          text={transS('Approver')} mod='small gray'/>
                                <Tip text={transS('Approval required for sending the document to the next team')}
                                     arrow='bottom center'/>
                            </div>}


                            {canEdit && canSign && <div styleName={cx('control', {'_checked': signee})}>
                                <Checkbox checked={signee} callback={() => func(number, 'signeeUser', {signeeUser: user, changeTo: !signee})} text={transS('Signee')} mod='small gray'/>
                                <Tip text={transS('After the document is finalised, a signee would need to sign the document. This requires logging in your DocuSign account.')}
                                    arrow='bottom center'/>
                            </div>}

                            {!isExt && <div styleName={cx('control', {'_checked': admin})}>
                                <Checkbox checked={admin} callback={() => func(number, 'adminUser', {adminUser: user, changeTo: !admin})} text={transS('Admin')} mod='small'/>
                                <Tip 
                                    text={transS('Project Admin can add and delete users and send the document without approvals.')} 
                                    arrow='bottom center'/>
                            </div>}

                        </div>}

                        {!noCheck && <button styleName='userInfo_button' onClick={() => this.setState({isFullInfoMode: !isFullInfoMode})}><span /><span /><span /></button>}

                        {!isMe && (myUserGroup ? userId === user.invitedBy : canEdit) &&
                        <div className='close-cross' style={{top: '-5px', right: '0'}} onClick={this.crossClickHandler}>
                            +</div>}


                        {!noCheck && <div styleName='userInfo' style={this.refNameBlock ? {paddingTop: 36 + this.refNameBlock.offsetHeight} : null}>
                            {this.makeUserStats()}
                        </div>}

                    </React.Fragment>}

                    {isAddListItem && <div styleName='addBtn'><Button mod='blue margin0' text={transS('Add')} callback={() => func(number, 'addMember', user)}/></div>}

                </div>

                {isRemovePopupShow && <div styleName='removePopupWrapper'>
                    <div styleName='removePopup' ref={e => this.refRemovePopup = e}>
                        <div className='close-cross' style={{top: '15px', right: '20px'}} onClick={() => this.setState({isRemovePopupShow: false})}>+</div>
                        <div className='h3' style={{lineHeight: 'normal', marginBottom: '20px'}}>{transS('Remove from Team?')}</div>
                        <Button text={transS('No')} mod='blue arch' style={{width: '100px', marginRight: '10px'}} callback={() => this.setState({isRemovePopupShow: false})}/>
                        <Button text={transS('Remove')} mod='red arch margin0' style={{width: '100px'}} callback={this.removeUser}/>
                    </div>
                </div>}

            </React.Fragment>
        );
    }
}


SmallUser.propTypes = {
  func: PropTypes.func,
  number: PropTypes.number,
  user: PropTypes.object,
  prove: PropTypes.bool,
  deleted: PropTypes.bool
};



export default SmallUser;
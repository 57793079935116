import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import CSSModules from 'react-css-modules';
import styles from './footer.css';
import Logo from "../logo/Logo"
import appConfig from '../../../configs/appConfig';
import { publicHost } from '../../services/helpers/lang';

const Footer = () => {
  return (
    <div styleName="wrapper">
        <div styleName='logo'><Logo /></div>
        <span styleName="copyright">©  {new Date().getFullYear()} Contract.one, Inc.</span>
        <NavLink styleName='link' to='/schedule'>Request Trial</NavLink>
        <NavLink styleName='link' to='/legal'>Legal</NavLink>
        <NavLink styleName='link' to='/security'>Security</NavLink>
        <NavLink styleName='link' to='/contacts'>Contacts</NavLink>
        <a styleName='link' href={`${publicHost}/blog/`}>Blog</a>
        <NavLink styleName='link mission' to='/mission'>Clarity & Love: <span>Mission Statement</span></NavLink>
    </div>
)};

export default new CSSModules(Footer, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });

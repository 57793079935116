import React from 'react'
import styles from '../../topMenu.css';
import CSSModules from "react-css-modules";

import Button from "../../../../../../components/button/Button";
import Comment from '../../../../../../components/comment/Comment'
import {connect} from 'react-redux'
import api from '../../../../../../services/api/api'
import {isEqual} from 'lodash'
import Tip from '../../../../../../components/tip/Tip'
import { transL, transS } from '../../../../../../services/helpers/lang';

@connect(state => ({
    user: state.userReducer.user,
    internalUserList: state.userReducer.internalUserList,
    externalUserList: state.userReducer.externalUserList,
    actualDocument: state.document.actualDocument,
    currentProject: state.projectReducer.currentProject,
    messages: state.projectReducer.currentProject.messages,
}), {})
@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
export default class SendMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addNote: null, // eventName
            sendMessages: [],
        }
    }

    componentDidMount() {
        const {actualDocument} = this.props
        if (this.props.messages) {
            const sendMessages = this.props.messages
                .filter(m => m.type === 3)
                .filter(m => !m.delete)
                .filter(m => !!m.freeze)
                .filter(m => m.document === actualDocument.coreDocument)
                .filter(m => m.when > Date.parse(actualDocument.createdAt))
            this.setState({sendMessages})
        }

        window.addEventListener('beforeunload', this.unloadPageHandler)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {actualDocument} = this.props
        if (this.props.messages) {
            const sendMessages = this.props.messages
                .filter(m => m.type === 3)
                .filter(m => !m.delete)
                .filter(m => !!m.freeze)
                .filter(m => m.document === actualDocument.coreDocument)
                .filter(m => m.when > Date.parse(actualDocument.createdAt))
            if (!isEqual(this.state.sendMessages, sendMessages)) {
                this.setState({sendMessages})
            }
        } else if (this.state.sendMessages && this.state.sendMessages.length) {
            this.setState({sendMessages: []})
        }

    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.unloadPageHandler)
    }

    unloadPageHandler = () => {
        if (this.props.actualDocument.writingCoverMessage) {
            this.addMessageBtnClickHandler()
        }
    }

    messagesFunc = (action, params) => {
        const {addNote} = this.state
        const {actualDocument, user} = this.props
        switch (action) {
            case 'addMessage':
                api.addMessage(3, actualDocument.projectId, params.content, actualDocument.coreDocument, [], user.userGroup, true, addNote)
                break
            case 'addReply':
                api.replyMessage(params.messageId, params.content, user.userGroup)
                break
            case 'editMessage':
            case 'editReply':
                api.editMessage(params.messageId, params.content, params.reply)
                break
            case 'deleteMessage':
            case 'deleteReply':
                api.deleteMessage(params.messageId, params.reply)
                break
            case 'resolveMessage':
                api.resolveMessage(params.messageId, params.value)
                break
            case 'unreadCheck':
                api.unreadCheckMessage(params.messageId, params.reply)
                break
            case 'changeTitle':
                api.changeTitle(params.messageId, params.title)
                break
        }
    }

    addMessageBtnClickHandler = (eventName = null) => {
        const {currentProject, actualDocument, user} = this.props

        api.setWritingCoverMessage({action: !!eventName, projectId: currentProject._id, documentId: actualDocument._id, group: user.userGroup})

        this.setState({addNote: eventName})
    }

    makeAddMessageBtn = (eventName) => {
        const {addNote, sendMessages} = this.state
        const {actualDocument, internalUserList, isExternal, isProjectAdmin, hint, approvers} = this.props
        const approversList = !hint && approvers(true)
        const userWritingCoverMessage = actualDocument.writingCoverMessage && internalUserList.find(el => el._id === actualDocument.writingCoverMessage)
        const canSend = !userWritingCoverMessage && (isExternal || isProjectAdmin || (!approversList || !approversList.length))
        const addNoteBlocked = !canSend || hint || sendMessages.length || userWritingCoverMessage;
        const isDoc = !actualDocument.contentType;

        if (addNote || sendMessages.filter(el => el.event === eventName).length) return
        let modContent = 'blue ' + (addNoteBlocked ? ' disabled' : ''),
          buttonText = eventName === 'SEND' ?
            (isDoc ? transS('Send with Message') : transS('Share with Note'))
            : transS('Add Message');
        if (eventName !== 'SEND' || !isDoc) modContent += ' arch';
        
        return <div styleName='sendMenu_messageBtn'>
            <Button text={buttonText} mod={modContent + ' top10'}
               callback={() => addNoteBlocked ? null : this.addMessageBtnClickHandler(eventName)}/>
            {!!addNoteBlocked &&
            <Tip text={hint ? hint : userWritingCoverMessage
                ? `${userWritingCoverMessage.firstname + ' ' + userWritingCoverMessage.lastname} is drafting a message now.`
                : 'You can only add a message to one action — Send, Collaborate or Finalise.'} arrow='bottom left' />}
        </div>
    }

    makeAddMessageEl = (eventName) => {
        const {addNote} = this.state
        const {internalUserList, externalUserList, user} = this.props

        if (eventName !== addNote) return

        return <div styleName='sendMenu-note'>
            <Comment isCoverMessage={true} isNewComment={true} isEdit={true} me={user} blurHandler={() => this.addMessageBtnClickHandler()} sendBtnText={transS('Save')}
                     side={1} func={this.messagesFunc} externalUserList={externalUserList} internalUserList={internalUserList} />
        </div>
    }

    makeMessagesList = (eventName) => {
        const {sendMessages} = this.state
        const {user, isProjectAdmin, externalUserList, internalUserList} = this.props

        return sendMessages
            .filter(el => el.event === eventName)
            .map(el => <div styleName='sendMenu-note' key={el._id + 'smns'}>
                <Comment  {...el} me={user} sendBtnText={transS('Save')} isProjectAdmin={isProjectAdmin}
                          recipients={el.recipients.length ? el.recipients : null}
                          isNoResolve={true} isNoSendNow={true} isEditCreator={true} isNoReply={true}
                          externalUserList={externalUserList} internalUserList={internalUserList}
                          func={this.messagesFunc} isCoverMessage={true} />
            </div>)
    }

    render() {
        const {
                close, send, collaborate, approvers, isCollaborative, hint, requestBack,
                takeOverEdit, isProjectAdmin, isExternal, finalize, approverUserList, 
                approved, user, internalUserList, externalUserList, currentProject, 
                actualDocument, freezeFunc
            } = this.props
        const {addNote, sendMessages} = this.state
        const approversList = !hint && approvers(true)
        const userWritingCoverMessage = actualDocument.writingCoverMessage && internalUserList.find(el => el._id === actualDocument.writingCoverMessage)
        const canFreeze =  
            isExternal || isProjectAdmin || (!approversList || !approversList.length),
            canSend = canFreeze && !hint && !userWritingCoverMessage
        const addNoteBlocked = !canSend || sendMessages.length || userWritingCoverMessage
        const maxHeight = window.innerHeight - 187
        const isDoc = !actualDocument.contentType,
            isImported = actualDocument.orig === 20
        if (hint && isImported) {
            return <div styleName='sendMenu-part'>
                <div styleName='sendMenu-approvals'>
                    <div styleName='approvals-needed-text'>{hint}</div>
                </div>
            </div>
        }
        const showNormalSend = isDoc || addNote
           || sendMessages.filter(el => el.event === 'SEND').length;

        return (
            <React.Fragment>
              { showNormalSend ?  
                <div styleName='sendMenu-part _scroll' style={{maxHeight}}>
                    {isDoc && <div className='close-cross' onClick={close}>+</div>}

                    <div styleName='sendMenu-send'>
                        {isDoc && <div className='h3' style={{marginBottom: '10px'}}>
                            {transS('Send to Counterparty')}</div> }
                        {isDoc ? <div styleName='sendMenu-send-small'
                          dangerouslySetInnerHTML={{__html: transL('team will be able')}} />                            
                        :
                        <div styleName='sendMenu-send-smallthin'>
                            {transS('The counterparty will be able to see this document. You can revoke access.')}
                        </div> }
                        {this.makeAddMessageEl('SEND')}
                        {this.makeMessagesList('SEND')}

                        <Button
                            text={isDoc ? transS('Send') : transS('Share')}
                            mod={canSend ? 'blue fill' : 'blue fill disabled'}
                            style={{marginRight: '10px', marginTop: '20px'}}
                            callback={canSend ? () => {close();send()} : null}/>
                        { this.makeAddMessageBtn('SEND')}
                        {!!user.continuity && isDoc &&  <React.Fragment>
                            <div styleName={`btnFreeze${canFreeze ? '' : ' nonFrost'}`} onClick={()=> canFreeze && freezeFunc(null)}>
                                Export to Send Via Email and Freeze
                            </div>
                            <div styleName='txtFreeze'>
                                Export to Send Via Email and Freeze will:<br/>
                                &nbsp;&nbsp;•&nbsp;&nbsp;export this document in .docx;<br/>
                                &nbsp;&nbsp;•&nbsp;&nbsp;freeze this version until you import a new version or unfreeze.<br/>
                                When you import a new version, Contract.one will treat it as a version from the Counterpaty.<br/>
                            </div>
                        </React.Fragment>}
                    </div>

                    {isDoc && <div styleName='sendMenu-collaborate'>
                        <div className='space50'></div>
                        <div className='h3' style={{marginBottom: '10px'}}>
                            {isCollaborative ? transS('Terminate Collaboration') : transS('Collaborate with Counterparty')}</div>

                        {!isCollaborative && <div styleName='sendMenu-collaborate-small'
                           dangerouslySetInnerHTML={{__html: transL('together')}}
                        />}
                        {isCollaborative ?
                            <React.Fragment>
                                {this.makeAddMessageEl('REQUEST_BACK')}
                                {this.makeMessagesList('REQUEST_BACK')}
                                <Button text={transS('Request Back')}
                                        style={{marginTop: '20px'}}
                                        mod={canSend ? 'blue arch' : 'blue arch disabled'} 
                                        callback={canSend ? () => requestBack() : null}/>
                                {this.makeAddMessageBtn('REQUEST_BACK')}

                                {isProjectAdmin && <React.Fragment>
                                    <br />
                                    {this.makeAddMessageEl('TAKE_OVER')}
                                    {this.makeMessagesList('TAKE_OVER')}
                                    <Button text={transS('Take Over Editing')}
                                            style={{marginTop: '20px'}}
                                            mod={canSend ? 'red arch' :'red arch disabled'} 
                                            callback={canSend ? () => takeOverEdit() : null}/>
                                    {this.makeAddMessageBtn('TAKE_OVER')}
                                </React.Fragment>}
                            </React.Fragment>
                            :
                            <React.Fragment>
                                {this.makeAddMessageEl('COLLABORATE')}
                                {this.makeMessagesList('COLLABORATE')}

                                <Button text={transS('Collaborate')}
                                        mod={canSend ? 'blue arch' : 'blue arch disabled'}
                                        style={{marginRight: '10px', marginTop: '20px'}}
                                        callback={canSend ? () => {close();collaborate()} : null}/>
                                {this.makeAddMessageBtn('COLLABORATE')}
                            </React.Fragment>
                        }
                    </div> }

                    {isDoc && <div styleName='sendMenu-finalize'>
                        <div className='h3' style={{marginBottom: '10px'}}>{transS('Finalise Document')}</div>
                        <div styleName='sendMenu-finalize-small'
                          dangerouslySetInnerHTML={{__html: transL('final')}} />

                        {this.makeAddMessageEl('FINALIZE')}
                        {this.makeMessagesList('FINALIZE')}

                        <Button text={transS('Start Finalising')}
                                style={{marginRight: '10px', marginTop: '20px'}}
                                mod={canSend ? 'green arch' : 'green arch disabled'} callback={canSend ? () => {
                            close();
                            finalize()
                        } : null}/>
                        {this.makeAddMessageBtn('FINALIZE')}
                    </div> }

                </div> 
                : <div styleName='sendMenu-part _scroll _thin' style={{maxHeight}}>
                        <Button
                            text={isDoc ? transS('Send') : transS('Share')}
                            mod={canSend ? 'blue fill' : 'blue fill disabled'}
                            callback={canSend ? () => {close();send()} : null}/>
                        <div styleName='sendMenu-send-smallthin'                          
                            style={{marginTop: '10px', marginBottom: '20px'}}>
                            {transS('The counterparty will be able to see this document. You can revoke access.')}
                        </div> 

                        { this.makeAddMessageBtn('SEND')}
                         <div styleName='sendMenu-send-smallthin' style={{ marginTop: '10px'}}>
                            {transS('Share this document with the counterparty and send a note to it.')}
                        </div> 

                   </div> }


                {isDoc && !isExternal && approversList && !!approversList.length &&
                <div styleName='sendMenu-part sendMenu-part_2'>
                    <div styleName='sendMenu-approvals'>
                        <div className='h3' style={{marginBottom: '20px'}}>
                            {`${transS('Approvals Needed')} (${
                                approverUserList.length - approved.length}/${
                                approverUserList.length})`}
                        </div>
                        <div styleName='approvals-needed-text'>
                            {transS('Before you send, export or finalise the document, you need it to be approved by:')}
                        </div>
                        <div styleName='sendMenu-approvalsList'>{approversList}</div>                        
                    </div>
                </div>}

            </React.Fragment>
        )
    }
}
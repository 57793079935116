import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import styles from './projectPage.css';
import cx from 'classnames';

const Flow = props => {
  let { item, selected, callback, no } = props;
  return (
    <div styleName={cx('flows', {'_isSelected':selected===true})} onClick={callback ? ()=>callback(no) : null} >
        <div styleName='flowName'>{item.name}</div>
        {item.desc.map(i => <div key={i}>{i}</div>)}         
    </div>
  );
};


Flow.propTypes = {
  item: PropTypes.object,
  selected: PropTypes.bool,
  callback: PropTypes.func,
  no: PropTypes.number
};

export default new CSSModules(Flow, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });



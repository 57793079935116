import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import styles from './promo.css';
import cx from 'classnames';

const Price = props => {
  let { isSmall, price, priceRemark} = props;
  return (
    <div styleName={cx('price-container', {'_isSmall':isSmall})}>
        <div styleName={cx('price', {'_isSmall':isSmall})}>{price}</div>
        { priceRemark &&
          <div styleName='priceRemark'>{priceRemark}</div>
        }
    </div>    
  );
};


Price.propTypes = {
  isSmall: PropTypes.bool,
  price: PropTypes.string,
  priceRemark: PropTypes.string
};

export default new CSSModules(Price, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });

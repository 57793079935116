import { push } from 'connected-react-router';
import { projectConstants, dataConstants } from '../constants';
import api from '../api/api';
import { popUpWithInputReset, popUpReset } from '../helpers';
import store from '../../services/store';
import { toggleLoaderAction, getPopUpAction } from '../../services/actions/dataActions';
import {eventSubtypes, eventTypes, saveStats} from "../helpers/logger"
import { transS } from '../helpers/lang';

// get project list action
export const getProjectList = () => async dispatch => {
  let projectList = null;

  try {
    projectList = await api.getProjectList();
  } catch (err) {
    throw err;
  }

  dispatch({
    type: projectConstants.GET_PROJECT_LIST,
    projectList: projectList.data.data.projects
  });
  return projectList.data.data.projects
};

export const getProjectDocuments = () => async dispatch => {
  let projectDocList = null;

  try {
    projectDocList = await api.getProjectDocList();
  } catch (err) {
    throw err;
  }
  let state = store.getState(), 
    list = state.userReducer.user.docs, 
    projects = projectDocList.data.data.projects;
    projects.forEach(p => p.docs && p.docs.forEach(
       d => {
         if (!d.dochanges) return;
         let l = list && list[d.coreDocument];
         if (l) {
           let ass = JSON.parse(d.dochanges);
           for (let id in ass) {
             if (l[id] && l[id] >= ass[id])
               delete ass[id];
           }
           d.dochanges = ass;
         } else d.dochanges = null;
       }
    ));

  dispatch({
    type: projectConstants.GET_PROJECT_DOCUMENTS_LIST,
    projectDocList: projectDocList.data.data.docs,
    projectList: projects
  });
  return projects;
};

// get project list action
export const getProjectExport = (projectId) => async dispatch => {
  let result = null;

  try {
    result = await api.getProjectExport(projectId);
  } catch (err) {
    throw err;
  }

  dispatch({
    type: projectConstants.GET_PROJECT_EXPORT,
    result: result.data
  });
};


export const setCurrentProject = data => dispatch => {
  let currentProject = null;

  const prevCurrentProject = store.getState().projectReducer.currentProject
  if (prevCurrentProject._id !== data.projectId && !!data.projectId) {
    dispatch(saveStats({type: eventTypes.PROJECT, subtype: eventSubtypes.OPEN_PROJECT, project: data.projectId, when: Date.now()}))
  }

  if (data.projectId && data.projectList) {
    currentProject = data.projectList.filter(item => item._id === data.projectId)[0];
  }

  dispatch({
    type: projectConstants.SET_CURRENT_PROJECT,
    currentProject: currentProject || {}
  });
};

export const setCurrentProjectAndProjects = data => dispatch => {
  dispatch({
    type: projectConstants.SET_CURRENT_PROJECT,
    currentProject: data.project,
    projects: data.projectList
  });
};

export const addProjectAction = data => async dispatch => {
  let project = null;

  try {
    project = await api.addProject(data);
  } catch (err) {
    throw err;
  }

  if (project.data.data.errorList) {
      dispatch(getPopUpAction({
        name: 'confirm',
        text: project.data.data.errorList,
        confirm: {
                name: transS('Confirm'),
                event: () => { 
                    dispatch(getPopUpAction(popUpReset)); 
                    dispatch({
                        type: projectConstants.ADD_PROJECT,
                        project: project.data.data,
                        currentProject: project.data.data
                    });
                    dispatch(push(`/#/${project.data.data._id}`));
                }
        }
      }));      
  }
  else {
      dispatch({
        type: projectConstants.ADD_PROJECT,
        project: project.data.data,
        currentProject: project.data.data
      });

      dispatch(push(`/#/${project.data.data._id}`));
  }
  return project.data.data
};

export const inviteToProject = ({ projectId, checkedUsers, addedUsers, deletedExternals, deletedInternals, proved, provedAdded, canTeam }) => async dispatch => {
  let result = null;
  try {
    result = await api.inviteToProject(projectId, checkedUsers, addedUsers, deletedExternals, deletedInternals, proved, provedAdded, canTeam);
  } catch (err) {

  }
  
  if (result && result.data && result.data.data) {
      dispatch(getPopUpAction({
        name: 'confirm',
        text: result.data.data,
        confirm: {
                name: transS('Confirm'),
                event: () => { dispatch(getPopUpAction(popUpReset)); }
        }
      }));
  }
  //dispatch(getInvitePopupAction(false));
};

/**
 * Action to update project in store and database
 * @param {string} projectId - It is the id of the project being renamed
 * @param {string} newName - It is a new name for the project
 * @param {array || null} admins - It is a new admins array for the project
 * @param {array || null} signee - It is a new signee array for the project
 */
export const updateProjectAction = (projectId, newName, admins = null, signee = null) => async dispatch => {
  /**
   * Update project in database
   */
  let proj
  try {
    let res = await api.updateProject(projectId, newName, admins, signee);
    proj = res.data.data
  } catch (err) {
    throw err;
  }
  /**
   * Update in store
   */
  if (proj.admins || proj.signee) {
    dispatch({
      type: projectConstants.UPDATE_PROJECT,
      projectId,
      newName,
      admins: proj.admins,
      signee: proj.signee
    });
  } else {
    dispatch({
      type: projectConstants.RENAME_PROJECT,
      projectId,
      newName
    });
  }


  /**
   * Reset poput with input
   */
  dispatch({
    type: dataConstants.GET_POP_UP_WITH_INPUT,
    popUpWithInput: popUpWithInputReset().popup
  });

  let state = store.getState();
  let list = state.projectReducer.projectList;
  let newCurrentProject = list.find(item => item._id === projectId)
  //newCurrentProject = Object.assign({}, )

  /**
   * Updating current project
   */
  dispatch({
    type: projectConstants.SET_CURRENT_PROJECT,
    currentProject: newCurrentProject
  });

  dispatch(toggleLoaderAction(false));
};

/**
 * It deletes the project from everywhere
 * @param {string} projectId - Id of the project to delete
 */
export const deleteProjectAction = projectId => async dispatch => {
  /**
   * it deletes the project in database
   */
  try {
    await api.deleteProject(projectId);
  } catch (err) {
    throw err;
  }

  /**
   * deletes the project from store
   */
  dispatch({
    type: projectConstants.DELETE_PROJECT,
    projectId
  });

  /**
   * closes confirmation popup
   */
  dispatch({
    type: dataConstants.GET_POP_UP,
    popUp: popUpReset()
  });

  /**
   * returns to main page as we don't have a current project any more
   */
  dispatch(push('/'));
};

/**
 * Event after a project was deleted by another person. Used in websockets communication
 * @param {string} projectId - Id of the deleted project. Deprecated.
 */
export const projectDeletedAction = projectId => async dispatch => {
  dispatch({
    type: dataConstants.GET_POP_UP,
    popUpWithInput: popUpReset()
  });

  /**
   * redirect to main page as we do not have current project any more
   */
  dispatch(push('/'));
};

/**
 * Dispatches an event to toogle popup for invitation people
 * @param {string} projectId - Project id we want to invite to. Not necessary for closing popup
 * @param {boolean} show - If show === true popup will be shown, otherwise will be hidden
 * @param showInSidebar
 */
export const getInvitePopupAction = (show, projectId = '', showInSidebar, transfer) => ({
  type: projectConstants.GET_INVITE_PROJECT_POPUP,
  payload: {projectId, show, showInSidebar, transfer}
});

export const userExpelledAction = () => async dispatch => {
  dispatch({
    type: dataConstants.GET_POP_UP,
    popUpWithInput: popUpReset()
  });

  dispatch(push('/'));
};

export const setMessage = (message) => ({
  type: projectConstants.SET_MESSAGE,
  message
})


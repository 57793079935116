import React from 'react';
import { colors } from '../colors';

const styled = {flexGrow: 0, flexShrink: 0, 
    alignSelf: "baseline", height: "1em"}

export const Icon_Doc = ({ color = colors.gray, width = 11, height = 13 }) => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 13"
      width={width}
      height={height}
      stroke={color}
      fill="none" 
      fillRule="evenodd"
      style={styled}
    >
      <path d="M1 1h9v11H1V1zm2 2h5M3 5h5M3 7h5" />
    </svg>
  )

  export const Icon_ArrowDown = ({ color = colors.arrowGray, 
    width = 10, height = 12, flip }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 12"
      width={width}
      height={height}
      stroke={color}
      fill="none" 
      fillRule="evenodd" 
      strokeLinecap="square"
      style = {flip ? {...styled, transform: "scaleY(-1)"} : styled}
    >
      <path d="M5 7.958 9.222 4M.778 4 5 7.958" />
    </svg>
  )

  export const Icon_TickCircle = ({ back = colors.green, 
    border = colors.green,
    front = colors.white,
    size = 12}) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
      width={size}
      height={size}
      fill="none" 
      fillRule="evenodd"       
      style={styled}
    >
      <circle stroke={border} fill={back} cx="5" cy="5" r="5"/>
      <path fill={front} d="m7.229 3.501-.072-.041a.487.487 0 0 0-.552.098L4.47 5.693a.495.495 0 0 0-.058.07L3.275 4.627a.406.406 0 0 0-.505-.051l-.061.05a.403.403 0 0 0 0 .566l1.413 1.413a.405.405 0 0 0 .567 0 .395.395 0 0 0 .11-.215.486.486 0 0 0 .236-.131L7.17 4.124a.49.49 0 0 0 .099-.551l-.04-.072z"/>
    </svg>
  )

  export const Icon_Folder = ({ color = colors.gray, width = 13, height = 10 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 12"
        width={width}
        height={height}
        stroke={color}
        fill="none" 
        fillRule="evenodd"
        style={styled}      >
        <path d="M1 3.5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 1.999l6 .001a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H3l-.025-.001L2 11.5a1 1 0 0 1-1-1v-7z" />
      </svg>
    )

  export const Icon_Mouse = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 11 15"
        width="11"
        height="15"
        stroke={colors.darkGray}
        transform="translate(1 .2)"
        fill="none" 
        fillRule="evenodd"
        style={{...styled, transform: "translate(1, 0.2)"}}  >
        <rect y="3" width="7" height="11" rx="3.5"/>
        <path d="M4 4a2 2 0 0 1 2 2v1H4V4z" fill={colors.darkGray}/>
        <path strokeLinecap="square" d="m9.1 3.25-1.05.5M5.67.563l-.34.934M7.74 1.62l-.75.78M1 7h6" />
      </svg>
    )

    export const Icon_Doc_Auto = ({ color = colors.white, width = 12, height = 12 }) => (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 12 12"
          width={width}
          height={height}
          stroke={color}
          fill="none" 
          fillRule="evenodd"
          style={styled}
        >
          <path d="M2 3V.5h9v11H3m1-9h5m-3.5 2H9m-2 2h2"/>
          <path d="M3.57 4.5 1.92 7.462h2.75L1.98 9.7l1.04-1.9H.82l1.1-3.3h1.65zm-1.1.55-1.1 2.2" strokeWidth="1.1" strokeLinecap="square"/>
        </svg>
      )

  export const Icon_Cog = ({ color = colors.primary, size = 12 }) => (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width={size} 
      height={size}
      fill={color}
      fillRule="evenodd"
      style={styled}
      viewBox="0 0 12 12">
      <path d="m7 .5.001 1.112c.497.113.962.308 1.382.57l.781-.782 1.414 1.414-.776.777c.27.426.471.901.587 1.409H11.5v2l-1.112.001c-.11.482-.296.935-.547 1.344l.787.79-1.414 1.413-.77-.77a4.475 4.475 0 0 1-1.443.61L7 11.5H5v-1.111a4.474 4.474 0 0 1-1.427-.599l-.759.758L1.4 9.134l.77-.77A4.473 4.473 0 0 1 1.612 7L.5 7V5h1.111c.114-.502.312-.972.578-1.395l-.789-.79L2.814 1.4l.791.79A4.474 4.474 0 0 1 5 1.61V.5h2zm-1 3a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5z"/>
    </svg>
  )

  export const Icon_Pin =  ({ color = colors.primary, width = 12, height = 12 }) => (
    <svg 
      viewBox="0 0 12 12" 
      xmlns="http://www.w3.org/2000/svg"
      width={width} 
      height={height}
      fill={color}
      style={styled}
      fillRule="nonzero">
      <path d="M7.571.424v.665l.426.83-3.795 2.53H1.018c-.37 0-.555.443-.278.72l2.648 2.64L0 12l4.202-3.36 2.648 2.64c.277.277.721.074.721-.277V7.809l2.518-3.785.833.425h.667c.37 0 .555-.443.277-.72L8.293.129a.42.42 0 0 0-.722.295z" />
    </svg>
  )

  export const Icon_Pinned =  ({ color = colors.primary, width = 13, height = 12 }) => (
    <svg viewBox="0 0 13 12" 
      xmlns="http://www.w3.org/2000/svg"
      width={width} 
      height={height}
      fill={color}
      style={styled}
      fillRule="nonzero">
      <path d="m3.225 1.231.47.47.888.287-.895 4.472L1.437 8.71c-.262.262-.08.706.313.706l3.738-.005.567 2.245.597-2.234 3.738-.005c.393 0 .563-.458.315-.706L8.447 6.453 7.55 1.997l.889-.289.471-.471c.262-.262.08-.706-.313-.706L3.526.511a.42.42 0 0 0-.301.72z" />
    </svg>
  )
  
  export const Icon_Download =  ({ color = colors.primary, width = 11, height = 12 }) => (
    <svg viewBox="0 0 11 12" 
      xmlns="http://www.w3.org/2000/svg"
      width={width} 
      height={height}
      fill={color}
      style={styled}
      fillRule="evenodd">
      <path d="M5.665.762 5.55.755a.838.838 0 0 0-.84.83l.001 6.505-2.343-2.342a.832.832 0 0 0-1.101-.064l-.08.07a.838.838 0 0 0-.007 1.182l3.776 3.775a.832.832 0 0 0 1.125.044.804.804 0 0 0 .113-.093l3.775-3.776a.833.833 0 0 0-.007-1.18.838.838 0 0 0-1.18-.008L6.391 8.09 6.39 1.585a.832.832 0 0 0-.725-.823z"/>
    </svg>
  )

  export const Icon_x = ({color = colors.lightGray2}) => (
    <svg 
      width="10" 
      height="12" 
      viewBox="0 0 10 12" 
      fill={color}
      fillRule="evenodd"
      style={styled}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M.179 1.179a.852.852 0 0 1 1.205 0L5 4.794 8.616 1.18a.852.852 0 0 1 1.125-.071l.08.07a.852.852 0 0 1 0 1.206L6.205 6 9.82 9.616c.307.307.331.79.071 1.125l-.07.08a.852.852 0 0 1-1.206 0L4.999 7.206 1.384 10.82a.852.852 0 0 1-1.125.071l-.08-.07a.852.852 0 0 1 0-1.206L3.793 6 .18 2.384A.852.852 0 0 1 .108 1.26z" />
    </svg>
  )

  
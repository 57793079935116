import api from "../api/api"

export const eventTypes = {
    PROJECT: 'PROJECT',
    DOCUMENT: 'DOCUMENT',
    USER: 'USER',
}
export const eventSubtypes = {
    // project subtypes
    INVITE_USER: 'INVITE_USER',
    ADD_ADMIN_RIGHTS: 'ADD_ADMIN_RIGHTS',
    DELETE_ADMIN_RIGHTS: 'DELETE_ADMIN_RIGHTS',
    ADD_SIGNEE_RIGHTS: 'ADD_SIGNEE_RIGHTS',
    DELETE_SIGNEE_RIGHTS: 'DELETE_SIGNEE_RIGHTS',
    ADD_APPROVE_RIGHTS: 'ADD_APPROVE_RIGHTS',
    DELETE_APPROVE_RIGHTS: 'DELETE_APPROVE_RIGHTS',
    OPEN_PROJECT: 'OPEN_PROJECT',

    // document subtypes
    READING_DOCUMENT: 'READING_DOCUMENT',
    APPROVE_DOCUMENT: 'APPROVE_DOCUMENT',
    DISAPPROVE_DOCUMENT: 'DISAPPROVE_DOCUMENT',

    // user subtypes
    INVITE_ACCEPTED: 'INVITE_ACCEPTED',
}

export const saveStats = data => async dispatch => {
    await api.saveStats(data)
    return 1
}
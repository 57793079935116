import React, { useEffect, useRef, useState } from 'react'
import CSSModules from 'react-css-modules'
import styles from './templatesList.css'
import Dropzone from 'react-dropzone'
import Button from '../../../../components/button/Button'
import {PlusSign} from '../../../../components/plusSign/PlusSign'
import Spinner from '../../../../components/spinner/Spinner';
import { RenamePopup } from './RenamePopup'
import { TemplatesActionCreators } from '../../../../redux/template/action'
import { useDispatch } from 'react-redux'
import { transS } from '../../../../services/helpers/lang'

const UploadPopup = (props) => {
    const {closeHandler, dropHandler, transferNames, createTemplateFromTemplate, isTemplate} = props
    const overlayRef = useRef(null)
    const dispatch = useDispatch()
    const [templatesList, setTemplatesList] = useState([])
    const [isHidePart, setIsHidePart] = useState(false)
    const [clickedOnce, setIsClickedOnce] = useState(false)
    const [overDropZone, setIsOverDropZone] = useState(false)
    const clickOutsideHandler = ({target}) => target === overlayRef.current ? closeHandler() : null
    const createTemplateFromTemplateHandler = (template) => {
        if (clickedOnce) return;
        setIsClickedOnce(true);
        createTemplateFromTemplate(template)
        closeHandler()
    }
    useEffect( () => {
        window.addEventListener('click', clickOutsideHandler);
        (async () => {
          const templatesList = await dispatch(TemplatesActionCreators.getFinalizedTemplatesList())
          setTemplatesList(templatesList.filter(t => t.contentType !== 'folder'))
          if (templatesList.length > 5) setIsHidePart(true)
        })();
        return () => {
            window.removeEventListener('click', clickOutsideHandler)
        }
    }, [])
    return (
        <div styleName='popup_overlay' ref={overlayRef}>
            {clickedOnce && <div styleName='spinwrapper'><Spinner withoutLogo={true} /></div> }
            <div styleName='popup_wrapper' style={{width: '800px', minHeight: '340px', height: 'fit-content'}}>
                <div className='close-cross' onClick={closeHandler}>+</div>
                <div className='flex'>
                    <div style={{marginRight: '40px'}}>
                        <div className='h3'>{transS('Upload')}</div>
                        <Dropzone styleName={'dropzone UP_dropzone' 
                           + (overDropZone ? ' active' : '')}
                            onDrop={dropHandler} 
                            accept={isTemplate ? '.docx' : ''}                        
                            onDragEnter={() => setIsOverDropZone(true)}
                            onDragLeave={() => setIsOverDropZone(false)} >
                            <PlusSign margin="0" />
                            <div styleName='dropzone_text'>{transS('Upload Document')}</div>                            
                        </Dropzone>
                    </div>
                    <div>
                        <div className='h3'>{transS('Or Create from Another Template')}</div>
                        <div styleName='UP_docList'>
                            {(isHidePart ? templatesList.slice(0, 4) : templatesList).map(template => (
                                <div style={{cursor: 'pointer'}}
                                     styleName='UP_docList_item'
                                     key={template._id}
                                     onClick={() => createTemplateFromTemplateHandler(template)}
                                >
                                    <svg styleName='docIcon' width="11" height="13" viewBox="0 0 11 13">
                                        <path fill="none" fillRule="evenodd" stroke="#ACACAC"
                                              d="M1 1h9v11H1V1zm2 2h5M3 5h5M3 7h5" />
                                    </svg>
                                    <span>{template.title}</span>
                                </div>
                            ))}
                        </div>
                        {templatesList.length > 5 &&
                        <Button text={isHidePart ? transS('Show More') : transS('Show Less')} mod='blue' callback={() => setIsHidePart(prev => !prev)} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CSSModules(UploadPopup, styles, {allowMultiple: true})

import api from "../api/api"

export const downloader = downloadParams => {
  if (!downloadParams) return;
  let {format, version, subVersion, isHistory, callback, actualDocument, 
    displayVersion} = downloadParams;
  const {_id, projectId, coreDocument, title} = actualDocument,
    nonDocx = !!actualDocument.contentType,        
    isSignedDoc = (format === 'signed');
  let anchor = document.getElementById('c1DownloadLink');
  if (!anchor) {
      anchor = document.createElement('a');
      anchor.id = 'c1DownloadLink';
      anchor.style.display = "none";
      anchor.download = "";
      document.body.appendChild(anchor);
  }
  if (!version && !subVersion && !displayVersion) {
      version = actualDocument.version;
      subVersion = actualDocument.subVersion;
      isHistory = false;
  } else if (!version && !subVersion && displayVersion) {
      [version, subVersion] = displayVersion.split('.');
      isHistory = true;
  }
  (async () => {
      let res = 1;
      if (nonDocx) {
        anchor.href = `/api/documents/getpure/${projectId}/${coreDocument}/${title}`;
      } else {
        if (isSignedDoc) {
          res = await api.downloadSignedDocument(coreDocument, projectId);            
        } else {
          res = await api.downloadDocument(_id, projectId, version, subVersion, format, isHistory);
        }
        if (res?.status === 200) anchor.href = res.data;
        else res = null;
      }
      if (res) anchor.click();
      if (callback) callback();
    })();      
};
import React, { useEffect, useRef, useState } from 'react'
import CSSModules from 'react-css-modules'
import styles from './templatesList.css'
import Button from '../../../../components/button/Button'
import {PlusSign} from '../../../../components/plusSign/PlusSign'
import Dropzone from 'react-dropzone'
import cn from 'classnames'
import UploadPopup from './UploadPopup'
import { RenamePopup } from './RenamePopup'
import { TemplatesActionCreators } from '../../../../redux/template/action'
import { useDispatch } from 'react-redux'
import { transS } from '../../../../services/helpers/lang'

const AddTemplatePopup = ({closeHandler, addTemplateHandler}) => {
    const dispatch = useDispatch()
    const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false)
    const [isRenamePopupOpen, setIsRenamePopupOpen] = useState(false)
    const [isDragActive, setIsDragActive] = useState(false)
    const [files, setFiles] = useState([])
    const overlayRef = useRef(null)
    const dropTimeout = useRef(null)

    const renamePopupOpenHandler = () => setIsRenamePopupOpen(prev => !prev)
    const renameTemplateHandler = newTitle => {
        setFiles(prev => [{...prev[0], title: newTitle}] )
    }
    const clickOutsideHandler = ({target}) => target === overlayRef.current ? closeHandler() : null
    const dropHandler = files => {
        if (!files.length) return
        if (files.length > 1) files = [files[0]] // <- temporary

        const file = files[0]
        const reader = new FileReader()
        reader.onloadend = () => {
            setFiles(files => [{title: file.name.replace('.docx', ''), file: reader.result}])
        }
        reader.readAsDataURL(file)
        setIsDragActive(false)
        setIsUploadPopupOpen(false)
    }
    const createTemplateHandler = () => {
        addTemplateHandler(files[0])
        closeHandler()
    }
    const createTemplateFromTemplate = (template) => {
        setFiles(files => [{title: template.title, file: {templateId: template._id}}])
    }
    const dragHandler = e => {
        let isInside = e.target === overlayRef.current || overlayRef.current.contains(e.target)
        switch (e.type) {
            case 'dragover':
            case 'dragenter':
                if (!isDragActive && isInside) {
                    setIsDragActive(true)
                } else if (isDragActive && !isInside) {
                    setIsDragActive(false)
                }
                clearTimeout(dropTimeout.current)
                break
            case 'drop':
                setIsDragActive(false)
                break
        }
        dropTimeout.current = setTimeout(() => setIsDragActive(false), 200)
    }
    const deleteTemplateFromDownload = filename => {
        setFiles(files => files.filter(file => file.title !== filename))
    }

    useEffect(() => {
        window.addEventListener('click', clickOutsideHandler)
        window.addEventListener('dragenter', dragHandler)
        window.addEventListener('dragover', dragHandler)
        window.addEventListener('drop', dragHandler)
        return () => {
            window.removeEventListener('click', clickOutsideHandler)
            window.removeEventListener('dragenter', dragHandler)
            window.removeEventListener('dragover', dragHandler)
            window.removeEventListener('drop', dragHandler)
        }
    }, [])

    return (
        <div styleName='popup_overlay' ref={overlayRef}>
            <div styleName='popup_wrapper' style={{width: '480px', height: '412px'}}>
                <div styleName={cn('ATP_fakeMask', {'_hide': isDragActive})}
                     onClick={() => setIsUploadPopupOpen(true)} />
                <Dropzone styleName='dropzone' accept='.docx'
                          onDragOver={dragHandler}
                          onDrop={dropHandler}>
                    <div styleName='dropzone_title'>{transS('New Template')}</div>
                    {files.length ?
                        <div styleName='docList' onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}>
                            {files.map(file => (
                                <div styleName='docList-item' key={file.title}>
                                    <div>
                                        <svg styleName='docIcon' width="11" height="13" viewBox="0 0 11 13">
                                            <path fill="none" fillRule="evenodd" stroke="#ACACAC"
                                                  d="M1 1h9v11H1V1zm2 2h5M3 5h5M3 7h5" />
                                        </svg>
                                        <span>{file.title}</span>
                                    </div>
                                    <Button text={transS('Rename')} mod='blue margin0' callback={renamePopupOpenHandler} />
                                    <div className='close-cross' styleName='deleteBtn'
                                         onClick={() => deleteTemplateFromDownload(file.title)}
                                    >+
                                    </div>
                                    {isRenamePopupOpen && <RenamePopup title={file.title}
                                                                       style={{left: '-20px'}}
                                                                       closeHandler={renamePopupOpenHandler}
                                                                       saveHandler={renameTemplateHandler} />}
                                </div>
                            ))}
                        </div>
                        :
                        <>
                            <PlusSign margin="50px" />
                            <div styleName='dropzone_text'>Drag & Drop or Click to Create Document</div>
                        </>}
                </Dropzone>
                <Button text='Create Template' mod={'blue fill margin0' + (files.length ? '' : ' disabled')} style={{marginRight: '10px'}}
                        callback={createTemplateHandler} />
                <Button text={transS('Cancel')} mod='blue arch margin0' callback={closeHandler} />
            </div>
            {isUploadPopupOpen && <UploadPopup closeHandler={() => setIsUploadPopupOpen(false)}
                                               createTemplateFromTemplate={createTemplateFromTemplate}
                                               dropHandler={dropHandler} files={files}  
                                               isTemplate='true' />}
        </div>
    )
}

export default CSSModules(AddTemplatePopup, styles, {allowMultiple: true})

import api from '../../services/api/api'
import { push } from 'connected-react-router';
import { templatesConstants } from './constants'
import * as actionTypes from '../document/constants'
import store from '../../services/store'
import { getPopUpAction } from '../../services/actions/dataActions'
import { popUpReset } from '../../services/helpers'
import { getActualDocumentAction, setCurrentDocumentAction  } from '../document/actions'
import { transF, transL, transS } from '../../services/helpers/lang';

export const TemplatesActionCreators = {
    setTemplates: (templatesList) => ({type: templatesConstants.SET_TEMPLATES_LIST, payload: templatesList}),
    setTemplatesLoading: (payload) => ({type: templatesConstants.SET_LOADING, payload}),
    setEditPopup: (bool, templateId) => ({type: templatesConstants.SET_EDIT_POPUP, payload: {bool, templateId}}),
    setOpenFolders: payload => ({type: templatesConstants.SET_OPEN_FOLDERS, payload}),

    fetchTemplates: () => async dispatch => {
        dispatch(TemplatesActionCreators.setTemplatesLoading(true))
        const res = await api.getTemplatesList()
        dispatch(TemplatesActionCreators.setTemplates(res.data.data))
        dispatch(TemplatesActionCreators.setTemplatesLoading(false))
    },
    fetchTemplatesImmediate: async () => {
        const res = await api.getTemplatesList();
        store.dispatch(TemplatesActionCreators.setTemplates(res.data.data));
        return res.data.data;
    },
    addTemplate: (template) => async dispatch => {
        dispatch(TemplatesActionCreators.setTemplatesLoading(true))
        await api.addTemplate({template})
        const res = await api.getTemplatesList()

        dispatch(TemplatesActionCreators.setTemplates(res.data.data))
        dispatch(TemplatesActionCreators.setTemplatesLoading(false))
    },
    editTemplate: (id, title, folder, description, team) => async dispatch => {
        const res = await api.updateTemplate(id, title, folder, description, team)
        if (!res || res.status !== 200) return;
        const templatesList = JSON.parse(JSON.stringify(store.getState().templateReducer.templatesList))
        let templateIndex
        if (!id) {
          templateIndex = templatesList.length
          templatesList.push(res.data.data)
          id = res.data.data._id
        } else templateIndex = templatesList.findIndex(el => el._id === id);
        if (title === 'delete folder') {
           templatesList.forEach(t => {
             if (t.parent === id) 
                t.parent = templatesList[templateIndex].parent
           })
           templatesList.splice(templateIndex, 1)
        } else if (templatesList[templateIndex].title !== title) {
          dispatch({
            type: actionTypes.RENAME_DOCUMENT_FROM_SIDEBAR,
            newName: title
          });
          templatesList[templateIndex].title = title
        }
        if (folder !== undefined) {
          templatesList[templateIndex].parent = folder
        }        
        if (description != null) templatesList[templateIndex].description = description
        if (team != null) templatesList[templateIndex].team = team
        //dispatch(setCurrentDocumentAction(templatesList[templateIndex]));
        dispatch(TemplatesActionCreators.setTemplates(templatesList))
    },
    deleteTemplate: (id) => async dispatch => {
        const res = await api.deleteTemplate(id)
        if (!res || res.status !== 200) return;
        dispatch(getPopUpAction(popUpReset()))
        if (res.status !== 200) return
        const templatesList = JSON.parse(JSON.stringify(store.getState().templateReducer.templatesList))
            .filter(template => template._id !== id)
        dispatch(TemplatesActionCreators.setTemplates(templatesList))
    },
    getFinalizedTemplatesList: () => async dispatch => {
        const res = await api.getFinalizedTemplatesList()
        return res.data.data;
    },
    cloneTemplate: (id, core, oldName, title) => async dispatch => {
        dispatch(TemplatesActionCreators.setTemplatesLoading(true));
        let template = { file: {templateId: core}, title }, templates;
        let newDoc = await api.addTemplate({template});
        if (newDoc?.status === 201) {
          newDoc = newDoc.data.data[0];
          const res = await api.getTemplatesList();
          templates = res?.data?.data;
          if (templates) {
            dispatch(TemplatesActionCreators.setTemplates(templates));
            template = templates.find(el => el._id === newDoc);          
            dispatch(push(`/template/${newDoc}`));
            dispatch(getPopUpAction({
              name: 'confirm',
              header: transS('Template Copied'),              
              text: transF('templateCopied')(oldName, template),              
              cancel: {
                name: transS('Got it'),
                event: () => dispatch(getPopUpAction(popUpReset))
              }
            }));
            dispatch(setCurrentDocumentAction(template));
            dispatch(getActualDocumentAction({ documentId: newDoc, projectId: null, preventChangeRoute: true }));
          } 
        }
        if (!templates) {
          dispatch(getPopUpAction({
            name: 'confirm',
            text: `Create template problem`,
            cancel: {
              name: transS('Got it'),
              event: () => dispatch(getPopUpAction(popUpReset))
            }
          }));
        }
        dispatch(TemplatesActionCreators.setTemplatesLoading(false));
    },
    publishTemplate: (id, core, displayVersion) => async dispatch => {
        dispatch(getPopUpAction({
            name: 'confirm',
            header: transS('Publish Template?'),
            text: transF('After you publish')(displayVersion),
            confirm: {
                name: transS('Publish'),
                event: async () => { 
                  const res = await api.publishTemplate(id, core);
                  processPublishResponse(res, dispatch);
                }
            },
            cancel: {
                name: transS("Don't Publish"),
                event: () => dispatch(getPopUpAction(popUpReset))
            }
        }));
    },
    unpublishTemplate: (id) => async dispatch => {
        dispatch(getPopUpAction({
            name: 'confirm',
            header: transS('Unpublish Template?'),
            text: transL('Unpublish Template?'),
            confirm: {
                name: transS('Unpublish'),
                mod: 'red arch',
                event: async () => {
                    const res = await api.unpublishTemplate(id)
                    processPublishResponse(res, dispatch);
                }
            },
            cancel: {
                name: transS('Cancel'),
                event: () => dispatch(getPopUpAction(popUpReset))
            }
        }));        
    }
}

const processPublishResponse = (res, dispatch) => {
  if (!res || res.status !== 200) return;
  const template = res.data.data
  const templatesList = JSON.parse(JSON.stringify(store.getState().templateReducer.templatesList))
  const templateIndex = templatesList.findIndex(el => el._id === template.coreDocument)
  templatesList[templateIndex].orig = template.orig;
  dispatch(TemplatesActionCreators.setTemplates(templatesList)) // too bad!!!
  dispatch(getActualDocumentAction({ documentId: template.coreDocument, projectId: null, preventChangeRoute: true }));
  dispatch(getPopUpAction(popUpReset()))  
}
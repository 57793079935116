import React from 'react'

export const StdRadio = ({selections, selected, callback}) => 
  <span className='dev-std-radio'>
    {selections.map((text, i) => <div 
        key={i}
        className={i === selected ? 'dev-std-radio-selected' : ''}
        onClick={() => i === selected ? null : callback(i)}
      >
      {text}
    </div>
    )}
  </span>


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink} from 'react-router-dom';
import cssModules from 'react-css-modules';
import styles from './login.css';
import api from '../../../services/api/api';

import { inputValidation, blurValidation, submitValidation } from '../../../../common/validation';

import { signUpAction, logInAction } from '../../../services/actions/userActions';
import {getProjectList} from '../../../services/actions/projectActions';

import Button from '../../../components/button/Button';
import Logo from "../../../components/logo/Logo"
import Checkbox from "../../../components/checkbox/Checkbox"
import {setCookies} from "../../../components/cookies/Cookies"
import { transEL, transLoc, publicHost } from '../../../services/helpers/lang';
import appConfig from '../../../../configs/appConfig';

@connect(state => ({}), {
  signUpAction, logInAction, getProjectList
})
@cssModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
export default class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: {
        value: '',
        dirty: false,
        invalid: false
      },
      lastname: {
        value: '',
        dirty: false,
        invalid: false
      },
      email: {
        value: '',
        dirty: false,
        invalid: false
      },
      password: {
        value: '',
        dirty: false,
        invalid: false
      },
      confirmPassword: {
        value: '1',
        dirty: false,
        invalid: false
      },
      message: '',
      isInvited: false,
      projectTitle: '',
      companyName: '',
      invitor: '',
      isAgree: false,
      passwordChanged: false
    };
  }
  _projectId = null;
  _companyName = null;
  _isPending = true;

  _extractTokenFromLink = queryString => queryString ? queryString.slice(1) : false;

  _input = e => {
    if (e.currentTarget.name === 'newPassword') this.setState({passwordChanged: true});
    inputValidation(this, e.currentTarget);
  }

  _blur = e => blurValidation(this, e.currentTarget);

  _submit = e => {
    e.preventDefault();

    let { firstname, lastname, email, password, newPassword, opassword, isInvited, passwordChanged } = this.state;
    let validationList = isInvited
        ? ['firstname', 'lastname', 'password']
        : ['firstname', 'lastname', 'email', 'password'];

    let _password = password.value //? password.value : newPassword.value;

    if (submitValidation(this, validationList)) {
      return false;
    }

    let { signUpAction, logInAction, getProjectList } = this.props;

    signUpAction({
      firstname: firstname.value,
      lastname: lastname.value,
      email: email.value,
      password: _password,
      projectId: this._projectId,
      isInvited
    })
        .then(() => {
          if (!this._projectId) this.props.history.push(`/login`)
          logInAction({ email: email.value, password: _password })
              .then(() => {
                if (this._projectId) this.props.history.push(`/${this._projectId}/documents/`)
              });
          this.setState({
            firstname: { ...firstname, value: '' },
            lastname: { ...lastname, value: '' },
            email: { ...email, value: '' },
            opassword: { ...opassword, value: '' },
            password: { ...password, value: '' }
          });
        })
        .catch(resp => {
          let { data: { err } } = resp.response;
          this.setState({ message: err });
        });
    setCookies()
  };

  async componentDidMount() {
    const linkToken = this._extractTokenFromLink(this.props.location.search);
    let linkValidationInfo;
    if (!linkToken)  {
      this.props.history.push('/activationError');
    } else {
      try {
        linkValidationInfo = await api.validateInvitationLink(linkToken);
        if (!linkValidationInfo) return this.props.history.push('/activationError')

        this._projectId = linkValidationInfo.data.data.projectId;
        this._companyName = linkValidationInfo.data.data.companyName;
        if (!this._projectId && !this._companyName) {
          this.props.history.push('/activationError');
        } else {
          if (!linkValidationInfo.data.data.password) {
            this.props.history.push(`/login/${this._projectId}`);
          } else {
            this._isPending = false
            this.setState(Object.assign({}, this.state, { email: {
                    invalid: false,
                    value: linkValidationInfo.data.data.email,
                    dirty: true
                  } },
                { firstname: {
                    invalid: false,
                    value: linkValidationInfo.data.data.firstname,
                    dirty: true
                  } },
                { lastname: {
                    invalid: false,
                    value: linkValidationInfo.data.data.lastname,
                    dirty: true
                  } },

                // { newPassword: {
                //     invalid: false,
                //     value: linkValidationInfo.data.data.password,
                //     dirty: true
                //   } },
                { password: {
                    invalid: false,
                    value: '',
                    dirty: true
                  } },

                { isInvited: true,
                  projectTitle: linkValidationInfo.data.data.projectTitle,
                  companyName: linkValidationInfo.data.data.companyName,
                  invitor: linkValidationInfo.data.data.invitor }
            ));
          }
        }
      } catch (err) {
        this.props.history.push('/activationError');
      }
    }
  }

  render() {
    let { firstname, lastname, email, password, opassword, message, projectTitle, companyName, isAgree,
      invitor, passwordChanged, newPassword } = this.state;

    if (this._isPending) return

    return (
        <div styleName='wrapper'>
          <form onSubmit={this._submit} noValidate style={{paddingTop: '0'}}>
            <div styleName='inviteText'> {
              (projectTitle || companyName) ?
                  (projectTitle ? <div>{invitor ? 
                    <span><span styleName='invitor'>{invitor} </span> 
                    {transLoc('invited you')} </span> : transLoc('You invited')+' '}  
                    {transLoc('to collaborate')} <br/>
                    {transLoc('in')} "{projectTitle}". <br/>
                    {transLoc('To start, please sign up.')}    
                      </div> :
                      <div>{invitor ? <span><span styleName='invitor'>{invitor}</span> 
                        {transLoc('invited you')}</span> : transLoc('You invited')} 
                        {transLoc('to')} "{companyName}". <br/>
                        {transLoc('To start, please sign up.')}
                      </div>)
                  : transLoc('Save hours of work and hundreds of dollars every month.')
            }
              <div styleName='logo'><Logo link={publicHost}/></div>
            </div>
            <div styleName='form-inner'>
              <div styleName='title'>{transLoc('Sign up')}</div>
              <div styleName='loginLink'><NavLink to='/login'>{transLoc('Already have an account?')}</NavLink></div>
              <label styleName='row border'>
                <div styleName='error-message'>
                  {
                    (() => {
                      if (firstname.invalid === 'required') return transEL('requiredName');
                      else if (firstname.invalid === 'minLength') return transEL('minLengthName');
                      else if (firstname.invalid === 'maxLength') return transEL('maxLengthName');
                    })()
                  }
                </div>
                <input styleName='input'
                       type='text'
                       name='firstname'
                       value={firstname.value}
                       onChange={this._input}
                       onBlur={this._blur}
                       autoComplete='off'
                       placeholder={transLoc('First Name')}
                       required />
              </label>
              <label styleName='row border'>
                <div styleName='error-message'>
                  {
                    (() => {
                      if (lastname.invalid === 'required') return transEL('requiredSurname');
                      else if (lastname.invalid === 'minLength') return transEL('minLengthSurname');
                      else if (lastname.invalid === 'maxLength') return transEL('maxLengthSurname');
                    })()
                  }
                </div>
                <input styleName='input'
                       type='text'
                       name='lastname'
                       value={lastname.value}
                       onChange={this._input}
                       onBlur={this._blur}
                       autoComplete='off'
                       placeholder={transLoc('Last Name')}
                       required />
              </label>
              <label styleName='row border'>
                <div styleName='error-message'>
                  {
                    (() => {
                      if (email.invalid === 'required') return transEL('requiredEmail');
                      else if (email.invalid === 'email') return transEL('errorEmail');
                      else if (email.invalid === 'maxLength') return transEL('maxLengthEmail');
                    })()
                  }
                </div>
                <input styleName={this.state.isInvited ? 'disabled-input' : 'input'}
                       type='email'
                       name='email'
                       value={email.value}
                       onChange={this._input}
                       onBlur={this._blur}
                       autoComplete='off'
                       placeholder={transLoc('Email Address')}
                       required
                       disabled={this.state.isInvited} />
              </label>
              <label styleName='row'>
                <div styleName='error-message'>
                  {
                    (() => {
                      if (password.invalid === 'required') return transEL('requiredPassword');
                      else if (password.invalid === 'minLength') return transEL('minLengthPassword');
                      else if (password.invalid === 'maxLength') return transEL('maxLengthPassword');
                    })()
                  }
                </div>
                <input styleName='input'
                       type='text'
                       name='password'
                       value={password.value}
                       onChange={this._input}
                       onBlur={this._blur}
                       autoComplete='off'
                       placeholder={transLoc('Password')}
                       required />
              </label>
              <div styleName='row checkbox'>
                <Checkbox checked={isAgree} callback={() => this.setState({isAgree: !isAgree})}
                          text={`<div class="${styles['checkbox-text']}">I agree with Contract.one’s <a target='_blank' href='/legal/terms-of-use'>
                                            Terms of Use</a> and <a target='_blank' href='/legal/data-protection'>Data Protection Policy</a></div>`}/>
              </div>
              <div styleName='button-row'>
                {isAgree ? <Button text={transLoc('Sign up and Sign in')} mod='promoMainBtn freePad' type='submit' />
                    :
                    <Button text={transLoc('Sign up and Sign in')} mod='promoMainBtn freePad disabled' callback={() => null} />}

              </div>
            </div>
            {
              message && <div styleName='message-wrapper'>{message}</div>
            }
            <div styleName='link-row'>{transLoc('Already have an account?')} 
              <NavLink styleName='link' to='/login'>{
                transLoc('Sign in')}</NavLink></div>
          </form>
        </div>
    );
  }
}

import React, { useEffect, useRef } from 'react';
import CSSModules from 'react-css-modules'
import styles from './templatesUnderMenu.css'

const ConditionalOption = params => {
   let {id, list, title, callback} = params;
   const dropdownRef = useRef(null)

   const clickOutsideHandler = ({target}) => {
    if (!dropdownRef.current || !target || !document.contains(target)) return; 
    if (!dropdownRef.current.contains(target)) return callback(null);    
   }

   useEffect(() => {
       window.addEventListener('click', clickOutsideHandler)
       return () => {
           window.removeEventListener('click', clickOutsideHandler)
       }
   }, []);
   
   return (
    <div styleName='cond-dropdown' ref={dropdownRef}>
       <div styleName='cond-dropdown-title' onClick={() => callback(1)}>
          {title}</div>
       {list.map(l =><div styleName='cond-dropdown-option' key={l.id}>
          {l.id === id ?
            <span styleName='option_selected' onClick={() => callback(null)}>
              {l.title || l.option} </span>
            : <span onClick={() => callback(l.id)}>
            {l.title || l.option} </span>
          }
       </div>)}
    </div> 
    )
}
   
export default CSSModules(ConditionalOption, styles, {allowMultiple: true});


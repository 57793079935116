import {notCorporateDomains} from './listOfFreeEmailsProviders'

// function for is required check
const isRequired = (str => !str.length && 'required');
const minPasswordLength = 1;

const strictPermissionCheck = 2, freeDocsCount = 5;
export const oldPermissionCheck = () => strictPermissionCheck === 1;
export const hasMemberInternalGroupPermission = () => (strictPermissionCheck !== 1);
export const hasPermissionApprove = () => (strictPermissionCheck !== 1);
export const hasDownloadDocumentPermission = () => (strictPermissionCheck !== 1);

export const docs5PermissionCheck = projectlist => {
   if (strictPermissionCheck !== 2 || !projectlist ) return true;
   let count = 0;
   for(let i = 0; i < projectlist.length; i++) {
     count += projectlist[i].docs ? projectlist[i].docs.length : 0;
     if (count > freeDocsCount) return 0;
   }
   return count+1;
};
export const block5PermissionCheck = count => !count || (count > (freeDocsCount+1));

// function for is email check
export const isEmail = str => !/^([A-Z|a-z|0-9](\.|_|-|\+){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9](\.|_|-){0,1})+((\.){0,1}[A-Z|a-z|0-9]){1}\.[A-Z|a-z]{2,}$/.test(str) && 'email';

// function to check if the email is corporate
export const isCorporate = str => notCorporateDomains.indexOf(str.slice(str.indexOf("@") + 1)) !== -1 && 'corporate';

// function for min string length check
const minStrLength = (str, num) => str.length < num && 'minLength';

// function for max string length check
const maxStrLength = (str, num) => str.length > num && 'maxLength';

// function for max file size check
const maxFileSizeValidation = (file, size) => file.size > size && 'overSize';

const passwordsMatch = (first, second) => first !== second && 'passMatch';

// function for validation name
const nameValidation = value => isRequired(value) || minStrLength(value, 2) || maxStrLength(value, 50);

// function for validation email
const emailValidation = value => isRequired(value) || isEmail(value) || maxStrLength(value, 50);

const corporateEmailValidation = value => emailValidation(value) || isCorporate(value);

// function for is validation password
const passwordValidation = value => isRequired(value) || minStrLength(value, minPasswordLength) || maxStrLength(value, 50);
const newPasswordValidation = (value, second, current) => {
  if (!current.length && !second.length) return false;
  if (!current.length && second.length) {
    return isRequired(value) || minStrLength(value, minPasswordLength) || maxStrLength(value, 50);
  }
  if (current.length && second.length) {
    return isRequired(value) || minStrLength(value, minPasswordLength) || maxStrLength(value, 50);
  }
  return false;
};
const confirmPasswordValidation = (first, second, current) => {
  if (!first.length && !current.length) return false;
  if (first.length && current.length) {
    return isRequired(second) || minStrLength(second, minPasswordLength) || maxStrLength(second, 50) || passwordsMatch(first, second);
  }
  if (first.length && !current.length) {
    return isRequired(second) || minStrLength(second, minPasswordLength) || maxStrLength(second, 50) || passwordsMatch(first, second);
  }
  if (!first.length) {
    return false;
  };
};
const confirmResetPasswordValidation = (first, second) => {
  if (!first.length) {
    return false;
  };
  return isRequired(second) || minStrLength(second, minPasswordLength) || maxStrLength(second, 50) || passwordsMatch(first, second);
};

// function for is validation projectName
const projectNameValidation = value => isRequired(value) || minStrLength(value, 1) || maxStrLength(value, 256);

// function for is validation documentName
const documentNameValidation = value => isRequired(value) || maxStrLength(value, 256);

// function for max file size check
export const fileSizeValidation = file => maxFileSizeValidation(file, 15000000);

// function for file doc-docx format check
export const fileFormatValidation = file => false; /*
 (file.type && file.type !== 'application/msword' 
 && file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') 
 && file.type !== 'application/vnd.oasis.opendocument.text'
 && 'invalidFormat'; */
 
// function for file name check
const fileNameValidation = file => isRequired(file.name) || maxStrLength(file.name, 256);

const fileAttachmentValidation = file => Object.keys(file).length === 0 && 'emptyAttachment';

const passwordChangeValidationSubmit = (current, first, second) => {
  if (current.length && (!first.length || second.length)) {
    return false;
  }
  if (!current && (first.length || second.length)) {
    return passwordValidation(current);
  }
  return false;
};

/**
 * Validation for input event
 *
 * @param {Object} _this
 * @param {HTMLInputElement} target
 */
export const inputValidation = (_this, target) => {
  let { firstname, lastname, email, company, job, phone, team,
    password, projectName, documentName, newPassword, confirmPassword, reset } = _this.state,
    fieldName = target.name,
    value = target.value;

  switch (fieldName) {
  case 'firstname':
    if (!firstname.dirty) {
      _this.setState({ firstname: { ...firstname, value } });
    } else {
      _this.setState({ firstname: { ...firstname, value, invalid: nameValidation(value) } });
    }
    break;
  case 'lastname':
    if (!lastname.dirty) {
      _this.setState({ lastname: { ...lastname, value } });
    } else {
      _this.setState({ lastname: { ...lastname, value, invalid: nameValidation(value) } });
    }
    break;
  case 'email':
    if (!email.dirty) {
      _this.setState({ email: { ...email, value } });
    } else {
      if (email.corporate) _this.setState({ email: { ...email, value, invalid: corporateEmailValidation(value) } })
      else _this.setState({ email: { ...email, value, invalid: emailValidation(value) } });
    }
    break;
  case 'company':
    if (!company.dirty) {
      _this.setState({ company: { ...company, value } });
    } else {
      _this.setState({ company: { ...company, value, invalid: nameValidation(value) } });
    }
    break;
  case 'job':
    if (!job.dirty) {
      _this.setState({ job: { ...job, value } });
    } else {
      _this.setState({ job: { ...job, value, invalid: nameValidation(value) } });
    }
    break;
  case 'phone':
    if (!phone.dirty) {
      _this.setState({ phone: { ...phone, value } });
    } else {
      _this.setState({ phone: { ...phone, value, invalid: nameValidation(value) } });
    }
    break;
  case 'team':
    if (!team.dirty) {
      _this.setState({ team: { ...team, value } });
    } else {
      _this.setState({ team: { ...team, value, invalid: nameValidation(value) } });
    }
    break;
  case 'password':
    if (!password.dirty) {
      _this.setState({ password: { ...password, value } });
    } else {
      _this.setState({ password: { ...password, value, invalid: passwordValidation(value) } });
    }
    break;

  case 'newPassword':
    // if (!value.length) return;
    _this.setState({ newPassword: { ...newPassword, value, dirty: true, invalid: passwordValidation(value) } });
    break;
  case 'confirmPassword':
    // if (!value.length) return;
    if (reset) {
      _this.setState({ confirmPassword: { ...confirmPassword, value, dirty: true, invalid: confirmResetPasswordValidation(password.value, value) } });
    } else {
      _this.setState({ confirmPassword: { ...confirmPassword, value, dirty: true, invalid: confirmPasswordValidation(newPassword.value, value, password.value) } });
    }
    break;

  case 'projectName':
    _this.setState({ projectName: { ...projectName, value, invalid: projectNameValidation(value) } });
    break;
  case 'documentName':
    if (!documentName.dirty) {
      _this.setState({ documentName: { ...documentName, value } });
    } else {
      _this.setState({ documentName: { ...documentName, value, invalid: documentNameValidation(value) } });
    }
    break;
  }
};

/**
 * Validation for blur event
 *
 * @param {Object} _this
 * @param {HTMLInputElement} target
 */
export const blurValidation = (_this, target) => {
  let { firstname, lastname, email, company, job, password, projectName, documentName, newPassword, confirmPassword, reset } = _this.state,
    fieldName = target.name,
    value = target.value;

  switch (fieldName) {
  case 'firstname':
    if (!value.length) return;
    _this.setState({ firstname: { ...firstname, dirty: true, invalid: nameValidation(value) } });
    break;
  case 'lastname':
    if (!value.length) return;
    _this.setState({ lastname: { ...lastname, dirty: true, invalid: nameValidation(value) } });
    break;
  case 'email':
    if (!value.length) return;
    if (email.corporate) _this.setState({ email: { ...email, dirty: true, invalid: corporateEmailValidation(value) } });
    else _this.setState({ email: { ...email, dirty: true, invalid: emailValidation(value) } });
    break;
  case 'company':
    if (!value.length) return;
    _this.setState({ company: { ...company, dirty: true, invalid: nameValidation(value) } });
    break;
  case 'job':
    if (!value.length) return;
    _this.setState({ job: { ...job, dirty: true, invalid: nameValidation(value) } });
    break;
  case 'password':
    if (!value.length) return;
    _this.setState({ password: { ...password, dirty: true, invalid: passwordValidation(value) } });
    break;

  case 'newPassword':
    if (!value.length) return;
    _this.setState({ newPassword: { ...newPassword, dirty: true, invalid: passwordValidation(value) } });
    break;
  case 'confirmPassword':
    if (!value.length) return;
    if (reset) {
      _this.setState({ confirmPassword: { ...confirmPassword, value, dirty: true, invalid: confirmResetPasswordValidation(password.value, value) } });
    } else {
      _this.setState({ confirmPassword: { ...confirmPassword, value, dirty: true, invalid: confirmPasswordValidation(newPassword.value, value, password.value) } });
    }
    // _this.setState({ confirmPassword: { ...confirmPassword, dirty: true, invalid: passwordValidation(value) } });
    break;
  case 'projectName':
    if (!value.length) return;
    _this.setState({ projectName: { ...projectName, dirty: true, invalid: projectNameValidation(value) } });
    break;
  case 'documentName':
    if (!value.length) return;
    _this.setState({ documentName: { ...documentName, dirty: true, invalid: documentNameValidation(value) } });
    break;
  }
};

export const accountValidation = (_this, fieldsForCheck) => {
  const { firstname, lastname, email, password, uploadFile, newPassword, confirmPassword } = _this.state;
  let errorCounter = 0;
  let invalidData = {};

  fieldsForCheck.forEach(item => {
    switch (item) {
    case 'firstname':
      invalidData['firstname'] = nameValidation(_this.state[item].value);
      _this.setState({ firstname: { ...firstname, dirty: true, invalid: invalidData['firstname'] } });
      break;
    case 'lastname':
      invalidData['lastname'] = nameValidation(_this.state[item].value);
      _this.setState({ lastname: { ...lastname, dirty: true, invalid: invalidData['lastname'] } });
      break;
    case 'password':
      if (_this.state.newPassword.value.length && _this.state.confirmPassword.value.length) {
        invalidData['password'] = passwordChangeValidationSubmit(_this.state[item].value, _this.state.newPassword.value, _this.state.confirmPassword);
      } else if (!_this.state[item].value.length) {
        invalidData['password'] = false;
      } else {
        invalidData['password'] = passwordValidation(_this.state[item].value);
      }
      _this.setState({ password: { ...password, dirty: true, invalid: invalidData['password'] } });
      break;
    case 'newPassword':
    // if (!value.length) return;
      invalidData['newPassword'] = newPasswordValidation(_this.state[item].value, _this.state.confirmPassword.value, _this.state.password.value);
      _this.setState({ newPassword: { ...newPassword, dirty: true, invalid: invalidData['newPassword'] } });
      break;
    case 'confirmPassword':
      invalidData['newPassword'] = confirmPasswordValidation(_this.state.newPassword.value, _this.state[item].value, _this.state.password.value);
      _this.setState({ confirmPassword: { ...confirmPassword, dirty: true, invalid: invalidData['newPassword'] } });
      break;
    }
  });

  for (var key in invalidData) {
    if (invalidData[key]) {
      errorCounter++;
    }
  }

  return !!errorCounter;
};

/**
 * Validation for submit event
 *
 * @param {Object} _this
 * @param {Array} fieldsForCheck
 * @return {boolean}
 */
export const submitValidation = (_this, fieldsForCheck) => {
  let { firstname, lastname, email, password, 
    company, job,
    projectName, documentName, uploadFile, newPassword, confirmPassword, reset } = _this.state,
    invalid_fin = false, invalid;

  fieldsForCheck.forEach(item => {
    switch (item) {
    case 'firstname':
      invalid = nameValidation(_this.state[item].value);
      _this.setState({ firstname: { ...firstname, dirty: true, invalid } });
      break;
    case 'lastname':
      invalid = nameValidation(_this.state[item].value);
      _this.setState({ lastname: { ...lastname, dirty: true, invalid } });
      break;
    case 'email':
      invalid = _this.state[item].corporate ? corporateEmailValidation(_this.state[item].value) : emailValidation(_this.state[item].value);
      _this.setState({ email: { ...email, dirty: true, invalid } });
      break;
    case 'company':
      invalid = nameValidation(_this.state[item].value);
      _this.setState({ company: { ...company, dirty: true, invalid } });
      break;
    case 'job':
      invalid = nameValidation(_this.state[item].value);
      _this.setState({ job: { ...job, dirty: true, invalid } });
      break;
    case 'password':
      if (_this.state.newPassword && _this.state.confirmPassword) {
        invalid = passwordChangeValidationSubmit(_this.state[item].value, _this.state.newPassword.value, _this.state.confirmPassword);
      } else {
        invalid = passwordValidation(_this.state[item].value);
      }
      _this.setState({ password: { ...password, dirty: true, invalid } });
      break;
    case 'newPassword':
    // if (!value.length) return;
      invalid = newPasswordValidation(_this.state[item].value, _this.state.confirmPassword.value, _this.state.password.value);
      _this.setState({ newPassword: { ...newPassword, dirty: true, invalid } });
      break;
    case 'confirmPassword':
    // if (!value.length) return;
      if (reset) {
        // _this.setState({ confirmPassword: { ...confirmPassword, value, dirty: true, invalid: confirmResetPasswordValidation(password.value, value) } });
        invalid = confirmResetPasswordValidation(_this.state.password.value, _this.state[item].value);
      } else {
        // _this.setState({ confirmPassword: { ...confirmPassword, value, dirty: true, invalid: confirmPasswordValidation(newPassword.value, value, password.value) } });
        invalid = confirmPasswordValidation(_this.state.newPassword.value, _this.state[item].value, _this.state.password.value);
      }
      // invalid = invalid || confirmPasswordValidation(_this.state.newPassword.value, _this.state[item].value, _this.state.password.value);
      _this.setState({ confirmPassword: { ...confirmPassword, dirty: true, invalid } });
      break;

    case 'projectName':
      invalid = projectNameValidation(_this.state[item].value);
      _this.setState({ projectName: { ...projectName, dirty: true, invalid } });
      break;
    case 'documentName':
      invalid = documentNameValidation(_this.state[item].value);
      _this.setState({ documentName: { ...documentName, dirty: true, invalid } });
      break;
    case 'fileSize':
      invalid = fileSizeValidation(uploadFile);
      _this.setState({ documentName: { ...documentName, dirty: true, invalid } });
      break;
    case 'fileFormat':
      invalid = fileFormatValidation(uploadFile);
      _this.setState({ documentName: { ...documentName, dirty: true, invalid } });
      break;
    case 'fileName':
      invalid = fileNameValidation(uploadFile);
      _this.setState({ documentName: { ...documentName, dirty: true, invalid } });
      break;
    case 'fileEmpty':
      invalid = fileAttachmentValidation(uploadFile);
      _this.setState({ documentName: { ...documentName, dirty: true, invalid } });
      break;
    }
    invalid_fin = invalid_fin || invalid;
  });

  return invalid_fin;
};

export const addUserByEmailSubmitValidation = (formState, fieldsForCheck) => {
  let { email } = formState;
  let invalid = false;
  let result = {};

  fieldsForCheck.forEach(field => {
    switch (field) {
    case 'email':
      invalid = invalid || emailValidation(email.value);
      if (invalid) {
        result.email = Object.assign({}, email, { dirty: true, invalid });
      }
    }
  });

  return Object.keys(result).length ? result : null;
};

export const addUserByEmailInputValidation = (formState, target) => {
  let { email } = formState;
  let fieldForCheck = target.name;
  let value = target.value;
  let result = {};

  switch (fieldForCheck) {
  case 'email':
    result.email = email.dirty
      ? Object.assign({}, email, { value, invalid: emailValidation(value) })
      : Object.assign({}, email, { value, invalid: false });
    break;
  }

  return result;
};

export const addUserByEmailBlurValidation = (formState, target) => {
  let { email } = formState;
  let fieldForCheck = target.name;
  let value = target.value;
  let result = {};

  switch (fieldForCheck) {
  case 'email':
    if (!value.length) return;
    result.email = Object.assign({}, email, { dirty: true, invalid: emailValidation(value) });
    break;
  }

  return result;
};

import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './login.css';
import { transS } from '../../../services/helpers/lang';

const Activation = props => (
  <main styleName='activation'>
    <div>
      <p>{transS('We sent you an email. Please confirm your account to start.')}</p>
      <p styleName='link-row'><a styleName='link' href='/login'>{transS('Refresh the page')}</a></p>
    </div>
  </main>
);

export default new CSSModules(Activation, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });

import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './formatting.css';
import { blockEvent } from '../../../../services/helpers';
import ScrollBar from '../../../../components/scrollBar/ScrollBar';
import BIH from './BIH';

const fSize = s => +s.replace('px', '')

const leftAlign = active => <svg width="14" height="15" viewBox="0 0 14 15" 
  xmlns="http://www.w3.org/2000/svg">
  <g stroke={active ? "#157EFB" : "#ACACAC"} fill="none" fillRule="evenodd" strokeLinecap="square">
    <path d="M1 12h7M1 3h12M1 6h10M1 9h12"/>
  </g></svg>

const centerAlign = active => <svg width="14" height="15" viewBox="0 0 14 15" 
  xmlns="http://www.w3.org/2000/svg">
  <g stroke={active ? "#157EFB" : "#ACACAC"} fill="none" fillRule="evenodd" strokeLinecap="square">
    <path d="M4 12h7M1 3h12M2 6h10M1 9h12"/>
  </g></svg>

const rightAlign = active => <svg width="14" height="15" viewBox="0 0 14 15" 
  xmlns="http://www.w3.org/2000/svg">
  < g stroke={active ? "#157EFB" : "#ACACAC"} fill="none" fillRule="evenodd" strokeLinecap="square">
    <path d="M6 12h7M1 3h12M3 6h10M1 9h12"/>
  </g></svg>

const justifyAlign = active => <svg width="14" height="15" viewBox="0 0 14 15" 
  xmlns="http://www.w3.org/2000/svg">
  <g stroke={active ? "#157EFB" : "#ACACAC"} fill="none" fillRule="evenodd" strokeLinecap="square">
    <path d="M1 3h12M1 6h12M1 9h12M1 12h12"/>
  </g></svg>

const Formatting = () => {
  const {formatting, frameProgress} = useSelector(state => state.paragraphReducer)
  const {fonts} = useSelector(state => state.document)
  const sendToFrame = frameProgress?.sendToFrame
  const popupRef = useRef(null)
  const pickerRef = useRef(null)
  const fontSize = !formatting ? '--' :
    Math.round(75 * fSize(formatting.fontSize) 
      / fSize(getComputedStyle(document.body.parentElement).fontSize)) / 10,
    textAlign = formatting?.textAlign,
    textAlignNumber = !textAlign ? 0 :
      textAlign === 'center' ? 1 :
      textAlign.includes('justify') ? 3 :
      (textAlign === 'right' || textAlign === 'end') ? 2 : 0

  const [fontPopupOpen, setFontPopupOpen] = useState(false)
  const decreaseFont = (isDecrease) => {
    let size
    if (isDecrease) {
      if (fontSize < 4) return
      size = fontSize <= 8 ? fontSize - 0.5 : fontSize - 1
    } else
      size = fontSize < 8 ? fontSize + 0.5 : fontSize + 1
    const remSize = (size/7.5).toFixed(3)
    return remSize < 1.6 ? `max(var(--fscale), ${remSize}rem)` : `${remSize}rem`
  }

  const  doAdjust = no => {
    const aligns = ['start', 'center', 'end', 'justify']
    if (no === textAlignNumber) return;
    sendToFrame({c1: "format", paraStyle: {textAlign: aligns[no]}})
  }
  
  const clickOutsideHandler = ({target}) => {
     if (popupRef.current && !(
        popupRef.current.contains(target) || 
        pickerRef.current.contains(target)) )
         setFontPopupOpen(false);
  };
    
  useEffect(() => {
    window.addEventListener('click', clickOutsideHandler);
    return () => {
      window.removeEventListener('click', clickOutsideHandler);
    };
  }, []);
  const currentFont = formatting
    ? formatting.fontFamily.split(",")[0].replaceAll('"', "")
    : "---"
  
  return (
    <div styleName="wrapper">
      {!formatting && (
        <div styleName="screen" onMouseDown={(e) => blockEvent(e)}></div>
      )}
      <div styleName="row centered">
        <div styleName="fontPicker  clickable" ref={pickerRef}>
          <div styleName={`bordered${fontPopupOpen ? ' opened' : ''}`}
           onClick={() => setFontPopupOpen(!fontPopupOpen)}>
            <span>{currentFont}</span>
            <div className={"arrow" + (fontPopupOpen ? " arrowOpen" : "")} 
              styleName="shifted5"></div>
            {fontPopupOpen && !!formatting && (
              <div styleName="font_popup" ref={popupRef}
                  style={{height:`min(220px, ${fonts.length * 22 - 15}px`}}>
                <ScrollBar
                  style={{ height: "100%", width: "calc(100% + 0px)" }}>
                  {fonts.map((el) => (el !== currentFont) && (
                    <div styleName="font-line"
                      key={el}
                      onClick={() => {
                        sendToFrame({c1: "format", font: el})
                        setFontPopupOpen(false)
                      }}>
                      {el}
                    </div>
                  ))}
                </ScrollBar>
              </div>
            )}
          </div>
        </div>
        <div styleName="sizePicker bordered">
          <span styleName="sign minus clickable"
            onClick={() =>
              sendToFrame({
                c1: "format",
                format: { fontSize: decreaseFont(true)}})}>-</span>
          {fontSize}
          <span styleName="ptClass">pt</span>
          <span styleName="sign minus clickable"
            onClick={() =>
              sendToFrame({
                c1: "format",
                format: { fontSize: decreaseFont()}})}>+</span>
        </div>  
        <span styleName="vertical"
          style={{margin: '0 15px 0 0px'}}></span>
        <BIH sendToFrame={sendToFrame}  />
        {/* to do link
        <span styleName="vertical"
          style={{margin: '0 15px'}}></span>
        <div styleName="copyBtn"></div>*/}
      </div>

      <div styleName="row marg12">
        <div styleName="svgIcons clickable">
          <div onClick={() => doAdjust(0)}>{leftAlign(textAlignNumber === 0)}</div>
          <div onClick={() => doAdjust(1)}>{centerAlign(textAlignNumber === 1)}</div>
          <div onClick={() => doAdjust(2)}>{rightAlign(textAlignNumber === 2)}</div>
          <div onClick={() => doAdjust(3)}>{justifyAlign(textAlignNumber === 3)}</div>
        </div>
        {/*<div styleName="prima clickable">More options</div> to do */}
      </div>
    </div>
  );
}

export default new CSSModules(Formatting, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});

import React, {Component} from 'react'
import styles from './Changes.css';
import CSSModules from "react-css-modules";
import Button from "../../../../../../components/button/Button";
import cx from "classnames"
import Tip from "../../../../../../components/tip/Tip"
import Radio from "../../../../../../components/radio/Radio"
import { transS } from '../../../../../../services/helpers/lang';

@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})

export default class Changes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            versionRadioBtn: 2,
            textRadioBtn: null
        }
    }

    componentDidMount() {
        const {stateMenu} = this.props;
        if (stateMenu.redline) {
            this.setState({versionRadioBtn: 1})
        }
        window.addEventListener('click', this.clickOutsideHandler)
        window.addEventListener('resize', this.changesMenuSetPosition)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.changesMenuSetPosition()

        const {stateMenu} = this.props

        if (stateMenu.showChanges === 3 && this.state.versionRadioBtn !== 0) {
            this.setState({versionRadioBtn: 0}) // CLEAN VERSION
        } else if (stateMenu.showChanges === 0 && !stateMenu.redline && this.state.versionRadioBtn !== 2) {
            this.setState({versionRadioBtn: 2}) // SIMPLIFIED REDLINE
        } else if (stateMenu.showChanges === 0 && stateMenu.redline && this.state.versionRadioBtn !== 1) {
            this.setState({versionRadioBtn: 1}) // FULL REDLINE
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.clickOutsideHandler)
        window.removeEventListener('resize', this.changesMenuSetPosition)
    }

    _changeShowChanges = (val, statRedLine) => {
        let {stateMenu, setStateMenu } = this.props;
        let result = {showChanges: val};
        if (val === 4) result.filterParagraphs = true;
        else if (stateMenu.showChanges === 4) result.filterParagraphs = false;
        if (statRedLine != null) result.redline = statRedLine

        setStateMenu(result);
    }

    _changeShowNewer = () => {
        if (this.state.versionRadioBtn === 0)
            return

        this.props.setStateMenu({showNewer: 1});
    }
    
    _filterParagraphs = e => {
        let {stateMenu, setStateMenu } = this.props;
        if (e.target.value === '0' && stateMenu.filterParagraphs) {
            if (stateMenu.showChanges === 4) this._changeShowChanges(0);
            else setStateMenu({filterParagraphs: false});
        } else if (e.target.value === '1' && !stateMenu.filterParagraphs) {
            setStateMenu({filterParagraphs: true});
        } else {
            return
        }
        window.scrollTo(0, 0);
    }

    _versionRadioCallback = e => {
        let {stateMenu, setStateMenu} = this.props;
        const v = +e.target.value
        if (this.state.versionRadioBtn === v) return;

        if (v === 0) this._changeShowChanges(3)
        else this._changeShowChanges(stateMenu.showChanges === 3 ? 0 : stateMenu.showChanges, v === 1)

        this.setState({versionRadioBtn: v})
    }

    _textRadioCallback = e => {
        const {setStateMenu} = this.props
        const v = +e.target.value

        if (v === 1) setStateMenu({textNarrowMode: false})
        else if (v === 0) setStateMenu({textNarrowMode: true})
    }

    _commentsRadio = e => {
        const {setStateMenu} = this.props
        const v = +e.target.value

        setStateMenu({radioBtn: v})
    }

    clickOutsideHandler = e => {
        if (e.target !== this.refWrapper && !this.refWrapper.contains(e.target))
            this.setState({isOpen: false})
    }

    jumpChangesClickHandler = e => {
        e.preventDefault()
        if (!e.target.dataset.dir) return

        this.props.frameProgress.jumpDocChanges(+e.target.dataset.dir)
    }

    frameUndo = () => {
      let {stateMenu, frameProgress} = this.props;
      if (stateMenu.undo) frameProgress.sendToFrame({c1:'undo'});
    }

    frameRedo = () => {
      let {stateMenu, frameProgress} = this.props;
      if (stateMenu.redo) frameProgress.sendToFrame({c1:'redo'});
    }

    changesMenuSetPosition = () => {
        if (!this.refChangesMenu) return
        let btn = this.refChangesMenuBtn, block = btn.parentElement, wrapper = block.parentElement
        let leftOffsetBtn = btn.offsetLeft + block.offsetLeft + wrapper.offsetLeft + 40
        let width = document.documentElement.clientWidth // ширина видимой области окна браузера (без учёта скроллбара)

        if (window.innerWidth <= 1366) {
            let scrollBarWidth = window.innerWidth - width
            width = 1366 - scrollBarWidth
        }

        let rightOffsetBtn = width - leftOffsetBtn // отступ от левой границы кнопки до правого края окна браузера
        let sendMenuPosition = rightOffsetBtn - 401

        if (sendMenuPosition >= -1) {
            this.refChangesMenu.style.left = ''
        } else {
            this.refChangesMenu.style.left = `${sendMenuPosition}px`
        }
    }

    render() {
        let {isOpen, versionRadioBtn} = this.state;
        let {stateMenu, jumpChanges, isInternal } = this.props;

        return (
            <React.Fragment>
                {isOpen && <div styleName='screen' />}

                <div styleName={cx('wrapper', {'_open': isOpen, '_j': jumpChanges && jumpChanges.vis})} ref={e => this.refWrapper = e}>
                    <div ref={e => this.refChangesMenuBtn = e}
                       styleName={cx('under-popup', {'_vis': isOpen})}>
                        <Button text={transS('Changes & View')} mod={'changes leftArrow ' + (isOpen ? 'leftArrow_open changes_open' : '')}
                                callback={() => this.setState({isOpen: !isOpen})}/>

                        {jumpChanges && jumpChanges.vis && <div styleName='changesJump' onClick={this.jumpChangesClickHandler}>
                            <div styleName={cx('left', {'_disabled': jumpChanges.end === 1 || jumpChanges.end === 3})} data-dir='1'>
                                <Tip arrow='top left' text={transS(`Go to previous paragraph <br> with changes or comments`)} />
                            </div>

                            <div styleName={cx('right', {'_disabled': jumpChanges.end === 2 || jumpChanges.end === 3})} data-dir='2'>
                                <Tip arrow='top left' text={transS(`Go to next paragraph <br> with changes or comments`)} />
                            </div>
                        </div>}

                        {!!isInternal && <div styleName='changesUndo'>
                            <div styleName={cx('undo', {'_disabled': !stateMenu.undo})} onClick={this.frameUndo}>
                                <img src='/images/icon-undo-active.svg'/>
                                <Tip arrow='top left' text={transS(`Undo changes`)} />
                            </div>

                            <div styleName={cx('undo', {'_reverse':true, '_disabled': !stateMenu.redo})} onClick={this.frameRedo}>
                                <img src='/images/icon-undo-active.svg'/>
                                <Tip arrow='top left' text={transS(`Redo changes`)} />
                            </div>
                        </div>}

                        <div styleName='desc'>{!stateMenu.showChanges ? transS('Showing all')
                            : (stateMenu.showChanges === 1 ? transS('Now: This Version Changes')
                                : (stateMenu.showChanges === 2 ? transS('Showing 2 Latest Versions')
                                    : (stateMenu.showChanges === 3 ? transS('Showing Clean') : transS('Only New'))))}</div>
                    </div>

                    <div styleName={cx('popup', {'_vis': isOpen})}
                         ref={e => this.refChangesMenu = e}>

                        <Radio name='view mode' text0={transS('Clean Version')} text2={transS('Simplified Redline')}
                               text={transS('Full Redline')} 
                               radioBtn={versionRadioBtn} 
                               callback={this._versionRadioCallback}
                               mod='blue'/>

                        <div styleName={cx('changes', {'_dis': this.state.versionRadioBtn === 0})}>
                            <div styleName='subTitle' style={{marginBottom: '20px'}}>
                                {transS('Show Changes')}
                            </div>

                            <div styleName='changes-toggles'>

                                <button styleName={cx('changes-btn', {'_selected': stateMenu.showChanges === 1})} onClick={()=> this._changeShowChanges(1)}>
                                    {transS('In This Version')}</button>

                                <div>
                                    <button styleName={cx('changes-btn', {'_selected': stateMenu.showChanges === 2})}
                                            onClick={() => this._changeShowChanges(2)}>
                                        <span dangerouslySetInnerHTML={{__html: transS('In 2 Latest <br/>Versions')}} />                                       
                                    </button>
                                    <div styleName='changes-2versionDesc'
                                        dangerouslySetInnerHTML={{__html: transS("Counterparty’s <br/>and yours")}} />
                                </div>

                                <button styleName={cx('changes-btn', {'_selected': !stateMenu.showChanges})}
                                        onClick={() => this._changeShowChanges(0)}>
                                    {transS('In All Versions')}
                                </button>

                                <div styleName='changes-onlyNew'>
                                    {!!stateMenu.hadChanges && stateMenu.showChanges !== 4 &&
                                    <Button mod='blue bold margin0' text={transS('Only New')} callback={() => this._changeShowChanges(4)} style={{paddingTop: '1px'}}/>}

                                    {stateMenu.showChanges === 4 && <React.Fragment>
                                        <div styleName='changes-btn _selected'>{transS('Showing New')}</div>
                                        {!!stateMenu.changesCount &&
                                        <Button mod='blue margin0' text={transS('Show Newer')}
                                           callback={() => this._changeShowNewer()}/>}
                                    </React.Fragment>}

                                    {!stateMenu.hadChanges && stateMenu.showChanges !== 4 &&
                                    <Button mod='blue disabled margin0' text={transS('Only New')}  />}

                                    {!!stateMenu.changesCount && <div styleName='counter' style={stateMenu.changesCount > 99 ? {fontSize: '8px'} : null}>
                                        {stateMenu.changesCount > 99 ? '99+' : stateMenu.changesCount}</div>}
                                </div>

                            </div>

                        </div>

                        <div styleName='showParagraphs'>
                            <div styleName='subTitle'>{transS('Show Paragraphs')}</div>
                            <Radio name='paragraph filter' text0={transS('All')} text={transS('With Changes Only')}
                                   callback={this._filterParagraphs} radioBtn={stateMenu.filterParagraphs ? 1 : 0}/>
                        </div>

                        <div styleName='comments'>
                            <div styleName='subTitle'>{transS('Comments')}</div>
                            <Radio name='comments view' text0={transS('Collapse All')} 
                                text={transS('Expand All')}
                                callback={this._commentsRadio} radioBtn={stateMenu.radioBtn ? 1 : 0}/>
                        </div>

                        <div styleName='showText'>
                            <div styleName='subTitle'>{transS('Show Text')}</div>
                            <Radio name='text formatting' text0={transS('Original Formatting')} text={transS('Reading Mode')}
                                   callback={this._textRadioCallback} radioBtn={stateMenu.textNarrowMode === false ? 1 : 0}/>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}
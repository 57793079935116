import React, {Component, Fragment} from 'react';
import CSSModules from "react-css-modules"
import styles from "./dataProtection.css"
import {NavLink} from "react-router-dom"
import Footer from "../../../components/footer/Footer"

@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
class DataProtection extends Component {

    componentDidMount() {
        window.scrollTo(0,0)
    }

    render() {
        return (
            <Fragment>
                <div styleName='wrapper'>
                    <div styleName='title'>Data Protection Policy <img src="../../../images/pad-lock.png" alt=""/></div>
                    <div styleName='selection'>
                        <div styleName='selection-link'><NavLink to={'/legal/data-protection/eu'}>EEA and EU</NavLink></div>
                        {/*<div styleName='selection-link'><NavLink to={'/legal/data-protection'}>United Kingdom</NavLink></div>*/}
                        {/*<div styleName='selection-link'><NavLink to={'/legal/data-protection'}>California, U.S.A.</NavLink></div>*/}
                        <div styleName='selection-link'><NavLink to={'/legal/data-protection/global'}>Everybody Else</NavLink></div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}

export default DataProtection
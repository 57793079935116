import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import cx from 'classnames';

import styles from '../static/promo.css';

const Remark = props => {
  let {image, name, ceo, paragraphs, longParagraphs, longo = false, callback = () => {} } = props;    

  return (
                      <div styleName={cx('remark',{'_width' : longo})}>
                        <img src={image} width="70" height="70"/>
                        <div>{name}</div>
                        <span>{ceo}</span>
                        {paragraphs && paragraphs.map(item => {
                            return <p>{item}</p>;
                        })}
                        {longo && longParagraphs && longParagraphs.map(item => {
                            return <p>{item}</p>;
                        })}
                        {longParagraphs && 
                           <article styleName='clink' onClick={() => {callback()}}>
                              {longo ? 'Hide text' : 'Read full story'}
                           </article>}
                      </div>
  )
};
Remark.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  ceo: PropTypes.string,
  paragraphs: PropTypes.array,
  longParagraphs: PropTypes.array,
  callback: PropTypes.function,
  longo: PropTypes.bool
};
export default new CSSModules(Remark, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });

import React from 'react'

export const StdPager = ({selections, selected, callback}) => 
  <div className='dev-std-pager'>
    {selections.map((text, i) => <div 
        key={i}
        className={i === selected ? 'dev-std-pager-selected' : ''}
        onClick={() => i === selected ? null : callback(i)}
      >
      {text}
    </div>
    )}
  </div>


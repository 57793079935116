import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './popupWithInput.css';
import { inputValidation, blurValidation } from '../../../common/validation';

import Button from '../button/Button';
import { updateProjectAction } from '../../services/actions/projectActions';
import { transL } from '../../services/helpers/lang';

class ConfirmWithInput extends Component {
  constructor(props) {
    super(props);
    // this.handleChange = this.handleChange.bind(this);
    this.state = {
      [this.props.popUp.fieldName]: {
        value: this.props.popUp.inputText,
        dirty: true,
        invalid: false
      }
    };
  }

  _input = e => {
    inputValidation(this, e.currentTarget);
  };

  _blur = e => blurValidation(this, e.currentTarget);

  render() {
    let { popUp: { text, confirm, cancel, projectId, fieldName, placeholder } } = this.props;
    let st = this.state;

    const _stopPropagation = e => e.stopPropagation();

    return (
      <div styleName='rename-wrapper' onClick={_stopPropagation}>
        <h2 styleName='rename-title'>{text}</h2>
        <div styleName='rename-close-button' onClick={cancel.event} />
        <div styleName='rename-input-area'>
          <div styleName='error-message'>
            {
              (() => {
                if(fieldName === 'documentName') {
                  return {
                    'required': transL('requiredName'),
                    'minLength': transL('minLengthName'),
                    'maxLength': transL('documentNameMaxLength')
                  }[st[fieldName].invalid];

                } else {

                  return {
                    'required': transL('requiredName'),
                    'minLength': transL('minLengthName'),
                    'maxLength': transL('projectNameMaxLength')
                  }[st[fieldName].invalid];
                }
              }
              )()
            }
          </div>
          <input placeholder={placeholder} styleName={cx('input', { '_empty': !this.state[fieldName].value.length }, 'rename-input')} name={fieldName} value={this.state[fieldName].value} onChange={this._input} />
        </div>
        <div styleName={cx('rename-buttons-row', { '_single-btn': !cancel })}>
          { cancel && <Button text={cancel.name} mod='blue arch big w220' callback={cancel.event} />}
          { confirm && <Button text={confirm.name} mod='blue fill big w220' callback={confirm.event(projectId, this.state[fieldName])} />}
        </div>
      </div>
    );
  }
};

ConfirmWithInput.propTypes = {
  popUp: PropTypes.object
};

export default connect(state => ({
  popUp: state.dataReducer.popUpWithInput
}), { renameProjectAction: updateProjectAction })(new CSSModules(ConfirmWithInput, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' }));

import React, { useRef, useState } from 'react'
import styles from './templatesList.css'
import CSSModules from 'react-css-modules'
import { getDateString } from '../../../../../common/getDateString'
import { useHistory } from 'react-router'
import cn from 'classnames'
import DocumentListItemDropDown from "../../components/DocumentListItemDropDown/DocumentListItemDropDown";
import { transS } from '../../../../services/helpers/lang'
import { useDispatch, useSelector } from 'react-redux'
import { TemplatesActionCreators } from '../../../../redux/template/action'

const DocumentLine = ({ _id, setShowDropDown, coreDocument, 
     title, createdAt, addedBy, description, orig, isPin, 
     parent, parentName,
     lvl, subs, contentType, visible, pinHandler }) => {
    const [isTitleCut, setIsTitleCut] = useState(false)
    const {openFolders, setOpenFolders} = useSelector(state => state.templateReducer)
    const {dateData} = useSelector(state => state.userReducer);
    const titleRef = useRef(null)
    const { push } = useHistory()
    const dispatch = useDispatch()
        
    const _docMouse = (event, toShow) => {
      if (!toShow) return;
      setShowDropDown({id: _id, top: event.pageY, left: event.pageX,
        parent, parentName,
        _id, coreDocument, orig, title, contentType});
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
    const isFolder = contentType === 'folder',
      isOpen = openFolders.has(_id),
      chgOpenFolder = (isOpen) => {
        if (isOpen)
          openFolders.delete(_id)
        else openFolders.add(_id)
        dispatch(TemplatesActionCreators.setOpenFolders(new Set(openFolders)))
      }
    
    return <div styleName={'templatesList_row' + (visible ? '' : ' unvisible')}
        onMouseDown = { e => _docMouse(e, e.button ===2)} >
        <div onClick={() => isFolder ? chgOpenFolder(isOpen)
          : push('/template/' + _id)}
          style={{paddingLeft: (40*lvl)+"px"}} >
          <div style={{display: 'flex', position: 'relative'}}>
            {isFolder ?
            <img styleName='docIcon' src="/images/icons-folder.png" width="13" height="13" />
            : <svg styleName='docIcon' width="11" height="13" viewBox="0 0 11 13">
              <path fill="none" fillRule="evenodd" stroke="#ACACAC" d="M1 1h9v11H1V1zm2 2h5M3 5h5M3 7h5"/>
            </svg>
            }
            
            <div ref={titleRef} styleName={cn('doc_title', {'_cut': isTitleCut})}>{title}
              {isFolder && !!(subs?.length) &&
                  <span styleName="arrowShift"           
                    className={`arrow${isOpen ? ' arrowOpen' : ''}`}
                    onClick={() => chgOpenFolder(isOpen)}
                  ></span>  }
            </div>
              {isTitleCut && <div styleName='doc_title_hint'>{title}</div>}
            </div>            
            {description && <div styleName='doc_description'>{description}</div>}
        </div>
        <div>
          {isFolder ? ''      
          : orig !== 300 ? transS('Draft') : transS('Published')}
        </div>
        <div></div>
        <div>
          {isFolder ? '' : getDateString(Date.parse(createdAt), -4, dateData)}
        </div>
        <div>
          {isFolder ? '' : (addedBy.firstname + ' ' + addedBy.lastname)}
        </div>
        <div>
          <img styleName='pinIcon' alt=''
            src={`/images/icon-pin-${isPin ? 'gray' : 'blue'}.svg`}
            onClick={pinHandler} />
          <div styleName='onDocControls'>
            <DocumentListItemDropDown 
              callback={e => _docMouse(e, true)}
            /></div>
          <div style={{position: 'relative'}}></div>
        </div>
      </div>
           
}

export default new CSSModules(DocumentLine, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})

import { transS } from "../../services/helpers/lang"

export const SupportedBrowsers = 'Please use Google Chrome (85 or higher), Safari (13 or higher).'

export const checkBrowserSupport = () => {
    return commonBrowsersCheck() && !IECheck()
}

const checkNotFullyBrowserSupport = () => {
    const browser = getBrowserName()
    if (browser.toLowerCase().includes('chrome')) {
        const version = browser.split(' ')[1]
        return +version < 85
    } else if (browser.toLowerCase().includes('safari')) {
        const version = browser.split(' ')[1]
        return +version < 13
    }
    return true
}

export const isNeedNotFullyBrowserSupportAlert = () => {
    if (sessionStorage.getItem('nfbsa')) {
        return false
    }

    return checkNotFullyBrowserSupport()
}

export const isNeedNotFullyBrowserSupportNotice = () => {
    if (sessionStorage.getItem('nfbsn')) {
        return false
    }

    return checkNotFullyBrowserSupport()
}

export const notFullySupportBrowserPopup = (btnCallback) => {
    return {
        name: 'confirm',
        header: 'This browser cannot run Contract.one correctly',
        text: `Unfortunately ${getBrowserName()} does not fully support the needed functionality that 
                is required to run Contract.one safely.  Some features may work incorrectly.
                <br><br>
                ${SupportedBrowsers}`,
        confirm: {
            name: transS('Got it'),
            event: () => {
                sessionStorage.setItem('nfbsa', '1')
                btnCallback()
            }
        },
    }
}

export const getBrowserName = () => {
    let ua = navigator.userAgent, tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
}

const IECheck = () => {
    if (!!window.MSInputMethodContext && !!document.documentMode) // true on IE 11
        return true
    else if (navigator.userAgent.indexOf('MSIE') + 1 || navigator.appVersion.indexOf('Trident/') + 1) // true on IE 6-11
        return true

    return false
}

export const chromeCheck = () => {
    return window.chrome != null
}

const commonBrowsersCheck = () => {
    if (!('localStorage' in window)) return false
    else if (!('addEventListener' in window)) return false
    else if (!('matchMedia' in window)) return false
    else if (!('querySelector' in document)) return false

    return true
}
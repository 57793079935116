import React from 'react'
import CSSModules from 'react-css-modules'
import cx from 'classnames'
import styles from "./navigationBtn.css"
import Tip from "../../../../components/tip/Tip"
import { transS } from '../../../../services/helpers/lang'

const NavigationBtn = props => {
    const {isOpen, openHandler, isFullMode, navigation, jumpBack} = props

    return <div styleName={cx('wrapper', {'_open': isOpen, 'fullMode': isFullMode})}>
        <div styleName='menu-btn'>
            <div styleName='menu-img' onClick={() => openHandler()}>
                <img src={isOpen ? "../../../../images/icon-navi-gray.png" : "../../../../images/icon-navi-blue.png"} alt="navigation" />
            </div>
            <div styleName='tip'><Tip text={transS('Document Navigation')} arrow='bottom left'/></div>
        </div>
        {navigation.back && <div styleName='back'>
            <div styleName='back-btn' onClick={jumpBack} data-back='1'>Back</div>
            <div styleName='tip'><Tip text={transS('Back to previous paragraph')} arrow='bottom left'/></div>
        </div>}
    </div>
}

export default new CSSModules(NavigationBtn, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})

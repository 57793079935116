import React from 'react';
import { colors } from '../../../../components/colors';
import { ButtonStd } from '../../../../components/button/Buttons';
import { transS } from '../../../../services/helpers/lang';
import { TeamMember } from './TeamMember';

export const Team = ({external, title, titleEditable, canAdd, canApprove,
  memberList, action}) => {
    
  const styleCont = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 20,
    padding: 40,
    width: 400,
    borderRadius: 8,
    backgroundColor: external ? colors.externalTeam : colors.internalTeam
  }, whiteButton = {
    backgroundColor: colors.white,
    color: colors.primary,
  }

  return <div style={styleCont}>
    <div className='h3'>{title}</div>
    {!!canAdd && <div></div>}
    {!!canAdd && <ButtonStd style={whiteButton}
      text={transS('Add Members')}
    />}
    {memberList.map(memberItem => <TeamMember 
      key={memberItem.id}
      luserId={memberItem.id}
      external={external}
      callback={action}
      right={memberItem.right} 
      directRight={memberItem.directRight || 0}
      editable={canApprove}
      removable={canAdd}
      extension={null}
    /> )}    
  </div>
}
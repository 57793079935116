import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './Spinner.css';
import Logo from "../logo/Logo";
// import SpinnerIcon from '../../images/spinner.png';
// was src={SpinnerIcon}

class Spinner extends React.Component {
    // componentDidMount() {
    //     if (this.props.deleteScroll) this.deleteScroll('mount')
    // }
    //
    // componentWillUnmount() {
    //     if (this.props.deleteScroll) this.deleteScroll('unmount')
    // }
    //
    // deleteScroll = (lifeCycle) => {
    //     if (lifeCycle === 'mount')
    //         document.body.style.overflowY = 'hidden'
    //     else
    //         document.body.style.overflowY = 'auto'
    // }
    
    render() {
        let withoutLogo = !!this.props.withoutLogo
        return (
            <div styleName={'spinnerWrapper'}>
                {!withoutLogo && <Logo/>}
                <img
                    src='/images/spinner.png'
                    alt=''
                    styleName={'spinner'}
                />
            </div>

        );
    }
    }
        
export default new CSSModules(Spinner, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});

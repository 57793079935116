import React, { useState } from 'react';
import { ButtonLarge } from '../../../../components/button/Buttons';
import { colors } from '../../../../components/colors';
import { Icon_Doc, Icon_Doc_Auto, Icon_Folder } from '../../../../components/svglib/svglib';
import { transS } from '../../../../services/helpers/lang';
import { UniPopup } from '../../../../components/popupWindows/UniPopup';
import { newFolderDialog } from './UnitsHelper';
import { unitFromLocation } from '../../../../services/sockets/directStore';

const dashedStyle = {borderColor: colors.primary,
    backgroundColor: colors.white,
    color: colors.primary,
    borderStyle: "dashed"},
  orangeStyle = {backgroundColor: colors.orange,
    borderColor: colors.orange,
    color: colors.white}
  

export const ButtonBlock = () => {
  const [showPopup, setShowPopup] = useState(null)
  return (
    <div style={{margin: "50px 0",
        display: "flex", gap: "20px"}}>
    <ButtonLarge 
        style={orangeStyle}
        Picture={Icon_Doc_Auto}
        text={transS('Create Standard Document')}
    />
    <ButtonLarge 
        style={dashedStyle}
        Picture={() => <Icon_Doc color={colors.primary} />}
        text={transS('Add Document')}
    />
    <ButtonLarge 
        style={dashedStyle}
        Picture={() => <Icon_Folder color={colors.primary} />}
        text={transS('Add Folder')}
        action={() => 
            newFolderDialog(unitFromLocation(), null, setShowPopup)}
    />
    {!!showPopup && <UniPopup {...showPopup} />}
    </div>)
}
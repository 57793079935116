import React from 'react'
import { useSelector } from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './unitList.css'
import Tip from '../../../../components/tip/Tip';
import {Avatar} from './Avatar';
import { getDateString } from '../../../../../common/getDateString';
import { Icon_ArrowDown } from '../../../../components/svglib/svglib';
import { showContext } from './Units'


const ShowActivity = ({id, lastAuthor, lastActivity, checkChanges}) => { 
    const {dateData} = useSelector(state => state.userReducer),
      lastDate = getDateString(lastActivity, 20, dateData)
    const {icons} = showContext()
    let changes, changesSize
    if (checkChanges) {
        const {unreadMap} = useSelector(state => state.unitReducer)
        changes = unreadMap.get(id)  
        changesSize = changes?.paras.length      
    }

    return <>
      <div styleName='activity-cont'>
        <Avatar userId={lastAuthor} />
        <div styleName='approvalsCount'>
            <div>{lastDate[0]}</div>
            <div styleName='time'>{lastDate[1]}</div>
        </div>
      </div>
      {!!changesSize && <div styleName='count-cont'>
        <span styleName='count-changes'>{changesSize}</span>
        <span> {icons.arrow_up} </span>
      </div>}
    </>
}

export default CSSModules(ShowActivity, styles, {allowMultiple: true});
import moment from 'moment';
import { rights } from '../../../../../configs/appConfig';
import { transS, transF } from '../../../../services/helpers/lang';
import api from '../../../../services/api/api';

export const makeNewUnit = (unit, luserId) => {
  const iAmInternal = unit?.integrated & rights.INTERNAL,
	iAmCreator = unit?.integrated & rights.CREATOR,
	right = !unit ? rights.INTERNAL + rights.CREATOR
	  : iAmInternal ? (iAmCreator ? 0 : rights.CREATOR)
	  : (iAmCreator ? 0 : rights.CREATOR)
  return ({ancestor: unit?.loc_id || null,
	parameters: [],
	members: right ? [luserId] : [],
	rights: right ? [right] : []
  })  
}

export const unitHelper = (unit, luserId) => {    
	const isDoc = !!(unit.d_created || unit.orig),
		contentType = unit.contentType,
		isFolder = !isDoc && !contentType,
		hasChildren = !!unit.folders?.length, 
		iAmInternal = unit.integrated & rights.INTERNAL,
		canEditTeams = unit.integrated & (rights.ADMIN_COMPANY + 
			rights.ADMIN_UNIT + rights.CREATOR + rights.MANAGE_UNIT) ||
			!iAmInternal && unit.integrated & rights.TEAM_UNIT,
		haveGroupRights = unit.rights && 
		  unit.rights.reduce((acc, r) => acc | r, 0) & rights.ALL_RIGHTS
		  || 0,
		isInternal = iAmInternal && haveGroupRights & rights.INTERNAL,
		hasExternals = haveGroupRights && (!iAmInternal || 
			haveGroupRights & rights.EXTERNAL),
		inGroup = iAmInternal && !unit.editorsgroup ||
			unit.editorsgroup && !iAmInternal,  // only for docs
		status = isDoc && unit.orig === 6 ? 'orig-collaboration' :
			unit.orig === 9 ? (inGroup ? 'orig-finByThem' : 'orig-finByUs'):
			unit.orig === 24 ? 'orig-signed' :
			unit.orig === 25 ? 'orig-signing' :
			unit.orig === 23 ? 'orig-signThem' :
			unit.orig === 22 ? 'orig-signUs' :
			unit.orig === 12 ? 'orig-final' :
			unit.orig === 302 ? 'orig-draft' :
			inGroup ? 'orig-internal' : 'orig-external',
		approvalsCount = iAmInternal && inGroup && unit.approvalsSet?.size || 0,
		approvedCount = approvalsCount && unit.approved?.length || 0,
		checkApprove = status === 'orig-internal' 
				|| unit.orig === 6 && iAmInternal && !unit.editorsgroup,

		docProps = isDoc && {
			status,
			amIApprover: iAmInternal && unit.integrated & rights.APPROVE,
			editor: unit.editor,
			edited: +unit.edited,
			lastAuthor: (+unit.edited > +unit.d_created && unit.editing_by)
				? unit.editing_by : unit.editor,
			lastActivity: (+unit.edited > +unit.d_created && unit.editing_by) 
				? +unit.edited : +unit.d_created,
			checkChanges: status === 'orig-internal' || status === 'orig-collaboration',
			externalOpenInfo: status === 'orig-external' && 
				transF('SentOpen')(`${moment(+unit.d_created).fromNow().replace(' ago','')
				}.${+unit.wasopened ? (' Opened ' + moment(+unit.wasopened).fromNow())
				: ' Not opened yet'}`),
			checkApprove,        
			approvalsCount, approvedCount,        
			approvalRequired: checkApprove && approvalsCount > approvedCount &&
				iAmInternal && unit.integrated & rights.APPROVE &&
				(!approvedCount || !unit.approved.find(x => x === luserId))
		}
    //   docHere = unit.orig && [1, 3, 4, 7, 10, 13, 20, 21, 50, 302].includes(unit.orig),
    //   docThere = unit.orig && [2, 8, 9, 14].includes(unit.orig),
    //   docBoth = unit.orig && [6, 12, 22, 23, 24].includes(unit.orig)
      
    return {
      isDoc, contentType, isFolder, hasChildren, iAmInternal, canEditTeams,
      isInternal, hasExternals, inGroup, docProps
    }
  }


export const resetTeamMembers = (unit, userId, integrated, nextParent) => {
	// calcs inherited rights and make new teams()
	let parent = nextParent(unit)
	while (parent && !parent.members?.length)
	  parent = nextParent(parent)
	
	unit.members = parent ? [...parent.members] : [userId]
  	unit.rights = parent ? [...parent.rights] : [integrated]
		
	if (parent) {
		const idx = unit.members.findIndex(el => el === userId)
		unit.rights[idx] = integrated
	}
}

const addRightToTeam = (team, user, right) => {
  const i = team.findIndex(u => u.id === user)
  if (i === -1)
	team.push({id: user, right})
  else
	team[i].right |= right
}

export const findUnitTeams = (unit, getUnit, user) => {
  const teams = []
  let parent = unit.ancestor ? getUnit(unit.ancestor) : null,
    onTopRights = true

  unit.members.forEach((el,i) => {
	const right = unit.rights[i] & rights.FILTER_RIGHTS
	teams.push({id: user, right, directRight: right})
  })

  while (parent) {
	parent.members?.forEach((el,i) => {
	  const right = parent.rights[i] & rights.FILTER_RIGHTS
	  addRightToTeam(teams, el, right )
	  onTopRights = false
	})
	parent = parent.ancestor ? getUnit(parent.ancestor) : null
  }  

  const internalTeam = teams.filter(u => u.right & rights.INTERNAL), 
  	externalTeam = teams.filter(u => u.right & rights.EXTERNAL), 
	userRights = teams.find(u => u.id === user).right

  return ({internalTeam, externalTeam, userRights, onTopRights})
}

export const getAllTeamMembers = (companyTree, id, found = new Set()) => {
  const node = companyTree.find(n => n.loc_id === id)
  node.members?.forEach(m => m && found.add(m))
  node.folders?.forEach(f => f && getAllTeamMembers(companyTree, f, found))
  return found
}
import React, {Component, Fragment} from 'react';
import CSSModules from "react-css-modules"
import styles from "./dataProtection.css"
import {NavLink} from "react-router-dom"
import Footer from "../../../components/footer/Footer"

@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
class Legal extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <Fragment>
                <div styleName='wrapper'>
                    <div styleName='title'>Legal</div>
                    <div styleName='selection-cols'>
                        <div styleName='selection-cols-col'>
                            <div styleName='selection-cols-col-head'>Data Protection Policy</div>
                            <div styleName='selection-cols-col-link'><NavLink to={'/legal/data-protection/eu'}>EEA and EU</NavLink></div>
                            {/*<div styleName='selection-cols-col-link'><NavLink to={'/legal'}>United Kingdom</NavLink></div>*/}
                            {/*<div styleName='selection-cols-col-link'><NavLink to={'/legal'}>California, U.S.A.</NavLink></div>*/}
                            <div styleName='selection-cols-col-link'><NavLink to={'/legal/data-protection/global'}>Everybody Else</NavLink></div>
                        </div>
                        <div styleName='selection-cols-col'>
                            <div styleName='selection-cols-col-head'>Terms of Use</div>
                            <div styleName='selection-cols-col-link'><NavLink to={'/legal/terms-of-use'}>Terms of Use (Global)</NavLink></div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}

export default Legal
import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './PromoFuture.css'
import Footer from '../../../components/footer/Footer'
import {NavLink} from 'react-router-dom'

const PromoFuture = () => (
    <div styleName='wrapper'>
        <div styleName='firstBlock_container'>
            <div styleName='firstBlock'>

                <div styleName='pageTitle'>
                    The future of <br/>
                    negotiations and <br/>
                    collaborative <br/>
                    contracting
                </div>
                <div styleName='pageTitleDesc'>
                    Negotiate on <b>one</b> platform.<br/>
                    Collaborate on <b>one</b> platform. Contract.<b>one</b>
                </div>
                <div styleName='pageTitleButton'>
                    <NavLink to='/sign-up'>
                        <button styleName='transparentButton'>Sign up</button>
                    </NavLink>
                </div>

                <ParallaxEl />

                <div styleName='quoteBlock' style={{width: '400px'}}>
                    <div styleName='quoteText'>
                        “Contract.one is a great example of how contracting should be done in the modern world.
                        <br/><br/>
                        Simple, effective, efficient, intuitive and collaborative.”
                    </div>
                    <div styleName='quoteDesc'>
                        Innovation Lead in a Global Law Firm
                    </div>
                </div>
            </div>
        </div>
        <div styleName='featuredBlock_container'>
            <div styleName='featuredBlock'>
                <div styleName='featuredBlock_item'>
                    <div styleName='h2 darkGray fz60' style={{marginBottom: '0'}}>
                        Bring crystal clear clarity<br/>
                        to negotiations
                    </div>
                    <div styleName='featuredBlock_item_img' style={{marginTop: '-20px'}}>
                        <img src="/images/landing_comments.png" alt="" />
                    </div>
                    <div styleName='flex darkGray'>
                        <div styleName='fz40' style={{marginRight: '100px', minWidth: '400px'}}>
                            Collaborate on one platform to bring a new level of context
                            and clarity to every aspect of negotiating.
                        </div>
                        <div styleName='fz20' style={{marginTop: '6px'}}>
                            End the negotiation nightmare of endless emails back and forth, unfindable
                            information, multiple versions, and exposure of your sensitive data. Communication
                            with Contract.one is not only structured and clear, but secure.
                            <br/><br/>
                            Retain complete security of your private internal and external communications
                            through separate and safe storage.
                        </div>
                    </div>
                </div>
                <div styleName='featuredBlock_item'>
                    <div styleName='h2 lightRed fz60' style={{marginBottom: "0"}}>
                        Join the red-line revolution
                    </div>
                    <div styleName='featuredBlock_item_img' style={{marginTop: '45px', marginBottom: '48px'}}>
                        <img src="/images/landing_redline.png" alt="" width='1120px'/>
                    </div>
                    <div styleName='flex darkGray'>
                        <div styleName='fz40' style={{marginRight: '100px', minWidth: '400px'}}>
                            Next generation track changes allow you to view the entire history of negotiations.
                        </div>
                        <div styleName='fz20' style={{marginTop: '6px'}}>
                            Join the red-line revolution and say goodbye to important edits being missed,
                            deleted, or viewed by external parties. View what, when and who made the changes
                            easier and faster than ever before. Up to 4x faster.
                            <br/><br/>
                            Maintain the utmost security of internal track change communications, with only a
                            summary of the changes being sent to external parties.
                        </div>
                    </div>
                </div>
                <div styleName='featuredBlock_item'>
                    <div styleName='h2 darkGray'>
                        Rewind the negotiations<br/>
                        with the Time Machine
                    </div>
                    <div styleName='featuredBlock_item_img' style={{marginTop: '80px', marginBottom: '74px'}}>
                        <img src="/images/landing_timemachine.png" alt=""/>
                    </div>
                    <div styleName='flex darkGray'>
                        <div styleName='fz40' style={{marginRight: '100px', minWidth: '400px'}}>
                            Seamlessly scroll through the entire paragraph history with our Time Machine.
                        </div>
                        <div styleName='fz20' style={{marginTop: '6px'}}>
                            Revolutionise the way you review paragraph changes. Leave us to lay out changes
                            made, so you can focus on negotiating and not comparing document versions line by
                            line.
                            <br/><br/>
                            By allowing you to collaborate in real time, the Time Machine updates the master
                            document so multiple document versions don’t clog up your computer.
                        </div>
                    </div>
                </div>
                <div styleName='featuredBlock_item'>
                    <div styleName='h2 darkGray'>
                        Unlock data to improve contracts<br/>
                        and negotiations
                    </div>
                    <div styleName='featuredBlock_item_img' style={{marginTop: '-15px', marginBottom: '70px'}}>
                        <img src="/images/landing_docstats.png" alt=""/>
                    </div>
                    <div styleName='statsGradient' />
                    <div styleName='flex darkGray'>
                        <div styleName='fz40' style={{marginRight: '100px', minWidth: '400px'}}>
                            Unlock in-depth statistics to discover, analyse, and improve how your team works.
                        </div>
                        <div styleName='fz20' style={{marginTop: '6px'}}>
                            Discover how much time each stage of the contracting process takes you, even down to
                            the <br/>
                            specific clauses that demand the most attention.
                            <br/><br/>
                            Through capturing key data, Contract.one has the capability to improve business
                            negotiation
                            strategy, standard documents and reduce turnaround time by two thirds.
                        </div>
                    </div>
                </div>

                <div styleName='featuredBlock_flex'>
                    <div styleName='featuredBlock_item'>
                        <div styleName='h2 darkGray fz30' style={{marginBottom: '57px'}}>Original Formatting
                        </div>
                        <div styleName='featuredBlock_item_img' style={{textAlign: 'center'}}>
                            <img src="/images/landing_selmenu.png" alt=""/>
                        </div>
                        <div styleName='fz24 darkGray'>The entire original formatting preserved: home styles,
                            cross-references, tables, etc.
                            <br/><br/>
                            The most precise cloud-based formatting parsing
                        </div>
                    </div>
                    <div styleName='featuredBlock_item'>
                        <div styleName='h2 darkGray fz30'
                             style={{marginBottom: '65px'}}>High-Speed Navigation
                        </div>
                        <div styleName='fz24 darkGray'>Navigating through a big document is now so much faster
                        </div>
                        <div style={{
                            height: '360px',
                            width: '300px',
                            margin: '40px 0 -100px',
                            transform: 'translate(-100px, -50px)'
                        }}>
                            <img src="/images/landing_navigation.png" alt=""/>
                        </div>
                    </div>
                </div>

                <div styleName='featuredBlock_item'>
                    <div style={{marginTop: '50px'}}>
                        <img src="/images/landing_docusign-icon.png" alt=""
                             style={{width: '182px', height: '40px'}}/>
                    </div>
                    <div styleName='featuredBlock_item_img' style={{marginTop: '127px', marginBottom: '115px'}}>
                        <img src="/images/landing_docusign.png" alt=""/>
                    </div>
                    <div styleName='flex darkGray'>
                        <div styleName='fz40' style={{marginRight: '100px', minWidth: '400px'}}>
                            Secure from the first <br/>
                            draft to the executed version.
                        </div>
                        <div styleName='fz24' style={{marginTop: '15px'}}>
                            Sign directly from Contract.one.
                            <br/><br/>
                            Complete document integrity from upload to signature
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div styleName='benefits_container'>
            <div styleName='benefits'>
                <div styleName='quoteBlock'>
                    <div styleName='quoteText'>
                        One of the most impressive things about Contract.one is how easy it is to capture
                        contractual data as it progresses through various stages of negotiation. It’s no secret
                        that trawling through multiple versions and running redline compares is a laborious
                        task, so the “Time Machine” functionality will be a total game changer for lawyers!
                    </div>
                    <div styleName='quoteDesc'>
                        Ciara Henry <br/>
                        Head of Automation <br/>
                        Support Legal
                    </div>
                </div>

                <div styleName='benefits_152'>
                    <div styleName='h2 darkGray'>Quick Proof of Concept</div>
                    <div styleName='benefits_152_numbers'>1-5-2</div>

                    <div styleName='flex darkGray'>
                        <div styleName='fz40' style={{marginRight: '100px', minWidth: '400px'}}>
                            Just: <br/>
                            1 week <br/>
                            5 steps <br/>
                            2 hours with lawyers
                        </div>
                        <div styleName='fz20'>
                            Running standard pilots is hard.
                            We talked to the market and came up with a better approach.
                            <br/><br/>
                            With minimal training required and the cleanest user interface/experience for the
                            whole team, our pilot programme is rapid for a reason.
                        </div>
                    </div>

                </div>

                <div styleName='quoteBlock'>
                    <div styleName='quoteText'>
                        Contract.one is aiming to tackle a big challenge in legal work – how to most effectively
                        and intuitively collaborate on drafting documents, with both clients and other lawyers,
                        in a way that works for legal transactions.
                    </div>
                    <div styleName='quoteDesc'>
                        Ben Hunt <br/>
                        Senior Legal Technology Manager <br/>
                        DLA Piper
                    </div>
                </div>

                <div styleName='benefits_cards'>
                    <div styleName='benefits_cards_item'>
                        <div styleName='benefits_cards_item_head darkRed'>
                            <span>Up to</span>
                            3x
                        </div>
                        <div styleName='benefits_cards_item_text'>
                            Faster<br/>
                            turnaround<br/>
                            time
                        </div>
                    </div>

                    <div styleName='benefits_cards_item'>
                        <div styleName='benefits_cards_item_head lightRed'>
                            <span>Up to</span>
                            4x
                        </div>
                        <div styleName='benefits_cards_item_text'>
                            Reduction in time spent for internal collaboration and approvals
                        </div>
                    </div>

                    <div styleName='benefits_cards_item'>
                        <div styleName='benefits_cards_item_head orange'>
                            <span>Up to</span>
                            30%
                        </div>
                        <div styleName='benefits_cards_item_text'>
                            Subsequent reduction in costs related to transactions
                        </div>
                    </div>

                    <div styleName='benefits_cards_item'>
                        <div styleName='benefits_cards_item_head green' style={{paddingTop: '35px'}}>
                            Data
                        </div>
                        <div styleName='benefits_cards_item_text'>
                            Capturing of key data to assist with ongoing legal risk management and potential
                            litigation
                        </div>
                    </div>

                    <div styleName='benefits_cards_item'>
                        <div styleName='benefits_cards_item_head blue fz50'>
                            Analytics
                        </div>
                        <div styleName='benefits_cards_item_text'>
                            Analytics for improving standard documents and negotiation strategy
                        </div>
                    </div>

                    <div styleName='benefits_cards_item'>
                        <div styleName='benefits_cards_item_head lightBlue fz50'>
                            Clarity
                        </div>
                        <div styleName='benefits_cards_item_text'>
                            Game-changing increase of clarity and trust in negotiation process
                        </div>
                    </div>

                    <div styleName='benefits_cards_item double'>
                        <div styleName='benefits_cards_item_head fz50'>
                            Superior Experience
                        </div>

                        <div styleName='flex'>
                            <div styleName='benefits_cards_item_text' style={{marginRight: '60px'}}>
                                Superior review experience for internal and external teams
                            </div>
                            <div styleName='benefits_cards_item_text'>
                                All versions and redlines problems eliminated
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div styleName='learnMore_container'>
            <div styleName='learnMore'>
                <div styleName='h2' style={{textAlign: 'center'}}>Learn More</div>
                <div styleName='learnMore_cards'>
                    <NavLink to='/security' styleName='learnMore_cards_item'>
                            <div styleName='learnMore_cards_item_title' style={{paddingLeft: '38px'}}>
                                <img styleName='securityIcon' src='../../../images/pad-lock.png' alt=''/>
                                Security
                            </div>
                            <div styleName='learnMore_cards_item_text'>
                                In Contract.one, we keep your data safe. Here you can find out about how we use
                                trusted services provider, maintain reliable infrastructure and protect your data.
                            </div>
                    </NavLink>
                    <NavLink to='/sign-up' styleName='learnMore_cards_item'>
                            <div styleName='learnMore_cards_item_title'>
                                Sign up
                            </div>                            
                            <div styleName='learnMore_cards_item_text'>
                                Get a free account and see how easy it is to add clarity to contracting.
                            </div>
                    </NavLink>
                    {/*<NavLink to='/new'>
                    {/*    <div styleName='learnMore_cards_item'>
                    {/*        <div styleName='learnMore_cards_item_title'>
                    {/*            negotiate<span styleName='negotiateNew'>.new</span>
                    {/*        </div>
                    {/*        <div styleName='learnMore_cards_item_text'>
                    {/*            Start structured negotiations by just typing this command into your browser.
                    {/*            <br/><br/>
                    {/*        </div>
                    {/*    </div>
                    {/*</NavLink> }


                    {/*<div styleName='learnMore_cards_item'>*/}
                    {/*    <div styleName='learnMore_cards_item_title'>*/}
                    {/*        The problems that should've been solved long ago.<br/>*/}
                    {/*        But haven't*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div styleName='learnMore_cards_item'>*/}
                    {/*    <div styleName='learnMore_cards_item_title'>Upgrade your practice</div>*/}
                    {/*    <div styleName='learnMore_cards_item_text'>*/}
                    {/*        Learn how to cut time to a signed contract, enhance clients’ experience, improve*/}
                    {/*        your templates and upgrade contract work.*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </div>

                <div styleName='learnMore_btnContainer _mob'>
                    <NavLink to='/schedule'>
                        <button styleName='blueButtonFill' style={{marginBottom: '50px', width: '302px', padding: '30px'}}>
                            Request Trial
                        </button>
                    </NavLink>
                </div>

                <div styleName='learnMore_btnContainer'>
                    <NavLink to='/sign-up'>
                        <button styleName='blueButton'>Sign up</button>
                    </NavLink>
                </div>

            </div>
        </div>

        {/*<div styleName='reviews'></div>*/}
        <div styleName='reviews' />

        <Footer/>
    </div>
)

const ParallaxEl = new CSSModules(class ParallaxEl extends React.Component {
    componentDidMount() {
        window.addEventListener('scroll', this.scrollHandler)
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollHandler)
    }
    scrollHandler = () => {
        const moveCoef = 0.1
        if (!this.refParallax) return

        const r = this.refParallax.getBoundingClientRect()

        const parallaxYCenter = r.y + r.height / 2
        const scrollYCenter = window.innerHeight / 2

        const move = (parallaxYCenter - scrollYCenter) * moveCoef

        this.refParallax.style.top = `${move}px`
    }
    render() {
        return <div styleName='parallax' ref={e => this.refParallax = e}>
            <div styleName='c1screen'>
                <img src="/images/landing_c1screen_left.png" alt="" width="780" height="584"/>
                <img src="/images/landing_c1screen_center.png" alt="" width="866" height="649"/>
                <img src="/images/landing_c1screen_right.png" alt="" width="780" height="584"/>
            </div>
        </div>
    }
}, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})


export default new CSSModules(PromoFuture, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import styles from './promo.css';
import Price from './Price'
import { NavLink } from 'react-router-dom';
import Button from '../button/Button';

const PlanElement = props => {
  let { title, price, priceRemark, bonusText, callback = () => {}, buttons = [], 
      prophits, prophitsRemark, clicked = 0, promo = '' } = props;
  let myElement = (buttons.length === 1) ? 3 : 1;
  let rClicked = (clicked ===  2) ? 1 : 0;
  let selected = (clicked === myElement) || (clicked === myElement + rClicked);
  return (
    <div styleName='planWrapper'>
        <div styleName={cx('planElement', {'_promo': !!promo}, {'_clicked':selected}, { '_first': !selected && (buttons.length === 2) }, {'_last': !selected && (buttons.length === 0)} ) } 
            onClick={() => {callback(myElement + rClicked)}} >
          <h1 styleName='title'>{title}</h1>
          <Price isSmall={false}
            price={price}
            priceRemark={priceRemark}
          />
          { !!promo && (promo != '-') &&
            <NavLink to='/sign-up' styleName='promo-nav'>
                <Button text={promo} mod={myElement === 1 ? '_blue1 _flat1 _login' :'_blue _flat1 _login'} />
            </NavLink>
          }
          <div styleName='bonusText'>{bonusText}</div>
          <div styleName={cx('radio',{'_clicked':selected})}>
            {buttons[0] && <div onClick={() => {callback(myElement)}}>
                <div styleName={cx('radioButton',{'_clicked':!rClicked})}></div>
                <div>{buttons[0]}</div>
            </div>}
            {buttons[1] && <div onClick={() => {callback(myElement + 1)}}>
                <div styleName={cx('radioButton',{'_clicked':!!rClicked})}></div>
                <div>{buttons[1]}</div>
            </div>}
          </div>
        </div>
        <ul styleName={cx('prophits', {'_promo': !!promo})}>
        {prophits.map( (item, index) => {return (
          <li key={'prophits' + index}>{item}</li>
        ) } ) }
        </ul>
        {prophitsRemark && <div styleName='prophitsRemark'>{prophitsRemark}</div>}
    </div>
  );
};


PlanElement.propTypes = {
  title: PropTypes.string,
  price: PropTypes.string,
  priceRemark: PropTypes.string,
  bonusText: PropTypes.string,
  callback: PropTypes.func,
  buttons: PropTypes.array,
  prophits: PropTypes.array,
  prophitsRemark: PropTypes.string,
  clicked: PropTypes.number,
  promo: PropTypes.string
};

export default new CSSModules(PlanElement, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });

import React, { useEffect, useRef, useState } from 'react'
import CSSModules from 'react-css-modules'
import styles from './templatesList.css'
import { transS } from '../../../../services/helpers/lang';
import Categories from '../clauseLib/Categories';

const drawFolder = (t, current, clickFunc, myFolder) => <div key={t._id}
      styleName={'folderLine'+(t._id === current ? ' selected-folder' : '')}
      style={{paddingLeft: (20*t.lvl)+'px'}}
        onClick={(e) => clickFunc(e, t)}>
            {t.title}
    </div>
  
const iterateFolders = (cont, arr, myFolder, lockFolder, firstStep) => {
    arr.forEach((t,i,a) => {
        if (t.contentType !== 'folder' ||  t._id === myFolder) return
        const subs = t.subs?.filter(x => x.contentType === 'folder'), 
          {_id, title, lvl} = t,
          el = {id: _id, _id, title, subs, lvl}
        if (firstStep)
           cont.push(el)
        else a[i] = el
        if (subs && t._id !== lockFolder)
           iterateFolders(cont, subs, myFolder, lockFolder, 0)
    })
}

const FolderControl = params => {
  const {folderParams, templates} = params;
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false)
  const [currentFolder, setCurrentFolder] = useState(folderParams.parent)
  const [closedAddFolders, setClosedAddFolders] = useState(new Set())
  const [name, setName] = useState(folderParams.parentName || '/')
  const myFolder = folderParams.contentType === 'folder' && folderParams._id
  const lockFolder = folderParams.contentType === 'folder' && folderParams.parent
  const canOpen = !!templates.find(t => t._id !== myFolder && t.contentType === 'folder')
   
  const clickOutsideHandler = ({target}) => {
     if (!dropdownRef.current?.contains(target))
       setIsOpen(false);
  };
    
  useEffect(() => {
    window.addEventListener('click', clickOutsideHandler);
    return () => {
      window.removeEventListener('click', clickOutsideHandler);
    };
  }, []);

  const clickFunc = (e, t) => {
    const newParent = t._id === currentFolder ? null : t._id
    setCurrentFolder(newParent)
    setName(newParent ? t.title : '/')
    folderParams.newParent = newParent
    setIsOpen(false)
    e.stopPropagation()
  }  

  const clickFunc1 = (res) => {
    const [id] = (!res || !res.size) ? [null] : res,
      newParent = id === currentFolder ? null : id
    setCurrentFolder(newParent)    
    folderParams.newParent = newParent
    setTimeout(() => setIsOpen(false), 20)    
  }  

  const sortedFolders = []
  iterateFolders(sortedFolders, templates, myFolder, lockFolder, 1)
    
  return <div onClick={(e) => { if (canOpen) setIsOpen(true);
      e.stopPropagation()}}
         ref={dropdownRef}
          styleName={canOpen ? "folderClick" : "folderShow"}>
        {//`${transS('Current folder')}: ${name}`
          transS('Select folder') 
        }
    <div
       styleName={'drop-down' + (isOpen ? '' : ' hidden')}
        >
      <Categories 
        cats={new Set(currentFolder ? [currentFolder] : null)}
        folders={sortedFolders}
        closedFolders={closedAddFolders}
        setOpenFolders={setClosedAddFolders}
        callback={clickFunc1}
        title={transS('Select folder')}
      /> 
          {/*
        <div className='h3 marginb40'>{transS('Select folder')}</div>
       {sortedFolders.map(t => drawFolder(t, currentFolder, clickFunc, myFolder)) }  
       
          */}
    </div>
  </div>
};
export default CSSModules(FolderControl, styles, {allowMultiple: true});
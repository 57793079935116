import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './Comment.css'
import cx from 'classnames'
import CommentRow from "./CommentRow"
import Button from "../button/Button"
import Checkbox from "../checkbox/Checkbox"
import SelectedRecipientsPopup from "./SelectedRecipientsPopup"
import Tip from "../tip/Tip"
import { transS } from '../../services/helpers/lang'

@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
class Comment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isTypeReply: false,
            isShowThreadPopup: false,
            isEdit: false,
            isTypeTitle: false,
            titleValue: '',
            showToggleSidePopup: false,
            selectedSide: 0,
            selectedRecipients: [],
            isShowSelRecipientsPopup: false,
            resolvedFullMode: false,
            isUncollapsed: false,

            staticMarginTop: null,
            hoveredMarginTop: null,
        }
    }

    componentDidMount() {
        if (this.props.title) this.setState({titleValue: this.props.title})

        if (this.props.isNewComment && this.props.side) {
            this.setState({selectedSide: this.props.side})
        } else if (this.props.toggleSide) {
            const remembered = localStorage.getItem(`rememberCommentSide_${this.props.me._id}`)
            if (remembered) {
                if (remembered !== 'int' && remembered !== 'ext') {
                    const selectedRecipients = JSON.parse(remembered).map(el => this.props.internalUserList.find(u => u._id === el))
                    this.setState({selectedSide: 3, selectedRecipients})
                } else {
                    this.setState({selectedSide: remembered === 'int' ? 0 : 1})
                }
            }
        }

        window.addEventListener('click', this.handleClickOutside)
        if (frames['iframe'] && frames['iframe'].document) frames['iframe'].document.addEventListener('click', this.handleClickOutside)
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleClickOutside)
        if (frames['iframe'] && frames['iframe'].document) frames['iframe'].document.removeEventListener('click', this.handleClickOutside)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.clps && this.refWrapper && this.refWrapper.previousElementSibling && !this.props.isFresh) {
            const staticMarginTop = this.refWrapper.previousElementSibling && -(this.refWrapper.previousElementSibling.offsetHeight - 20)
            const hoveredMarginTop = staticMarginTop + 40
            if (staticMarginTop !== this.state.staticMarginTop) this.setState({staticMarginTop, hoveredMarginTop})
        } else if (this.state.staticMarginTop || this.state.hoveredMarginTop) {
            this.setState({staticMarginTop: null, hoveredMarginTop: null, isUncollapsed: false})
        }

        if (this.props.isJumpTo) {
            this.setState({isUncollapsed: true})

            setTimeout(() => {
                const wrapperRect = this.refWrapper.getBoundingClientRect()
                window.scrollTo(0, window.pageYOffset + wrapperRect.top - 180)
            }, 50)

            this.props.setJumpTo()
        }

        if (prevProps.isApprovalsOpen && !this.props.isApprovalsOpen && this.state.resolvedFullMode) {
            this.setState({resolvedFullMode: false})
        }

        if (prevProps.title !== this.props.title) {
            this.setState({titleValue: this.props.title})
        }
    }

    handleClickOutside = e => {
        let check = e.target
        while (check && !(check === document.body || check === frames['iframe']?.document?.body)) check = check.parentElement
        if (!check) return

        if (this.refPopup && e.target !== this.refPopup && !this.refPopup.contains(e.target))
            this.setState({isShowThreadPopup: false})

        if (this.refSidePopup && e.target !== this.refSidePopup && !this.refSidePopup.contains(e.target))
            this.setState({showToggleSidePopup: false, isShowSelRecipientsPopup: false})
    }

    sideMouseHandler = (type, e) => {
        const {approverUserList, tipMouseHandler} = this.props
        if (!tipMouseHandler || !approverUserList) return
        const tipContent = approverUserList ? approverUserList.map(el => `<div class="${styles.tipElement}">${el.email} <br /> ${el.firstname}${el.lastname}</div>`).join('') : ''
        if (type === 'enter' || type === 'over') {
            const el = e.currentTarget
            const rect = el.getBoundingClientRect()
            let pos = rect.top - rect.height - 65 - approverUserList.length * 30
            if (pos < -40) pos = -40
            tipMouseHandler(type, pos, tipContent)
        } else if (type === 'leave') {
            tipMouseHandler('leave')
        }
    }

    titleOnChangeHandler = e => {
        this.setState({titleValue: e.target.value})
    }

    titleOnBlurHandler = e => {
        const {titleValue} = this.state
        const {_id, func} = this.props

        if (!titleValue && !this.props.title) return this.setState({isTypeTitle: false})

        func('changeTitle', {messageId: _id, title: titleValue})

        if (!titleValue) {
            this.props.title = ''
            this.setState({isTypeTitle: false})
        }

        e.currentTarget.blur()
    }

    titleOnKeyDownHandler = e => {
        if (e.code === 'Enter' || e.key === 'Enter') this.titleOnBlurHandler(e)
    }

    toggleSideHandler = (val) => {
        this.setState({selectedSide: val, showToggleSidePopup: false, selectedRecipients: []})
        const remembered = localStorage.getItem(`rememberCommentSide_${this.props.me._id}`)
        if (remembered) {
            if (val === 0 && remembered !== 'int') localStorage.setItem(`rememberCommentSide_${this.props.me._id}`, 'int')
            else if (val === 1 && remembered !== 'ext') localStorage.setItem(`rememberCommentSide_${this.props.me._id}`, 'ext')

            this.props.toggleCommentSide(val)
        }
    }

    rememberSideBtnHandler = () => {
        const {selectedSide, selectedRecipients} = this.state
        const remembered = localStorage.getItem(`rememberCommentSide_${this.props.me._id}`)

        if (remembered) localStorage.removeItem(`rememberCommentSide_${this.props.me._id}`)
        else localStorage.setItem(`rememberCommentSide_${this.props.me._id}`, selectedSide === 0 ? 'int' : selectedSide === 1 ? 'ext' : JSON.stringify(selectedRecipients.map(el => el._id)))

        this.props.toggleCommentSide(remembered ? 0 : selectedSide)
    }

    selectRecipientCallback = (action, userId) => {
        const {internalUserList} = this.props

        let selectedRecipients = this.state.selectedRecipients.slice(0)
        if (action) selectedRecipients.push(internalUserList.find(el => el._id === userId))
        else selectedRecipients = selectedRecipients.filter(el => el._id !== userId)

        const remembered = localStorage.getItem(`rememberCommentSide_${this.props.me._id}`)
        if (remembered) {
            if (selectedRecipients.length) {
                localStorage.setItem(`rememberCommentSide_${this.props.me._id}`, JSON.stringify(selectedRecipients.map(el => el._id)))
                this.props.toggleCommentSide(3)
            } else {
                localStorage.setItem(`rememberCommentSide_${this.props.me._id}`, 'int')
                this.props.toggleCommentSide(0)
                this.setState({selectedSide: 0})
            }
        }

        this.setState({selectedRecipients})
    }

    hideSelectedRecipientsPopup = () => {
        const {selectedRecipients} = this.state
        this.setState({isShowSelRecipientsPopup: false, selectedSide: selectedRecipients.length ? 3 : this.state.selectedSide})
    }

    func = (action, params) => {
        const remembered = localStorage.getItem(`rememberCommentSide_${this.props.me._id}`)
        if (!remembered) this.setState({selectedSide: 0, selectedRecipients: []})
        this.props.func(action, params)
    }

    hoverHandler = (value) => {
        if (!this.props.hoverHandler) return
        if (value) this.props.hoverHandler(this.props._id)
        else this.props.hoverHandler(null)
    }

    render() {
        const {isTypeReply, isShowThreadPopup, isEdit, resolvedFullMode, isTypeTitle, titleValue, selectedSide, showToggleSidePopup, selectedRecipients, isShowSelRecipientsPopup, isUncollapsed, staticMarginTop, hoveredMarginTop} = this.state
        const {_id, user, me, content, when, replies, blurHandler, func, recipients, type, internalUserList, externalUserList, isPin, isNeedOffset, event, sendBtnText, isProjectAdmin,
            isNewComment, edit, deleted, resolve, readers, initialValueTA, title, width, toggleSide, group, isPublic, freeze, isPendingReply, clps, isCommentListHover, isInApproval, isCoverMessage} = this.props

        const isNoResolve = this.props.isNoResolve || type === 3
        const isNoSendNow = this.props.isNoSendNow || type === 3
        const isEditCreator = this.props.isEditCreator || type === 3
        const isNoReply = this.props.isNoReply

        if (deleted && (!replies || !replies.length || replies.every(el => !!el.delete)))
            return <div styleName={cx('deleted', {'ml40': isInApproval})}>{transS('Thread Deleted')}</div>

        if (resolve && !resolvedFullMode)
            return <div styleName={cx('resolved', {'ml40': isInApproval})} onClick={() => this.setState({resolvedFullMode: true})}>{transS('Thread Resolved')}</div>

        return (
            <div styleName={cx('wrapper', {'private': (recipients && recipients.length) || selectedSide === 3,
                                           'freshExt': isNewComment && selectedSide === 1 || freeze,
                                           'ext': isPublic,
                                           'clps': clps,
                                           'clps_hov': clps && isCommentListHover,
                                           'unclps': isUncollapsed || this.props.isFresh})}
                 style={{width: width || '', marginTop: isNeedOffset ? hoveredMarginTop : staticMarginTop, transition: clps && this.refWrapper && this.refWrapper.style.marginTop && 'all .2s'}}
                 ref={e => this.refWrapper = e}
                 onMouseEnter={() => this.hoverHandler(true)}
                 onMouseLeave={() => this.hoverHandler(false)}
                 onClick={() => clps ? this.setState({isUncollapsed: true}) : null}>
                {!isNewComment && <div styleName={cx('side', {'ext': isPublic})}>
                    {recipients ?
                        <React.Fragment>
                            {recipients.map((el, i, arr) => {
                                if (!internalUserList.find(user => user._id === el._id)) return `Removed User`

                                return el._id === me._id ?
                                        `${user.firstname + user.lastname ? `${user.firstname} ${user.lastname}` : user.email}${arr.length - 1 !== i ? ', ' : ''}`
                                        : `${el.firstname + el.lastname ? `${el.firstname} ${el.lastname}` : el.email}${arr.length - 1 !== i ? ', ' : ''}`
                                })}
                            {resolve ? ' (Resolved)' : ''}
                            <div styleName='side-tip'>
                                <Tip text={recipients.map(el => {
                                    if (!internalUserList.find(user => user._id === el._id)) return `Removed User`

                                        return el._id === me._id ?
                                            `${user.firstname + user.lastname ? `${user.firstname} ${user.lastname}<br/>` : ''}${user.email}`
                                            : `${el.firstname + el.lastname ? `${el.firstname} ${el.lastname}<br/>` : ''}${el.email}`
                                    }).join('<br/>')}
                                     arrow='left bottom' />
                            </div>
                        </React.Fragment>
                        :
                        <div onMouseLeave={() => this.sideMouseHandler('leave')}
                             onMouseOver={e => this.sideMouseHandler('over', e)}
                             onMouseEnter={e => this.sideMouseHandler('enter', e)}>
                            {isPublic ? transS('External') : transS('Internal')}{resolve ? transS(' (Resolved)') : ''}

                            {!this.props.tipMouseHandler && <div styleName='side-tip'>
                                <Tip text={(isPublic ? externalUserList : internalUserList).map(el => `<div>${el.firstname + el.lastname ? `${el.firstname} ${el.lastname}<br/>` : ''}${el.email}</div>`).join('<br/>')} arrow='left bottom' />
                            </div>}
                        </div>}
                </div>}

                { (title || isTypeTitle) && <input autoFocus={isTypeTitle} type="text" styleName='title' 
                        placeholder={transS('Enter Thread Title')} 
                        onKeyDown={this.titleOnKeyDownHandler}
                        disabled={resolve} value={titleValue} 
                        onChange={this.titleOnChangeHandler} onBlur={this.titleOnBlurHandler} />}

                {isNewComment ?
                    <CommentRow isCoverMessage={isCoverMessage} blurHandler={blurHandler} selectedRecipients={selectedRecipients} isPublic={selectedSide === 1} internalUserList={internalUserList}
                                isNewComment={isNewComment} func={this.func} me={me} recipients={recipients} initialValueTA={initialValueTA} sendBtnText={sendBtnText} />
                    :
                    <CommentRow isCoverMessage={isCoverMessage} _id={_id} user={user} blurHandler={() => this.setState({isEdit: false})} func={func} mainEditCallback={() => this.setState({isEdit: true})}
                                content={content} when={when} me={me} isEdit={isEdit} edit={edit} deleted={deleted} type={type} freeze={freeze} canEdit={!isEditCreator || user._id === me._id}
                                canDelete={!isEditCreator || user._id === me._id || isProjectAdmin} group={group}
                                addThreadTitle={(!titleValue && !isTypeTitle && !resolve) ? () => this.setState({isTypeTitle: true, isShowThreadPopup: false}) : null } sendBtnText={sendBtnText}
                                isUnread={user._id !== me._id && !readers.find(r => r === me._id)} internalUserList={internalUserList} event={event} isSendFreeze={isNoSendNow} />}

                {replies && replies.length && <div styleName='repliesList'>
                    {replies.map((el, i, arr) => {
                        if (!el) return ''
                        let addThreadTitle = (!titleValue && !isTypeTitle && deleted && !resolve && !el.delete && (!i || arr[i - 1].delete)) ?
                            () => this.setState({isTypeTitle: true, isShowThreadPopup: false}) : null

                        return <CommentRow key={_id + el.when} _id={_id} user={el.user} content={el.content} when={el.when} me={me} func={func} isReply={true}
                                    isUnread={el.user._id !== me._id && !el.readers.find(r => r === me._id)} internalUserList={internalUserList} isSendFreeze={freeze || (i && arr[i - 1].freeze)}
                                    addThreadTitle={addThreadTitle} isNeedControls={el.user._id === me._id && !isPublic} edit={el.edit} deleted={el.delete} freeze={el.freeze} />
                    })}
                </div>}

                {!isNewComment && !isEdit && !resolve && !isNoReply && (isTypeReply ?
                    <CommentRow _id={_id} me={me} blurHandler={() => this.setState({isTypeReply: false})}
                                func={func} isReply={true} internalUserList={internalUserList}
                                isNewComment={true} style={{marginTop: '15px', paddingLeft: '40px'}} isPendingReply={isPendingReply} />
                    :
                    <Button text={transS('Reply')} mod='blue margin0' style={{marginLeft: `${ (replies && replies[replies.length - 1] && !replies[replies.length - 1].delete) ? '98px' : '48px'}`, marginTop: '5px'}}
                            callback={() => this.setState({isTypeReply: true})}/>)}
                {isPin != null && <img styleName='pinImg' alt={isPin ? 'pinned' : 'unpinned'}
                         src={`/images/icon-pin-${isPin ? 'gray' : 'blue'}.svg`}
                         onClick={e => {func('pin', {messageId: _id});this.setState({isShowThreadPopup: false})}}/>}

                {!isNewComment && !isEdit && (isPublic ? (!titleValue && !resolve) : true) &&
                <div styleName={cx('popup_wrapper', {'_open': isShowThreadPopup})} ref={e => this.refPopup = e}>
                    <div styleName='popup_showBtn' onClick={() => this.setState({isShowThreadPopup: !isShowThreadPopup})}><span/><span/><span/></div>
                    <div styleName='popup'>
                        {isPin != null &&  <Button mod='blue margin0 nowrap' text={isPin ? 'Unpin' : transS('Pin')} callback={() => {func('pin', {messageId: _id});this.setState({isShowThreadPopup: false})}} />}
                        {!isNoResolve && <Button mod={(resolve ? 'blue' : 'green') + ' margin0'} text={transS(resolve ? 'Unresolve' : 'Resolve')} callback={() => func('resolveMessage', {messageId: _id, value: !resolve})}/>}
                        {!titleValue && !resolve && <Button mod='blue margin0 nowrap' 
                            text={transS('Add Thread Title')} 
                            callback={() => this.setState({isTypeTitle: true, isShowThreadPopup: false})}/>}
                        {!isPublic && user._id === me._id && !deleted && !resolve && <Button mod='blue margin0' text={transS('Edit')} callback={() => this.setState({isEdit: true, isShowThreadPopup: false})}/>}
                        {!isPublic && user._id === me._id && !deleted && <Button mod='red margin0' text={transS('Delete')} callback={() => func('deleteMessage', {messageId: _id})}/>}
                    </div>
                </div>}
                {isNewComment && recipients && <div styleName='recipients'>{recipients.map((el, i) => `${i > 1 ? ',' : ''}${el.firstname} ${el.lastname} `)}</div>}

                {isNewComment && toggleSide && <div styleName={cx('sideToggles-wrapper', {'_open': showToggleSidePopup})} ref={e => this.refSidePopup = e}>
                    <div styleName='sideToggles-popupBtn' onClick={() => this.setState({showToggleSidePopup: !showToggleSidePopup})}>
                        <div styleName='sideToggles-popupBtn_arrow'>
                          <Button text='' mod={`leftArrow${showToggleSidePopup ? ' leftArrow_open' : ''}`} 
                           style={{position: 'relative', top: '-4px'}}/>
                        </div>
                        <span style={selectedSide === 1 ? {color: 'var(--color_darkRed)'} : null}>
                            {selectedSide === 0 && transS('Internal')}
                            {selectedSide === 1 && transS('External')}
                            {selectedSide === 3 && <React.Fragment>
                                Personal:
                                {selectedRecipients.map((sr, i, arr) => <div style={{marginLeft: '24px'}} key={sr._id + 'ssu'}>
                                    {sr.firstname + sr.lastname ? `${sr.firstname} ${sr.lastname}` : sr.email}{arr.length - 1 !== i ? ',' : ''}
                                </div>)}
                            </React.Fragment>}
                        </span>
                    </div>
                    <div styleName={cx('sideToggle-popup', {'_vis': showToggleSidePopup})}>
                        <div styleName='sideToggle-popup_toggles'>
                            <div styleName={cx('sideToggle-popup_btn int', {'_sel': selectedSide === 0})} onClick={() => this.toggleSideHandler(0)}>
                                {transS('Internal')}
                                <div styleName='sideToggle-popup_btn_tip'>
                                    <Tip text={internalUserList.map(el => `<div>${el.firstname + el.lastname ? `${el.firstname} ${el.lastname}<br/>` : ''}${el.email}</div>`).join('<br/>')} arrow='left bottom' />
                                </div>
                            </div>
                            <div styleName={cx('sideToggle-popup_btn ext', {'_sel': selectedSide === 1})} onClick={() => this.toggleSideHandler(1)}>
                                {transS('External')}
                                <div styleName='sideToggle-popup_btn_tip'>
                                    <Tip text={externalUserList.map(el => `<div>${el.firstname + el.lastname ? `${el.firstname} ${el.lastname}<br/>` : ''}${el.email}</div>`).join('<br/>')} arrow='left bottom' />
                                </div>
                            </div>
                            <div styleName={cx('sideToggle-popup_btn int', {'_sel': selectedSide === 3})}>
                                <span onClick={() => this.setState({isShowSelRecipientsPopup: true})}>{transS('Personal')}</span>
                                {selectedSide === 3 && <React.Fragment>:
                                    {selectedRecipients.map((sr, i, arr) => <div key={sr._id + 'ssu1'}>
                                        {sr.firstname + sr.lastname ? `${sr.firstname} ${sr.lastname}` : sr.email}{arr.length - 1 !== i ? ',' : ''}
                                    </div>)}
                                </React.Fragment>}
                                <Button text={selectedSide === 3 ? transS('Edit') : transS('Choose Team Member')} style={{display: 'block', height: '12px', marginTop: '1px'}}
                                        mod={`${selectedSide === 3 ? 'blue' : 'gray'} margin0`} callback={() => this.setState({isShowSelRecipientsPopup: true})} />
                                {<SelectedRecipientsPopup candidates={internalUserList.filter(el => el._id !== me._id)} selectedRecipients={selectedRecipients}
                                                          hidePopup={this.hideSelectedRecipientsPopup}
                                                          selectRecipientCallback={this.selectRecipientCallback} isShowSelRecipientsPopup={isShowSelRecipientsPopup} />}</div>
                        </div>
                        <Checkbox mod='medium' text={transS('Remember')} callback={this.rememberSideBtnHandler} checked={!!localStorage.getItem(`rememberCommentSide_${this.props.me._id}`)} style={{zIndex: '1'}} />
                    </div>
                </div>}

                {resolve && resolvedFullMode && <div styleName='hideBtn'><Button text='Hide this' mod='blue arch margin0' callback={() => this.setState({resolvedFullMode: false})}/></div>}

            </div>
        )
    }
}

export default Comment

import React, { Component } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './progressAndTasks.css';
import VersionDownloadDropDown from '../versionDownloadDropDown/VersionDownloadDropDown';
import Comment from './Comment';
import { getDateString } from "../../../../../common/getDateString";
import { transS } from '../../../../services/helpers/lang';

@connect(state => ({
  user: state.userReducer.user,
  dateData: state.userReducer.dateData,
  currentProject: state.projectReducer.currentProject,
  paraTitles: state.paragraphReducer.paraTitles
}), {} )
@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
export default class Version extends Component {
  state = {
      isExpanded: 0
  };  
  
  constructor (props) {
    super(props);
    this._expand = this._expand.bind(this);
    this._unknown = this._unknown.bind(this);
  }
  
  _expand = () => {
      const { extExpand } = this.props;
      const {isExpanded } = this.state;
      if (extExpand >= isExpanded) 
        this.setState({ isExpanded: extExpand + 3 - 2*(extExpand % 2) });
      else
        this.setState({ isExpanded: isExpanded - 1 + 2*(isExpanded % 2)})
  };
  
  _unknown = () => '';
  
  _traverseHistory = () => {
      const {history, last, first, jumpDoc, paraTitles, dateData } = this.props
      let result = [], id, isParagraph;
      if (history)
      for (let i = 0; i < history.length; i++)
        if (!last || history[i].time < last || (last===first && history[i].time === last)) {
            if (history[i].time < first || !last && history[i].time === first) return result;
            isParagraph = history[i].isPrivate===undefined;
            id = isParagraph ? history[i].id : history[i].comment;
            result.push(
              <Comment
                key={id+'-'+history[i].time}
                isFreeze={history[i].freeze !== undefined}
                isPrivate={history[i].isPrivate}
                firstname={history[i].firstname}
                lastname={history[i].lastname}
                avatar={history[i].avatar}
                action={history[i].action}
                whoDueChanges={!!history[i].whoDueChanges}
                isThread={history[i].time === history[i].comment}
                thTitle={isParagraph ? paraTitles[id] : history[i].thTitle}
                ctime={history[i].time === 1963 ? '' : getDateString(history[i].time, 4, dateData)}
                upd={history[i].upd ? getDateString(history[i].upd, 4, dateData) : ''}
                title={history[i].title}
                content={history[i].content} 
                jumpId={isParagraph ? id : (id +'-'+(history[i].upd ? history[i].upd : history[i].time))}
                jumpDoc={jumpDoc}
                subver ={history[i].subver}
              />
            );
        }
      return result;
  }
  
  render() {
    const { versionName, statusText, changedText, isCurrent, extExpand,
      goToVersion, downloadVersion, history, last, first } = this.props;
    const {isExpanded} = this.state;  
    let trueExpanded = extExpand >= isExpanded ? extExpand % 2 : isExpanded % 2;
    // removed          <div styleName='change-creator'>{changedText}</div>
    
    return (
      <div styleName='versionField'>          
          <div styleName={versionName ? "h3" : "h2"} onClick={this._expand}>
              {versionName ? versionName : transS('Current Version')}
              { (versionName || history && history.length && history[0].time > first) 
                ? <span styleName={cx('arrow', { '_expanded':  trueExpanded})} /> : ''
              }
          </div>
          <div styleName='change-creator'>{statusText}</div>
          {!isCurrent && 
            <span styleName='change-creator inspan'
              onClick={goToVersion} >{transS('Go to Version')}
            </span>               
          }
          <VersionDownloadDropDown 
                  _message={downloadVersion._message}
                  download={downloadVersion.download}
                  version={downloadVersion.version}
                  subVersion={downloadVersion.subVersion} 
                  isHistory={downloadVersion.isHistory}
                  isPlanar='1'
           />
           { trueExpanded ? this._traverseHistory() : ''}
      </div>
    );
  }
  
 
}

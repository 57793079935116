import React from 'react'
import styles from './tempUserView.css'
import CSSModules from 'react-css-modules'
import cx from 'classnames'
import {connect} from "react-redux"
import ProjectPage from "../../components/projectPage/projectPage"
import Hint from "../../../../components/button/Hint"
import {PlusSign} from '../../../../components/plusSign/PlusSign';
import {
    block5PermissionCheck,
    docs5PermissionCheck,
    fileFormatValidation,
    fileSizeValidation
} from "../../../../../common/validation"
import {getProjectList, setCurrentProject, updateProjectAction} from "../../../../services/actions/projectActions"
import {deleteDocumentAction, downloadDocumentAction} from "../../../../services/actions/documentActions"
import {popUpReset} from "../../../../services/helpers"
import {getPopUpAction} from "../../../../services/actions/dataActions"
import {addFileAction} from "../../../../redux/document/actions"
import { transS } from '../../../../services/helpers/lang'

@connect(state => ({
    projectList: state.projectReducer.projectList,
    currentProject: state.projectReducer.currentProject,
    user: state.userReducer.user
}), {setCurrentProject, getProjectList, updateProjectAction, deleteDocumentAction, getPopUpAction, addFileAction})
@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
export class TempUserView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            isDragActive: false,
        }
        this.dropzoneTimeout = null
    }

    async componentDidMount() {
        const {currentProject} = this.props

        const projectList = await this.props.getProjectList()

        this.props.setCurrentProject({projectId: projectList[0]._id, projectList})

        if (currentProject._id)
            this.setState({title: currentProject.title})

        window.addEventListener('dragenter', this.dragHandler)
        window.addEventListener('dragover', this.dragHandler)
        window.addEventListener('drop', this.dragHandler)
    }

    componentWillUnmount() {
        window.removeEventListener('dragenter', this.dragHandler)
        window.removeEventListener('dragover', this.dragHandler)
        window.removeEventListener('drop', this.dragHandler)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {currentProject, projectList} = this.props

        if (!currentProject._id && projectList)
            this.props.setCurrentProject({projectId: projectList[0]._id, projectList})

        if (prevProps.currentProject.title !== currentProject.title && currentProject.title !== this.state.title) {
            this.setState({title: currentProject.title})
        }

    }

    renameProject = () => {
        const {title} = this.state
        const {updateProjectAction, currentProject} = this.props

        if (!title || title === currentProject.title)
            return this.setState({title: currentProject.title})

        updateProjectAction(currentProject._id, title.trim())
    }

    drop = files => {
        // let fileCount = 0
        // const transferNames = [], transferFiles = []
        //
        // const checkDocsCount = () => {
        //     let count = docs5PermissionCheck(this.props.projectList);
        //     if (!count || block5PermissionCheck(count + files.length)) {
        //         this.props.getPopUpAction({
        //             name: 'confirm',
        //             header: 'Upgrade Plan',
        //             swap: true,
        //             text: `In your Free Account you can add new documents until you have 5 in total.<br><br>Upgrade your account to add more documents.`,
        //             confirm: {
        //                 name: 'Upgrade',
        //                 event: () => this.props.getPopUpAction(popUpReset)
        //             },
        //             cancel: {
        //                 name: 'Cancel',
        //                 event: () => this.props.getPopUpAction(popUpReset)
        //             }
        //         })
        //         return false
        //     }
        //     return true
        // }
        //
        // files.forEach(f => {
        //     if (fileSizeValidation(f) || fileFormatValidation(f)) return
        //     const reader = new FileReader()
        //     reader.onloadend = () => {
        //         transferNames.push(f.name)
        //         transferFiles.push(reader.result)
        //         fileCount++
        //         if (fileCount === files.length && transferNames.length && checkDocsCount()) {
        //             this.props.addFileAction(transferFiles, transferNames, this.props.currentProject._id, null)
        //         }
        //     }
        //     reader.readAsDataURL(f)
        // })
    }

    dragHandler = e => {
        const {isDragActive} = this.state
        let isInside = e.target === this.refWrapper || this.refWrapper.contains(e.target)
        switch (e.type) {
            case 'dragover':
            case 'dragenter':
                if (!isDragActive && isInside ) {
                    this.setState({isDragActive: true})
                } else if (isDragActive && !isInside) {
                    this.setState({isDragActive: false})
                }
                clearTimeout(this.dropzoneTimeout)
                break;
            case 'drop':
                console.log(e)
                e.preventDefault()
                e.stopPropagation()
                this.setState({isDragActive: false})
                break;
        }
        this.dropzoneTimeout = setTimeout(() => this.setState({isDragActive: false}), 200)
    }

    createDocList = () => {
        const {currentProject, getPopUpAction, deleteDocumentAction} = this.props

        if (!currentProject.docs) return

        const showDeletePopup = docId => getPopUpAction({
            name: 'confirm',
            text: transS('Do you want to remove the project?'),
            confirm: {
                name: transS('Delete'),
                danger: true,
                event: () => {
                    deleteDocumentAction(docId, currentProject._id);
                },
            },
            cancel: {
                name: transS('Cancel'),
                event: () => {
                    getPopUpAction(popUpReset);
                }
            }
        })

        return <div styleName='docList' onClick={e => {e.preventDefault();e.stopPropagation()}}>
            {currentProject.docs.map(doc => <div styleName='docList-item' key={doc._id}>
                <div onClick={() => this.props.history.push(`${currentProject._id}/documents/${doc.coreDocument}`)}>{doc.title}</div>
                <div className='close-cross' styleName='deleteBtn' onClick={() => showDeletePopup(doc.coreDocument)}>+</div>
            </div>)}
        </div>
    }

    render() {
        const {title, isDragActive} = this.state
        const {currentProject, user} = this.props

        if (!currentProject._id) return

        return (
            <div styleName='wrapper' ref={e => this.refWrapper = e}>
                <div styleName='project-title'>
                    <input value={title}
                           placeholder={transS('Project name')}
                           maxLength='256'
                           onChange={e => this.setState({ title: e.target.value}) }
                           onBlur={this.renameProject}
                    />
                    <div styleName='project-title-hint'>
                        <Hint text={transS('Click to rename')} /></div>
                </div>
                <div styleName='row'>
                    <div styleName={cx('dropzone', {'_active': isDragActive})}
                              onDrop={this.drop}
                              onClick={() => this.props.getPopUpAction({name: 'auth'})}
                              // activeClassName="greyback"
                              // accept='.doc, .docx'
                              onDragOver={this.dragHandler}>
                        <div styleName='dropzone-title'>{transS('Documents')}</div>
                        {this.createDocList()}
                        <div styleName='plus'>
                            <PlusSign />
                            {transS('Upload a document')}
                        </div>
                    </div>
                    <ProjectPage inOneProject={true} />
                </div>

            </div>
        )
    }
}
import React from 'react'
import styles from './tempUserBlock.css'
import CSSModules from 'react-css-modules'
import cx from 'classnames'
import Button from "../../../../../../components/button/Button"
import {connect} from "react-redux"
import {getPopUpAction} from "../../../../../../services/actions/dataActions"
import api from "../../../../../../services/api/api"
import {logout, setTemporaryUserAction} from "../../../../../../services/actions/userActions"
import { transS } from '../../../../../../services/helpers/lang'


@connect(state => ({
    user: state.userReducer.user,
    currentProject: state.projectReducer.currentProject
}), {getPopUpAction, setTemporaryUserAction, logout})
@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
export class TempUserBlock extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            timeRemaining: 0,
            isShowAlert: false,
            isShowAlertLastTime: false,
        }
        this.timeRemainingInternal = null
    }

    componentDidMount() {
        this.startInterval()
        if (this.props.user.temporary && !+this.props.user.email) this.confirmEmailPopup()
    }

    componentWillUnmount() {
        clearInterval(this.timeRemainingInternal)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!+this.props.user.email && this.props.user.temporary && this.state.isShowAlert) this.setState({isShowAlert: false})

        if (this.props.user.temporary && !+this.props.user.email) this.confirmEmailPopup()
    }

    startInterval = () => {
        if (!+this.props.user.email && this.props.user.temporary) return

        this.props.getPopUpAction({name: 'auth'})
        const tr = +this.props.user.email + 3600000 - Date.now()
        this.setState({timeRemaining: tr, isShowAlert: tr > 0})
        this.timeRemainingInternal = setInterval(() => {
            const timeRemaining = +this.props.user.email + 3600000 - Date.now()
            this.setState({timeRemaining})
            if (timeRemaining < 180000 && !this.state.isShowAlertLastTime) this.setState({isShowAlertLastTime: true, isShowAlert: true})
            if (timeRemaining < 0) {
                this.props.getPopUpAction({name: 'auth'})
                this.setState({isShowAlert: false})
            }
            if (!timeRemaining && timeRemaining !== 0) clearInterval(this.timeRemainingInternal)
        }, 1000)
    }

    makeAlert = () => {
        const {timeRemaining, isShowAlert} = this.state

        let minutes = Math.trunc(timeRemaining / 1000 / 60 % 60), seconds = Math.trunc(timeRemaining / 1000 % 60 )
        if (seconds.toString().length === 1) seconds = '0' + seconds

        return <div styleName={cx('alert', {'_vis': isShowAlert})}>
            <div className='close-cross' style={{top: '13px', right: '18px'}}
                 onClick={() => this.setState({isShowAlert: false})}>+</div>

            <div styleName='red'>Sign up to save the project</div>
            <div>Time remaining <span styleName={cx({'red': minutes < 3})}>{minutes}:{seconds}</span></div>

            <Button text='Sign Up' mod='blue fill margin0' style={{marginTop: '20px'}}
                    callback={() => {
                        this.setState({isShowAlert: false})
                        this.props.getPopUpAction({name: 'auth'})
                    }} />
        </div>
    }

    confirmEmailPopup = () => {
        this.props.getPopUpAction({
            name: 'confirm',
            header: transS('Confirm Email'),
            unClosable: true,
            text: `To continue please follow the link we sent you. <br/><br/>
                   If you haven't received the link please check spam or wait a little. <br/><br/>
                   <b style="white-space: nowrap">Once the email is confirmed please sign in.</b> <br/>`,
        })
    }

    render() {
        return (
            <div styleName='wrapper'>
                <Button text='Sign Up' mod='blue arch margin0' callback={() => this.props.getPopUpAction({name: 'auth'})} />
                {this.makeAlert()}
            </div>
        )
    }
}

import React, {Component, Fragment} from 'react';
import CSSModules from "react-css-modules"
import styles from "./dataProtection.css"
import Footer from "../../../components/footer/Footer"
import {NavLink} from "react-router-dom"

@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
class TermsUse extends Component {

    scrollTo = (target) => {
        switch (target) {
            case '1.1.1':
                window.scrollTo({top: this.ref111.offsetTop - 106, left: 0, behavior: "smooth"})
                break
            case '1.1.1(1)': {
                window.scrollTo({top: this.ref111.offsetTop - 6, left: 0, behavior: "smooth"})
                break
            }
            case '1.1.2':
                window.scrollTo({top: this.ref112.offsetTop - 106, left: 0, behavior: "smooth"})
                break
            case '1.1.2(1)(i)': {
                window.scrollTo({top: this.ref112.offsetTop - 6, left: 0, behavior: "smooth"})
                break
            }
            case '1.1.2(1)(ii)': {
                window.scrollTo({top: this.ref112.offsetTop + 42, left: 0, behavior: "smooth"})
                break
            }
            case '1.1.2(1)(iii)': {
                window.scrollTo({top: this.ref112.offsetTop + 117, left: 0, behavior: "smooth"})
                break
            }
            case '1.1.2(2)': {
                window.scrollTo({top: this.ref112.offsetTop + 177, left: 0, behavior: "smooth"})
                break
            }
            case '1.3.1(2)': {
                const scrollTop = this.ref1312.offsetTop + this.ref1312.offsetParent.offsetTop
                window.scrollTo({top: scrollTop - 100, left: 0, behavior: "smooth"})
                break
            }
            case '3.1':
                window.scrollTo({top: this.ref31.offsetTop - 106, left: 0, behavior: "smooth"})
                break
            case '3.2':
                window.scrollTo({top: this.ref32.offsetTop - 106, left: 0, behavior: "smooth"})
                break
            case '5.3':
                window.scrollTo({top: this.ref53.offsetTop - 106, left: 0, behavior: "smooth"})
                break
            case '5.4':
                window.scrollTo({top: this.ref54.offsetTop - 106, left: 0, behavior: "smooth"})
                break
            default:
                break
        }
    }

    render() {
        return (
            <Fragment>
                <div styleName='wrapper'>

                    <div styleName='title'>Terms of Use</div>
                    <div styleName='version'>Version 1.1 <br/>
                        Effective: 1 January 2020
                    </div>

                    <div styleName='section'>
                        <div styleName='subTitle'><span>1</span>Regulation. Changes to terms of use</div>
                        <div styleName='subTitle2'><span>1.1</span>Regulation</div>
                        <div styleName='paragraph' ref={e => this.ref111 = e}><span>1.1.1</span>
                            These terms of use (the <b>“Terms”</b>) of Contract.one, Inc (<b>“we”</b>) describes how we
                            provide our software (Contract.one platform, the <b>“Software”</b>) to:
                            <div styleName='subParagraph'><span>(1)</span>
                                customers, who upload documents (the <b>“Customers”</b>) and may invite users <br/>
                                <br/>
                                If a user represents a company (for example, her or his employer) when he or she buys a
                                subscription for the Software, uses the Software’s free plan or orders its demo version,
                                this company is the Customer. If not, this user is the Customer; and
                            </div>
                            <div styleName='subParagraph'><span>(2)</span>
                                users.
                            </div>
                        </div>
                        <div styleName='paragraph' ref={e => this.ref112 = e}><span>1.1.2</span>
                            These terms apply when:
                            <div styleName='subParagraph'><span>(1)</span>
                                the Customer —
                                <div styleName='subParagraph'><span>(i)</span>
                                    buys a subscription for the Software; or
                                </div>
                                <div styleName='subParagraph'><span>(ii)</span>
                                    orders the demo version of the Software; or
                                </div>
                                <div styleName='subParagraph'><span>(iii)</span>
                                    uses the Software’s free plan subject to promotion campaign; or
                                </div>
                            </div>
                            <div styleName='subParagraph'><span>(2)</span>
                                a user uses the Software subject to invitation from the Customer.
                            </div>
                            The Customer or a user (<b>“you”</b>) agree to use it, as specified in the Terms.
                        </div>
                        <div styleName='subTitle2'><span>1.2</span>Standard Contractual Clauses</div>
                        <div styleName='paragraph'><span>1.2.1</span>
                            <a styleName='link' download href="../../../images/Contract.one%20Standard%20Clauses%201.0.pdf">Standard Contractual Clauses</a>
                            {' '}
                            with us will apply to you, if
                            you:
                            <div styleName='subParagraph'><span>(1)</span>
                                are a Company that is located within an EEA, EU or UK jurisdiction; and
                            </div>
                            <div styleName='subParagraph'><span>(2)</span>
                                have to engage with us in Cross-Border Data Transfer.
                            </div>
                        </div>
                        <div styleName='subTitle2'><span>1.3</span>Changes to Terms</div>
                        <div styleName='paragraph'><span>1.3.1</span>
                            We:
                            <div styleName='subParagraph'><span>(1)</span>
                                may change the Terms; and
                            </div>
                            <div styleName='subParagraph' ref={e => this.ref1312 = e}><span>(2)</span>
                                will post the current version on our website (the <b>“Website”</b>).
                            </div>
                        </div>
                        <div styleName='paragraph'><span>1.3.2</span>
                            If a change of the Terms is material, we will notify you via email.
                        </div>
                        <div styleName='paragraph'><span>1.3.3</span>
                            If you do not accept the changes to the Terms, please, stop using the Software. If you are
                            user, not the Customer, please, instruct the Customer to ask us to delete your account.
                        </div>
                    </div>

                    <div styleName='section'>
                        <div styleName='subTitle'><span>2</span>License</div>
                        <div styleName='paragraph'>
                            As long as you comply with the Terms, we give you a limited, non-exclusive,
                            non-transferable, revocable license to use the Software, as described in the Terms.
                        </div>
                    </div>

                    <div styleName='section'>
                        <div styleName='subTitle'><span>3</span>Fee for using Software</div>
                        <div styleName='paragraph' ref={e => this.ref31 = e}><span>3.1</span>
                            The Customer must pay the fee for using the Software (the <b>“Fee”</b>). The standard Fee
                            may be
                            published on the Website. We will provide you with the applicable Fee’s amount upon your
                            request.
                        </div>
                        <div styleName='paragraph'>
                            <b>Exception:</b> the Customer doesn’t have to pay the Fee for using the trial version of
                            the
                            Software (
                            <div styleName='link' onClick={() => this.scrollTo('1.1.2(2)')}>Clause 1.1.2(2)</div>)
                            or its free plan (
                            <div styleName='link' onClick={() => this.scrollTo('1.1.2(1)(iii)')}>Clause 1.1.2(1)(iii)
                            </div>
                            ).
                        </div>
                        <div styleName='paragraph' ref={e => this.ref32 = e}><span>3.2</span>
                            The Customer must pay the Fee for the period of use before the Customer starts to use the
                            Software in this period.
                        </div>
                        <div styleName='paragraph'><span>3.3</span>
                            The Fee is non-refundable.
                        </div>
                        <div styleName='paragraph'><span>3.4</span>
                            We may change the Fee. We will notify you about it via email in advance.
                        </div>
                        <div styleName='paragraph'><span>3.5</span>
                            The Customer must pay all the taxes, which relate to the Fee.
                        </div>
                        <div styleName='paragraph'>
                            <b>Exception:</b> we will on our own pay the taxes based on our net income.
                        </div>
                        <div styleName='paragraph'><span>3.6</span>
                            If your account is set to auto-renewal, we may charge the Fee automatically at the end of
                            the period, which precedes the renewal period.
                        </div>
                        <div styleName='paragraph'>
                            <b>Exception:</b> we will not automatically charge the Fee if you notify us that you would
                            like to cancel or disable auto-renewal.
                        </div>
                    </div>

                    <div styleName='section'>
                        <div styleName='subTitle'><span>4</span>Acceptable use</div>
                        <div styleName='paragraph'><span>4.1</span>
                            Please, do not use the Software:
                            <div styleName='subParagraph'><span>(1)</span>
                                to violate the law in any way including sending spam messages;
                            </div>
                            <div styleName='subParagraph'><span>(2)</span>
                                interfere with or disrupt any user, host or network (by sending a virus, flooding etc.);
                            </div>
                        </div>
                        <div styleName='paragraph'><span>4.2</span>
                            Please, do not:
                            <div styleName='subParagraph'><span>(1)</span>
                                reverse engineer the Software or assist anyone to do it;
                            </div>
                            <div styleName='subParagraph'><span>(2)</span>
                                circumvent any security or authentication measures;
                            </div>
                            <div styleName='subParagraph'><span>(3)</span>
                                abuse our personnel;
                            </div>
                            <div styleName='subParagraph'><span>(4)</span>
                                provide access to your account to another person;
                            </div>
                            <div styleName='subParagraph'><span>(5)</span>
                                access or create accounts by any means other than using the interfaces we publicly
                                support;
                            </div>
                            <div styleName='subParagraph'><span>(6)</span>
                                scan or test the Software for vulnerability;
                            </div>
                            <div styleName='subParagraph'><span>(7)</span>
                                access the contracts, which you haven't been invited to comment.
                            </div>
                        </div>
                    </div>

                    <div styleName='section'>
                        <div styleName='subTitle'><span>5</span>Liability</div>
                        <div styleName='subTitle2'><span>5.1</span>Termination of Access to Software</div>
                        <div styleName='paragraph'><span>5.1.1</span>
                            We may at our discretion terminate your access to the Software with or without notice:
                            <div styleName='subParagraph'><span>(1)</span>
                                if we are required to do it by law; or
                            </div>
                            <div styleName='subParagraph'><span>(2)</span>
                                we reasonably believe you use the Software —
                                <div styleName='subParagraph'><span>(i)</span>
                                    in breach of law;
                                </div>
                                <div styleName='subParagraph'><span>(ii)</span>
                                    a manner, which causes a risk of damages to us or other users;
                                </div>
                            </div>
                            <div styleName='subParagraph'><span>(3)</span>
                                you violate the Terms.
                            </div>
                        </div>
                        <div styleName='paragraph'><span>5.1.2</span>
                            <div styleName='link'
                                 onClick={() => this.scrollTo('5.3')}>Sections 5.3
                            </div>
                            {' '}
                            (<i>Indemnities</i>) and <div styleName='link'
                                                          onClick={() => this.scrollTo('5.4')}>5.4</div> (<i>Limitation
                                and
                                Exclusion of Liability</i>) will survive
                            after we terminate your access to the Software.
                        </div>
                        <div styleName='subTitle2'><span>5.2</span>Suspension of Access to Software</div>
                        <div styleName='paragraph'>
                            We may suspend your access to the Software if your Customer delays paying the Fee
                            (
                            <div styleName='link' onClick={() => this.scrollTo('3.2')}>Clause 3.2</div>
                            ).
                        </div>
                        <div styleName='subTitle2' ref={e => this.ref53 = e}><span>5.3</span>Indemnities</div>
                        <div styleName='paragraph'>
                            The Customer must indemnify us against any losses, damages and expenses, which relate:
                            <div styleName='subParagraph'><span>(1)</span>
                                to the information, which you have uploaded;
                            </div>
                            <div styleName='subParagraph'><span>(2)</span>
                                using the Software, including using, which violates the Terms.
                            </div>
                        </div>
                        <div styleName='subTitle2' ref={e => this.ref54 = e}><span>5.4</span>Limitation and Exclusion of
                            Liability
                        </div>
                        <div styleName='paragraph'><span>5.4.1</span>
                            We will not be liable under any theory of liability (contract, tort, statutory or any
                            other):
                            <div styleName='subParagraph'><span>(1)</span>
                                for any indirect and exemplary damages, including, for example, loss of profit, data or
                                business opportunity, business interruption;
                            </div>
                            <div styleName='subParagraph'><span>(2)</span>
                                any losses or damages, which arise from your failure to keep confidential the password
                                from your account.
                            </div>
                        </div>
                        <div styleName='paragraph'><span>5.4.2</span>
                            Our liability will not exceed 300 % of the monthly Fee.
                        </div>
                        <div styleName='paragraph'><span>5.4.3</span>
                            Except for payment obligations, neither we nor you will be liable for improper performance
                            if it was caused by an issue, which is beyond our or your control.
                        </div>
                    </div>

                    <div styleName='section'>
                        <div styleName='subTitle'><span>6</span>Customer’s warranties. Absence of our warranties</div>
                        <div styleName='paragraph'><span>6.1</span>
                            The Customer warrants:
                            <div styleName='subParagraph'><span>(1)</span>
                                that its representative, who has bought a subscription for the Software (
                                <div styleName='link' onClick={() => this.scrollTo('1.1.2(1)(i)')}>Clause 1.1.2.(1)(i)
                                </div>
                                ) or ordered its demo version (
                                <div styleName='link' onClick={() => this.scrollTo('1.1.2(1)(ii)')}>Clause
                                    1.1.2(1)(ii)
                                </div>
                                ) has the necessary powers to act on its behalf —
                                if the Customer is a legal entity;
                            </div>
                            <div styleName='subParagraph'><span>(2)</span>
                                its users will comply with the Terms.
                            </div>
                        </div>
                        <div styleName='paragraph'><span>6.2</span>
                            We provide you with the Software on an “as is” and “as available” bases. We don’t make any
                            warranties, which relate to the Software, including that it will be uninterrupted or
                            error-free.
                        </div>
                    </div>

                    <div styleName='section'>
                        <div styleName='subTitle'><span>7</span>Other provisions</div>
                        <div styleName='subTitle2'><span>7.1</span>Interpretation</div>
                        <div styleName='paragraph'><span>7.1.1</span>
                            References to Sections and Clauses are references to sections and clauses of these Terms.
                            The term <b>“including”</b> (and other similar expressions) means <b>“including without
                                limitation”</b>.
                        </div>
                        <div styleName='paragraph'><span>7.1.2</span>
                            Definitions in the Terms have the following meanings:
                            <div styleName='subParagraph'><span>(1)</span>
                                <b>Customers</b> has the meaning, which is specified in <div styleName='link'
                                                                                             onClick={() => this.scrollTo('1.1.1(1)')}>Clause
                                    1.1.1(1)</div>;
                            </div>
                            <div styleName='subParagraph'><span>(2)</span>
                                <b>Fee</b> has the meaning, which is specified in <div styleName='link'
                                                                                       onClick={() => this.scrollTo('3.1')}>Clause
                                    3.1</div>;
                            </div>
                            <div styleName='subParagraph'><span>(3)</span>
                                <b>Software</b> has the meaning, which is specified in <div styleName='link'
                                                                                            onClick={() => this.scrollTo('1.1.1')}>Clause
                                    1.1.1</div>;
                            </div>
                            <div styleName='subParagraph'><span>(4)</span>
                                <b>Terms</b> has the meaning, which is specified in <div styleName='link'
                                                                                         onClick={() => this.scrollTo('1.1.1')}>Clause
                                    1.1.1</div>;
                            </div>
                            <div styleName='subParagraph'><span>(5)</span>
                                <b>You</b> has the meaning, which is specified in <div styleName='link'
                                                                                       onClick={() => this.scrollTo('1.1.2')}>Clause
                                    1.1.2</div>;
                            </div>
                            <div styleName='subParagraph'><span>(6)</span>
                                <b>We</b> has the meaning, which is specified in <div styleName='link'
                                                                                      onClick={() => this.scrollTo('1.1.1')}>Clause
                                    1.1.1</div>;
                            </div>
                            <div styleName='subParagraph'><span>(7)</span>
                                <b>Website</b> has the meaning, which is specified in <div styleName='link'
                                                                                           onClick={() => this.scrollTo('1.3.1(2)')}>Clause
                                    1.3.1(2)</div>.
                            </div>
                        </div>
                        <div styleName='paragraph'><span>7.1.3</span>
                            The definitions, which are used in the Terms, have the same meaning in the Contract.one,
                            Inc’s Privacy Policy, unless the Privacy Policy specifies another meaning for such
                            definitions.
                        </div>
                        <div styleName='subTitle2'><span>7.2</span>Beta Features</div>
                        <div styleName='paragraph'>
                            Sometimes we release the Software features, which we are still testing and evaluating. These
                            features will be marked as beta or in a similar way and may not be as reliable as the
                            standard features, so please, keep that in mind.
                        </div>

                        <div styleName='subTitle2'><span>7.3</span>Age Requirements</div>
                        <div styleName='paragraph'>
                            Please, do not use the Software if you are under the age of 18.
                        </div>

                        <div styleName='subTitle2'><span>7.4</span>Requests to you</div>
                        <div styleName='paragraph'>
                            If you receive a request about using the Software from another Customer, user or someone
                            else, please:
                            <div styleName='subParagraph'><span>(1)</span>
                                answer it using your own information;
                            </div>
                            <div styleName='subParagraph'><span>(2)</span>
                                contact us only if your own information is not enough.
                            </div>
                        </div>

                        <div styleName='subTitle2'><span>7.5</span>Severability</div>
                        <div styleName='paragraph'>
                            If a provision of the Terms is found unenforceable, we will modify it to reflect our
                            intention. The remaining provisions of the Terms will retain effect.
                        </div>

                        <div styleName='subTitle2'><span>7.6</span>Governing Law. Disputes Resolution</div>
                        <div styleName='paragraph'><span>7.6.1</span>
                            The Terms will be governed by the law of the State of Delaware.
                        </div>
                        <div styleName='paragraph'><span>7.6.2</span>
                            The Software is not intended to be applied for consumer purposes, so consumer laws will not
                            be applied.
                        </div>
                        <div styleName='paragraph'><span>7.6.3</span>
                            We and you will try to resolve any dispute informally. If you have any concerns, please,
                            contact us at <a styleName='link'
                                             href="mailto:global@contract.one">global@contract.one</a>. If we and you
                            fail to resolve a dispute within 30 days
                            after we received your email, we or you may start formal proceedings.
                        </div>
                        <div styleName='paragraph'><span>7.6.4</span>
                            We and you will bring any judicial proceedings to resolve claims, which relate to the Terms
                            or the Software, to the competent court of the State of Delaware.
                        </div>

                        <div styleName='paragraph'><span>7.6.5</span>
                            You:
                            <div styleName='subParagraph'><span>(1)</span>
                                may resolve disputes with us only on an individual basis; and
                            </div>
                            <div styleName='subParagraph'><span>(2)</span>
                                may not bring a claim in a class action.
                            </div>
                        </div>

                        <div styleName='subTitle2'><span>7.7</span>No Attorney-Client Relationship</div>
                        <div styleName='paragraph'>
                            We are not a law firm and using the Software does not create an attorney-client
                            relationship. We do not provide you with legal advice via the Software. If you need a legal
                            advice, please, contact a licensed attorney.
                        </div>

                        <div styleName='subTitle2'><span>7.8</span>Username Refusal or Revocation</div>
                        <div styleName='paragraph'>
                            We will ask you to select a username for your account. We may refuse to grant you a username
                            or revoke it for any reason at our discretion, for example, if we reasonably believe that
                            such username is offensive or is protected by a trademark.
                        </div>

                        <div styleName='subTitle2'><span>7.9</span>Assignment</div>
                        <div styleName='paragraph'><span>7.9.1</span>
                            You may not assign any rights or obligations under the Terms.
                        </div>
                        <div styleName='paragraph'><span>7.9.2</span>
                            We may assign any rights or obligations under the Terms without sending you a notice due to
                            a merger, acquisition, reorganization, sale of all or substantial part of our assets.
                        </div>

                        <div styleName='subTitle2'><span>7.10</span>Rights only for us, Customers and Users. No
                            Partnership and Agency Relations
                        </div>
                        <div styleName='paragraph'><span>7.10.1</span>
                            No one except us, Customers and users acquire rights subject to the Terms.
                        </div>
                        <div styleName='paragraph'><span>7.10.2</span>
                            There are no partnership relations between us and you. We and you are not agents of each
                            other.
                        </div>

                        <div styleName='subTitle2'><span>7.11</span>Entire Agreement</div>
                        <div styleName='paragraph'>
                            The Terms regulate all the conditions of interaction between us and you, which relates to
                            using the Software. They supersede all our prior oral and (or) written agreements.
                        </div>

                        <div styleName='subTitle2'><span>7.12</span>Contact</div>
                        <div styleName='paragraph'>
                            Please, contact us at <a styleName='link'
                                                     href="mailto:global@contract.one">global@contract.one</a>, if you
                            have any questions, comments or requests,
                            which relate to the Terms.
                        </div>
                    </div>

                    <div styleName='signature'></div>

                </div>
                <Footer/>
            </Fragment>
        )
    }
}

export default TermsUse
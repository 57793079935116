import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './login.css';

const Scheduled = props => (
  <main styleName='activation'>
    <div>
      <p>Thank you. We will get back to you shortly.</p>
      <p styleName='link-row'><a styleName='link' href='/'>Return to the main page</a></p>
    </div>
  </main>
);

export default new CSSModules(Scheduled, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });

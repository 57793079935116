import { socketConstants } from '../../../configs/appConfig';
import { dataConstants } from '../constants';
import { popUpReset, popUpWithInputReset } from '../helpers/index';


const initialState = {
  popUp: popUpReset(),
  popUpWithInput: popUpWithInputReset(),
  upopUp: null,
  loader: false,
  showStarted: false,
  deletedProjectId: null,
  signal: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
  case dataConstants.GET_POP_UP:
    if (action.popUp.name === 'uni')
      return {
        ...state,
        upopUp: action.popUp
      }
    return {
      ...state,
      popUp: action.popUp,
      upopUp: null
    };
  case dataConstants.GET_POP_UP_WITH_INPUT:
    return {
      ...state,
      popUpWithInput: action.popUpWithInput.popup
    };
  case dataConstants.TOGGLE_LOADER:
    return {
      ...state,
      loader: action.loader
    };
  case dataConstants.SHOW_DELETED_PROJECT_POPUP:
    return {
      ...state,
      popUp: action.popUp
    };
  case dataConstants.SHOW_STARTED:
    return {
      ...state,
      showStarted: action.showStarted
    };
  default:
    return state;
  }
};



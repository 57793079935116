import React from 'react'
import styles from './Approval.css'
import CSSModules from 'react-css-modules'
import cx from 'classnames'
import {eventSubtypes} from "../../../../../../services/helpers/logger"
import { getDateString } from "../../../../../../../common/getDateString"
import SmallUser from "../../../projectPage/SmallUser"
import Button from "../../../../../../components/button/Button"
import Comment from "../../../../../../components/comment/Comment"
import {connect} from "react-redux"
import { transS } from '../../../../../../services/helpers/lang'

@connect(state => ({    
    dateData: state.userReducer.dateData
}), {})
@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
class Approval extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showInputReqApproval: false,
            showApproveHistory: false,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isApprovalsOpen && !this.props.isApprovalsOpen) {
            this.setState({showInputReqApproval: false, showApproveHistory: false})
        }
    }

    makeApproverMessages = () => {
        const {approveMessages, func, internalUserList, isApprovalsOpen, isPendingReply, displayVersion, document} = this.props
        if (!approveMessages || !approveMessages.length) return

        let documentCreatedAt, documentNextVerCreatedAt
        if (displayVersion) {
            const histIndex = document.history.findIndex(el => el.version + '.' + el.subVersion === displayVersion)
            documentCreatedAt = document.history[histIndex].createdAt
            if (histIndex !== 0) {
                documentNextVerCreatedAt = document.history[histIndex - 1].createdAt
            }
        } else {
            documentCreatedAt = document.createdAt
        }
        const arr = approveMessages
            .filter(el => el.when > Date.parse(documentCreatedAt))
            .filter(el => !displayVersion || el.when < Date.parse(documentNextVerCreatedAt))

        return arr.map(el => <Comment _id={el._id} key={el._id + 'amu'} user={el.user}
                     replies={el.replies} recipients={el.recipients}
                     edit={el.edit} deleted={el.delete} readers={el.readers}
                     internalUserList={internalUserList} isPendingReply={isPendingReply}
                      isInApproval={true} isApprovalsOpen={isApprovalsOpen}
                     func={func} resolve={el.resolve} title={el.title}
                     me={this.props.me} content={el.content} when={el.when} />)
    }

    approverMouseHandler = (type, e, user) => {
        const {tipMouseHandler} = this.props
        if (type === 'enter' || type === 'over') {
            const el = e.currentTarget
            const rect = el.getBoundingClientRect()
            const nameBlock = el.firstChild && el.firstChild.firstChild && el.firstChild.firstChild.children.length && el.firstChild.firstChild.children[1]
            if (nameBlock && nameBlock.offsetWidth >= 120 && (e.target === nameBlock || nameBlock.contains(e.target))) {
                tipMouseHandler(type, rect.top, `<div>${user.email} <br/> ${user.firstname} ${user.lastname}</div>`)
            } else if ((!el || !nameBlock) || e.target !== nameBlock && !nameBlock.contains(e.target)) {
                tipMouseHandler('leave')
            }
        } else if (type === 'leave') {
            tipMouseHandler('leave')
        }
    }

    render() {
        const {showInputReqApproval, showApproveHistory} = this.state
        const {user, me, isProjectAdmin, func, approveMessages, internalUserList, 
            document, displayVersion, dateData} = this.props


        let documentCreatedAt, documentNextVerCreatedAt
        if (displayVersion) {
            const histIndex = document.history.findIndex(el => el.version + '.' + el.subVersion === displayVersion)
            documentCreatedAt = document.history[histIndex].createdAt
            if (histIndex !== 0) {
                documentNextVerCreatedAt = document.history[histIndex - 1].createdAt
            }
        } else {
            documentCreatedAt = document.createdAt
        }

        const approveEvents = user.approveEvents
            .filter(el => !el.action)
            .filter(el => el.when > Date.parse(documentCreatedAt))
            .filter(el => !displayVersion || el.when < Date.parse(documentNextVerCreatedAt))
        const isApproved = displayVersion
            ? approveEvents[approveEvents.length - 1] && approveEvents[approveEvents.length - 1].subtype === eventSubtypes.APPROVE_DOCUMENT
            : this.props.isApproved

        const lastApprovedEvent = isApproved && approveEvents.find((e, ind, arr) =>
            e.subtype === eventSubtypes.APPROVE_DOCUMENT && !arr.find(i => i.when > e.when ))
        const requestApproval = approveEvents.slice().find(el => el.action === 'request')

        if (isApproved && !lastApprovedEvent) return


        const events = approveEvents
            .filter(e => e !== lastApprovedEvent)
            .sort((a, b) => a.when < b.when ? 1 : -1)
            .map(e => {
                const text = e.subtype === eventSubtypes.APPROVE_DOCUMENT ?
                    transS('Approved') : e.subtype === eventSubtypes.DISAPPROVE_DOCUMENT && e.attributes && e.attributes.whoDueChanges 
                        ? transS('Approval cancelled by new changes')
                        : transS('Cancelled Approval')
                return <div styleName='stat' key={e.when + 'aps'}>
                    <span styleName='text'>{text}</span>
                    <span styleName={cx('time', {'_wrap': e.subtype === eventSubtypes.DISAPPROVE_DOCUMENT && !e.attributes})}
                    >{transS(' on ')}<span>{getDateString(e.when, 1, dateData)}</span></span>
                </div>
            })

        const isCanSendApproveMessage = !displayVersion && user._id !== me._id && !showInputReqApproval &&
            (!approveMessages || approveMessages.every(el => !!el.delete && (!el.replies || el.replies.every(el => !!el.delete)) ))

        return (
            <div styleName={cx('wrapper', {'noBottomBorder': (approveMessages && approveMessages.length) ?
                    (!approveMessages[approveMessages.length - 1].delete && !approveMessages[approveMessages.length - 1].resolve) : showInputReqApproval})}>
                <div styleName='wrapper_user'
                     onMouseLeave={() => this.approverMouseHandler('leave')}
                     onMouseOver={e => this.approverMouseHandler('over', e, user)}
                     onMouseEnter={e => this.approverMouseHandler('enter', e, user)}>
                    <SmallUser user={user} noCheck={1} maxNameWidth={130} noTip={1} />
                    <div styleName='right'>
                        {isApproved ?
                            <div styleName='approved'>
                                <img src='/images/approved.svg' alt='approve icon'/>
                                <div styleName='text'>{transS('Approved')}</div>
                                <div styleName='time'>{transS(' on ')}<br/> {getDateString(lastApprovedEvent.when, 1, dateData)}</div>
                            </div>
                            :
                            <React.Fragment>
                                <div styleName='notApproved'>
                                    {transS('Not approved yet')}
                                </div>
                                {requestApproval &&
                                    <div styleName='approved' style={{marginBottom: '10px'}}>
                                        <div styleName='text'>Requested to approve by {`${requestApproval.user.firstname} ${requestApproval.user.lastname}`}</div>
                                        <div styleName='time'>  on <br/> {getDateString(requestApproval.when, 1, dateData)}</div>
                                    </div>}
                                {isCanSendApproveMessage &&
                                    <Button text={transS('Request Approval')} mod='blue' callback={() => this.setState({showInputReqApproval: true})}/>}
                            </React.Fragment>}
                        {!!events.length && <div styleName='stats_arrow' onClick={() => this.setState({showApproveHistory: !showApproveHistory})}>
                            <Button text='' mod={`leftArrow${showApproveHistory ? ' leftArrow_open' : ''}`} /></div>}
                        {showApproveHistory && <div styleName='stats'>{events}</div>}
                    </div>
                </div>
                {showInputReqApproval && <Comment user={user} me={me}
                                                  recipients={[user]} isNewComment={true}
                                                  internalUserList={internalUserList}
                                                  func={func} initialValueTA={transS('Please approve the document')}
                                                  blurHandler={() => this.setState({showInputReqApproval: false})}/>}
                {this.makeApproverMessages()}
            </div>
        )
    }
}

export default Approval
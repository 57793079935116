import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import CSSModules from 'react-css-modules';
import Dropzone from 'react-dropzone';
import styles from './confirm.css';
import ApproversList from '../../views/privateArea/components/approveInfo/ApproversList';
import Button from '../button/Button';

import {getPopUpAction} from '../../services/actions/dataActions';
import {importFileAction} from "../../redux/document/actions"
import UserLogo from '../userLogo/UserLogo'
import { getDateString } from '../../../common/getDateString'
import api from '../../services/api/api'
import Comment from '../comment/Comment'
import {isEqual} from 'lodash'

@connect(state => ({
  popUp: state.dataReducer.popUp,
  externalUserList: state.userReducer.externalUserList,
  internalUserList: state.userReducer.internalUserList,
  user: state.userReducer.user,
  admins: state.projectReducer.currentProject.admins,
  actualDocument: state.document.actualDocument,
  messages: state.projectReducer.currentProject.messages,
  dateData: state.userReducer.dateData
}), {
  getPopUpAction, importFileAction
})
@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
export default class Confirm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      coverMessage: null
    }
  }

  componentDidMount() {
    const {actualDocument, messages} = this.props
    document.body.style.overflowY = 'hidden'

    if (this.props.popUp.coverMessage && this.props.popUp.coverMessage.event) {
      const coverMessage = messages
          .filter(m => m.document === actualDocument.coreDocument)
          .filter(m => m.when > Date.parse(actualDocument.createdAt))
          .filter(m => m.type === 3)
          .filter(m => m.event === this.props.popUp.coverMessage.event)
          .filter(m => !m.delete)
          .filter(m => !!m.freeze)
          .filter((m, _, arr) => !arr.find(i => i._id !== m._id && i.when > m.when))[0]
      this.setState({coverMessage})
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {actualDocument, messages} = this.props
    if (messages && this.props.popUp.coverMessage && this.props.popUp.coverMessage.event) {
      const coverMessage = this.props.messages
          .filter(m => m.document === actualDocument.coreDocument)
          .filter(m => m.when > Date.parse(actualDocument.createdAt))
          .filter(m => m.type === 3)
          .filter(m => m.event === this.props.popUp.coverMessage.event)
          .filter(m => !m.delete)
          .filter(m => !!m.freeze)
          .filter((m, _, arr) => !arr.find(i => i._id !== m._id && i.when > m.when))[0]
      if (!isEqual(this.state.coverMessage, coverMessage)) {
        this.setState({coverMessage})
      }
    } else if (this.state.coverMessage && this.state.coverMessage.length) {
      this.setState({coverMessage: null})
    }
  }

  componentWillUnmount() {
    document.body.style.overflowY = 'auto'
  }

  makeConfirmBtn = () => {
    const {popUp: {confirm}} = this.props

    return confirm.link ?
        <a href={confirm.link}>
          <Button text={confirm.name} callback={confirm.event}
                  mod={(confirm.danger ? 'red ' : '') + 'big margin0' + (confirm.mod ? ` ${confirm.mod}` : ' blue fill')}/>
        </a>
        :
        <Button text={confirm.name} callback={confirm.event}
                mod={(confirm.danger ? 'red ' : '') + 'big margin0' + (confirm.mod ? ` ${confirm.mod}` : ' blue fill')}/>
  }

  messagesFunc = (action, params) => {
    const {actualDocument, user, coverMessage} = this.props
    switch (action) {
      case 'addMessage':
        api.addMessage(3, actualDocument.projectId, params.content, actualDocument.coreDocument, [], user.userGroup, true, coverMessage.event)
        break
      case 'editMessage':
        api.editMessage(params.messageId, params.content, params.reply)
        break
      case 'deleteMessage':
        api.deleteMessage(params.messageId, params.reply)
        break
      case 'resolveMessage':
        api.resolveMessage(params.messageId, params.value)
        break
      case 'unreadCheck':
        api.unreadCheckMessage(params.messageId, params.reply)
        break
      case 'changeTitle':
        api.changeTitle(params.messageId, params.title)
        break
    }
  }
  
  plusSvg = () =>
        <svg styleName='plus-sign-icon' xmlns='http://www.w3.org/2000/svg' width='81' height='81' viewBox='0 0 81 81'>
            <path fill='none' fillRule='evenodd' stroke='#DEDEDE'
                  d='M47.29.5H33.71v33.21H.5v13.58h33.21V80.5h13.58V47.29H80.5V33.71H47.29V.5z'/>
        </svg>
    ;

  _drop = files => {
      if (!files.length) return;
      const { actualDocument, importFileAction } = this.props;
      let reader = new FileReader();
      reader.onloadend = () => {
        importFileAction(reader.result, files[0].name, actualDocument.projectId, actualDocument._id);              
      };
      reader.readAsDataURL(files[0]);
  }
  
  dragHandler = e => { return; }
  
  render() {
    const {popUp: {text, confirm, cancel, swap, notification, header, question, 
        ads, close, unClosable, coverMessage}, externalUserList, 
        internalUserList, admins, user, dateData} = this.props;

    const _stopPropagation = e => e.stopPropagation();

    const coverMessageBlock = !!coverMessage && (coverMessage.editable ?
        (this.state.coverMessage &&
        <div style={{marginTop: '20px', marginBottom: '40px'}}>
          <Comment {...this.state.coverMessage} externalUserList={externalUserList} internalUserList={internalUserList}
                   isProjectAdmin={!!admins.find(el => el === user._id)} me={user}
                   recipients={this.state.coverMessage.recipients.length ? this.state.coverMessage.recipients : null}
                   isNoResolve={true} isNoSendNow={true} isEditCreator={true} isNoReply={true} func={this.messagesFunc} />
        </div>)
        :
        coverMessage.message && <div styleName={cx('coverMessage_wrapper', {'coverMessage_wrapper_top': coverMessage.positionTop})}>
          {coverMessage.message.title && <div styleName='coverMessage_title'>{coverMessage.message.title}</div>}
          <div styleName='coverMessage'>
            <UserLogo {...coverMessage.message.user} mod='_small _border'/>
            <div styleName='mainPart'>

              <div styleName='head'>
                <div styleName='userName'>
                  {coverMessage.message.user && coverMessage.message.user.firstname} {coverMessage.message.user && coverMessage.message.user.lastname}
                </div>
                <div styleName='gray'>{getDateString(coverMessage.message.when, 2, dateData)}</div>
              </div>
              <div styleName='content' dangerouslySetInnerHTML={{__html: coverMessage.message.content}} />
            </div>
          </div>
        </div>)

    return (
        <div styleName='wrapper' onClick={_stopPropagation}>
            {!unClosable && <span className='close-cross' onClick={() => {
                if (close) close.event()
                else if (cancel) cancel.event()
                else confirm.event()
            }}>+</span>}
          {header && <div styleName='header' dangerouslySetInnerHTML={{__html: header}}/>}
          {coverMessage && coverMessage.positionTop && coverMessageBlock}
          <div styleName='text' dangerouslySetInnerHTML={{__html: text}}/>
          {notification && <div styleName='text notification'>{notification}</div>}
          {ads && (ads === 'approve') && <ApproversList/>}
          {ads && (ads === 'file') && <Dropzone styleName='dropzone'
                onDrop={this._drop}
                activeClassName='greyback'
                accept='.doc, .docx'
                onDragOver={this.dragHandler}>
                <div styleName='plus'>
                    {this.plusSvg()}
                    Upload a document
                </div>
            </Dropzone>}
          {coverMessage && !coverMessage.positionTop && coverMessageBlock}
          {question && <div styleName='question'>{question}</div>}
          {(confirm || cancel && cancel.name) && (!swap ?
              <div styleName={cx('buttons-row', {'_single-btn': (!cancel || !confirm), '_no-btns': !cancel && !confirm})}>
                {cancel && cancel.name &&
                <Button text={cancel.name} mod={'big margin0' + (cancel.mod ? ` ${cancel.mod}` : ' blue arch')}
                        callback={cancel.event}/>}
                {confirm && this.makeConfirmBtn() }
              </div>
              :
              <div styleName={cx('buttons-row', {'_single-btn': (!cancel || !confirm), '_no-btns': !cancel && !confirm})}>
                {confirm && this.makeConfirmBtn()}
                {cancel && cancel.name &&
                <Button text={cancel.name} mod={'big margin0' + (cancel.mod ? ` ${cancel.mod}` : ' blue arch')}
                        callback={cancel.event}/>}
              </div>
          )}
        </div>
    );
  }
}

Confirm.propTypes = {
  popUp: PropTypes.object,
  getPopUpAction: PropTypes.func
};

import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './comment.css';
import cx from 'classnames';
import UserLogo from '../../../../components/userLogo/UserLogo';
import { transF, transS } from '../../../../services/helpers/lang';

@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
export default class Comment extends Component {
  state = {
      isHide: true,
      checkLined: false
  }; 
  
  _expand = () => {
     this.setState(prevState => ({ isHide: !prevState.isHide }));
  }
  
  componentDidMount() {
     const { invisible, checkLine, updateInvisibleItems } = this.props; 
     let x = this.element;
      if (x && !invisible && checkLine && updateInvisibleItems && x.offsetTop > checkLine) {
        updateInvisibleItems();
        this.setState( { checkLined: true } );
     }
  }
  
  
  render() {
    const {isFreeze, isPrivate, firstname, lastname, avatar, isThread, thTitle,
      ctime, upd, title, content, jumpDoc, jumpId, due, invisible, checkLine, subver, action, whoDueChanges } = this.props;
    const {isHide, checkLined} = this.state;
    let isNeutral = isHide && (due === undefined), normTitle = thTitle ? ` "${thTitle}"` : '';
    let isParagraph = isPrivate===undefined, isVersion = subver !== undefined,
      haveButtons = subver === undefined;
    const approve = !!action
    return (
      <div styleName={cx("wrapper", { '_invisible': invisible || checkLine && checkLined})} ref={(f) => { this.element = f; }}>
      <div styleName={cx('comment', { '_isNeutral': isNeutral, '_isFreeze': isFreeze, '_isPublic': !isPrivate })}>
        <span styleName="logo">
        <UserLogo
                  firstname={firstname}
                  lastname={lastname}
                  avatar={avatar}
                  mod='_medium'
                />
        </span>
        <section styleName="section">
          <span>{firstname + ' ' + lastname}</span>
          <span styleName="time">{upd && !isNeutral ? upd : ctime}</span>
          {title && !isNeutral &&
             <div styleName="cmTitle">{title}</div>
          }
          { isNeutral ?
            (isParagraph ?              
              (isVersion ?
              <article styleName="article">Created this {subver ? 'sub' : ''}version</article>
              :
              action === 'APPROVE_DOCUMENT' || action === 'DISAPPROVE_DOCUMENT' ?
              <article styleName="article"
                       dangerouslySetInnerHTML={{ __html: `${action === 'APPROVE_DOCUMENT' ? transS('Approve') : 'Disapprove'} this document 
                       ${whoDueChanges ? 'due to changes in the document' : ''}`}} />
              :
              <article styleName="article"
                dangerouslySetInnerHTML={{ __html: transS('Made Changes to the paragraph') + normTitle }} />                        
              )
            :
              <article styleName="article">{transF('toThread')(upd, isThread)
                + normTitle }</article>
            )            
          :
            <article styleName="article" dangerouslySetInnerHTML={{ __html: content }} />
          }
        </section>
      </div>
      {haveButtons &&
      <div styleName="buttons">
        { (!isParagraph && due === undefined ) &&
          <span styleName="nav" onClick={this._expand}>{ transS(isNeutral ? 'See here' : 'Hide comment') }</span>
        }
          <span styleName="nav" onClick={() => jumpDoc(jumpId) }>{transS('Jump to Item')}</span>
        { due &&
          <div styleName="nav">{due}</div>
        }
      </div>
      }
      </div>
    );  
  }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import cx from 'classnames';
import styles from './promo.css';
import Remark from '../auth/Remark';
import Pane from '../auth/Pane';
import Price from '../../../components/promo/Price';
import PlanElement from '../../../components/promo/PlanElement'
import Button from '../../../components/button/Button';
import Footer from '../../../components/footer/Footer';
import api from '../../../services/api/api';
import { headerShowStartedAction } from '../../../services/actions/dataActions';
import LiveChat from 'react-livechat';

@connect(state => ({}), {
  headerShowStartedAction
})
@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
export default class PromoBam extends Component {
    state = {
      config: null,
      showHeaderStarted: false,
    }
    constructor(props) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
    }    
    
    oldScroll = 0;
    elOpen = true;
   
    componentWillUnmount() {
        let { headerShowStartedAction } = this.props;
        window.removeEventListener('scroll', this.handleScroll);
        headerShowStartedAction(false);
        if (typeof(LC_API) != "undefined") 
        try {
            LC_API.hide_chat_window();  
        } catch(err) {          
          return;
        }
    }
        
    componentDidMount() {
      let { headerShowStartedAction } = this.props;
      headerShowStartedAction(false);
      window.addEventListener('scroll', this.handleScroll);
      if (typeof(LC_API) != "undefined") 
      try {
          LC_API.minimize_chat_window();
      } catch(err) {          
          ; // no chat alternative - check LC_INVITE
      }
      if (!this.state.config)
      api.getConfig()
        .then( res => {
               this.setState({config: res.data});
      })
    }
    
   
    handleScroll(event) {
        let { headerShowStartedAction } = this.props;
        let s = window.pageYOffset;
        let isUp = s > this.oldScroll;
        this.oldScroll = s;
        /* parallax */
        if (document.images[0] && window.scrollY < 1300 ) {
          document.images[0].style.top = (53 - window.scrollY/30)+'px'
        }
        
    };    
    
    render() {      
      let cfg = this.state.config; 
      return cfg ? (
        <main styleName='wrapper'>
          <div styleName='inner'>
             <div styleName='title'>
                 Clarity and efficiency <br/>
                 for contract negotiations, <br/>
                 approvals and collaboration
             </div>
             <div style={{margin: '50px 0 150px 0'}} styleName='title subtitle'>
                 An online workspace for teams who work with contracts: <br/>
                 law firms, in-house legal, procurement, sales teams
             </div>
              <div styleName='title-row'>
                  <div styleName='title-rowBlock1'>
                      <img src="../../../images/promo-catherine.png" alt=""/>
                      <div styleName='title-quote'>
                          “Finally we have a product that understands how lawyers want to negotiate. Such a beautiful experience. This is what legal tech should look like.” <br/>
                          <br/>
                          Catherine Bamford
                          <br/>
                          <span>One of the top 10 ‘legal business <br/> technologists’ (Financial Times)</span>
                      </div>
                  </div>
                  <div styleName='title-rowBlock2'>
                      <img src="../../../images/promo-bamlegal.png" alt=""/>
                      <div styleName='title-bamText'>
                          Free account <br/>
                          for up to 5 documents <br/>
                          only for BamLegal followers <br/>
                          <div styleName='title-getAccBtn'>
                          <NavLink to='/sign-up'>
                              <Button text='Get Account' mod='promoMainBtn' />
                          </NavLink>
                          </div>
                      </div>
                  </div>
              </div>
             <div styleName='space80'></div>             
             
             <div styleName='sectionOne'>                 
                <img src='../../../images/contract-one-ipad-compressed.png' width="884"/>
             </div>

             <div styleName='space200'></div>
             
             <div styleName='sectionTwo'>
               <div styleName='sectionTwoColumn'>
                 <div styleName='sectionTwoHeader'>
                    Review Faster
                 </div>
                 With the most advanced <br />review tools.
               </div>
               <div styleName='sectionTwoColumn'>
                 <div styleName='sectionTwoHeader'>
                    Approve Faster
                 </div>
                 With true collaboration and adjustable approval procedures.
               </div>
               <div styleName='sectionTwoColumn'>
                 <div styleName='sectionTwoHeader'>
                    Close Faster
                 </div>
                 With transparency in negotiation processes and information.
               </div>
             </div>
             <div styleName='space200'></div>

             <div styleName='sectionPink'>
               <div styleName='titlePink'>
                  Multi-team contract discussions that bring clarity and help to agree
               </div>
               <div styleName='space80'></div>
               <div styleName='pink2column'>
                  <div styleName='pinkColumn'>
                      <div styleName='pinkContain'>
                         <img src='../../../images/glasses@3x.png' width="53" height="18" />
                      </div>
                      <span styleName='pinkText0'>Discuss and edit </span><br />
                      <span styleName='pinkText1'>internally</span><span styleName='pinkText2'> without<br /> 
                      other party</span><span styleName='pinkText3'> seeing</span>
                  </div>
                  <div styleName='pinkColumn'>
                      <div styleName='pinkContain'>
                        <img src='../../../images/sendp@3x.png' width="25" height="24" />
                      </div>
                      <span styleName='pinkText4'>Discuss and share with<br />your client, or your<br />subcontractor, or both</span>
                  </div>
               </div>                
               <div styleName='pink2column'>
                  <div styleName='pinkColumn'>
                      <div styleName='pinkContain'>
                        <img src='../../../images/discussions-icon@3x.png' width="30" height="34" />
                      </div>
                      Structured discussions<br /><span styleName='pinkText1'>
                      for transapency,<br />productivity and<br />
                      better decision-making</span>
                  </div>
                  <div styleName='pinkColumn'>
                    <span styleName='pinkText2'>re:</span> no more<br />
                    <span styleName='pinkText2'>re: re:</span> email<br />
                    <span styleName='pinkText2'>re: re: re: re:</span> ping<br />
                    <span styleName='pinkText2'>re: re: re:</span> pong
                  </div>
               </div> 
               <div styleName='pinkColumn2'>
                      <div styleName='pinkContain'>
                        <img src='../../../images/single-screen-icon@3x.png' width="53" height="34" />
                      </div>
                   A single screen for everything you need to do<br />
                   <span styleName='pinkText1'>No switching to keep you focused</span>
                    <div styleName='pinkColumn4'>
                       <div>Discuss and <br />comment</div>
                       <div>Draft <br />and edit</div>
                       <div>Review <br />and manage <br />changes</div>
                       <div>Track <br />progress <br />and history</div>
                    </div>
               </div>
             </div>
             <div styleName='space200'></div>
             <div styleName='titlePink'>
               More than collaboration. Built around contract processes
               <div styleName='ordinal'>
                 It’s not just working on the same screen.<br /><br />
                 Every single feature is designed from scratch with the utmost contract process efficiency and security in mind—Contract.one takes care of who sees what and when, how to create more time for you by eliminating bottlenecks and understanding what your next actions would be.
                 <br /><br />
                 We focus on the process so that you can focus on the end result.
               </div>
             </div>
             
             <div styleName='sectionSVG'></div>
             

             <div styleName='sectionPink'>
               <div styleName='titlePink'>Always a correct version
                 <div styleName='ordinal'> 
                    Contract.one would not let anyone waste time working in an outdated version or send the wrong document.
                 </div>
               </div>
               <div styleName='versions'></div>               
             </div>
             <div styleName='space200'></div>
             
             <div styleName='titlePink'>More than track changes.  A professional tool for fast and focused review.
              <div styleName='changes1'>
                <div styleName='changes'>
                   <img src='../../../images/feature@3x.png' width="452" height="32" />
                </div>
                <div styleName='changes'>
                   <div styleName='changesColumn ordinal'>
                     <span styleName='bold'>Full history</span><br />
                     of who and when made, accepted or discarded changes. For better context and internal security audits.
                   </div>
                   <div styleName='changesColumn ordinal'>
                     <span styleName='bold'>Multi-layer protection</span><br />
                     from errors and manipulations. Reduces distractions and frustrations, increases trust.
                   </div>
                </div>
                <div styleName='changes'>
                   <div styleName='changesColumn ordinal'>
                     <span styleName='bold'>Focus</span><br />
                     on new changes and tools to track your review progress.
                   </div>
                   <div styleName='changesColumn ordinal'>
                     <span styleName='bold'>Meta-data management</span><br />
                     that keeps the internal team’s work safe and confidential.
                   </div>
                </div>
              </div>
             </div>
             
             <div styleName='sectionPink sectionGrey'>
                <div styleName='titleGreyWhite'>&nbsp;&nbsp;Security
                  <svg width="28" height="33" viewBox="0 0 28 33">
                        <g fill="none" fillRule="evenodd" transform="translate(-365 -117)">
                            <rect width="1126" height="1258" fill="#E3E3E3" rx="20"/>
                            <g transform="translate(365 120)">
                                <rect width="28" height="19.091" y="10.909" fill="#ACACAC" rx="5"/>
                                <path stroke="#ACACAC" strokeWidth="5" d="M5.6 12.44C5.6 4.147 8.4 0 14 0c5.6 0 8.466 4.147 8.597 12.44"/>
                            </g>
                        </g>
                  </svg>
                </div>
                <div styleName='space80'></div>
                <div styleName='titleGrey'>                   
                <div styleName='sectionTwoColumn sectionTwoColumn268'>
                   <div styleName='sectionTwoHeader'>
                      Safer<br />than email
                   </div>
                   Email was not designed with any privacy or security in mind.  The access to the information sent via email is untraceable and unmanageable.  
                   <br /><br />
                   In Contract.one the information always stays within one secure system.  It can only be used by the authorized users when each point of contact stays under control—by design there is no way anybody can see or do anything they are not allowed.  
                   <br /><br />
                   Contract.one also eliminates breaches of confidentiality by error, it is just not possible to send the information to those who were not intended to receive it.
                </div>
                <div styleName='sectionTwoColumn sectionTwoColumn268'>
                   <div styleName='sectionTwoHeader'>
                      Secure<br />infrastructure
                   </div>
                   <img styleName='aws' src='../../../images/aws@3x.png' width="208" height="37" />
                   <br /><br />
                   Contract.one uses sophisticated and secure infrastructure based on AWS platform with monthly uptime of at least 99.99%.
                   <br /><br />
                   Defense against most common, frequently occurring network and transport layer DDoS attacks, comprehensive availability protection against all known infrastructure (Layer 3 and 4) attacks.
                   <div styleName='space80'></div>
                   <div styleName='sectionTwoHeader'>
                      Processes and policies
                   </div>
                   We implement strict internal policies and have processes in place to make sure nothing slips through the cracks.
                </div>
                <div styleName='sectionTwoColumn sectionTwoColumn268'>
                   <div styleName='sectionTwoHeader'>
                      Access<br />protection
                   </div>
                   Sophisticated access protection for every bit of information based on the built-in roles and rights management system.
                   <div styleName='space80'></div>
                   <div styleName='sectionTwoHeader'>
                      Encryption and<br />other measures
                   </div>
                   Encryption for the sensitive data in transit and authentication using SSL/TLS certificates.
                   <br /><br />
                   The server supports ECDHE and DHE ciphers that offer forward secrecy.  All networks transited between load balancers and application servers are owned and exclusively used by us.  We have HSTS configured with a max-age value of at least 6 months.                   
                </div>
                </div>
             </div>
             <div styleName='space200'></div>
             
             <div styleName='titlePink'>
                Start working in minutes. Set up for the enterprise in a week.
                <div styleName='space80'></div>
                <div styleName='cite'>
                    “Very simple to use.<br />No training needed to <br />start working!”
                </div>
                <div styleName='cite1'>
                    Kate Anderson<br />Director, Global Reach Legal<br />Zayo Group
                </div>
             </div>
             <div styleName='space200'></div>
             
             <div styleName='sectionPink sectionGrad '>
                <div styleName='titleGreyWhite'>Design</div> 
                <div styleName='sectionGrad1'>
                    When you work with<br />a tool every day,<br />design matters
                    <div styleName='ordinal'>
                       It’s easeir to love your work when you love your tools.
                       With elegant and clean design, work with contracts becomes a completely new experience.
                    </div>
                    <NavLink style={{textDecoration: 'none'}} to='/mission'>
                         <div styleName='clarity'>Clarity and Love: <br/>
                        <span>Read Our Mission Statement</span>
                    </div>
                    </NavLink>
                </div>
             </div>
             <div styleName='space200'></div>

             <div styleName='sectionPink sectionOrange'>
                    Unlock<br />
                    <span styleName='white'> 
                    <img src='../../../images/on@3x.png' width="50" height="29" />
                    your new superpower<br /></span>
                    of remote team work
                    <div styleName='space80'></div>
                    <NavLink to='/sign-up' styleName='schedule1'>
                        <div styleName='schedule'>Get Account</div>
                    </NavLink>
             </div>
             <div styleName='space200'></div>

             
            <div styleName='faq'>                
                
                  <div styleName='advSec'>FAQ</div>
                  
                                  
                <label>
                  <span>Do I need to install the software?</span>
                  <input type="checkbox" /><div></div>
                  <div styleName="input-hidden">No. Just register and you’re good to go. </div>
                </label>
                <label>
                  <span>What does my Counterparty need to have to review, edit, propose changes and approve a contract on the Contract.One platform?</span>
                  <input type="checkbox" /><div></div>
                  <div styleName="input-hidden">It’s easy; just invite the other party to the document discussion, and they receive a link. The only thing they need to do is to create a password. No painful registrations, no payment required.</div>
                </label>
                <label>
                  <span>Can I edit right in Contract.one?</span>
                  <input type="checkbox" /><div></div>
                  <div styleName="input-hidden">
                  <p>Yes, that’s the point. Just start typing right inside the project space.</p>
                  <p>To make work more productive, parties cannot make changes simultaneously; otherwise, negotiations would turn into the mess that you are trying to avoid. If you can’t edit, check if your Counterparty has sent their approved version back to you. This method prevents unauthorized or accidental access to the internal draft versions. Only explicitly approved versions can be viewed by the other party.</p>
                  </div>
                </label>
                <label>
                  <span>What’s the difference between an internal and external team?</span>
                  <input type="checkbox" /><div></div>
                  <div styleName="input-hidden">
                  <p>Usually people from your company are the internal team. You can review, edit, propose changes and approve the document section by section–or word by word–with no worries that the other party–the external team–would get your correspondence by mistake.</p>
                  <p>The external team would be the other party; they are also able to invite their team members to the platform to review, edit, propose changes and approve the content before it goes back to the original team.</p>
                  </div>
                </label>
                <label>
                  <span>Can the other party see what I’m editing?</span>
                  <input type="checkbox" /><div></div>
                  <div styleName="input-hidden">
                  <p>Not until you want to send the document the other ‘external’ team. You have to explicitly approve the document or content before the other party can view it.</p>
                  </div>
                </label>
                <label>
                  <span>Can we use Contract.one for just internal discussions?</span>
                  <input type="checkbox" /><div></div>
                  <div styleName="input-hidden">
                  <p>Definitely;  especially if you have multiple teams inside your organization. The same principles apply: you cannot work on the same document at the same time, keeping the mess out and keeping track of everybody’s input until all edits and proposed changes are addressed and approved. After you approve, just export the final version as a clean copy.</p>
                  </div>
                </label>
                <label>
                  <span>Is Contract.one just for lawyers?</span>
                  <input type="checkbox" /><div></div>
                  <div styleName="input-hidden">
                  <p>Not at all. Itis perfectly build for lawyers. But it’s also great for your sales, marketing or technical teams. Anyone who needs to review, edit, propose changes to a document and would like to have a history of this process will benefit greatly from Contract.One.</p>
                  <p>Procurement teams love Contract.one … a lot.</p>
                  </div>
                </label>
                <label>
                  <span>Is there a way to avoid sending wrong emails to the wrong people?</span>
                  <input type="checkbox" /><div></div>
                  <div styleName="input-hidden">
                  <p>That’s the best part! The mechanics of the Contract.one workflow are designed to keep the internal edits, proposed changes and discussion to your colleagues–the internal team. The external team can only view approved documents and content. Approval is explicit, yet simple.</p>
                  <p>There is simply no chance to send a wrong message to the other party – the external team, client or Counterparty. Try it out.</p>
                  </div>
                </label>
                <label>
                  <span>Why is the Contract.one way better than sending emails, google docs, version control or other document sharing processes?</span>
                  <input type="checkbox" /><div></div>
                  <div styleName="input-hidden">
                  <p>For many reasons. Emails aren’t really structured.</p>
                  <p>If one (internal) team member is left off by accident, the thread doesn’t work anymore. Pending on time delays, one person’s most recent comment or response may overlap with another ones and everything is out of order.</p>
                  <p>Document sharing allows for real-time editing, but the latest edit overrides the previous edit and there is no way to track who made which edit or proposal or who approved what.</p>
                  <p>Often, important thoughts are lost once the focus is on final edits, formatting and approvals.</p>
                  <p>Tracking changes in a document can get very messy very quickly, which then leads to a variety of versions–and version control is tedious at best. </p>
                  <p>We’re convinced you will love the easy, simply and transparent review, edit and approval process of Contract.one–try it out and see for yourself!</p>
                  </div>
                </label>
                                                
            </div>
            <div styleName='space80'></div>
            
            <Footer />          
             
          </div>
        </main>
      ) : ( 
         <main></main>
      )
      
    }
}

import React,  { useState, useEffect, useRef } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { colors, fontSize } from "../colors"
import { popUpReset } from '../../services/helpers';
import { getPopUpAction } from '../../services/actions/dataActions';
import { Icon_x } from '../svglib/svglib';

export const UniPopup = (props) => {
// 
  const {position, items, crossed, setState} = props,
    closePopup = () => setState(null)
  
  // Определение направления отображения (справа или слева от точки клика)
  const screenMiddle = window.innerWidth / 2;
  const positionStyle = position ? {
    position: 'absolute',
    top: position.y,
    left: position.x > screenMiddle ? position.x - 400 : position.x,
    zIndex: 8,    
  } : {position: 'fixed',
    left: '50vw',
    top: '50vh',
    zIndex: 8,    
  },
  styled = {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    fontSize: fontSize.standart,
    color: colors.black,
    padding: position ? 20 : 40,
    width: 400,
    boxShadow: colors.shadowMenu,
    backgroundColor: colors.white,
    position: 'absolute',
    left: 0,
    top: 0,
    transform: position ? 'none' : 'translate(-50%, -50%)'
  },
  crossedStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '5px'
  }
  
  return <>
    <div className='dev-screen' onMouseDown={closePopup}></div>
    <div style={positionStyle}>
       <div style={styled}>          
          {items.map((Element, keyNo) => <div key={keyNo}
            style={(!keyNo && crossed) ? crossedStyle : {}}>
              {!!Element &&
                (typeof(Element) === 'string' ?
                <div>{Element}</div>
                : <Element close={closePopup} />
              )}
              {!keyNo && !!crossed &&
                <span style={{cursor: 'pointer'}} onClick={closePopup}>
                  <Icon_x />
                </span>
              }
          </div>)}
       </div>
    </div>
  </>
}

 
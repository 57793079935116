import React, {memo} from 'react';
import { showContext } from './Units';

export const Tree = memo(({parent = null, ShowElement, showParams, level = 0}) => {
    const {tree} = showParams,
      {memoizedTrees} = showContext(),
      elements = tree.filter(t => t.ancestor === parent)

      const memoized = memoizedTrees.get(parent);
        if (memoized) {
          //return memoized;
        }
    const result = elements.map(t =>
      <div key={t.loc_id} data-tree="1">
          <ShowElement unit={t} level={level} showParams = {showParams} />
          {!!t.folders.length &&
          <Tree 
              parent={t.loc_id}
              ShowElement={ShowElement}
              level={level + 1}
              showParams = {showParams}
          />
          }
      </div>
    )
    memoizedTrees.set(parent, result)
    return result
  })

import React, {Fragment} from 'react'
import CSSModules from 'react-css-modules'
import cx from 'classnames'
import styles from "./navigationControls.css"
import Tip from "../../../../components/tip/Tip"
import { transF, transS } from '../../../../services/helpers/lang'

const NavigationControls = props => {
    const {isOpen, isPin, pinClickHandler, isFullMode, toggleModeHandler, paraFilter, toggleParaFilterHandler, navigation, jumpBack} = props

    return <div styleName={cx('wrapper', {'_open': isOpen, 'fullMode': isFullMode, 'b': navigation.back})}>

        <div styleName='flex'>
            <div styleName='pin'>
                <div styleName='pin-img' onClick={pinClickHandler}>
                    <img src={isPin ? "../../../../images/icon-pin-gray.svg" : "../../../../images/icon-pin-blue.svg" }
                         alt="pin"/>
                </div>
                <div styleName='tip'><Tip text={transF('pinNav')(isPin)} arrow='bottom left'/></div>
            </div>
            <div styleName='arrow'>
                <div styleName={cx('arrowBtn', {'reverse': isFullMode})} onClick={() => toggleModeHandler()}/>
                {/*<div styleName={cx('arrowBtn', {'reverse': isFullMode})} style={{opacity: 0.5, cursor: 'default'}} />*/}
                <div styleName='tip'><Tip text={`${transS(isFullMode ? 'Compact' : 'Full')} ${transS('Navigation ')}`} arrow='bottom left'/></div>
            </div>
            {navigation.back && <div styleName='back'>
                <div styleName='back-btn' onClick={jumpBack} data-back='1'>Back</div>
                <div styleName='tip'><Tip text='Back to previous paragraph' arrow='bottom left'/></div>
            </div>}
        </div>


        {isFullMode && <div styleName='filters'>
            <div styleName={cx('filters_btn', {'_current': paraFilter === 0})} onClick={() => toggleParaFilterHandler(0)}>{transS('Show All')}</div>
            <div styleName={cx('filters_btn', {'_current': paraFilter === 1})} onClick={() => toggleParaFilterHandler(1)}>{transS('Changes Only')}</div>
            <div styleName={cx('filters_btn', {'_current': paraFilter === 2})} 
              onClick={() => toggleParaFilterHandler(2)}
              dangerouslySetInnerHTML={{__html: transS('Changes and Comments')}} />
        </div>}
    </div>
}

export default new CSSModules(NavigationControls, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})

import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './progressAndTasks.css';
import ScrollBar from '../../../../components/scrollBar/ScrollBar';
import Tasks from './Tasks';
import Progress from './Progress';

const ScrolledPart  = props => {
    const { isProgress, dragHistory, dragTasks, jumpDoc, toggleBar,
         freezeFunc,prohibitNewVersion, download } = props;

    return (
      <div styleName='part'>
        <ScrollBar style={{
            height: '100%',
            width: 'calc(100% + 33px)'
          }}>
          { isProgress ?
            <Progress 
            toggleBar={toggleBar}
            dragHistory={dragHistory}
            download={download}
            jumpDoc={jumpDoc}
            freezeFunc={freezeFunc}
            prohibitNewVersion={prohibitNewVersion}
            />
          :
            <Tasks 
            dragTasks={dragTasks}
            jumpDoc={jumpDoc}
            />
          }
        </ScrollBar>
      </div>
    );
} 

export default new CSSModules(ScrolledPart, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });

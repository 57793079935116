import React, { useState, useEffect, useRef } from 'react';
import CSSModules from 'react-css-modules'
import styles from './clauseLib.css'
import { transS } from '../../../../services/helpers/lang';
import Tip from '../../../../components/tip/Tip';
import ShowCategories from './ShowCategories';


const Categories = params => {
  let {cats, folders, callback, isClause, title,
    closedFolders, setOpenFolders, restriction } = params;

  const ref = useRef(),
    [res, setRes] = useState(new Set(Array.from(cats))),
    restricted = new Set([1])
    if (restriction) restricted.add(restriction)
    
  const clickOutsideHandler = (e) => {    
    if (!ref.current?.contains(e.target)) {
      e.stopPropagation();
      e.preventDefault();
      callback(res);
    }
  }

  useEffect(() => {
    window.addEventListener('click', clickOutsideHandler)
    return () => {
        window.removeEventListener('click', clickOutsideHandler)
    }
  }, [clickOutsideHandler])

  return <div ref={ref} styleName="cats">
    <div className='h3 marginb40'>{title || transS('Select Category')}</div>
    <ShowCategories folders={folders}
      closedFolders={closedFolders}
      setOpenFolders={setOpenFolders} 
      callback={(f, i) => {
        if (res.has(f.id))
            res.delete(f.id)
        else
            res.add(f.id)
        if (isClause)
            setRes(new Set(res))
        else { 
          if (title && res.size) {
            const corrected = new Set([f.id])
            setRes(corrected)
            callback(corrected)
          }
          else callback(res)        
        }
      } }
      selectedFolders={res} 
      restricted={restricted} />
  </div>
}

export default CSSModules(Categories, styles, {allowMultiple: true});
import React from 'react'
import styles from './unregisterNew.css'
import CSSModules from 'react-css-modules'
import cx from "classnames"
import Dropzone from "react-dropzone"
import {fileFormatValidation, fileSizeValidation} from "../../../common/validation"
import api from "../../services/api/api"
import {PlusSign} from '../../components/plusSign/PlusSign';
import {cloneDeep} from "lodash"
import {connect} from "react-redux"
import {addProjectAction, getProjectList, setCurrentProject} from "../../services/actions/projectActions"
import {setTemporaryUserAction} from "../../services/actions/userActions"
import {toggleLoaderAction} from "../../services/actions/dataActions"
import { setLanguage, transS } from '../../services/helpers/lang'

@connect(state => ({

}), {addProjectAction, setTemporaryUserAction, toggleLoaderAction, getProjectList, setCurrentProject})
@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
class UnregisterNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDragActive: false
        }
        this.dropzoneTimeout = null
    }

    componentDidMount() {
        window.addEventListener('dragenter', this.dragHandler)
        window.addEventListener('dragover', this.dragHandler)
        window.addEventListener('drop', this.dragHandler)
    }

    componentWillUnmount() {
        window.removeEventListener('dragenter', this.dragHandler)
        window.removeEventListener('dragover', this.dragHandler)
        window.removeEventListener('drop', this.dragHandler)
    }

    dragHandler = e => {
        const {isDragActive} = this.state
        let isInside = e.target === this.refWrapper || this.refWrapper.contains(e.target)
        switch (e.type) {
            case 'dragover':
            case 'dragenter':
                if (!isDragActive && isInside) {
                    this.setState({isDragActive: true})
                } else if (isDragActive && !isInside) {
                    this.setState({isDragActive: false})
                }
                clearTimeout(this.dropzoneTimeout)
                break;
            case 'drop':
                this.setState({isDragActive: false})
                break;
        }
        this.dropzoneTimeout = setTimeout(() => this.setState({isDragActive: false}), 200)
    }

    setUpClickHandler = () => {
        this.props.history.push('/login?redirect=newProject')
    }

    dropHandler = files => {
        files = files.slice(0, 1)

        let fileCount = 0
        const transferNames = [], transferFiles = []
        files.forEach(f => {
            if (fileSizeValidation(f) || fileFormatValidation(f)) return
            const reader = new FileReader()
            reader.onloadend = () => {
                transferNames.push(f.name)
                transferFiles.push(reader.result)
                fileCount++
                if (fileCount === files.length) {
                    this.createTemporaryProject(transferFiles, transferNames)
                }
            }
            reader.readAsDataURL(f)
        })
    }

    createTemporaryProject = async (transferFiles, transferNames) => {
        this.props.toggleLoaderAction(true)
        localStorage.removeItem('state')

        const res = await api.immediateStart()
        if (res.data.code !== 200) return
        await window.socketConnect()

        let user = await api.getUserInfo()
        if (!user) return
        user = user.data.data
        setLanguage(user?.lang)
        const workflow = cloneDeep(user.workflows[0])
        workflow.members = [[{_id: user._id, prove: false, rep: false}]]
        const userlist = {[user._id]: user},
            title = transferNames.length > 1 ? 'New Project 1' : transferNames[0].replace('.docx', ''),
            admins = [user._id], signee = []
        this.props.setTemporaryUserAction(user)
        this.props.addProjectAction({ title, userlist, admins, signee, workflow, transferFiles, transferNames, transferTypes})
            .finally(async () => {
                const projectList = await this.props.getProjectList()
                this.props.setCurrentProject({projectId: projectList[0]._id, projectList})

                this.props.history.push(`/${projectList[0]._id}/documents/${projectList[0].docs[0].coreDocument}`)
                this.props.toggleLoaderAction(false)
            })
    }

    render() {
        const {isDragActive} = this.state

        return (
            <div styleName='wrapper' ref={e => this.refWrapper = e}>
                <Dropzone styleName={cx('dropzone', {'active': isDragActive})}
                          onDrop={this.dropHandler}
                          activeClassName="greyback"
                          accept='.doc, .docx'
                          onDragOver={this.dragHandler}>
                    <div>
                        <div styleName='title'>Immediate Start</div>
                        <div styleName='text'>
                            Upload the document. <br/>
                            Sign up within an hour.
                        </div>
                    </div>

                    <PlusSign margin="50px" />
                    {transS('Upload a document')}
                </Dropzone>

                <div styleName='setUp' onClick={this.setUpClickHandler}>
                    <div>
                        <div styleName='title'>Set Up Project</div>
                        <div styleName='text'>
                            Sign in or sign up. <br/>
                            Choose the existing <br/>
                            or create new project. <br/>
                            Upload the document. <br/>
                            Set up teams. <br/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UnregisterNew
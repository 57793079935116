import React,  { useState, useEffect, useCallback, useRef } from 'react';
import { colors, fontSize } from "../colors"

const styled = {
    display: 'flex',
    gap: '10px',
    alignItems: 'unset',
    fontSize: fontSize.standart,
    color: colors.primary,
  }


export const ContextMenu = ({items, x, y, onClose}) => {
// items -> array of nullable objects
// {text, Picture, style, action, line, disabled}

  const menuRef = useRef();

  // Закрытие меню при клике вне его области
  useEffect(() => {
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
        }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
    }, [onClose]);

  // Определение направления отображения (справа или слева от точки клика)
  const screenMiddle = window.innerWidth / 2;
  const positionStyle = {
    position: 'absolute',
    top: y,
    left: x > screenMiddle ? x - 200 : x,
  };

  return <div className='dev-menu-std' style={positionStyle} ref={menuRef}>
    {items.map(el => {
        if (!el) return false
        const {text, Picture, style, action, line, disabled} = el
        if (line)
          return <div key={text} className='dev-menu-std-line'></div>
        const applied = {...styled, ...style}
        applied.cursor = action && !disabled ? 'pointer' : 'initial'
        applied.opacity = disabled ? 0.3 : 1
        return (
          <div key={text} style={applied} 
            onClick={event => { onClose()
                if (action) action(event)}}>
            {!!Picture && <Picture />}
            {text}
          </div>  )      
    })}
  </div>
}

 
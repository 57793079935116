import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import styles from './EnterNewPassword.css';

import { inputValidation, blurValidation, submitValidation } from '../../../../../common/validation';

import api from '../../../../services/api/api';
import Button from '../../../../components/button/Button';
import { transL, transS } from '../../../../services/helpers/lang';

@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
export default class EnterNewPassword extends Component {
    state = {
      confirmPassword: {
        value: '',
        dirty: false,
        invalid: false
      },
      reset: true,
      password: {
        value: '',
        dirty: false,
        invalid: false
      },
      message: '',
      success: false
    };

    _input = e => {
      inputValidation(this, e.currentTarget);
      this.setState({ message: '' });
    };

    _blur = e => blurValidation(this, e.currentTarget);

    _submit = async e => {
      e.preventDefault();

      if (submitValidation(this, [ 'confirmPassword', 'password' ])) return;

      let { confirmPassword, password } = this.state;

      // const token = this.props.params.token;
      const token = this.props.match.params.token;
      let message = '';
      try {
        await api.resetPassword(password.value, confirmPassword.value, token);
        this.setState({ success: true });
      } catch (err) {
        switch (err.response.status) {
        case 403:
          message = transL('incorrectPassword')
          break;
        case 400:
          message = transL('incorrectLink')
          break;
        default:
          message = '';
        }
        this.setState({ message });
        return;
      }
      this.setState({
        confirmPassword: { ...confirmPassword, value: '' },
        password: { ...password, value: '' },
        message: ''
      });
    };

    render() {
      let { confirmPassword, password, message, success } = this.state;

      return (
        <main styleName='wrapper'>
          {
            !success
              ? <div styleName='inner'>
                <h1 styleName='title'>{transS('Enter new password')}</h1>
                <form onSubmit={this._submit} noValidate>
                  <div styleName='form-inner'>
                    <label styleName='row'>
                      <div styleName='error-message'>
                        {
                          (() => {
                            if (password.invalid === 'required') return transL('requiredPassword');
                            else if (password.invalid === 'minLength') return transL('minLengthPassword');
                            else if (password.invalid === 'maxLength') return transL('maxLengthPassword');
                          })()
                        }
                      </div>
                      <input styleName='input'
                        type='password'
                        name='password'
                        value={password.value}
                        onChange={this._input}
                        onBlur={this._blur}
                        autoComplete='off'
                        placeholder={transS('Enter new password')} />
                    </label>
                    <label styleName='row'>
                      <div styleName='error-message'>
                        {
                          (() => {
                            if (confirmPassword.invalid === 'required') return transL('requiredPassword');
                            else if (confirmPassword.invalid === 'minLength') return transL('minLengthPassword');
                            else if (confirmPassword.invalid === 'maxLength') return transL('maxLengthEmail');
                            else if (confirmPassword.invalid === 'passMatch') return transL('passMatch');
                          })()
                        }
                      </div>
                      <input styleName='input'
                        type='password'
                        name='confirmPassword'
                        value={confirmPassword.value}
                        onChange={this._input}
                        onBlur={this._blur}
                        autoComplete='off'
                        placeholder={transS('Re-enter your new password')} />
                    </label>
                  </div>
                  {
                    message && <div styleName='message-wrapper'>{message}</div>
                  }
                  <div styleName='button-row'>
                    <Button text={transS('Confirm')} mod='promoMainBtn' type='submit' />
                  </div>
                </form>
              </div>

              : <div styleName='inner'>
                <h1 styleName='title _success'>{transS('Done')}!</h1>
                <p styleName='slog'>{('You successfully changed your password')}</p>
                <div styleName='button-row _success'>
                  <Link to='/login'>
                    <Button text='Return to Dashboard' mod='promoMainBtn' type='submit' />
                  </Link>
                </div>
              </div>
          }

        </main>
      );
    }
}

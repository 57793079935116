import React, { useState } from 'react'

export const StdToolTip = ({ children, content, 
  display='inline-block', style={}, 
  // default - left aligned
  onBottom, arrowCenter, arrowRight, rightAligned, noArrow
  }) => {

  const [isVisible, setIsVisible] = useState(false),
    styled = { display, position: 'relative'},
    shift = arrowCenter ? '-21px' : '0',
    styledCont = onBottom 
    ? {bottom: 0, transform: `translate(${shift}, calc(100% + 5px))`}
    : {top: 0, transform: `translate(${shift}, calc(-100% - 5px))`},
    styledArrow = onBottom 
    ? {borderBottom: '7px solid var(--color_darkGray)', top: -6}
    : {borderTop: '7px solid var(--color_darkGray)', bottom: -6}
  
  if (rightAligned)
    styledCont.right = 0
  else {
    styledCont.left = arrowCenter ? '50%' : 0    
  }
  if (arrowRight)
    styledArrow.right = 15
  else
    styledArrow.left = 15

  return (
    <div
      style={styled} data-tt-visible={isVisible ? "1" : "0"}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {!!content && <div className="dev-tooltip-container" style={{...styledCont, ...style}}>
        {content}
        {!noArrow && 
          <div className="dev-tooltip-arrow" style={styledArrow} />}
      </div> }
    </div>
  );
};

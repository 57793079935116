import React from 'react'
import { colors, fontSize } from './colors';

export const StdList = ( { items, current, onSelect }) => {
  const handleClick = (id) => {
    onSelect(id === current ? null : id); 
  };

  return (
    <div style={styles.container}>
      {items.map((item) => {
        const id = item.id || item.loc_id
        return <div
          key={id}
          style={{
            ...styles.item,
            paddingLeft: `${(item?.level || 0)  * 20}px`, 
            color: id === current ? colors.black : colors.darkGray,
            fontWeight: id === current ? "bold" : "normal"
          }}
          onClick={() => handleClick(id)}
        >          <div style={styles.title}>{item.title}</div>
        </div>
      })}
    </div>
  );
};

const styles = {
  container: {
    // maxHeight: '400px', // Ограничение высоты компонента
    //overflowY: 'auto', // Прокрутка, если контент не помещается
    // padding: '5px',
    backgroundColor: '#fff',
  },
  item: {
    cursor: 'pointer',
    padding: '5px 0px',
    wordWrap: 'break-word', // Перенос длинных строк
    fontSize: fontSize.standart,
  },
  title: {
    whiteSpace: 'pre-wrap', // Учет переносов строк
  },
};

import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import styles from './promo.css';
import cx from 'classnames';


const Title = props => {
  let { message, big = false } = props;
  return (
    <h1 styleName={cx('title',{'_big':big})}>{message}</h1>
  );
};


Title.propTypes = {
  message: PropTypes.string,
  big: PropTypes.bool
};

export default new CSSModules(Title, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });

import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector  } from 'react-redux'
import CSSModules from 'react-css-modules'
import styles from './DocCommunication.css'
import api from "../../../../services/api/api"
import Radio from "../../../../components/radio/Radio"
import Button from "../../../../components/button/Button";
import Comment from "../../../../components/comment/Comment"
import { transS } from '../../../../services/helpers/lang'

const DocComm = ({ tet }) => {
    const {user, internalUserList, externalUserList, approverUserList} = useSelector(state => state.userReducer);
    const {frameProgress} = useSelector(state => state.paragraphReducer);
    const {actualDocument} = useSelector(state => state.document);
    const {currentProject} = useSelector(state => state.projectReducer);
    const {messages} = currentProject
    
    const getPinned = () => JSON.parse(localStorage.getItem('comPinMes')) || []
      
    const [filterRadioBtn, setFilterRadioBtn] = useState(0),
      [isViewPopupOpen, setIsViewPopupOpen] = useState(false),
      [commentsRadio, setCommentsRadio] = useState(0),
      [isPendingReply, setIsPendingReply] = useState(false),
      [isCommentListHover, setIsCommentListHover] = useState(false),
      [pinnedMessages, setPinnedMessages] = useState(() => getPinned()),      
      [freshComments, setFreshComments] = useState([]), // to do actualize it
      [hoveredComment, setHoveredComment] = useState(null)

  const refViewPopup = useRef(null)
  const setPinned = (pinnedMessages) => 
    localStorage.setItem('comPinMes', JSON.stringify(pinnedMessages))
  const documentMessages =
        messages.filter(m => m.document === actualDocument.coreDocument)
  useEffect(() => setIsPendingReply(false), [messages])

  const messagesFunc = (action, params) => {
    switch (action) {
      case "addMessage":
        api.addMessage(2,
          actualDocument.projectId,
          params.content,
          actualDocument.coreDocument,
          params.selectedRecipients,
          user.userGroup,
          params.isPublic
        );
        break;
      case "addReply":
        api.replyMessage(params.messageId, params.content, user.userGroup);
        setIsPendingReply(true)
        break;
      case "editMessage":
      case "editReply":
        api.editMessage(params.messageId, params.content, params.reply);
        break;
      case "deleteMessage":
      case "deleteReply":
        api.deleteMessage(params.messageId, params.reply);
        break;
      case "resolveMessage":
        api.resolveMessage(params.messageId, params.value);
        break;
      case "unreadCheck":
        api.unreadCheckMessage(params.messageId, params.reply);
        break;
      case "changeTitle":
        api.changeTitle(params.messageId, params.title);
        break;
      case "publishMessage":
      case "publishReply":
        api.publishMessage(params.messageId, params.reply);
        break;
      case "pin":
        const check = pinnedMessages.includes(params.messageId),
          newEx = check ? pinnedMessages.filter(
            (el) => el !== params.messageId ) :
            [...pinnedMessages, params.messageId];
        setPinned(newEx)
        setPinnedMessages(newEx)
        break;
    }
  };

  const makeDocumentMessages = () => { 
    if (!documentMessages?.length) return;
    return documentMessages.slice(0)
      .sort((a, b) => {
        const aPin = pinnedMessages.includes(a._id) ? -1 : 1,
          bPin = pinnedMessages.includes(b._id) ? -1 : 1
        if (aPin !== bPin) return aPin
        if (!filterRadioBtn) {
          let aTime = a.replies
            ? Math.max(...a.replies.map((el) => el.when))
            : a.when;
          let bTime = b.replies
            ? Math.max(...b.replies.map((el) => el.when))
            : b.when;
          return aTime < bTime ? 1 : -1;
        } else {
          return a.when < b.when ? 1 : -1;
        }
      })      
      .map((el, i, arr) => (
        <Comment
          clps={!commentsRadio}
          _id={el._id}
          key={el._id + "cdm"}
          user={el.user}
          edit={el.edit}
          deleted={el.delete}
          width={400}
          type={el.type}
          isPendingReply={isPendingReply}
          replies={el.replies}
          func={messagesFunc}
          resolve={el.resolve}
          readers={el.readers}
          recipients={el.recipients.length ? el.recipients : null}
          approverUserList={approverUserList}
          title={el.title}
          group={el.group}
          isPublic={el.isPublic}
          isProjectAdmin={isAdmin}
          isPin={pinnedMessages.includes(el._id)}
          freeze={el.freeze}
          isCommentListHover={isCommentListHover}
          isFresh={freshComments.includes(el._id)}
          event={el.event}
          hoverHandler={(id) => setHoveredComment(id)}
          isNeedOffset={i && hoveredComment === arr[i - 1]._id}
          isJumpTo= {false} 
          setJumpTo={() => null} 
          me={user}
          content={el.content}
          when={el.when}
          internalUserList={internalUserList}
          externalUserList={externalUserList}
        />
      ));
  };
  
  const outsideClickHandler = e => {
    if (refViewPopup.current && e.target !== refViewPopup.current 
         && !refViewPopup.current.contains(e.target)) {
        setIsViewPopupOpen(false)
    }
  }
  
  useEffect(() => {
    window.addEventListener('click', outsideClickHandler);
    return () => {
      window.removeEventListener('click', outsideClickHandler);
    };
  }, []);

  const isAdmin = currentProject.admins.includes(user._id)

  return (
    <React.Fragment>
      {isViewPopupOpen && <div styleName='screen' />}
      <div styleName="filters">
        <span>{transS('Sort')}</span>
        <Radio
          text0={transS('New First')} text={transS('Original Order')} name="filter"
          radioBtn={filterRadioBtn}
          callback={e => setFilterRadioBtn(+e.target.getAttribute('value'))}
        />
        <div styleName="viewPopup_wrapper" ref={refViewPopup}>
          <Button
            mod={`blue margin0 leftArrow${
              isViewPopupOpen ? " leftArrow_open" : ""
            }`}
            text={transS('View')}
            callback={() => setIsViewPopupOpen(!isViewPopupOpen)}
          />
          <div styleName={"viewPopup"+(isViewPopupOpen ? ' _vis' : '')}>
            <div styleName="viewPopup_subTitle">{transS('Comments')}</div>
            <Radio
              text0={transS('Collapse All')} text={transS('Expand All')}
              radioBtn={commentsRadio}
              callback={e => setCommentsRadio(+e.target.getAttribute('value'))}
            />
          </div>
        </div>
      </div>
      {!!internalUserList && <div styleName="communication">
        <Comment
          me={user}
          isNewComment={true}
          blurHandler={() => null}
          internalUserList={internalUserList}
          externalUserList={externalUserList}
          width={400}
          func={messagesFunc}
          toggleSide={true}
          toggleCommentSide={val => frameProgress?.sendToFrame && 
            frameProgress.sendToFrame({c1: 'toggleCommentSide', commentSide: val})}
        />
        <div
          styleName="messagesList"
          onMouseEnter={() => setIsCommentListHover(true)}
          onMouseLeave={() => setIsCommentListHover(false)}
        >
          {makeDocumentMessages()}
        </div>
      </div>}
    </React.Fragment>
  );
}

export default CSSModules(DocComm, styles, {allowMultiple: true})
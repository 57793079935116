import React, { Component } from 'react';
import cssModules from 'react-css-modules';
import styles from './activationError.css';

@cssModules(styles, {
  'allowMultiple': true,
  'handleNotFoundStyleName': 'throw'
})

class ActivationError extends Component {
  render() {
    return (
      <div styleName='wrapper'>
        <h1 styleName='title'>Activation link is not valid</h1>
        <a styleName='to-main-page-button' href='/'>Go to the main page</a>
      </div>
    );
  }
}

export default ActivationError;

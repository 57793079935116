import React, { Component } from 'react';
import { connect } from 'react-redux';
import {NavLink} from 'react-router-dom';
import cssModules from 'react-css-modules';
import styles from './login.css';

import api from '../../../services/api/api';

import { inputValidation, blurValidation, submitValidation } from '../../../../common/validation';

import { signUpAction } from '../../../services/actions/userActions';

import Button from '../../../components/button/Button';
import Checkbox from "../../../components/checkbox/Checkbox"
import Logo from "../../../components/logo/Logo"
import {setCookies} from "../../../components/cookies/Cookies"
import { transEL, transLoc, publicHost } from '../../../services/helpers/lang';
import appConfig from '../../../../configs/appConfig';

@connect(state => ({}), {
  signUpAction
})
@cssModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
export default class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: {
        value: '',
        dirty: false,
        invalid: false
      },
      lastname: {
        value: '',
        dirty: false,
        invalid: false
      },
      email: {
        value: '',
        dirty: false,
        invalid: false
      },
      password: {
        value: '',
        dirty: false,
        invalid: false
      },
      message: '',
      isInvited: false,
      isAgree: false
    };
  }

  _extractTokenFromLink = queryString => queryString ? queryString.slice(1) : false;

  _input = e => inputValidation(this, e.currentTarget);

  _blur = e => blurValidation(this, e.currentTarget);

  _submit = e => {
    e.preventDefault();

    let validationList = this.state.isInvited
      ? ['firstname', 'lastname', 'password']
      : ['firstname', 'lastname', 'email', 'password'];

    if (submitValidation(this, validationList)) {
      return false;
    }

    let { firstname, lastname, email, password, isInvited } = this.state;
    let { signUpAction } = this.props;

    signUpAction({
      firstname: firstname.value,
      lastname: lastname.value,
      email: email.value,
      password: password.value,
      isInvited
    })
      .then(() => {
        this.setState({
          firstname: { ...firstname, value: '' },
          lastname: { ...lastname, value: '' },
          email: { ...email, value: '' },
          password: { ...password, value: '' }
        });
      })
      .catch(resp => {
        let { data: { err } } = resp.response;
        this.setState({ message: err });
      });
    setCookies()
  };

  async componentDidMount() {
    const linkToken = this._extractTokenFromLink(this.props.location.search);
    let linkValidationInfo;

    try {
      if (linkToken) {
        linkValidationInfo = await api.validateInvitationLink(linkToken);
        if (linkValidationInfo) {
          this.setState(Object.assign({}, this.state, { email: {
            invalid: false,
            value: linkValidationInfo.data.data.email,
            dirty: true
          } },
          { isInvited: true }
          ));
        } else {
          this.props.history.push('/activationError');
        }
      }
    } catch (err) {
      this.props.history.push('/activationError');
    }
    window.scrollTo(0,0);
  }

  render() {
    let { firstname, lastname, email, password, message, isAgree } = this.state;

    return (
      <div styleName='wrapper'>
          <form onSubmit={this._submit} noValidate>

            <div styleName='form-inner'>
              <div styleName='logo'><Logo link={publicHost}/></div>
              <div styleName='title'>{transLoc('Sign up')}</div>
              <div styleName='loginLink'><NavLink to='/login'>{transLoc('Already have an account?')}</NavLink></div>
              <label styleName='row border'>
                <div styleName='error-message'>
                  {
                    (() => {
                      if (firstname.invalid === 'required') return transEL('requiredName');
                      else if (firstname.invalid === 'minLength') return transEL('minLengthName');
                      else if (firstname.invalid === 'maxLength') return transEL('maxLengthName');
                    })()
                  }
                </div>
                <input styleName='input'
                  type='text'
                  name='firstname'
                  value={firstname.value}
                  onChange={this._input}
                  onBlur={this._blur}
                  autoComplete='off'
                  placeholder={transLoc('First Name')}
                  required />
              </label>
              <label styleName='row border'>
                <div styleName='error-message'>
                  {
                    (() => {
                      if (lastname.invalid === 'required') return transEL('requiredSurname');
                      else if (lastname.invalid === 'minLength') return transEL('minLengthSurname');
                      else if (lastname.invalid === 'maxLength') return transEL('maxLengthSurname');
                    })()
                  }
                </div>
                <input styleName='input'
                  type='text'
                  name='lastname'
                  value={lastname.value}
                  onChange={this._input}
                  onBlur={this._blur}
                  autoComplete='off'
                  placeholder={transLoc('Last Name')}
                  required />
              </label>
              <label styleName='row border'>
                <div styleName='error-message'>
                  {
                    (() => {
                      if (email.invalid === 'required') return transEL('requiredEmail');
                      else if (email.invalid === 'email') return transEL('errorEmail');
                      else if (email.invalid === 'maxLength') return transEL('maxLengthEmail');
                    })()
                  }
                </div>
                <input styleName={this.state.isInvited ? 'disabled-input' : 'input'}
                  type='email'
                  name='email'
                  value={email.value}
                  onChange={this._input}
                  onBlur={this._blur}
                  autoComplete='off'                  
                  placeholder={transLoc('Email Address')}
                  required
                  disabled={this.state.isInvited} />
              </label>
              <label styleName='row'>
                <div styleName='error-message'>
                  {
                    (() => {
                      if (password.invalid === 'required') return transEL('requiredPassword');
                      else if (password.invalid === 'minLength') return transEL('minLengthPassword');
                      else if (password.invalid === 'maxLength') return transEL('maxLengthPassword');
                    })()
                  }
                </div>
                <input styleName='input'
                  type='password'
                  name='password'
                  value={password.value}
                  onChange={this._input}
                  onBlur={this._blur}
                  autoComplete='off'
                  placeholder={transLoc('Password')}
                  required />
              </label>
              <div styleName='row checkbox'>
                <Checkbox checked={isAgree} callback={() => this.setState({isAgree: !isAgree})}
                          text={`<div class="${styles['checkbox-text']}">I agree with Contract.one’s <a target='_blank' href="${
                            publicHost+'/legal/terms-of-use'}">
                              Terms of Use</a> and <a target='_blank' href="${
                            publicHost+'/legal/data-protection-policy'}">Data Protection Policy</a></div>`}/>
              </div>
              <div styleName='button-row'>
              {isAgree ? <Button text={transLoc('Create Account')} mod='promoMainBtn' type='submit' />
                  :
                  <Button text={transLoc('Create Account')} mod='promoMainBtn disabled' callback={() => null}/>}
              </div>
            </div>
            {
              message && <div styleName='message-wrapper'>{message}</div>
            }
            <div styleName='link-row'>{transLoc('Already have an account?')}
              <NavLink styleName='link' to='/login'>{
                transLoc('Sign in')}</NavLink></div>
          </form>
        </div>
    );
  }
}

import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './unitList.css'
import { transS, transL } from '../../../../services/helpers/lang';
import { showContext } from './Units';

const ShowDocStatus = ({docProps}) => {
    const {status, approvalRequired, checkApprove, externalOpenInfo,
        approvedCount, approvalsCount} = docProps,
      statusRefine = approvalRequired ? 'orig-approval' : status,
      append = transL('app_' + status),
      {icons} = showContext()
    return <>
      <div className='dev-button-std' styleName={statusRefine}>
          {transL(status)}
      </div>
      {externalOpenInfo && <div styleName='approvalsCount'>
        {externalOpenInfo}
        </div>}
      {checkApprove && !!approvalsCount && 
      <div styleName='status-approvals'>
        {
          approvalsCount === approvedCount ?
          <div styleName='approval-every'>
            {icons.tick_ok}
            <span>{transS('Everyone Approved')}</span>
          </div>
          : approvalRequired ? 
           <div styleName="approval-requred">
              {icons.tick_approve}
              <span>{transS('Your Approval Required')}</span>
           </div>
          : <span>{approvalsCount-approvedCount} / <span styleName='approvalsCount'>
                {approvalsCount} </span>
                {transS('Approval Required')}
            </span>
        }
      </div>}
    </>
  }
  
export default CSSModules(ShowDocStatus, styles, {allowMultiple: true});  
import { templatesConstants } from './constants'

const initialState = {
    templatesList: [],
    isLoading: true,
    isEditPopupOpen: false,
    editTemplate: null,
    openFolders: new Set(JSON.parse(
        localStorage.getItem('openTemplatesFolders') || '[]')),
}

const templateReducer = (state = initialState, action) => {
    switch (action.type) {
        case templatesConstants.SET_LOADING:
            return {...state, isLoading: action.payload}
        case templatesConstants.SET_TEMPLATES_LIST:
            return {...state, templatesList: action.payload}
        case templatesConstants.SET_EDIT_POPUP:
            const {bool, templateId} = action.payload
            return {...state, isEditPopupOpen: bool, editTemplate: templateId}
        case templatesConstants.SET_OPEN_FOLDERS:
            localStorage.setItem('openTemplatesFolders', 
                JSON.stringify(Array.from(action.payload)))
            return {...state, openFolders: action.payload}
        default:
            return state
    }
}

export default templateReducer
import keyMirror from 'keymirror';

export default keyMirror({
  GET_PARAGRAPH_LIST: null,
  GET_PARAGRAPH_LIST_SUCCESS: null,
  UPDATE_PARAGRAPH_LIST_IO:null,
  RESET_PARAGRAPHS: null,
  SET_FRAME: null,
  SET_MENU: null,

  SET_POPUP_MODE: null,
  SET_NOTICE_BANNER_CONTENT: null,
  SET_JUMP_CHANGES_END: null,

  UPDATE_COMMENT_LIST: null,

  SET_TASKS_COUNT: null
});

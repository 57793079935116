export default class PubSubServer {
  constructor() {
    this.messageBuffer = [];
    this.subscribers = new Object();
  }

  addSubscriber(topic, subscriber) {
    if (!this.subscribers[ topic ]) this.subscribers[ topic ] = [];
    this.subscribers[ topic ].push(subscriber);
  }

  removeSubscriber(subscriberSym, topic) {
    if (topic) {
      if (!this.subscribers[ topic ]) return;
      let index = null;
      this.subscribers[topic].forEach((sub, idx) => {
        let symbols = Object.getOwnPropertySymbols(sub);
        if (symbols.indexOf(subscriberSym) !== -1) {
          index = idx;
        }
      });
      if (index) this.subscribers[ topic ].splice(index, 1);
    }
  }

  notifySubscribers(topic, data) {
    if (this.subscribers.hasOwnProperty(topic)) {
      this.subscribers[topic].forEach(sub => {
        let sym = Object.getOwnPropertySymbols(sub)[0];
        sub[sym](data);
      });
    }
  }
}

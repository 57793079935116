import React, {useEffect} from 'react'
import CSSModules from "react-css-modules";
import styles from "./static.css";
import Footer from "../../../components/footer/Footer";

class Mission extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <React.Fragment>
                <div styleName='mission-wrapper'>
                    <div styleName='mission-title'>
                        <span>Clarity</span> and <span>Love</span>
                    </div>
                    <div styleName='mission-subTitle'>Mission Statement</div>
                    <div styleName='mission-text'>
                        There is enough information. We lack clarity. <br/>
                        <br/>
                        Without clarity, we prevent ourselves from doing the work we love, creative and challenging.
                        Instead we
                        disperse our attention managing a flow of changes, emails, comments, messages, versions,
                        figuring out
                        who's doing what and who can see what. <br/>
                        <br/>
                        We are a team of transactional lawyers and we are creating a tool that will unlock the
                        superpowers of
                        teams who work with contracts. With Contract.one not only do we eliminate bottlenecks, help to
                        focus, speed
                        up the workflow and save money. <br/>
                        <br/>
                        But we also want to bring love to this process. We have just one life and we need to enjoy what
                        we're
                        doing. Clarity and focus help to make work more meaningful and satisfying. <br/>
                        <br/>
                        To achieve that, we started with the key areas: structured communication, advanced document
                        changes,
                        smart versioning and project management built around contract process. <br/>
                        <br/>
                        We will not stop there—we have the vision and expertise to rethink negotiations, covenant
                        control,
                        document automation with a single purpose—to unlock teams' superpowers. <br/>
                    </div>
                </div>
                <Footer/>
            </React.Fragment>
        )
    }
}

export default new CSSModules(Mission, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});
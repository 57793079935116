import React from 'react'
import styles from './docStats.css'
import CSSModules from 'react-css-modules'
import cx from 'classnames';
import UserListItem from "../../components/createProjectDropDown/UserListItem"
import Spinner from "../../../../components/spinner/Spinner"
import { statDate, transF, transS } from '../../../../services/helpers/lang';

@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
export class DocStats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0
        }
    }

    _makeUserList = () => {
        const {docStats, isProjectAdmin} = this.props

        const formatTime = (time) => {
            //const seconds = Math.trunc(time / 1000 % 60 )
            let minutes = Math.trunc(time / 1000 / 60 % 60)
            const hours = Math.trunc(time / 1000 / 60 / 60)
            if (time !== 0 && minutes === 0) minutes++
            return `${hours}:${minutes.toString().length === 1 ? `0${minutes}` : minutes}`
        }
        const formatTotalTime = (arr) => {
            const sumMinutes = arr.reduce((acc, current) => {
                let m = Math.trunc(current / 1000 / 60 % 60)
                return acc += (current !== 0 && m === 0) ? 1 : m
            }, 0)
            const minutes = sumMinutes % 60
            const hours = arr.reduce((acc, current, index) => {
                let h = Math.trunc(current / 1000 / 60 / 60)
                if (index === arr.length - 1) acc += Math.trunc(sumMinutes / 60)
                return acc += h
            }, 0)
            return `${hours}:${minutes.toString().length === 1 ? `0${minutes}` : minutes}`
        }

        let result = []
        docStats.usersActivity.forEach(i => {
            let readingTime = i.readingDuration - i.editingDuration - i.commentingDuration
            if (readingTime < 0) readingTime = 0

            const total = readingTime + i.editingDuration + i.commentingDuration
            const readingLineWidth = Math.trunc(200 * readingTime / total)
            const editingLineWidth = Math.trunc(200 * i.editingDuration / total)  
            const commentingLineWidth = Math.trunc(200 * i.commentingDuration / total)  
            return result.push(<div styleName='userListItem' key={i.user._id}>
                <UserListItem user={{...i.user, isActivated: true}} noCheck={true}/>
                <div styleName='timeCounter'>
                    <div styleName='small gray'>{transS('Total')}</div>
                    <div>{formatTotalTime([readingTime, i.editingDuration, i.commentingDuration])}</div>
                </div>
                <div styleName='detailStats'>
                    <div styleName='timeCounter'>
                        <div styleName='small gray'>{transS('Reading')}</div>
                        <div>{formatTime(readingTime)}</div>
                        <div styleName='readingLine' style={{width: readingLineWidth}}/>
                    </div>
                    <div styleName='timeCounter'>
                        <div styleName='small gray'>{transS('Commenting')}</div>
                        <div styleName='green'>{formatTime(i.commentingDuration)}</div>
                        <div styleName='commentingLine' style={{width: commentingLineWidth}}/>
                    </div>
                    <div styleName='timeCounter'>
                        <div styleName='small gray'>{transS('Editing')}</div>
                        <div styleName='red'>{formatTime(i.editingDuration)}</div>
                        <div styleName='editingLine' style={{width: editingLineWidth}}/>
                    </div>
                </div>
            </div>)
        })

        if (isProjectAdmin) {
            result.push(<div styleName='counterPartStats' key='counterpart'>
                <div>{transS('Counterparty')}</div>
                <div styleName='timeCounter'>
                    <div styleName='small gray'>{transS('Total')}</div>
                    <div>{formatTime(docStats.counterpartActivity)}</div>
                </div>
            </div>)
        }

        return result
    }

    _tab = (page) => {
        switch (page) {
            case 0:
                this.props.setFrameVis(true)
                return this.setState({selectedTab: 0})
            case 1:
                this.props.setFrameVis(false)
                return this.setState({selectedTab: 1})
            case 2:
                this.props.setFrameVis(false)
                return this.setState({selectedTab: 2})
        }
    }

    render () {
        const {docStats, actualDocument, user, isError, filter, selectedTime, paraFilter, sideFilter} = this.props
        const {selectedTab} = this.state

        if (isError) return <div>No statistics found for this document</div>
        if (!docStats || !docStats.createdAt || !actualDocument._id) return <Spinner />

        const formatTime = (date) => {
            if (!date) return
            let d = new Date()
            let time = new Date(d.setTime(Date.parse(date)))
            if (!time.getTime()) time = new Date(date) // for timestamps
            return statDate(time)
        }
        let dsStatus, dsUpdated;
        if (actualDocument.dsEnvelope) {
           let env = JSON.parse(actualDocument.dsEnvelope);
           dsUpdated = env.updatedAt;
           switch (env.dsStatus) {
             case 'completed': dsStatus = transS('Signed Version'); break;
             case 'declined':  dsStatus = transS('Declined to sign'); break;
             default: dsStatus = transS('Signing in Progress'); break;
           }
        }

        return (
            <React.Fragment>
                <div className='h1' style={{marginBottom: '40px'}}>{transS('Document Statistics')}</div>

                <div styleName='tabs-block'>
                    <div styleName={cx('tabs', {'_isSelected': selectedTab === 0})}
                         onClick={() => this._tab(0)}>{transS('Paragraphs Stats')}
                    </div>
                    <div styleName={cx('tabs', {'_isSelected': selectedTab === 1})}
                         onClick={() => this._tab(1)}>{transS('Time Spent')}
                    </div>
                    <div styleName={cx('tabs', {'_isSelected': selectedTab === 2})}
                         onClick={() => this._tab(2)}>{transS('General')}
                    </div>
                </div>

                {selectedTab === 0 && <React.Fragment>
                    <div className='h2' style={{marginBottom: '20px'}}>{transS('Paragraphs that required most attention')}</div>
                    <div styleName='flex' style={{marginBottom: '40px'}}>
                        <div styleName='controls' onClick={e => 
                            filter('para', +e.target.dataset.btn)}>
                            <button data-btn='0' 
                              styleName={cx({'_active': paraFilter === 0})}>
                                {transS('Most Edits')}</button>
                            <button data-btn='1' 
                              styleName={cx({'_active': paraFilter === 1})}>
                                {transS('Most Comments')}</button>
                            <button data-btn='2' styleName={cx({'_active': paraFilter === 2})}>
                                {transS('Most Edits and Comments')}
                            </button>
                        </div>
                        <div styleName='sideControls' onClick={e => filter('side', +e.target.dataset.btn)}>
                            <button data-btn='1' 
                              styleName={cx({'_active': sideFilter === 1})}>
                                {transS('External')}</button>
                            <button data-btn='0' 
                              styleName={cx({'_active': sideFilter === 0})}>
                                {transS('Internal')}</button>
                            <button data-btn='2' 
                              styleName={cx({'_active': sideFilter === 2})}>
                                {transS('Both')}</button>
                        </div>
                    </div>
                </React.Fragment>}

                {selectedTab === 1 && <div styleName='columns_time'>
                    <div className='h2' style={{marginBottom: '5px'}}>{transS('Time')}</div>
                    <div styleName='controls' onClick={e => filter('time', +e.target.dataset.btn)}>
                        <button data-btn='0' styleName={cx({'_active': selectedTime === 0})}>{transS('This Version')}</button>
                        <button data-btn='1' styleName={cx({'_active': selectedTime === 1})}>{transS('Previous Version')}</button>
                        <button data-btn='2' styleName={cx({'_active': selectedTime === 2})}>{transS('Today')}</button>
                        <button data-btn='3' styleName={cx({'_active': selectedTime === 3})}>{transS('This Week')}</button>
                        <button data-btn='4' styleName={cx({'_active': selectedTime === 4})}>{transS('This Month')}</button>
                        <button data-btn='5' styleName={cx({'_active': selectedTime === 5})}>{transS('Last Month')}</button>
                        <button data-btn='6' styleName={cx({'_active': selectedTime === 6})}>{transS('From Start')}</button>
                    </div>
                    <div styleName='userList'>
                        {this._makeUserList()}
                    </div>
                </div>}

                {selectedTab === 2 && <div styleName='columns_general'>
                    <div className='h2' style={{marginBottom: '25px'}}>{transS('General ')}</div>
                    <div><span styleName='gray'>{transS('Created by')}</span> {docStats.addedBy.firstname} {docStats.addedBy.lastname}</div>
                    <div><span styleName='gray'>{transS('on')}</span> {formatTime(docStats.createdAt)}</div>
                    <div className='space20'/>
                    <div><span styleName='gray'>{transS('Sent between the Parties')}</span> {`${
                      transF('times')(actualDocument.version - 1)}`}</div>
                    <div className='space20'/>
                    {dsStatus ?
                        <React.Fragment>
                            <div><span>{dsstatus}</span></div>
                            <div><span styleName='gray'>{transS('since')}</span> {formatTime(dsUpdated)}</div>
                        </React.Fragment>
                        :
                        actualDocument.orig >= 12 ?
                        <React.Fragment>
                            <div><span>{transS('Final Version')}</span></div>
                            <div><span styleName='gray'>{transS('since')}</span> {formatTime(actualDocument.createdAt)}</div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div>
                                <span styleName='gray'>{transS('Now being edited by')}</span>
                                {(actualDocument.orig === 6 || actualDocument.orig === 7) ? 
                                    transS(' both teams') :
                                    (actualDocument.editorsGroup === user.userGroup) ? transS(' our team') 
                                      : transS(' other team')}
                            </div>
                            <div><span styleName='gray'>{transS('since')}</span> {formatTime(actualDocument.createdAt)}</div>
                        </React.Fragment>
                    }
                    </div>}

            </React.Fragment>
        )
    }
}

import React from 'react';
import CSSModules from 'react-css-modules'
import styles from './templatesUnderMenu.css'

const CheckBox = params => {
  let {filled, text, ronly, changed, big} = params;    
  return (
    <div styleName={big ? "reqCheckboxBig" : "reqCheckbox"} onClick={changed} data-read={ronly}>          
       <span styleName={big ? "chkBig" : "chk"} data-fill={filled} >
         <svg viewBox="0 0 11 8" width="100%" height="100%"><path d="M4.243 4.778L2.115 2.65a.993.993 0 0 0-1.408.007 1 1 0 0 0-.006 1.408l2.841 2.841c.193.193.446.29.7.29.258-.004.512-.101.706-.295l4.953-4.953A.996.996 0 0 0 9.9.536.999.999 0 0 0 8.487.534L4.243 4.778z" fill="#FFF" fillRule="evenodd"></path></svg>
       </span>{text}
    </div>
  )};
export default CSSModules(CheckBox, styles, {allowMultiple: true});


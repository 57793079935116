import React, { useEffect, useState } from 'react'

export const StdRadio = ({selections, selected, callback, callForever}) => {
  const [internal, setInternal] = useState(selected)
  useEffect(() => setInternal(selected), [selected])
  return <span className='dev-std-radio'>
    {selections.map((text, i) => <div 
        key={i}
        className={i === internal ? 'dev-std-radio-selected' : ''}
        onClick={() => { if (!callForever && i === internal) return
          setInternal(i)
          callback(i)
        } }
      >
      {text}
    </div>
    )}
  </span>
}

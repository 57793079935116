import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './clauseLib.css';
import { transL, transS } from '../../../../services/helpers/lang';

import ShowCategories from './ShowCategories';
import userConstants from '../../../../services/constants/userConstants';

const ClauseLibUser = ()  => {
  const {user, clauseLib} 
    = useSelector(state => state.userReducer)
  const {insertOn, frameProgress} = useSelector(state => state.paragraphReducer);
  const refFind = useRef(),
    dispatch = useDispatch()
  const [findNotFound, setFindNotFound] = useState(false);
  const [founded, setFounded] = useState(null)
  const [openedFolders, setOpenFolders] = useState(new Set())
  const [filteredFolders, setFilteredFolders] = useState(null)
  const [srchPattern, setSrchPattern] = useState(null)
  
  const [viewClause, setViewClause] = useState(null)
  
  const setAll = (NotFound, FilteredFolders, SrchPattern, Founded) => {
    setFindNotFound(NotFound)
    setFilteredFolders(FilteredFolders)
    setSrchPattern(SrchPattern)
    setFounded(Founded)
  }

  const expandAll = isExpand => {
    const s = new Set()
    if (isExpand)     
      clauseLib.folders.forEach(f => traverseFolders(f,
        folder => s.add(folder.id)))
    setOpenFolders(s)
  }
  
  const checkFindFilter = el => {    
    if (el && el.childElementCount) {
      el.innerHTML = el.textContent
      el.blur()
    }
    let foundFolders = false
    const found = new Set(), badFolders = new Set(),
      pattern = refFind.current.textContent.trim().toLowerCase()

    if (!pattern) 
      return setAll(false, null, null, null)
    
    clauseLib.folders.forEach(f => traverseFolders(f,
      folder => { if (!folder.title.toLowerCase().includes(pattern))
       badFolders.add(folder.id)
       else foundFolders = true
    }))

    clauseLib.clauses.forEach(c => {
      if ((c.title+'$'+c.hint+'$'+c.content).toLowerCase().includes(pattern))
        found.add(c.id)      
    });
    if (found.size || foundFolders) {
      clauseLib.folders.forEach(f => traverseFolders(f,
        (folder, callStack) => {
            const isGood = !badFolders.has(folder.id)
            if (isGood || folder.clauses.some(id => found.has(id))) {
              callStack.forEach(id => badFolders.delete(id))
              if (isGood)
                folder.clauses.forEach(id => found.add(id))
            }
        }, [] ) )
      expandAll(true)
      setAll(false, badFolders, new RegExp(pattern, "gi"), found)
      return
    }
    setAll(true, null, null, null)
  }

  const clearFindFilter = (e) => {
    setAll(false, null, null, null)
    refFind.current.textContent = ''
  }
  
  //const reverse = /<span data-x="1">([^<]*)<\/span>/gi
  const drawFind = s => srchPattern ? 
    s.replace(srchPattern, '<span data-x="1">$&</span>') 
    : s

  const traverseFolders = (folder, func, callStack) => {
      const locStack = callStack ? [...callStack, folder.id] : null
      func(folder, locStack)
      if (folder.subs)
        folder.subs.forEach(f => traverseFolders(f, func, locStack))
    }

  const copyClause = (clause, e) => {
    const newClause = {...clause,
      id: Date.now(),
      edited: user._id,
      isDraft: true,
    }
    setViewClause(newClause)
    setEditClause(newClause, e)
  }

  const setEditClause = (clause, e) => {
    const shift = e.target.parentElement.parentElement.getBoundingClientRect().top
    dispatch({type: userConstants.SET_EDIT_CLAUSE, 
      editClause: {clause, shift,
      rOnly: !clause.isDraft || clause.edited !== user._id,
      kind: "clause",
      modifyClause: () => dispatch(
        {type: userConstants.SET_EDIT_CLAUSE}
      )}})
  }

  const insertClause = (clause) => {
    if (!insertOn) return;
    frameProgress.sendToFrame({c1:'clause', clause});
  }

  const showClause = (id, fid, lvl) => {
    // [{id:10, title: 'first clause', hint: 'its hint', content: 'content of it',
    //   addedBy: 1231324, addedAt: 23423424, isDraft: true]
    const clause = clauseLib.clauses.find(x => x.id === id)
    if (!clause) return false
    if (clause.isDraft && clause.edited !== user._id ||
      founded && !founded.has(clause.id))
      return false
    return <div key={''+fid+id} styleName={`card${clause.isDraft
      ? ' draft':''}${viewClause === clause ? ' edited' : '' }`}
      className={viewClause === clause ? 'pointable' : ''}
      style={{marginLeft: (40*lvl)+"px", width: (360-lvl*40)+"px"}}
      onClick={() => setViewClause(clause)} > 
      
      <div styleName='title' dangerouslySetInnerHTML={{ __html:drawFind(clause.title)}}></div>
      <div styleName='hint' dangerouslySetInnerHTML={{ __html:drawFind(clause.hint)}}></div>
      
      <div styleName='clause' dangerouslySetInnerHTML={{ __html:drawFind(clause.content)}}></div>
      
      {viewClause === clause && <div 
        styleName={`subclause${clause.isDraft ? ' draft':''}`}>
        <span data-off={!insertOn} onClick={(e) => { insertClause(clause);
            e.stopPropagation()}}
          >{transS('Insert')}</span>
        <span onClick={e=>copyClause(clause, e)} styleName={lvl ? 'lvl' : ''}
          >{transS('Copy')}</span>
        <span onClick={e=>setEditClause(clause, e)} 
          >{transS('Expand / Edit')}</span>      
      </div>}
    </div>
  }

  return <div styleName="wrapperUser">
    <div className='flex'>      
      <div styleName='pdOptions'>{transS('Find:')}</div>
      <div 
        styleName={`pdOptions dFind${findNotFound ? ' nfound' :''}`}
        ref={refFind}
        onInput={e => checkFindFilter(e.target)}
        contentEditable="true">        
      </div>
      <span styleName='crossPlace corrected' onClick={e => clearFindFilter(e)}>
        <span className='close-cross'>+</span>
      </span>      
    </div>
    <div className='space20'></div>

    <ShowCategories 
        clausesCallback={showClause}
        folders={clauseLib.folders}
        closedFolders={openedFolders}
        drawFind={drawFind}
        restricted={filteredFolders}
        setOpenFolders={setOpenFolders} 
        hideLines="1"
        callback={() => null} />              
  </div>   
}

export default CSSModules(ClauseLibUser, styles, {allowMultiple: true});


import { push } from 'connected-react-router';
import { projectConstants, dataConstants, documentConstants } from '../constants';
import api from '../api/api';
import {getPopUpAction, toggleLoaderAction} from '../actions/dataActions';
import { popUpWithInputReset, popUpReset } from '../helpers';
import store from '../../services/store';
import * as actionTypes from '../../redux/document/constants'
import { transS } from '../helpers/lang';

export const getDocStatsAction = (documentId) => async dispatch => {
  let docStats
  try {
    const res = await api.getDocStats(documentId);
    docStats = res.data.data
  } catch (err) {
    dispatch({
      type: documentConstants.SET_DOCUMENT_STATS,
      docStats: null
    });
    throw err;
  }

  dispatch({
    type: documentConstants.SET_DOCUMENT_STATS,
    docStats
  });

  return 1
}

export const folderAction = (documentId, newName, parentDoc) => async dispatch => {
    // folder action (documentId, folderName) - put doc to NewFolder
    //              (documentId) - unfold document
    //              (documentId, null, parentDoc) - putDoc to parentDoc
  try {
    await api.foldDocument(documentId, newName, parentDoc);
  } catch (err) {
    console.log('folding error');
    console.log(err);
  }
  dispatch({
    type: dataConstants.GET_POP_UP_WITH_INPUT,
    popUpWithInput: popUpWithInputReset().popup
  });
};

export const renameDocumentAction = (documentId, newName) => async dispatch => {
  /**
   * Rename document in database
   */
  try {
    await api.renameDocument(documentId, newName);
  } catch (err) {
    throw err;
  }

  /**
   * Reset poput with input
   */
  dispatch({
    type: dataConstants.GET_POP_UP_WITH_INPUT,
    popUpWithInput: popUpWithInputReset().popup
  });

};

export const documentChangedAction = data => async dispatch => {
  switch (data.action) {
    case "DELETE": dispatch(documentDeletedAction(data.coreDocumentId)); break;
    case "RENAME": dispatch(documentRenamedAction(data.document._id, data.document.title)); break;
    case "SHARED": 
      // {document, userId, shared: isCollaborate, action: "SHARED"}
      dispatch({
        type: projectConstants.SHARE_DOCUMENT,
        shared: data.shared,
        document: data.document
      }); break;
    case "DOC_TO_FOLDER":       
      dispatch({
        type: projectConstants.DOC_TO_FOLDER,
        data: data
      }); break;
      break;
    case "DELETE_FOLDER": break;
  }
}

export const documentRenamedAction = (documentId, newName) => async dispatch => {
  dispatch({
    type: actionTypes.RENAME_DOCUMENT_FROM_SIDEBAR,
    documentId,
    newName
  });

  dispatch({
    type: projectConstants.RENAME_DOCUMENT,
    documentId,
    newName
  });

}

export const copyDocumentAction = (actualDoc, projTitle) => async dispatch => {
  const docTitle40 = (actualDoc.title.length < 41) ? actualDoc.title : (actualDoc.title.substr(0,37) + '...')
  const projTitle40 = (projTitle.length < 41) ? projTitle : (projTitle.substr(0,37) + '...')

  const popup = {
    success: {
        name: 'confirm',
        header: 'Document Copied',
        text: `A copy of the document "${docTitle40}" was made into the project "${projTitle40}"`,
        cancel: {
          name: transS('Got it'),
          event: () => dispatch({
            type: dataConstants.GET_POP_UP,
            popUp: popUpReset()
          })
        }
    },
    error: {
        name: 'confirm',
        header: 'Document Copied error',
        text: `Document not copy <br>`,
        cancel: {
          name: transS('Got it'),
          event: () => dispatch({
            type: dataConstants.GET_POP_UP,
            popUp: popUpReset()
          })
        }
    }
  }

  try {
    await api.copyDocument(actualDoc.projectId, actualDoc._id, actualDoc.version, actualDoc.subVersion)
  } catch (e) {
    popup.error.text += e
    dispatch({type: dataConstants.GET_POP_UP, popUp: popup.error})
    return 0
  }

  dispatch({type: dataConstants.GET_POP_UP, popUp: popup.success})
  return 1
}

/**
 * Delting document action
 * @param {string} documentId - It is an id of the document to delete
 */
export const deleteDocumentAction = (documentId, projectId) => async dispatch => {
  /**
   * Delete document in database
   */
  try {
    store.dispatch(getPopUpAction(popUpReset));
    dispatch(toggleLoaderAction(true));
    await api.deleteDocument(documentId);
    dispatch(toggleLoaderAction(false));
  } catch (err) {
    throw err;
  }
};

export const documentDeletedAction = (documentId) => async dispatch => {
  const actualDoc = store.getState().document.actualDocument
  if (actualDoc?._id === documentId || actualDoc?.coreDocument === documentId) {
    store.dispatch(getPopUpAction({
        name: 'confirm',
        text: 'The document was deleted!',
        confirm: {
            name: transS('Confirm'),
            event: () => {
                store.dispatch(getPopUpAction(popUpReset));
                dispatch(push(`/${projectId}/documents`));
            }
        }
    }));
    const projectId = actualDoc.projectId
    
    dispatch(toggleLoaderAction(false));

    dispatch({
      type: documentConstants.SET_CURRENT_DOCUMENT
    });
    return;
  }

  dispatch({
    type: projectConstants.DELETE_DOCUMENT,
    documentId
  });
};

export const remindUserDocumentApproveAction = (documentId, userId, fromUserId) => async dispatch => { // Доделать когда появятся шаблоны
  const popup = {
    name: 'confirm',
    text: 'Send a reminder of approval by email?',
    confirm: {
      name: 'Send',
      event: () => {
        dispatch(getPopUpAction(popUpReset))
      }
    },
    cancel: {
      name: transS('Cancel'),
      event: () => {
        dispatch(getPopUpAction(popUpReset))
      }
    }
  }
  dispatch(getPopUpAction(popup))
}

export const downloadDocumentAction = (documentId, projectId, version, subVersion, format) => async dispatch => {
  let url = null;


  dispatch(toggleLoaderAction(true));

  try {
    url = await api.downloadDocument({documentId, projectId, version, subVersion, format});
  } catch (err) {
    ;
  }


  dispatch(toggleLoaderAction(false));
}

export const signDocumentAction = (documentId, projectId, signee) => async dispatch => {
  let result = await api.signDocument(documentId, projectId, signee);
  // dispatch(push(`/api/documents/sign/${projectId}/${documentId}`));
};

import CSSModules from 'react-css-modules'
import React from 'react'
import styles from './finalize.css'
import Button from '../../../../../components/button/Button'
import cx from "classnames"
import Changes from "../elements/Changes/Changes";
import { transS } from '../../../../../services/helpers/lang'

@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})

export class FinalizedInternal extends React.Component {
    render() {
        const {getPopUpAction, popup, calcReviewedAndOpened, setMenuAction, stateMenu, frameProgress, jumpChanges, openInvitePopup} = this.props

        return (
            <div styleName="wrapper">

                <div style={{paddingLeft: '20px'}}>
                    <div styleName='text checkMark'>{transS('Finalised on your side')}</div>
                    <div styleName='time'>{calcReviewedAndOpened()}</div>
                </div>

                <div styleName='cancelFinalize'>
                    <Button text={transS('Cancel Finalise')} mod='blue nowrap'
                          callback={() => getPopUpAction(popup.cancelFinalize)}/>
                </div>

                <Changes setStateMenu={arg => setMenuAction(arg)} jumpChanges={jumpChanges} stateMenu={stateMenu} frameProgress={frameProgress}/>

                <Button mod='blue margin0' callback={openInvitePopup} text={transS('Teams')} />
                {/*<Share openInvitePopup={openInvitePopup}/>*/}

            </div>
        )
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import styles from './checkbox.css';
import cx from 'classnames';
import iconCheckbox from './checkbox.svg';

const Checkbox = props => {
  let { checked = false, callback = () => {}, text = '', mod, style } = props;

  return (
      <label styleName={cx('wrapper', {[mod]: mod, 'checked': checked})} style={style}>
          <input type='checkbox' checked={checked} onChange={callback}/>

          <span styleName='fake'>
                <svg styleName='icon-checkbox' viewBox={iconCheckbox.viewBox}
                     dangerouslySetInnerHTML={{__html: `<use xlink:href="#${iconCheckbox.id}" />`}}/>
          </span>

          {text && <span styleName='checkbox-text' dangerouslySetInnerHTML={{__html: text}} />}
      </label>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  callback: PropTypes.func,
  text: PropTypes.string
};

export default new CSSModules(Checkbox, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });

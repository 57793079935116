import React from 'react'
import styles from './projectPage.css';
import cx from 'classnames';
import CSSModules from "react-css-modules";

import InputBox from '../../../../components/input/InputBox';
import Button from '../../../../components/button/Button';
import { transS } from '../../../../services/helpers/lang';

class SelectCompany extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            search: '',
            role: '',
            email: '',
            first_name: '',
            last_name: '',
        }
    }
    componentDidMount() {
        let scrollIntoViewOptions = {
            behavior: 'smooth',
            block: 'center'
        }
        this.divRef.scrollIntoView(scrollIntoViewOptions);
    }

    cancel = () => {
        this.props.func(0, 'selectCompanyClose')
    }


    render() {
        let {func} = this.props
        let {search, role, email, first_name, last_name} = this.state

        return <div styleName='screen addTeamPosition'>
            <div styleName='addTeam'
                 ref={(f) => {
                     this.divRef = f;
                 }}>
                <span className='close-cross' onClick={this.cancel}>+</span>
                <div className="h1" styleName={cx('title')}>Select Company</div>
                <div className='space5'></div>
                <div styleName='addTeamsCont'>
                    <div styleName='addTeamsPart'>
                        <InputBox placeholder="Search"
                                  func={e => this.setState({search: e.target.value})} value={search} />
                        <div className='space50'></div>
                        <div styleName='SC_title' className='h2'>Recent</div>
                        Сompanies will be here
                        <div className='space40'></div>
                        <div styleName='SC_title' className='h2'>All</div>
                        Сompanies will be here
                    </div>
                    <div styleName='addTeamsPart'>
                        <div className='space5'></div>
                        <div styleName='SC_title' className='h2'>Company</div>
                        About company will be here
                        <div className='space40'></div>
                        <div styleName='SC_title' className='h2'>Role</div>
                        <InputBox placeholder="Role" value={role}
                                  func={e => this.setState({role: e.target.value})} />
                        <div className='space40'></div>
                        <div styleName='SC_title' className='h2'>Team for this Project</div>
                        <div styleName='SC_title-invite' className='h3'>Invite a New Participant</div>
                        <InputBox placeholder="Email"
                                  func={e => this.setState({email: e.target.value})} value={email} />
                        <InputBox placeholder="First Name" value={first_name}
                                  func={e => this.setState({first_name: e.target.value})}
                                  inputWidth='155px' margin='10px 9px 20px 0' display="inline-block"/>
                        <InputBox placeholder="Last Name" value={last_name}
                                  func={e => this.setState({last_name: e.target.value})}
                                  inputWidth='155px' margin='10px 0 20px 0' display="inline-block"/>
                        <Button text="Add a new person to the team"
                                mod='blue arch' callback = {() => this._addMail()} />
                        <div className='space50'></div>
                    </div>
                </div>
                <Button text={transS("Save Changes")}
                        mod='blue fill' callback={() => null}/>
            </div>
        </div>
    }
}

export default new CSSModules(SelectCompany, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './dateControl.css'

import { getDoWeek, parseDate, showDate, showMonth, transL, transS } from '../../../../services/helpers/lang';
import { showDateControl } from '../../../../redux/document/actions';

const DateControl = ({callback})  => {
  const {dateControl} = useSelector(state => state.document), 
    dispatch = useDispatch(),
    {top, left, dateVar} = dateControl,
    ref = useRef(null),
    [day, setDay] = useState(),
    [month, setMonth] = useState(),
    [year, setYear] = useState(),
    [date, setDate] = useState(),
    [valid, setValid] = useState(true),
    [calendar, setCalendar] = useState(null)


  const hideMe = val => {
    callback(val)
    dispatch(showDateControl())
  }

  const clickOutsideHandler = (e) => {    
    if ( !ref.current?.contains(e.target) && e.target.tagName !== 'HTML') {
        hideMe(null)
    }
  }
  
  const makeArray = (year, month) => {
    const firstDate = new Date(year, month, 1), arr = []
    let shift = firstDate.getDay()
    shift = shift ? shift - 1 : 6
    if (shift) {
      for(let i = (new Date(year, month, 1-shift)).getDate(); shift; shift--)
         arr.push(i++)
    }
    shift = (new Date(year, month+1, 0)).getDate()
    for (let i = 1; i <= shift; i++) arr.push(i)
    shift = 7 - arr.length % 7
    if (shift !== 7) 
      for (let i = 1; i <= shift; i++) arr.push(i)
    return arr
  }

  useEffect(() => {
    if (!date) return;
    const month = date.getMonth(), year = date.getFullYear(), 
      calendarNew = {month, year}
    
    if (calendar?.month === month 
      && calendar.year === year) return
    /*
    let firstDate = new Date(year, month, 1), 
      shift = firstDate.getDay(),
      lastDate = new Date(year, month+1, 0)
    shift = shift ? shift - 1 : 6
    if (shift) firstDate = new Date(year, month, 1 - shift)
    shift = lastDate.getDay()
    if (shift) lastDate = new Date(year, month+1, 7 - shift)
    shift = Math.floor((lastDate - firstDate)/(1000 * 60 * 60 * 24)) + 1
    for (let i = 0, d = firstDate.getDate(),
      m = firstDate.getMonth(), y = firstDate.getFullYear(); i < shift/7; i++) {
      const a = []
      for (let j = 0; j < 7; j++) {
        a.push((new Date(y,m,d++)).getDate())
      }
      arr.push(a)
    } */
    calendarNew.arr = makeArray(year, month)
    setCalendar(calendarNew)
  }, [date])

  useEffect(() =>{
    const dat = parseDate(dateVar.value)
    setDate(dat ? dat : new Date())
  },[])

  useEffect(() => {
    window.addEventListener('mousedown', clickOutsideHandler)
    return () => {
        window.removeEventListener('mousedown', clickOutsideHandler)
    }
  }, [clickOutsideHandler])
  
  const handleInput = e => {
    const d = parseDate(e.target.value)
    if (d) {
      setDate(d)
      setValid(true)
    } else setValid(false)
  }

  const keyDown = (e) => {
    if (e.keyCode === 27) return hideMe(null)
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      return hideMe(valid ? showDate(date) : null)
    } 
  }

  const propMonth = (e, n) => {
    e.preventDefault();
    e.stopPropagation();
    date.setMonth(date.getMonth() + n)
    setDate(new Date(date))
  }

  const shiftMonth = (d, number) => (d > 20 && number < 15) ? -1
    : (d < 10 && number > 15) ? 1 : 0  

  const setDateCalendar = (e, d, number) => {
    e.preventDefault();
    e.stopPropagation();
    const month = date.getMonth() + shiftMonth(d, number), year = date.getFullYear()
    return hideMe(showDate(new Date(year, month, d)))
  }
  
  const keyed = date ? date.getFullYear() + ' ' +date.getMonth() : ''

  return <div styleName="wrapper" ref={ref}    
    style={{top, left}}>
      <div styleName='datePlace'>
        <input type="text" name="datePlace" maxLength="14"
          defaultValue={showDate(date)}
          onInput={handleInput}          
          onKeyDown={keyDown}
          styleName={`input${valid ? '' : ' invalid'}`}
        />
      </div>
      <div styleName='calendar'>
        <div styleName='monthPlace'>
           <span>{showMonth(date)}</span>
           <span>
              <span styleName='propMonth'
                onClick={e => propMonth(e, -1)}>&lt;</span>
              <span styleName='propMonth'
                onClick={e => propMonth(e, 1)}>&gt;</span>
           </span>
        </div>
        <div>
          {getDoWeek().forEach(d => <span
            key={d} styleName='dow gray'>
              {d}
          </span>)}
        </div>
        {!!calendar && /*calendar.arr.map((line, number) => <div key={number}>
          {line.map(d => <span key={number + ' ' + d}
            styleName={`day dow${shiftMonth(d, number) ? ' gray' : ''}${d === date.getDate() ? ' bold' : ''}`}
            onClick={() => setDateCalendar(d, number)}
          >
            {d}
          </span>)}
        </div> )*/
           calendar.arr.map((d, number) => <span key={keyed + number}
            styleName={`day dow${shiftMonth(d, number) ? ' gray' : ''}${d === date.getDate() ? ' bold' : ''}`}
            onClick={e => setDateCalendar(e, d, number)}
          >
            {d}
          </span>)
        }
      </div>      
          
  </div>    
}

export default CSSModules(DateControl, styles, {allowMultiple: true});


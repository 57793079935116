import React from 'react'
import CSSModules from 'react-css-modules'
import styles from './BrowserSupportBlockAlers.css'
import Logo from "../logo/Logo"
import Button from "../button/Button"
import {logout} from "../../services/actions/userActions"
import {connect} from "react-redux"
import {getBrowserName, SupportedBrowsers} from './browsersCheck'

@connect(state => ({}), {logout})
@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
class BrowserSupportBlockAlert extends React.Component {
    btnClickHandler = () => {
        this.props.logout()
    }

    render() {
        const browser = getBrowserName()

        return (
            <div styleName='wrapper'>
                <div styleName='card'>
                    <div styleName='logo'><Logo activeLink={false}/></div>
                    <div styleName='title'>This browser cannot run Contract.one</div>
                    <div styleName='text'>Unfortunately {browser} does not support the needed functionality
                        that is required to run Contract.one safely. <br/>
                        <br/>
                        {SupportedBrowsers}
                    </div>
                    {/*<div styleName='btn'><Button text='Go to the home page' mod='blue arch' callback={() => this.btnClickHandler()}/></div>*/}
                </div>
            </div>
        )
    }
}

export default BrowserSupportBlockAlert

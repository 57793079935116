import keyMirror from 'keymirror';

export default keyMirror({
  GET_POP_UP: null,
  TOGGLE_LOADER: null,
  GET_POP_UP_WITH_INPUT: null,
  RENAME_PROJECT: null,
  PROJECT_DELETED: null,
  SHOW_STARTED: null,
  SHOW_DELETED_PROJECT_POPUP: null
});

import CSSModules from 'react-css-modules'
import React from 'react'
import styles from './finalize.css'
import cx from "classnames"
import RequestBackMenu from '../elements/RequestBackMenu/RequestBackMenu'
import { transL } from '../../../../../services/helpers/lang'

@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
export class FinalizeExternalRefuse extends React.Component {

    render() {
        const {getPopUpAction, popup, isProjectAdmin} = this.props

        return (
            <React.Fragment>
                <div styleName='buttonBlock'>
                    <div styleName='text_red' style={{margin: '0'}}
                      dangerouslySetInnerHTML={{__html: transL('refuseStatus')}}
                     ></div>
                </div>

                <RequestBackMenu isProjectAdmin={isProjectAdmin} getPopUpAction={getPopUpAction} popup={popup} />

            </React.Fragment>
        )
    }
}
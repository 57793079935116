import React from 'react';
import { NavLink } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import styles from './static.css';
import Footer from '../../../components/footer/Footer';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Privacy = props => {
  let {isPrivacy = true} = props;
  let title = isPrivacy ? "CONTRACT.ONE'S PRIVACY POLICY" :
          "CONTRACT.ONE'S TERMS OF USE";
  let posted = isPrivacy ? 'Effective: Aug 22, 2018' : 'Effective: Aug 22, 2018';
  window.scrollTo(0,0);
  return (
      <React.Fragment>
    <div styleName="wrapper">  
      <div styleName="content">  
        <div name ='top' id="top" styleName="header centered">{title}</div>
        <div styleName="subheader subheader_mrg centered">{posted}</div>
        {isPrivacy ?
        <div styleName="switching">
            <NavLink to='/terms' styleName="sw left unchecked">Terms of Use</NavLink>
            <div styleName='sw right checked'>Privacy Policy</div>
        </div>
        :
        <div styleName="switching">
            <div styleName="sw left checked">Terms of Use</div>
            <NavLink to='/privacy' styleName='sw right unchecked'>Privacy Policy</NavLink>
        </div>
        }
        { isPrivacy ? 
        <div styleName="normal">
            <ol styleName="lol">
                <li styleName="bold opad">REGULATION. CHANGES TO PRIVACY POLICY
                <ol styleName="lol">
                    <li styleName="bold">Regulation
                    <ol styleName="lol">
                        <li name ='1.1.1' id="1.1.1">This privacy policy (the “<span styleName="bold">Policy</span>”) describes how we collect, use and share the information,
which can be identified as related to a user (the “<span styleName="bold">Information</span>”). </li>
                        <li id ='1.1.2'>The Policy is applied when the user (“<span styleName="bold">you</span>”) uses this the Website or the Software.</li>
                    </ol>
                    </li>
                    <li styleName="bold">Changes to Policy
                    <ol styleName="lol">
                        <li>We:</li>
                        <div><span styleName="enum tbl">(1)</span>may change the Policy; and </div>
                        <div><span styleName="enum tbl">(2)</span>will post the current version on the Website. </div>
                        <li>If a change of the Policy is material, we will notify you via email.</li>
                        <li>If you do not accept the changes to the Policy, please, stop using the Software. If you use
the Software subject to invitation from the Customer, please, instruct the Customer to ask us
to remove the Information it controls.</li>
                    </ol>
                    </li>
                </ol>
                </li>
                <li styleName="bold opad">CONSENT
                <ol styleName="lol">
                    <li>You agree that we collect, use and share the Information, as specified in the Policy:
                        <div><span styleName="enum tbl">(1)</span>by visiting the Website; or</div>
                        <div><span styleName="enum tbl">(2)</span>by using the Software</div>
                    </li>
                    <li>If you do not agree with the Policy, please, do not use the Website and the Software.</li>
                </ol>
                </li>
                <li styleName="bold opad">INFORMATION WE COLLECT  
                <div>
                    <div styleName="ital tbl width">Information</div>
                    <div styleName="ital tbl wright">Description and comments</div> 
                </div> 
                <hr></hr>
                <ol styleName="lol">
                    <li>
                    <span id="3.1" styleName="width tbl">Account Information</span>
                    <span styleName="wright tbl">the Information, which you give us when register or manage
the account — for example, your name, email, photo,
payment information (if applicable).</span>
                    </li>
                <hr></hr>
                    <li>
                    <span id="3.2" styleName="width tbl">Content</span>
                    <span styleName="wright tbl">agreements, messages, comments etc., which you upload.</span>
                    </li>
                <hr></hr>
                    <li>
                    <span styleName="width tbl">Data from your device and log files</span>
                    <span styleName="wright tbl">the device and the browser you use, IP address, internet
provider, the time you visited the Website, which pages you
visited on the Website, which Software features you used etc.
</span>
                    </li>
                <hr></hr>
                    <li>
                    <span styleName="width tbl">Cookies, pixel tags and other similar technologies data</span>
                    <span styleName="wright tbl">
                        <span styleName="enum tbl">(1)</span><span styleName="wrights-small tbl">cookies are small files, which identify you as a unique
user by storing certain information about you, for
example, login information or your preferences.<br /><br />
You can set up your browser not to accept cookies, but
this may limit your ability to use the Software.<br /><br /></span>
<span styleName="enum tbl">(2)</span><span styleName="wrights-small tbl">Pixel tags are small pieces of code, which we may
embed on the Website and to emails. They allow us
to understand, for example, when you opened our
email.</span>
                    </span>
                    </li>
                <hr></hr>
                    <li>
                    <span styleName="width tbl">The Information from third parties</span>
                    <span styleName="wright tbl">
                        <span styleName="enum tbl">(1)</span><span styleName="wrights-small tbl">The Customer may provide us with your email to invite
you to use the Software.<br /><br />
</span>
<span styleName="enum tbl">(2)</span><span styleName="wrights-small tbl">We may receive the Information about how you use
the Website from third parties (for example, from
providers of online marketing services). Such
Information can be statistics of using the Website, the
ways, how you get to the Website etc.</span>            
                    </span>
                    </li>
                </ol>
                </li>
                <li styleName="bold opad">WITH WHOM WE MAY SHARE INFORMATION
                <ol styleName="lol">
                    <li> We may share the Information:
                    <div>
                        <span styleName="enum tbl">(1)</span>
                        <span styleName="enum-add tbl">with other users.
                        <br /><br />
It is necessary to arrange communication between the users. For example, your name and photo are automatically displayed when you comment a contract;</span>    
                    </div>
                    <div>
                        <span styleName="enum tbl">(2)</span>
                        <span styleName="enum-add tbl">trusted services providers — for example, providers of the analytical software, which allows to search vulnerabilities.
                        <br /><br />
When you use the Website, such software may collect the Information about your online activities on other websites and over time;</span>    
                    </div>
                    <div>
                        <span styleName="enum tbl">(3)</span>
                        <span styleName="enum-add tbl">integrated services providers.
                        <br /><br />
The Software feature may be available, which on demand allows to integrate the Software with third parties’ software. We may share the Information with such third parties if you or the Customer ask for such integration.
                        <br /><br />
The Policy is not applied to any third parties’ software (if any). Please, study such third parties’ privacy policy;
</span>    
                    </div>
                    <div>
                        <span styleName="enum tbl">(4)</span>
                        <span styleName="enum-add tbl">any third party if we reasonably suppose that the disclosure is necessary —
                        <br /><br />
                        <span styleName="enum tbl">(i)</span>to comply with the law;
                        <br /><br />
                        <span styleName="enum tbl">(ii)</span>prevent a fraud against users or us;
                        <br /><br />
                        <span styleName="enum tbl">(iii)</span>protect our rights;
                        </span>    
                    </div>
                    <div>
                        <span styleName="enum tbl">(5)</span>
                        <span styleName="enum-add tbl">any third party, which is specified by the Customer. Please, check your Customer’s privacy regulations;</span>    
                    </div>
                    <div>
                        <span styleName="enum tbl">(6)</span>
                        <span styleName="enum-add tbl">any third party, which is involved into change of our business (merger, acquisition etc.);</span>    
                    </div>
                    <div>
                        <span styleName="enum tbl">(7)</span>
                        <span styleName="enum-add tbl">any member of our group (subsidiaries, corporate affiliates, if any).</span>    
                    </div>
                    </li>
                </ol>                
                </li>
                <li styleName="bold opad">YOUR WARRANTIES
                <ol styleName="lol">
                    <div>You warrant:</div>
                    <div>
                        <span styleName="enum tbl">(1)</span>
                        <span styleName="enum-add tbl">that the Information you upload do not infringe any third party’s rights;</span>    
                    </div>
                    <div>
                        <span styleName="enum tbl">(2)</span>
                        <span styleName="enum-add tbl">that the Customer has obtained from you all the permissions and consents, which are necessary to lawfully use the Information and to provide you with the possibility to use the Software.</span>    
                    </div>
                </ol>
                </li>
                <li styleName="bold opad">OTHER PROVISIONS
                    <ol styleName="lol">
                    <li styleName="bold">Interpretation
                        <ol styleName="lol">
                        <li>References to <span styleName="bold">Clauses</span> are references to clauses of this Policy. The term <span styleName="bold">“including”</span> (and other similar expressions) means “including without limitation”.</li>
                        <li>Definitions in the Policy have the following meanings:
                        <br /><br />
                        <span styleName="enum tbl">(1)</span>
                        <span styleName="bold">Information</span> has the meaning, which is specified in <a href='#1.1.1'>Clause 1.1.1</a>;
                        <br /><br />
                        <span styleName="enum tbl">(2)</span>
                        <span styleName="bold">Policy</span> has the meaning, which is specified in <a href='#1.1.1'>Clause 1.1.1</a>;
                        <br /><br />
                        <span styleName="enum tbl">(3)</span>
                        <span styleName="bold">you</span> has the meaning, which is specified in <a href='#1.1.2'>Clause 1.1.2</a>.
                        </li>
                        <li>In the Policy the definitions from Contract.one, Inc’s Terms of Use are used: <span styleName="bold">Customer; Software; we; Website.</span></li>
                        </ol>
                    </li>
                    <li styleName="bold">Commercial Emails Opt Out</li>
                    <div>We may from time to time send you emails, for example, about new Software features. You
can opt out at any time by clicking the unsubscribe link in any email.</div>
                    <li styleName="bold">Do Not Track</li>
                    <div>You may set up do not track feature for your browser, which allows to avoid tracking your
interactions with third parties’ websites over time. However, the Website currently does not
respond do not track signals from browsers.</div>
                    <li styleName="bold">Security
                        <ol  styleName="lol">
                            <li>Security of the Information is important for us. We use the standard security practices, which
are appropriate to the nature of the Information, to protect it from unauthorized access,
destruction, use, modification or disclosure.</li>
                            <li>However, any data transmission via the Internet is not 100 % secure. So, we do not warrant
the security of the Information you transmit to or from the Software, and you do it at your
own risk.</li>
                        </ol>
                    </li>
                    <li styleName="bold">Territorial Scope
                        <ol  styleName="lol">
                        <li>We may store, process, and transmit the Information in the United States and locations around
the world, including outside the United States.</li>
                        <li>We do to offer to use the Software to you if you are in the European Union or in Switzerland.
If you are there, please, do not use the Website and the Software</li>
                        </ol>
                    </li>
                    <li styleName="bold">Governing Law</li>
                    <div>Storage, processing and transmission of the Information are governed by the law of the State of Delaware.</div>
                    <li styleName="bold">Updating Information
                        <div>
                            <div styleName="ital tbl width">The Information you can update</div>
                            <div styleName="ital tbl wright">How to update</div> 
                        </div> 
                        <hr></hr>
                        <ol styleName="lol">
                            <li>
                            <span styleName="width tbl">Your comments to the contracts<br /> (<a href="#3.2">Clause 3.2</a>)</span>
                                <span styleName="wright tbl">just click the comment and change the text.</span>
                            </li>
                            <hr></hr>
                            <li>
                            <span styleName="width tbl">Your account Information<br /> (<a href="#3.1">Clause 3.1</a>)</span>
                                <span styleName="wright tbl">just click the comment and change the text.</span>
                            </li>
                        </ol>
                    </li>    
                    <li styleName="bold">Retention
                        <ol styleName="lol">
                            <div>We will retain the Information for as long as:
                            <br /><br />
                                <span styleName="enum tbl">(1)</span>your account is active; or 
                                <br /><br />
                                <span styleName="enum tbl">(2)</span>we need the Information —
                                <br /><br />
                                <span styleName="enum tbl"> </span>
                                <span styleName="enum tbl">(i)</span>to provide you with the Software; or
                                <br /><br />
                                <span styleName="enum tbl"> </span>
                                <span styleName="enum tbl">(ii)</span>comply with our obligations, resolve disputes, enforce our agreements.
                            </div>
                        </ol></li>
                    <li styleName="bold">Feedback
                        <ol styleName="lol"><div>You may provide us with feedback about the Software. You grant us a perpetual, irrevocable,
worldwide, royalty-free license to use such feedback in any manner.</div></ol></li>
                    <li styleName="bold">Contact
                        <ol styleName="lol"><div>Please, contact us at <span  styleName="link">global@contract.one</span>, if you have any questions, comments or requests, which relate to the Policy.</div></ol>
                    </li>
                    </ol>
                </li>
            </ol>
        </div>
        
        
        :
        
            
        <div styleName="normal">
            <ol styleName="lol">
                <li styleName="bold opad">REGULATION. CHANGES TO TERMS OF USE
                <ol styleName="lol">
                    <li styleName="bold">Regulation
                    <ol styleName="lol">
                        <li id="1.1.1">These terms of use (the “<span styleName="bold">Terms</span>”) is the agreement, which regulates, how Contract.one, Inc’s 
(“<span styleName="bold">our</span>”): </li>
                        <div>
                            <span styleName="enum tbl">(1)</span>
                            <span styleName="enum-add tbl">customers, who upload the contract and may invite users (the “<span styleName="bold">Customers</span>”)
                                <br /><br />If a user represents a company (for example, his or her employer) when he or she buys a subscription for the Software or orders its demo version, this company is the Customer. If not, this user is the Customer; and
                            </span>
                            <br /><br />
                            <span styleName="enum tbl">(2)</span>
                            <span styleName="enum-add tbl">users —</span>
                        </div>
                        <div>may use our software (the “<span styleName="bold">Software</span>”).</div>
                        <li id ='1.1.2'>If:
                        <br /><br /><span styleName="enum tbl">(1)</span>the Customer buys a subscription for the Software; or
                        <br /><br /><span styleName="enum tbl">(2)</span>the Customer orders the demo version of the Software; or
                        <br /><br /><span styleName="enum tbl">(3)</span>a user uses the Software subject to invitation from the Customer —
                        <br /><br />the Customer or a user (“<span styleName="bold">you</span>”) agree to use it, as specified in the Terms.
                        </li>
                    </ol>
                    </li>
                    <li styleName="bold">Changes to Terms
                    <ol styleName="lol">
                        <li>We:</li>
                        <div><span styleName="enum tbl">(1)</span>may change the Terms; and</div>
                        <div><span styleName="enum tbl">(2)</span>will post the current version on our website (the “<span styleName="bold">Website</span>”).</div>
                        <li>If a change of the Terms is material, we will notify you via email.</li>
                        <li>If you do not accept the changes to the Terms, please, stop using the Software. If you are user, not the Customer, please, instruct the Customer to ask us to delete your account.</li>
                    </ol>
                    </li>
                </ol>
                </li>
                <li styleName="bold opad">LICENSE
                <ol styleName="lol">
                <div>As long as you comply with the Terms, we give you a limited, non-exclusive, non-transferable, revocable license to use the Software, as described in the Terms.</div></ol></li>
                
                <li styleName="bold opad">FEE FOR USING SOFTWARE  
                <ol styleName="lol">
                    <li id="3.1">The Customer must pay the fee for using the Software (the “<span styleName="bold">Fee</span>”). The amount is specified on the Website. Exception: the Customer must not pay the Fee for using the trial version of the Software (<a href="#1.1.2">Clause 1.1.2(2)</a>).</li>
                    <li id="3.2">The Customer must pay the Fee for the period of use before the Customer starts to use the Software in this period.</li>
                    <li>The Fee is non-refundable.</li>
                    <li>We may change the Fee.  We will notify you about it via email in advance.</li>
                    <li>The Customer must pay all the taxes, which relate to the Fee. Exception: we will on our own pay the taxes based on our net income.</li>
                    <li>If your account is set to auto-renewal, we may charge the Fee automatically at the end of the period, which precedes the renewal period. Exception: we will not automatically charge the Fee if you notify us that you would like to cancel or disable auto-renewal.</li>
                </ol>
                </li>
                
                <li styleName="bold opad">ACCEPTABLE USE
                <ol styleName="lol">
                    <li>Please, do not use the Software:</li>
                    <div>
                        <span styleName="enum tbl">(1)</span>
                        <span styleName="enum-add tbl">to violate the law in any way including sending spam messages;</span>
                    </div>
                    <div>
                        <span styleName="enum tbl">(2)</span>
                        <span styleName="enum-add tbl">interfere with or disrupt any user, host or network (by sending a virus, flooding etc.);</span>
                    </div>
                    <li>Please, do not:</li>
                    <div>
                        <span styleName="enum tbl">(1)</span>
                        <span styleName="enum-add tbl">reverse engineer the Software or assist anyone to do it;</span>
                    </div>
                    <div>
                        <span styleName="enum tbl">(2)</span>
                        <span styleName="enum-add tbl">circumvent any security or authentication measures;</span>
                    </div>
                    <div>
                        <span styleName="enum tbl">(3)</span>
                        <span styleName="enum-add tbl">abuse our personnel;</span>
                    </div>
                    <div>
                        <span styleName="enum tbl">(4)</span>
                        <span styleName="enum-add tbl">provide access to your account to another person</span>
                    </div>
                    <div>
                        <span styleName="enum tbl">(5)</span>
                        <span styleName="enum-add tbl">access or create accounts by any means other than using the interfaces we publicly support;</span>
                    </div>
                    <div>
                        <span styleName="enum tbl">(6)</span>
                        <span styleName="enum-add tbl">scan or test the Software for vulnerability;</span>
                    </div>
                    <div>
                        <span styleName="enum tbl">(7)</span>
                        <span styleName="enum-add tbl">access the contracts, which you haven't been invited to comment</span>
                    </div>
                </ol>                
                </li>
                
                <li styleName="bold opad">LIABILITY
                <ol styleName="lol">
                <li styleName="bold">Termination of Access to Software
                    <ol styleName="lol">
                    <li>We may at our discretion terminate your access to the Software with or without notice:
                        <br /><br /><span styleName="enum tbl">(1)</span>if we are required to do it by law; or
                        <br /><br /><span styleName="enum tbl">(2)</span>we reasonably believe you use the Software —
                        <br /><br /><span styleName="enum tbl"></span><span styleName="enum tbl">(i)</span>in breach of law;
                        <br /><br /><span styleName="enum tbl"></span><span styleName="enum tbl">(ii)</span>a manner, which causes a risk of damages to us or other users;
                        <br /><br /><span styleName="enum tbl">(3)</span>you violate the Terms.</li>
                    <li><a href="#5.3">Sections 5.3</a> (<span styleName="ital">Indemnities</span>) and <a href="#5.4">5.4</a> (<span styleName="ital">Limitation and Exclusion of Liability</span>) will survive after we terminate your access to the Software.</li>
                    </ol></li>
                <li styleName="bold">Suspension of Access to Software
                    <ol styleName="lol">
                    <div>We may suspend your access to the Software if your Customer delays paying the Fee (<a href="#3.2">Clause 3.2</a>).</div>
                    </ol></li>
                <li id="5.3" styleName="bold">Indemnities
                    <ol styleName="lol">
                        <div>The Customer must indemnify us against any losses, damages and expenses, which relate:
                        <br /><br /><span styleName="enum tbl">(1)</span>to the information, which you have uploaded;
                        <br /><br /><span styleName="enum tbl">(2)</span>using the Software, including using, which violates the Terms.
                        </div>
                    </ol></li>
                <li id="5.4" styleName="bold">Limitation and Exclusion of Liability
                    <ol styleName="lol">
                        <li>We will not be liable under any theory of liability (contract, tort, statutory or any other):
                        <br /><br /><span styleName="enum tbl">(1)</span>
                        <span styleName="enum-add tbl">for any indirect and exemplary damages, including, for example, loss of profit, data or business opportunity, business interruption;</span>
                        <br /><br /><span styleName="enum tbl">(2)</span>
                        <span styleName="enum-add tbl">any losses or damages, which arise from your failure to keep confidential the password from your account.</span>
                        </li>
                    <li>Our liability will not exceed 300 % of the monthly Fee.</li>
                    <li>Except for payment obligations, neither we nor you will be liable for improper performance if it was caused by an issue, which is beyond our or your control.</li>
                    </ol></li>
                </ol>
                </li>
                
                <li styleName="bold opad">CUSTOMER’S WARRANTIES. ABSENCE OF OUR WARRANTIES
                    <ol styleName="lol">
                    <li>The Customer warrants:
                        <br /><br /><span styleName="enum tbl">(1)</span>
                        <span styleName="enum-add tbl">that its representative, who has bought a subscription for the Software (<a href="#1.1.2">Clause 1.1.2(1)</a>) or ordered its demo version (<a href="#1.1.2">Clause 1.1.2(2)</a>) has the necessary powers to act on its behalf — if the Customer is a legal entity;</span>
                        <br /><br /><span styleName="enum tbl">(2)</span>
                        <span styleName="enum-add tbl">its users will comply with the Terms.</span>
                    </li>
                    <li>We provide you with the Software on an “as is” and “as available” bases. We don’t make any warranties, which relate to the Software, including that it will be uninterrupted or error-free.</li>
                    </ol>
                </li>
                
                <li styleName="bold opad">OTHER PROVISIONS
                <ol styleName="lol">
                <li styleName="bold">Interpretation
                <ol styleName="lol">
                    <li>References to <span styleName="link">Sections</span> and <span styleName="link">Clauses</span> are references to sections and clauses of these Terms. The term “<span styleName="bold">including</span>” (and other similar expressions) means “including without limitation”.</li>
                    <li>Definitions in the Terms have the following meanings:
                        <br /><br /><span styleName="enum tbl">(1)</span>
                        <span styleName="bold">Customers </span>has the meaning, which is specified in <a href ="#1.1.1">Clause 1.1.1(1)</a>;
                        <br /><br /><span styleName="enum tbl">(2)</span>
                        <span styleName="bold">Fee </span>has the meaning, which is specified in <a href ="#3.1">Clause 3.1</a>;
                        <br /><br /><span styleName="enum tbl">(3)</span>
                        <span styleName="bold">our</span>has the meaning, which is specified in <a href ="#1.1.1">Clause 1.1.1</a>;
                        <br /><br /><span styleName="enum tbl">(4)</span>
                        <span styleName="bold">Software</span>has the meaning, which is specified in <a href ="#1.1.1">Clause 1.1.1</a>;
                        <br /><br /><span styleName="enum tbl">(5)</span>
                        <span styleName="bold">Terms</span>has the meaning, which is specified in <a href ="#1.1.1">Clause 1.1.1</a>;
                        <br /><br /><span styleName="enum tbl">(6)</span>
                        <span styleName="bold">you</span>has the meaning, which is specified in <a href ="#1.1.2">Clause 1.1.2</a>;
                        <br /><br /><span styleName="enum tbl">(7)</span>
                        <span styleName="bold">Website</span>has the meaning, which is specified in <a href ="#1.1.2">Clause 1.2.1(2)</a>.
                    </li>
                    <li>The definitions, which are used in the Terms, have the same meaning in the Contract.one, Inc’s Privacy Policy, unless the Privacy Policy specifies another meaning for such definitions.</li></ol></li>
                <li styleName="bold">Beta Features
                <ol styleName="lol"><div>Sometimes we release the Software features, which we are still testing and evaluating. These features will be marked as beta or in a similar way and may not be as reliable as the standard features, so please, keep that in mind.</div></ol></li>
                <li styleName="bold">Age Requirements
                <ol styleName="lol"><div>Please, do not use the Software if you are under the age of 18.</div></ol></li>
                <li styleName="bold">Requests to you
                <ol styleName="lol"><div>If you receive a request about using the Software from another Customer, user or someone else, please:
                    <br /><br /><span styleName="enum tbl">(1)</span>answer it using your own information;
                    <br /><br /><span styleName="enum tbl">(2)</span>contact us only if your own information is not enough.
                    </div></ol></li>
                <li styleName="bold">Severability
                <ol styleName="lol"><div>If a provision of the Terms is found unenforceable, we will modify it to reflect our intention. The remaining provisions of the Terms will retain effect.</div></ol></li>
                <li styleName="bold">Governing Law. Disputes Resolution
                <ol styleName="lol">
                    <li>The Terms will be governed by the law of the State of Delaware.</li>
                    <li>The Software is not intended to be applied for consumer purposes, so consumer laws will not be applied.</li>
                    <li>We and you will try to resolve any dispute informally. If you have any concerns, please, contact us at <span styleName="link">global@contract.one</span>. If we and you fail to resolve a dispute within 30 days after we received your email, we or you may start formal proceedings.</li>
                    <li>We and you will bring any judicial proceedings to resolve claims, which relate to the Terms or the Software, to the competent court of the State of Delaware.</li>
                    <li>You:
                        <br /><br /><span styleName="enum tbl">(1)</span>may resolve disputes with us only on an individual basis; and
                        <br /><br /><span styleName="enum tbl">(2)</span>may not bring a claim in a class action.
                    </li></ol></li>                
                <li styleName="bold">No Attorney-Client Relationship
                <ol styleName="lol"><div>We are not a law firm and using the Software does not create an attorney-client relationship. We do not provide you with legal advice via the Software. If you need a legal advice, please, contact a licensed attorney.</div></ol></li>
                <li styleName="bold">Username Refusal or Revocation
                <ol styleName="lol"><div>We will ask you to select a username for your account. We may refuse to grant you a username or revoke it for any reason at our discretion, for example, if we reasonably believe that such username is offensive or is protected by a trademark.</div></ol></li>
                <li styleName="bold">Assignment
                <ol styleName="lol">
                    <li>You may not assign any rights or obligations under the Terms.</li>
                    <li>We may assign any rights or obligations under the Terms without sending you a notice due to a merger, acquisition, reorganization, sale of all or substantial part of our assets.
                    </li></ol></li>
                <li styleName="bold">Rights only for us, Customers and Users. No Partnership and Agency Relations
                <ol styleName="lol">
                    <li>No one except us, Customers and users acquire rights subject to the Terms.</li>
                    <li>There are no partnership relations between us and you. We and you are not agents of each other.</li></ol></li>
                <li styleName="bold">Entire Agreement
                <ol styleName="lol"><div>The Terms regulate all the conditions of interaction between us and you, which relates to using the Software. They supersede all our prior oral and (or) written agreements.</div></ol></li>
                <li styleName="bold">Contact
                <ol styleName="lol"><div>Please, contact us at <span  styleName="link">global@contract.one</span>, if you have any questions, comments or requests, which relate to the Terms.</div></ol>
                </li>
                </ol>
                </li>
            </ol>
        </div>     }   
      </div>
      <div styleName="align"></div>
    </div>
          <Footer />
      </React.Fragment>
)};

Privacy.propTypes = {
  isPrivacy: PropTypes.bool
};

export default new CSSModules(Privacy, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });



import { put, takeLatest, select, take, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as actionTypes from './constants';
import api from '../../services/api/api';
import store from '../../services/store';
import { documentDeletedAction, documentChangedAction } from '../../../src/services/actions/documentActions';
import { projectDeletedAction } from '../../../src/services/actions/projectActions';
import { getExternalUserList, getUserGroup} from '../../services/actions/userActions';
import { projectConstants } from '../../services/constants';

import {
  setActualDocumentAction,
  setCurrentDocumentAction,
  getActualDocumentAction,
  goActualDocumentAction,
  setDisplayOnlyAction,
  getDocumentByRequestSocket,
  setDocumentViewedAction,
  setDocumentViewedSuccessAction, addNewVersionSocket, transferDocumentEditingAction
} from './actions';

import { toggleLoaderAction, getPopUpAction } from '../../services/actions/dataActions';
import { popUpReset } from '../../services/helpers';
import { setCurrentProject } from '../../services/actions/projectActions';
import { getParagraphList, setNoticeBannerContent } from '../../services/actions/paragraphActions';
import { blockParagraphsAction } from '../paragraph/actions';
import { socketConstants } from '../../../configs/appConfig';
import { transL, transS } from '../../services/helpers/lang';

function * addFile(action) {
  yield put(toggleLoaderAction(true));

  let state = yield select();
  let title = action.name;
  if (!Array.isArray(title) && (action.name.split('.doc')[0]) ) {
    title = action.name.split('.doc')[0];
  }

  let requestBody = {
    title: title,
    projectId: action.projectId,
    dataType: action.dataType,
    data: action.file
  };

  try {
    const response = yield api.addDocument(requestBody);

    if (response && response.status === 201) {
      if (response.data.data)
      if (response.data.data.version)
        yield put(push(`/${response.data.data.projectId}/documents`));
      else {
       store.dispatch({
        type: projectConstants.ADD_PROJECT,
        project: response.data.data,
        currentProject: response.data.data
       });
       store.dispatch(push(`/${response.data.data._id}/documents`));
      }        
    }
  } catch (e) {
    console.log('unprocessed error ', e);
  }

  yield put(toggleLoaderAction(false));
}

function * importFile(action) {
  yield put(blockParagraphsAction(true));
  yield put(getPopUpAction(popUpReset));

  let state = yield select();
  const currentDocumentId = state.document.currentDocument.coreDocument;
  let requestBody = {
    title: action.name,
    projectId: action.projectId,
    documentId: action.documentId,
    data: action.file
  };

  try {
    const response = yield api.importDocument(requestBody);

    if (response && response.status === 201) {
      yield put(setDisplayOnlyAction(false, ''));
      yield put(getActualDocumentAction({ documentId: currentDocumentId, projectId: state.document.currentDocument.projectId }));
      return;
    } else {
      yield put(fetchParsedDocumentFailedAction('Fetch Parsed Document File error'));
    }
  } catch (e) {
    yield put(fetchParsedDocumentFailedAction('Fetch Parsed Document File error'));
  }
  yield put(blockParagraphsAction(false));
}

function * addFileTemplate(action) {
  yield put(toggleLoaderAction(true));

  let state = yield select();

  let title = action.name;

  if (action.name.split('.doc')[0]) {
    title = action.name.split('.doc')[0];
  }

  let requestBody = {
    title: title,
    projectId: action.projectId,
    data: action.data
  };

  try {
    const response = yield api.addDocumentTemplate(requestBody);

    if (response && response.status === 201) {
      yield put(push(`/${state.projectReducer.currentProject._id}/documents`));
    } else {
      yield put(fetchParsedDocumentFailedAction('Fetch Parsed Document File error'));
    }
  } catch (e) {
    yield put(fetchParsedDocumentFailedAction('Fetch Parsed Document File error'));
  }

  yield put(toggleLoaderAction(false));
}

function * getDocumentList(action) { // todo process nonsero history at action  
  const state = yield select();
  const projectList = state.projectReducer.projectList;

  yield put(getDocumentListSuccessAction([], 1));
  if (!window.socket) yield window.socketConnect()
  yield window.socket.emit(socketConstants.ENTER_THE_PROJECT, { projectId: action.projectId });
  return;
  try {
    const response = yield api.getDocumentList(action.projectId);

    if (response && response.status === 200) {
      // const responseData = yield response.json()

      if (response.data.data) {
        yield put(getDocumentListSuccessAction(response.data.data, 0));
        //yield put(setCurrentProject({ projectList, projectId: action.projectId }));
      }
    } else {
      yield put(getDocumentListFailedAction('Get Document List error else'));
    }
  } catch (e) {
    if (e.response.status === 404) {
      yield put(push('/not-found/404/'));
    }
    if (e.response.status === 403) {
      yield put(push('/not-found/402/'));
    }
    // store.dispatch(getPopUpAction({
    //   name: 'confirm',
    //   text: 'The project was deleted!',
    //   confirm: {
    //     name: 'Confirm',
    //     event: () => {
    //       store.dispatch(projectDeletedAction(action.projectId));
    //     }
    //   }
    //
    // }));

    yield put(getDocumentListFailedAction('Get Document List error catch'));
  }
}

function * getActualDocument(action) {
  try {
    if (!action.preventListUpdate) {
      yield put(blockParagraphsAction(true)); 
    }
    const state = yield select();
    if (action.projectId && (!state.projectReducer.currentProject 
      || action.projectId !== state.projectReducer.currentProject._id)) {
        yield put(setCurrentDocumentAction(action.documentId));        
        //yield put(setCurrentProject({ projectId: action.projectId }));
    }
    const actualDocument = yield api.getActualDocument(action.documentId, action.projectId);

    if (actualDocument && actualDocument.status === 200 
            && actualDocument.data && actualDocument.data.data) {
      yield put(setActualDocumentAction({...actualDocument.data.data.document, approveHistory: actualDocument.data.data.approveHistory}));
      yield put(getExternalUserList(actualDocument.data.data.externalUsers, 
        actualDocument.data.data.internalUsers, 
        actualDocument.data.data.approverUsers,
        actualDocument.data.data.hasExternals));
      yield put(getUserGroup(actualDocument.data.data.userGroup));

      const actualDoc = actualDocument.data.data.document,
        isFrozen = actualDoc.orig === 21;
      const isDoc = !actualDoc.contentType;

      if (state.userReducer.user._id !== actualDoc.editor._id && !actualDoc.viewedBy.includes(state.userReducer.user._id) ) {
        const blocked = actualDoc.blocked,
            subversion = actualDoc.subVersion,
            approvals = actualDocument.data.data.approverUsers,
            isCollaborative = actualDoc.orig == 6 || actualDoc.orig == 7,
            isDirectedToFinalize = actualDoc.orig == 9 || actualDoc.orig == 10,
            isFinalizeSuccess = actualDoc.orig == 12,
            isRequestCancellingFinalize = actualDoc.orig === 13 || actualDoc.orig === 14;            

        const popup = { // for finalize doc
          directedToFinalize: {
            name: 'confirm',
            header: transL('reqFinHdr'),
            text: transL('reqFinTxt'),
            closable: true,
            cancel: {
              name: transS('Review First'),
              mod: 'blue arch',
              event: () => {
                store.dispatch(setDocumentViewedAction({ documentId: actualDoc.coreDocument, projectId: actualDoc.projectId }));
                store.dispatch(getPopUpAction(popUpReset)); }
            },
            confirm: {
              name: transS('Finalise')+'!',
              mod: 'green arch',
              event: () => store.dispatch(transferDocumentEditingAction(actualDoc.coreDocument, false, false, true))
            },
          },
          directedToFinalizeWithApprovals: {
            name: 'confirm',
            header: transL('reqFinHdr'),
            ads: 'approve',
            closable: true,
            text: `${transL('reqFinTxt')}
                <div class='h3' style="margin-top: 50px;margin-bottom: 20px">${transS('Approvals Needed')} 
                        (${ (approvals) ? ` ${approvals.length}/${approvals.length}` : ''})</div> 
                        ${transS('Before you can send you need the document to be approved by:')}`,
            cancel: {
              name: transS('Got it'),
              event: () => { store.dispatch(setDocumentViewedAction({ documentId: actualDoc.coreDocument, projectId: actualDoc.projectId }));
                  store.dispatch(getPopUpAction(popUpReset)); }
            }
          },
          finalizedByEveryone: {
            name: 'confirm',
            header: `<div style="font-size: var(--fontSize_h1); font-weight: bold; color: #00ab26; display: inline-block">
                ${transS('The Document <br> is finalised by <br> everyone!')}
                <img src="../../images/approve.png" alt="" style="margin-left: 10px" />
            </div>`,
            text: `${transS('Now it really is final!')}<br><br>`,
            closable: true,
            confirm: {
              name: transS('Export in .docx'),
              mod: 'green arch',
              event: () => {
                api.downloadDocument(actualDoc._id, actualDoc.projectId, actualDoc.version, actualDoc.subVersion, 'docx', false)
                    .then(res => {
                      const url = res.data;
                      const ifr = document.createElement('iframe');
                      document.body.appendChild(ifr)
                      ifr.src = url;
                      setTimeout(() => document.body.removeChild(ifr), 10000)
                      store.dispatch(setDocumentViewedAction({ documentId: actualDoc.coreDocument, projectId: actualDoc.projectId }));
                      store.dispatch(getPopUpAction(popUpReset));
                    })
              }
            },
            cancel: {
              name: transS('Perfect'),
              mod: 'green arch',
              event: () => { store.dispatch(setDocumentViewedAction({ documentId: actualDoc.coreDocument, projectId: actualDoc.projectId }));
                  store.dispatch(getPopUpAction(popUpReset)); }
            },
          },
          requestCancellingFinalVersion: {
            name: 'confirm',
            closable: true,
            question: '',
            text: transS('Another team sent a request to cancel Final Version.'),
            cancel: {
              name: transS('Ok'),
              event: () => { store.dispatch(setDocumentViewedAction({ documentId: actualDoc.coreDocument, projectId: actualDoc.projectId }));
                  store.dispatch(getPopUpAction(popUpReset)); }
            },
          }
        }

        if (isDirectedToFinalize) {
          if (!(actualDocument.data.data.userGroup ? actualDoc.orig === 10 : actualDoc.orig === 9))
            (actualDocument.data.data.approverUsers.length && !actualDocument.data.data.userGroup) ?
                store.dispatch(getPopUpAction(popup.directedToFinalizeWithApprovals)) : store.dispatch(getPopUpAction(popup.directedToFinalize))
        } else if (isRequestCancellingFinalize) {
          (actualDoc.orig === 13 && !actualDocument.data.data.userGroup 
            || actualDoc.orig === 14 && actualDocument.data.data.userGroup) ?
              store.dispatch(getPopUpAction(popup.requestCancellingFinalVersion)) : null
        } else if (isFinalizeSuccess) {
          store.dispatch(getPopUpAction(popup.finalizedByEveryone))
        } else if (!blocked) {
          if (subversion) {
            store.dispatch(getPopUpAction({
              name: 'confirm',
              text: isFrozen ? 'The document is frozen for import' : transS('New Document Version'),
              confirm: {
                name: transS('Got it'),
                event: () => { store.dispatch(setDocumentViewedAction({ documentId: actualDoc.coreDocument, projectId: actualDoc.projectId }));
                    store.dispatch(getPopUpAction(popUpReset));}
              }
            }));
          } else if (isCollaborative) {
            store.dispatch(getPopUpAction({
              name: 'confirm',
              text: transS('The document is now in collaborative mode'),
              confirm: {
                name: transS('Confirm'),
                event: () => { store.dispatch(setDocumentViewedAction({ documentId: actualDoc.coreDocument, projectId: actualDoc.projectId })); 
                  store.dispatch(getPopUpAction(popUpReset));}
              }
            }));
          } else {
            store.dispatch(setDocumentViewedAction({ documentId: actualDoc.coreDocument, projectId: actualDoc.projectId }));
            store.dispatch(getPopUpAction(popUpReset));
          }
        } else if (!subversion && isDoc) {
          store.dispatch(getPopUpAction({
            name: 'confirm',
            text: isCollaborative ? transS('The document is now in collaborative mode') : 
              transS('The document is now edited by the Counterparty.'),
            confirm: {
              name: transS('Confirm'),
              event: () => { store.dispatch(setDocumentViewedAction({ documentId: actualDoc.coreDocument, projectId: actualDoc.projectId })); 
              store.dispatch(getPopUpAction(popUpReset));}
            }
          }));
        }
      }
      
      if (action.viewDoc) {
        const theGroup = state.projectReducer.currentProject.group;
        yield window.socket.emit(socketConstants.ENTER_THE_DOCUMENT, { userGroup: theGroup, projectId: action.projectId, documentId: action.documentId });
        yield window.socket.emit(socketConstants.JOIN_TO_GROUP, theGroup);
        return;
      }

      if (action.historyDocumentId) yield put(getParagraphList(action.historyDocumentId, true, action.historyDocumentId));
      else  yield put(getParagraphList(actualDoc._id, true, action.historyDocumentId));

      if (action.historyDocumentId) {
        const historyDocument = actualDoc.history.find(document => document._id === action.historyDocumentId);
        yield put(setDisplayOnlyAction(true, `${historyDocument.version}.${historyDocument.subVersion}`));
      } else if (isFrozen || actualDoc.orig === 50)
        yield put(setDisplayOnlyAction(true, `${actualDoc.version}.${actualDoc.subVersion}`));
      else put(setDisplayOnlyAction(false, ''));

      if (action.preventListUpdate) {
        return;
      }

      if (action.preventChangeRoute) {
        return;
      } else {
        if (!action.historyDocumentId) {
          yield put(push(`/${action.projectId}/documents/${actualDoc.coreDocument}`));
        } else {
          yield put(push(`/${action.projectId}/documents/${actualDoc.coreDocument}/history/${action.historyDocumentId}`));
        }
      }
    } else throw 404;
  } catch (e) {
    if (!e.response) {
      return console.log(e)
    }
    if (e.response.status === 404) {
      yield put(push('/not-found/404/'));
    }
    if (e.response.status === 403) {
      yield put(push('/not-found/403/'));
    }


    // if (e.response.status === 403) {
      yield put(push('/not-found/400/'));
    // }
    // store.dispatch(getPopUpAction({
    //   name: 'confirm',
    //   text: 'The document was deleted!',
    //   confirm: {
    //     name: 'Confirm',
    //     event: () => {
    //       store.dispatch(documentDeletedAction(action.projectId, action.documentId));
    //     }
    //   }
    //
    // }));
  }
}

function * goActualDocument(action) {
  
    if (!action.preventListUpdate) {
      yield put(blockParagraphsAction(true)); 
    }
      if (action.preventListUpdate) {
        return;
      }

      if (action.preventChangeRoute) {
        return;
      } else {
        if (!action.historyDocumentId) {
          yield put(push(`/${action.projectId}/documents/${action.documentId}`));
        } else {
          yield put(push(`/${action.projectId}/documents/${action.documentId}/history/${action.historyDocumentId}`));
        }
      }
      
}

function * newDemo(action) {
    yield put(toggleLoaderAction(true));
    const state = yield select();
    try {
    const response = yield api.newDemo(action.kind);
    if (response && response.status === 200) {
      const doc = response.data.data.doc,
        project = response.data.data.project;
      yield put(push(`/${project}/documents/${doc}`));
      yield put(setNoticeBannerContent({
         text: transL('This template'),
         text1: transL('This template1'),
         button: transS('I understand')   
      }
      ));
            
    }
    }
    catch (e) {
      ;
    }
    yield put(toggleLoaderAction(false));
}

function * saveNewSubversion(action) {
  const state = yield select();
  const projectId = state.projectReducer.currentProject._id;
  const currentDocumentId = state.document.currentDocument.coreDocument;
  yield put(blockParagraphsAction(true));
  yield put(getPopUpAction(popUpReset));

  try {
    const newDocumentVersionResponse = yield api.newDocumentVersion(action.documentId, projectId, action.toFreeze);

    if (newDocumentVersionResponse && newDocumentVersionResponse.status === 200) {
      const actualDoc = newDocumentVersionResponse.data.data
      if (action.toFreeze)
          api.downloadDocument(actualDoc._id, actualDoc.projectId, actualDoc.version, actualDoc.subVersion, 'docx', false)
                    .then(res => {
                      const url = res.data;
                      const ifr = document.createElement('iframe');
                      document.body.appendChild(ifr)
                      ifr.src = url;
                      setTimeout(() => document.body.removeChild(ifr), 10000)
                    })
      else
          yield put(setDisplayOnlyAction(false, ''));
      yield put(getActualDocumentAction({ documentId: currentDocumentId, projectId }));
    } else {
      ;
    }
  } catch (e) {
    ;
  }
}

function * returnSubversion(action) {
  const state = yield select();
  const projectId = state.projectReducer.currentProject._id;
  const currentDocumentId = state.document.currentDocument.coreDocument;
  yield put(blockParagraphsAction(true));
  yield put(getPopUpAction(popUpReset));

  try {
    const newDocumentVersionResponse = yield api.returnDocumentVersion(action.documentId, projectId);

    if (newDocumentVersionResponse && newDocumentVersionResponse.status === 200) {
      yield put(setDisplayOnlyAction(false, ''));
      yield put(getActualDocumentAction({ documentId: currentDocumentId, projectId }));
    } else {
      ;
    }
  } catch (e) {
    ;
  }
}

function * transferEditing(action) {
  const state = yield select();
  const currentDocumentId = state.document.currentDocument.coreDocument;
  const projectId = state.projectReducer.currentProject._id;
  const isDoc = !state.document.currentDocument.contentType;

  if (isDoc) yield put(blockParagraphsAction(true));
  yield put(getPopUpAction(popUpReset));
  try {
    const transferDocumentResponse = yield api.requestDocumentEditingRight(action.documentId, projectId, true, action.isCollaborate, action.unhideFirstVersion,
        action.toFinalize, action.isFinalizeRefuse, action.cancelFinalize);

    if (transferDocumentResponse && transferDocumentResponse.status === 200) {
        if (isDoc)
            yield put(getActualDocumentAction({ documentId: currentDocumentId, projectId }));
        else 
            yield put(documentChangedAction(transferDocumentResponse.data));
    } else {
      ;
    }
  } catch (e) {
    ;
  }
}

function * approveEditing(action) {
  const state = yield select();
  const projectId = state.projectReducer.currentProject._id;
  const currentDocumentId = state.document.currentDocument.coreDocument;
  try {
    const approveDocumentResponse = yield api.approveDocument(action.documentId, 1);

    if (approveDocumentResponse && approveDocumentResponse.status === 200) {
      //yield put(getActualDocumentAction({ documentId: currentDocumentId, projectId }));
      ;
    } else {
      ;
    }
  } catch (e) {
    ;
  }
}

function * disapproveEditing(action) {
  const state = yield select();
  const projectId = state.projectReducer.currentProject._id;
  const currentDocumentId = state.document.currentDocument.coreDocument;
  try {
    const approveDocumentResponse = yield api.approveDocument(action.documentId, 0);

    if (approveDocumentResponse && approveDocumentResponse.status === 200) {
      //yield put(getActualDocumentAction({ documentId: currentDocumentId, projectId }));
      ;
    } else {
      ;
    }
  } catch (e) {
    ;
  }
}

function * requestEditing(action) {
  const state = yield select();
  const projectId = state.projectReducer.currentProject._id,
     group = state.userReducer.user.userGroup;
  try {
    const requestEditingDocumentResponse = yield api.askRequestDocumentEditingRight(action.documentId, projectId);

    if (requestEditingDocumentResponse && requestEditingDocumentResponse.status === 200) {
        if (group === state.document.actualDocument.editorsGroup)
            yield put(setActualDocumentAction({...state.document.actualDocument, editorsContRequest: Date.now()}));
        else
        yield put(setActualDocumentAction({...state.document.actualDocument, editorsRequest: Date.now()}));
    } else {
      ;
    }
  } catch (e) {
    ;
  }
}

function * overtakeEditing(action) {
  const state = yield select();
  const currentDocumentId = state.document.currentDocument.coreDocument;
  const projectId = state.projectReducer.currentProject._id;

  yield put(blockParagraphsAction(true));
  yield put(getPopUpAction(popUpReset));
  try {
    const overtakeEditingDocumentResponse = yield api.forceDocumentEditingRight(action.documentId, projectId);

    if (overtakeEditingDocumentResponse && overtakeEditingDocumentResponse.status === 200) {
      yield put(getActualDocumentAction({ documentId: currentDocumentId, projectId }));
    } else {
      ;
    }
  } catch (e) {
    ;
  }
}

function * getHistoryDocument(action) {
  const state = yield select();
  const projectId = state.projectReducer.currentProject._id,
    displayVersion = state.document.displayVersion,
    actualDoc = state.document.actualDocument,
    sample = `${actualDoc.version}.${actualDoc.subVersion}`;
  if (actualDoc.orig === 21 && !action.version
    && displayVersion === sample) return;
  yield put(setDisplayOnlyAction(action.blocked, action.version));
  yield put(getParagraphList(action.documentId, false, action.version ? action.historyDocumentId : null, action.id));
  if (action.version) { //if (state.document.actualDocument._id !== action.documentId ) {
    yield put(push(`/${projectId}/documents/${action.historyDocumentId._id}/history/${action.documentId}${action.id 
      ? "/" + action.id : ''}`));
  } else {
    if (actualDoc.orig === 21 || actualDoc.orig === 50) yield put(setDisplayOnlyAction(true, sample));
    else yield put(setDisplayOnlyAction(false, ''));
    yield put(push(`/${projectId}/documents/${action.historyDocumentId}${action.id 
      ? "/" + action.id : ''}`));
  }
  ;
}

function * newDocumentSubversionSocket(action) {
  const state = yield select();
  const projectId = state.projectReducer.currentProject._id;
  yield put(setDocumentViewedAction({ documentId: action.document.coreDocument, projectId: projectId }));
  yield take(actionTypes.SET_DOCUMENT_VIEWED_SUCCESS);
  yield put(setDisplayOnlyAction(false, ''));
  yield put(getActualDocumentAction({ documentId: action.document.coreDocument, projectId: projectId, preventListUpdate: false }));
  yield put(getPopUpAction(popUpReset));
}

function * newDocumentVersionSocket(action) {
  const state = yield select();
  const projectId = state.projectReducer.currentProject._id;
  yield put(setDocumentViewedAction({ documentId: action.document.coreDocument, projectId: projectId }));
  yield take(actionTypes.SET_DOCUMENT_VIEWED_SUCCESS);
  yield put(getActualDocumentAction({ documentId: action.document.coreDocument, projectId: projectId }));
  // yield put(getPopUpAction(popUpReset));
}

function * getDocumentByRequestSocketSaga(action) {
  const state = yield select();
  const projectId = state.projectReducer.currentProject._id;

  try {
    const requestDocumentEditingRightResponse = yield api.requestDocumentEditingRight(action.document.coreDocument, projectId, action.confirm);

    if (requestDocumentEditingRightResponse && requestDocumentEditingRightResponse.status === 200) {
      yield put(setDocumentViewedAction({ documentId: action.document.coreDocument, projectId: projectId }));
      yield take(actionTypes.SET_DOCUMENT_VIEWED_SUCCESS);
      yield put(getActualDocumentAction({ documentId: action.document.coreDocument, projectId: projectId }));
      yield put(getPopUpAction(popUpReset));
    } else {
      ;
    }
  } catch (e) {
    ;
  }
}

function * getDocumentByForceSocket(action) {
  const state = yield select();
  const projectId = state.projectReducer.currentProject._id;
  yield put(setDocumentViewedAction({ documentId: action.document.coreDocument, projectId: projectId }));
  yield take(actionTypes.SET_DOCUMENT_VIEWED_SUCCESS);
  yield put(getActualDocumentAction({ documentId: action.document.coreDocument, projectId: projectId }));
  // yield put(getPopUpAction(popUpReset));
}

function * cleanpopup(action) {
  const state = yield select();
  const routes = state.router.location.pathname.split('/');
  const routeLastsOnDocuments = routes.length !== routes.findIndex(route => route === 'documents') + 1;
  const isOutOfDocuments = !(routes.find(route => route === 'documents') && routeLastsOnDocuments);

  if (isOutOfDocuments) {
    yield put(getPopUpAction(popUpReset));
  }
}

function * setDocumentViewed({ documentId, projectId }) {
  try {
    const setDocumentViewedResponse = yield api.setDocumentViewed(documentId, projectId);

    if (setDocumentViewedResponse && setDocumentViewedResponse.status === 200) {
      yield put(setDocumentViewedSuccessAction());
      // yield put(getPopUpAction(popUpReset));
    } else {
      ;
    }
  } catch (e) {
    ;
  }
}

export default function * documentSaga() {
  yield takeLatest(actionTypes.IMPORT_FILE, importFile);
  yield takeLatest(actionTypes.ADD_FILE, addFile);
  yield takeLatest(actionTypes.NEW_SUBVERSION, saveNewSubversion);
  yield takeLatest(actionTypes.RETURN_SUBVERSION, returnSubversion);
  yield takeLatest(actionTypes.NEW_DEMO, newDemo);
  yield takeLatest(actionTypes.APPROVE_EDITING, approveEditing);
  yield takeLatest(actionTypes.DISAPPROVE_EDITING, disapproveEditing);
  yield takeLatest(actionTypes.TRANSFER_EDITING, transferEditing);
  yield takeLatest(actionTypes.REQUEST_EDITING, requestEditing);
  yield takeLatest(actionTypes.OVERTAKE_EDITING, overtakeEditing);
  yield takeLatest(actionTypes.GET_HISTORY_DOCUMENT, getHistoryDocument);
  yield takeEvery(actionTypes.GET_ACTUAL_DOCUMENT, getActualDocument);
  yield takeLatest(actionTypes.GO_ACTUAL_DOCUMENT, goActualDocument);
  yield takeEvery(actionTypes.NEW_DOCUMENT_SUBVERSION_SOCKET, newDocumentSubversionSocket);
  yield takeEvery(actionTypes.NEW_DOCUMENT_VERSION_SOCKET, newDocumentVersionSocket);
  yield takeEvery(actionTypes.GET_DOCUMENT_BY_REQUEST_SOCKET, getDocumentByRequestSocketSaga);
  yield takeEvery(actionTypes.GET_DOCUMENT_BY_FORCE_SOCKET, getDocumentByForceSocket);
  yield takeLatest('@@router/LOCATION_CHANGE', cleanpopup);
  yield takeLatest(actionTypes.SET_DOCUMENT_VIEWED, setDocumentViewed);
}

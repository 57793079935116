import React from 'react'
import styles from './eventBanner.css'
import CSSModules from 'react-css-modules'
import Button from "../button/Button"
import cx from 'classnames'

@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
class EventBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showBanner: true,
            isCookiesShow: false
        }
    }

    componentDidMount() {
        const results = document.cookie.match('(^|;) ?' + 'hideEvent' + '=([^;]*)(;|$)');
        const checkSession = window.sessionStorage.getItem('hideEvent')
        if (results || checkSession) this.setState({showBanner: false})

        const resultsCookie = document.cookie.match('(^|;) ?' + 'cookiesEnabled' + '=([^;]*)(;|$)');
        const checkSessionCookies = window.sessionStorage.getItem('cookiesEnabled')
        if (!resultsCookie && !checkSessionCookies) this.setState({isCookiesShow: true})
    }

    close = () => {
        const today = new Date()
        const expires = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1).toGMTString()

        document.cookie = `hideEvent=true; expires=${expires}`
        this.setState({showBanner: false})
    }

    btnCallback = () => {
        this.close()
        window.location.href = 'http://event.contract.one'
    }

    render() {
        if (!this.state.showBanner) return

        return (
            <div styleName={cx('wrapper', {'offsetTop': this.state.isCookiesShow})}>
                <div className='close-cross' style={{top: '31px'}} onClick={this.close}>+</div>

                <div styleName='event-name'>
                    <img src='./images/icon-calendar.png' alt='icon calendar' />
                    <div>
                        <div className='h2' style={{lineHeight: 'normal', marginBottom: '10px'}}>Contract.one Event</div>
                        <div styleName='text'>
                            Register @ <span styleName='btnBlue' onClick={this.btnCallback}>event.contract.one</span> <br/>
                            11 June, 9 am <span styleName='gray'>(UK time)</span>
                        </div>
                    </div>
                </div>

                <div styleName='event-tagline'>
                    The <img src='./images/icon-text-new.png' alt='NEW' /> way <br/>
                    to negotiate
                </div>

                <Button text='Register' mod='fill green margin0' style={{marginRight: '48px'}} callback={this.btnCallback} />
            </div>
        )
    }
}

export default EventBanner
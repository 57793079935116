import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { StdList } from '../../../../components/StdList';
import { StdPager } from '../../../../components/StdPager';
import { transS } from '../../../../services/helpers/lang';
import { colors } from '../../../../components/colors';
import { SelectLocation } from './SelectLocation';
import { SelectTeams } from './SelectTeams';
import { SelectParams } from './SelectParams';
import { makeNewUnit } from './unitsFuncs';

export const Settings = ({unit, luserId, startSelectedItem, startEdited, callback}) => {
  // callback returns changes on edited unit
  const {unitsList} = 
      useSelector(state => state.unitReducer),
    [itemSelected, setItemSelected] = useState(startSelectedItem),
    [edited, setEdited] = useState(startEdited),
    [location, setLocation] = useState(unit)
  
  const pagerLine = {
    width: 'calc(100% + 80px)',
    height: 1,
    backgroundColor: colors.lightGray2,    
    margin: '-1px -40px 40px -40px',
  }, contStyle = {
    padding: '50px 40px 40px',
    borderLeft: `1px solid ${colors.lightGray2}`,
    width: 800,
  }
  
  return <div style={contStyle}>
    <StdPager selected={itemSelected}
      callback={setItemSelected}
      selections={[
        transS('Where to save'),
        transS('Companies & Teams'),
        transS('Parameters & Settings')]} />
    <div style={pagerLine}></div>
    {itemSelected === 0 && <SelectLocation 
      unit={location}
      action = {loc => {        
        setLocation(loc)
        const release = makeNewUnit(loc, luserId)
        setEdited(release)
        setItemSelected(1)
        callback(release)
      }}
      />}
    {itemSelected === 1 && <SelectTeams 
      unit={edited}
      action = {(edited) => callback(edited)}
      />}
    {itemSelected === 2 && <SelectParams 
      unit={edited}
      action = {params => ({params})}
      />}
  </div>

  
}
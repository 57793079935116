import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import CSSModules from 'react-css-modules';
import styles from './logo.css';
import {getInvitePopupAction} from "../../services/actions/projectActions"
import {connect} from "react-redux"
import { transLogo } from '../../services/helpers/lang';

const Logo = props => {
  let { activeLink = true, getInvitePopupAction, white,
   link = '/'  } = props;
  const img = <img src={transLogo(white)} width="118" height="22" alt='logo'/>
  return <div onClick={() => getInvitePopupAction(false)}>
      {activeLink ? (
          link ? <a href={link}>{img}</a>
          : <NavLink to="/">{img}</NavLink>
      ) : (
          <div styleName='wrapper'>{img}</div>
      )}
  </div>
};

Logo.propTypes = {
  activeLink: PropTypes.bool,
  getInvitePopupAction: PropTypes.func
};

export default connect(null, {getInvitePopupAction})(new CSSModules(Logo, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' }))

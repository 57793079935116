import React from 'react'
import CSSModules from 'react-css-modules'
import cx from 'classnames'
import styles from './Share.css'
import Button from "../../../../../../components/button/Button"
import { transS } from '../../../../../../services/helpers/lang'

@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
export default class Share extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        }
    }

    componentDidMount() {
        window.addEventListener('click', this.clickOutsideHandler)
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.clickOutsideHandler)
    }

    clickOutsideHandler = e => {
        if (e.target !== this.refWrapper && !this.refWrapper.contains(e.target)) this.setState({isOpen: false})
    }

    render() {
        const {isOpen} = this.state
        const {openInvitePopup} = this.props

        return (
            <React.Fragment>
                {isOpen && <div styleName='screen'/>}
                <div styleName={cx('wrapper', {'_open': isOpen})} ref={e => this.refWrapper = e}>

                    <Button text={transS('Share')} mod={`blue margin0 leftArrow${isOpen ? ' leftArrow_open' : ''}`}
                            callback={() => this.setState({isOpen: !isOpen})} />

                    <div styleName='popup'>
                        <Button text={transS('Teams')} mod='blue fill nowrap margin0'
                                callback={() => {openInvitePopup();this.setState({isOpen: false})}} />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
import * as actionTypes from './constants';


export const addFileAction = (file, name, projectId, dataType) => ({
  type: actionTypes.ADD_FILE,
  file,
  name,
  projectId,
  dataType
});

export const importFileAction = (file, name, projectId, documentId) => ({
  type: actionTypes.IMPORT_FILE,
  file,
  name,
  projectId,
  documentId
});

export const addFileSuccessAction = () => ({
  type: actionTypes.ADD_FILE_SUCCESS
});

export const addFileFailedAction = message => ({
  type: actionTypes.ADD_FILE_FAILED,
  message
});

export const setCurrentDocumentAction = (documentId = null) => ({
  type: actionTypes.SET_CURRENT_DOCUMENT,
  documentId
});

export const setActualDocumentAction = document => ({
  type: actionTypes.SET_ACTUAL_DOCUMENT,
  document
});

export const addVariableAction = vartype => ({
  type: actionTypes.ADD_VARIABLE,
  vartype
});
export const setVarList = varlist => ({
  type: actionTypes.SET_VARIABLE, varlist
});
export const setUsedVars = (usedVars, unusedVars) => ({
  type: actionTypes.SET_USED_VARS, usedVars, unusedVars
});
export const setFormatable = (fonts) => ({
  type: actionTypes.SET_FORMATABLE,
  fonts
});
export const showDateControl = (left, top, dateVar, fromParagraph) => ({
  type: actionTypes.SHOW_DATE_CONTROL,
  left, top, dateVar, fromParagraph
});
export const setVarClick = (stamp, record, option, y) => ({
  type: actionTypes.SET_VAR_CLICK, click: {stamp, record, option, y}
});
export const setVarChange = (variable) => ({
  type: actionTypes.SET_VAR_CHANGE, variable
});

export const setDocumentEnvelope = envelope => ({
  type: actionTypes.SET_DOCUMENT_ENVELOPE,
  envelope
});

export const goActualDocumentAction = ({ documentId, projectId, historyDocumentId = null, preventListUpdate = false, preventChangeRoute = false }) => ({
  type: actionTypes.GO_ACTUAL_DOCUMENT,
  documentId,
  projectId,
  historyDocumentId,
  preventListUpdate,
  preventChangeRoute
});

export const getActualDocumentAction = ({ documentId, projectId, historyDocumentId = null, preventListUpdate = false, preventChangeRoute = false, viewDoc = null }) => ({
  type: actionTypes.GET_ACTUAL_DOCUMENT,
  documentId,
  projectId,
  historyDocumentId,
  preventListUpdate,
  preventChangeRoute,
  viewDoc,
});

export const saveNewDocumentSubVersionAction = (documentId, toFreeze) => ({
  type: actionTypes.NEW_SUBVERSION,
  documentId,
  toFreeze
});

export const returnSubVersionAction = documentId => ({
  type: actionTypes.RETURN_SUBVERSION,
  documentId
});

export const newDemo = kind => ({
  type: actionTypes.NEW_DEMO,
  kind
});

export const transferDocumentEditingAction = (documentId, isCollaborate = false, unhideFirstVersion = false, toFinalize = false, isFinalizeRefuse = false, cancelFinalize = false) => ({
  type: actionTypes.TRANSFER_EDITING,
  documentId,
  isCollaborate,
  unhideFirstVersion,
  toFinalize,
  isFinalizeRefuse,
  cancelFinalize
});

export const approveDocumentEditingAction = documentId => ({
  type: actionTypes.APPROVE_EDITING,
  documentId
});

export const disapproveDocumentEditingAction = documentId => ({
  type: actionTypes.DISAPPROVE_EDITING,
  documentId
});

export const chgApprovals = data => ({
  type: actionTypes.CHANGE_APPROVAL,
  data: data
});

export const requestDocumentEditingAction = documentId => ({
  type: actionTypes.REQUEST_EDITING,
  documentId
});

export const overtakeEditingAction = documentId => ({
  type: actionTypes.OVERTAKE_EDITING,
  documentId
});

export const getHistoryDocumentAction = (documentId, historyDocumentId, blocked, version, id) => ({
  type: actionTypes.GET_HISTORY_DOCUMENT,
  documentId,
  historyDocumentId,
  blocked,
  version,
  id
});

export const setDisplayOnlyAction = (value, version) => ({
  type: actionTypes.DISPLAY_ONLY,
  displayOnly: value,
  displayVersion: version
});

export const addNewSubVersionSocket = data => ({
  type: actionTypes.NEW_DOCUMENT_SUBVERSION_SOCKET,
  document: data
});

export const addNewVersionSocket = data => ({
  type: actionTypes.NEW_DOCUMENT_VERSION_SOCKET,
  document: data
});
export const getDocumentByRequestSocket = (data, confirm) => ({
  type: actionTypes.GET_DOCUMENT_BY_REQUEST_SOCKET,
  document: data,
  confirm
});
export const getDocumentByForceSocket = (data, confirm) => ({
  type: actionTypes.GET_DOCUMENT_BY_FORCE_SOCKET,
  document: data
});

export const setDocumentViewedAction = ({ documentId, projectId }) => ({
  type: actionTypes.SET_DOCUMENT_VIEWED,
  documentId,
  projectId
});

export const setDocumentViewedSuccessAction = () => ({
  type: actionTypes.SET_DOCUMENT_VIEWED_SUCCESS
})

export const renameDocumentFromSidebarAction = (newName) => ({
  type: actionTypes.RENAME_DOCUMENT_FROM_SIDEBAR,
  newName
})

export const setDocumentNavigationAction = (current, paras, isJump, isNeedBack) => ({
  type: actionTypes.SET_DOCUMENT_NAVIGATION,
  current,
  paras,
  isJump,
  isNeedBack
})
export const clearBackNavigationAction = () => ({
  type: actionTypes.CLEAR_BACK_NAVIGATION
})

export const toggleNavigationVisAction = (open, fullMode) => ({
  type: actionTypes.TOGGLE_DOCUMENT_NAVIGATION_VIS,
  open,
  fullMode
})

export const clearWriterCoverMessageAction = () => ({
  type: actionTypes.CLEAR_WRITER_COVER_MESSAGE,
})

export const setWriterCoverMessageAction = (userId) => ({
  type: actionTypes.SET_WRITER_COVER_MESSAGE,
  userId
})

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import CSSModules from 'react-css-modules';
import styles from './paragraphList.css';

import {
    setCurrentDocumentAction,
    setDisplayOnlyAction,
    getActualDocumentAction, 
} from '../../../../redux/document/actions';
import { setFrameAction } from '../../../../services/actions/paragraphActions';
import {turnCommunicationVis} from '../../../../redux/paragraph/actions'
import { getProjectDocuments, setCurrentProject} from '../../../../services/actions/projectActions';
import Spinner from '../../../../components/spinner/Spinner';


const ViewDoc = props => {
  const { projectList, currentProject } = useSelector(state => state.projectReducer);
  const { internalUserList} = useSelector(state => state.userReducer);
  const dispatch = useDispatch();
  let address = '';
    
  const checkStart = () => {
    
    setDisplayOnlyAction(false, '');
    if (!projectList || !projectList.find(item => item._id === projectId)) {
        dispatch(getProjectDocuments());
    }
    dispatch(setCurrentDocumentAction(documentId));
    dispatch(getActualDocumentAction({ documentId, projectId, 
        preventChangeRoute: true, viewDoc: true }));    
    dispatch(turnCommunicationVis(1))
  };
  
  const spinner = () => 
    <div style={{position: 'fixed', width: '100%', height: '100%', top: '0', left: '0', background: 'white'}}><Spinner /></div>;
            
  const dispNone = () => 
    <div>Document content can't be converted to pdf. You can download it</div>

  const dispImage = () => 
    <>
    <div styleName='viewImage'>
      <span styleName='viewImageSpan'>
         <img src={`/api/documents/getpure/${projectId}/${documentId}/${docName}`}
           style={{width: imageScale+'%'}}
           onLoad={frameIsReady} />
       </span>
    </div>
    <div styleName="sizeControl">
        <div onClick={()=>resize(false)}>-</div>
        <div styleName="percent" onClick={()=>resize(null)}>{imageScale}%</div>
        <div onClick={()=>resize(true)}>+</div>
    </div>
    </>
    
  const resize = arg => {
    let newScale;
    if (arg === null) newScale = 100;
    else if (arg && imageScale < 990) newScale = imageScale + 10;
    else if (!arg && imageScale > 10) newScale = imageScale - 10;
    if (newScale && imageScale !== newScale) setImageScale(newScale);
  };
  
  const dispFrame = () => 
    <iframe name='iframeV' src={`/api/documents/${selector === 'pdf' ? 'getpure' : 'getcontent'}/${projectId}/${documentId}/${docName}`}
          width="100%" height="100%"
          frameBorder="0" marginHeight="0" marginWidth="0"
          onLoad={frameIsReady} />
            
  const frameIsReady = (x) => {
      setContentReady(true);
      dispatch( setFrameAction(()=>null, {toggleBar: null}) );
  };
   
  useEffect(() => checkStart(), []);
  
  const { documentId, projectId, selector, docName } = props.match.params;
  const [nonEmptyContent, setContentReady] = useState(selector === 'none');
  const [imageScale, setImageScale] = useState(100);
  if (projectList && currentProject._id !== projectId)
      dispatch( setCurrentProject({projectId, projectList}) );
  return ( //
    <div styleName='wrapper'> 
      <div styleName='viewFrame'>
         { selector === 'none' ? dispNone() : 
           selector === 'img' ? dispImage() : dispFrame()}
      </div>
      { !nonEmptyContent && spinner()}
    </div>
  );
};
export default CSSModules(ViewDoc, styles, {allowMultiple: true});



import React, { useEffect, useRef } from 'react';
import CSSModules from 'react-css-modules'
import styles from './DocumentListItemDropDown.css';

const UniversalListMenuDropDown = params => {
  const {item, top, left, close, menuItems} = params;
  const dropdownRef = useRef(null);
   
  const clickOutsideHandler = ({target}) => {
     if (!dropdownRef.current?.contains(target))
         close();
  };
    
  useEffect(() => {
    window.addEventListener('click', clickOutsideHandler);
    return () => {
      window.removeEventListener('click', clickOutsideHandler);
    };
  }, []);
    
  return <div
       styleName='drop-down'
       style={{top: top+"px", left: (left < 300 ? left : left - 200)+"px", display: !!item ? "block" : "none"}}
       ref={dropdownRef} >
       {menuItems.map( (el, num) => !el.hidden && (
            <div key={num} styleName='drop-down-inner'>
                {el.divided && <div styleName='divider'></div>}
                <button styleName={'drop-down-item' + (el.style ? ' ' + el.style : '') } 
                    type='button' onClick={() => el.click(item)} >
                    {el.name}
                </button>
            </div> 
         ))
       }    
  </div>
};
export default CSSModules(UniversalListMenuDropDown, styles, {allowMultiple: true});

import React from 'react'
import CSSModules from 'react-css-modules'
import cx from 'classnames'
import styles from "./documentNavigation.css"
import {
    clearBackNavigationAction,
    setDocumentNavigationAction,
    toggleNavigationVisAction
} from "../../../../redux/document/actions"
import {connect} from "react-redux"
import NavigationControls from "./navigationControls"
import NavigationBtn from "./navigationBtn"
import NavigationContent from "./navigationContent"
import { transS } from '../../../../services/helpers/lang'

@connect(state => ({
    navigation: state.document.navigation,
    currentDocument: state.document.currentDocument
}), {
    setDocumentNavigationAction,
    clearBackNavigationAction,
    toggleNavigationVisAction
})
@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
export class DocumentNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isToggleProgress: false, // процесс открытия/закрытия
            isOpen: false,
            isPinSmallMode: false,

            isFullMode: false,
            isPinFullMode: false,

            paraFilter: 0, // 0 show all; 1 changes only; 2 changes and comments
        }
    }

    componentDidMount() {
        window.addEventListener('click', this.clickOutsideHandler)
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.clickOutsideHandler)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const isToggleOpen = prevProps.navigation.open !== this.props.navigation.open && this.props.navigation.open !== this.state.isOpen
        const isToggleMode = prevProps.navigation.fullMode !== this.props.navigation.fullMode && this.props.navigation.fullMode !== this.state.isFullMode
        if (isToggleOpen) {
            this.openHandler(this.props.navigation.open)
        }
        if (isToggleMode) {
            this.toggleModeHandler(this.props.navigation.fullMode)
        }
    }

    openHandler = value => {
        if (value || !this.state.isOpen) {
            this.setState({isToggleProgress: true})
            setTimeout(() => {
                this.setState({isOpen: true, isFullMode: true, isToggleProgress: false})
                this.props.toggleNavigationVisAction(true, true)
            }, 1)
        } else {
            this.setState({isOpen: false, isToggleProgress: true, isFullMode: false, isPinSmallMode: false, isPinFullMode: false, paraFilter: 0})
            this.props.toggleNavigationVisAction(false, false)
            setTimeout(() => this.setState({isToggleProgress: false}), 200)
        }
    }

    toggleModeHandler = (value) => {
        const v = value !== undefined ? value : !this.state.isFullMode

        v ?
            this.setState({isFullMode: true})
            :
            this.setState({isFullMode: false, isPinFullMode: false})
    }

    toggleParaFilterHandler = value => {
        this.setState({paraFilter: value})
    }

    pinClickHandler = () => {
        this.state.isFullMode ?
            this.setState({isPinFullMode: !this.state.isPinFullMode})
            :
            this.setState({isPinSmallMode: !this.state.isPinSmallMode})
    }

    clickOutsideHandler = e => {
        const {isOpen, isPinSmallMode, isFullMode, isPinFullMode} = this.state
        if (!e.target.dataset.back && e.target !== this.refWrapper && !this.refWrapper.contains(e.target) && isOpen && (isFullMode ? !isPinFullMode : !isPinSmallMode)) {
            isFullMode ? this.toggleModeHandler(false) : this.openHandler(false)
        }

    }

    jumpBackCallback = () => {
        this.props.jump(this.props.navigation.back, 1)
        this.props.clearBackNavigationAction()
    }

    render() {
        const {isOpen, isToggleProgress, isPinSmallMode, isFullMode, isPinFullMode, paraFilter} = this.state
        const {navigation, jump} = this.props

        const isPin = isFullMode ? isPinFullMode : isPinSmallMode

        return <React.Fragment>
            {isOpen && !isPin && <div styleName='screen'/>}

            <div styleName={cx('wrapper', {'_open': isOpen, 'fullMode': isFullMode})} ref={e => this.refWrapper = e}>

                {isFullMode ? <div className='h2' styleName='title'>{transS('Navigation')}</div> :
                    <NavigationBtn isOpen={isOpen} openHandler={this.openHandler} isFullMode={isFullMode} navigation={navigation}
                                   jumpBack={this.jumpBackCallback}/>}

                {(isToggleProgress || isOpen) && <NavigationControls isOpen={isOpen} pinClickHandler={this.pinClickHandler} isPin={isPin}
                                    isFullMode={isFullMode} toggleModeHandler={this.toggleModeHandler} navigation={navigation}
                                    jumpBack={this.jumpBackCallback}
                                    toggleParaFilterHandler={this.toggleParaFilterHandler} paraFilter={paraFilter}/>}

                {(isToggleProgress || isOpen) && <NavigationContent isOpen={isOpen} navigation={navigation} jump={jump} isFullMode={isFullMode}
                                   paraFilter={paraFilter} wrapper={this.refWrapper} />}
            </div>
        </React.Fragment>
    }
}

import React, {useState, useEffect, useRef} from 'react'
import CSSModules from 'react-css-modules'
import styles from './Tip.css'
import Button from '../button/Button'
import cx from 'classnames'

const Tippp = props => {
  const {text, header, btn, arrow, align} = props;
  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);
  
  useEffect(() => {
    setHeight(elementRef.current.clientHeight);
  }, []); //empty dependency array so it only runs once at render
  const styled = {top: (-10-height)+'px'};
  if (align) styled.right = '0px';

  return (
    <div ref={elementRef} styleName={cx('wrapper', {[arrow]: arrow, 'bottom right': !arrow})} 
       style={styled}>
       {header && <div styleName='header' dangerouslySetInnerHTML={{__html: header}} />}
          <div dangerouslySetInnerHTML={{__html: text}} />
          {btn && <Button text={btn.text} callback={btn.callback} 
            mod='small arch gray' style={{marginTop: '10px',
            backgroundColor: '#00000000'}}/>}
    </div>
  )
}

export default new CSSModules(Tippp, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
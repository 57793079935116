import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { findUnitTeams } from './unitsFuncs';
import { transS } from '../../../../services/helpers/lang';
import { rights } from '../../../../../configs/appConfig';
import { Team } from './Team';

export const SelectTeams = ({unit, action}) => {
  // action - signals that unit chnged
  const {unitsList, unitsTree, knownUsers, luserId} = 
    useSelector(state => state.unitReducer),
    {internalTeam, externalTeam, userRights, onTopRights} = 
      findUnitTeams(unit, u => unitsList.get(u), luserId)

  const [internalTeamState, setinternalTeam] = useState(internalTeam),
    [externalTeamState, setexternalTeam] = useState(externalTeam),
    [userRightsState, setuserRights] = useState(userRights),
    [topRights, setTopRights] = useState(onTopRights),
    inInternal = userRightsState & rights.INTERNAL,
    externalNumber = userRightsState & rights.EXTERNAL,
    internalEditor = inInternal && (userRightsState & rights.CAN_REMOVE),
    externalEditor = externalNumber && (userRightsState & rights.TEAM_UNIT)

  const onTeamChange = (external, command, userId, right) => {
    // add, remove, right, title
    const thisTeam = external ? externalTeamState : internalTeamState,
      thatTeam = external ? internalTeamState : externalTeamState,
      foundIdx = thisTeam.findIndex(el => el.id === userId),
      modIdx = unit.members.findIndex(el => el === userId)
    switch (command) {
      case 'title':
        break;
      case 'add':
        // dialog
        return
      case 'remove':
        if (modIdx === -1) break
        unit.members.splice(modIdx,1)
        unit.rights.splice(modIdx,1)
        thisTeam.splice(foundIdx, 1)        
        break
      case 'right':
        thisTeam[foundIdx].right -= right
        thisTeam[foundIdx].directRight -= right
        thisTeam[foundIdx] = {...thisTeam[foundIdx]}
        unit.rights[modIdx] -= right
        break 
    }
    if (external) setexternalTeam([...thisTeam])
    else setinternalTeam([...thisTeam])
    action(unit)
  }
  
  const styleCont = {
    display: 'flex',
    flexDirection: 'column',
    gap: 20
  }

  return <div style={styleCont}>
    <div className='h2'>{transS('Companies & Teams')}</div>
    <Team
      external="1"
      title={transS("Unspecified Companies")}
      titleEditable={topRights && (internalEditor || externalEditor)}
      canAdd={internalEditor}
      memberList={inInternal ? externalTeamState : []}
      action={onTeamChange}
    />
    <Team 
      title={transS("Internal Team")}
      canAdd={internalEditor || externalEditor}
      canApprove={internalEditor}
      memberList={!inInternal ? externalTeamState : internalTeamState}
      action={onTeamChange}
    />
  </div>
}
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './popupWindows.css';

import { popUpReset } from '../../services/helpers';

import { getPopUpAction } from '../../services/actions/dataActions';

import Confirm from './Confirm';

const PopupWindows = props => {
  let { getPopUpAction } = props;

  // const _close = () => getPopUpAction(popUpReset());
  const _close = () => {};

  return (
    <div styleName='wrapper' onClick={_close}>
      <Confirm />
    </div>
  );
};

PopupWindows.propTypes = {
  getPopUpAction: PropTypes.func
};

export default connect(null, {
  getPopUpAction
})(new CSSModules(PopupWindows, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' }));

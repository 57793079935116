import React, { useState, useEffect } from 'react'

export const TreeNode = React.memo(({unit, ShowElement, getChildren, mark, unfold}) => {
  const [drawState, setDrawState] = useState(1)
  useEffect(() => {
    unit.trefresh = () => setDrawState(d => d + 1)
    return () => {
      unit.tRefresh = null
    }
  },[])

  const result = (<>
    <ShowElement unit={unit} mark={mark}/>
    {(unfold || !mark) && <div data-tree="1">
      {getChildren(unit).map(c => !!c && <TreeNode 
        key={c.loc_id || c.id}
        unit={c}
        ShowElement={ShowElement}
        getChildren={getChildren}
        mark={(() => mark)()}
      />)}      
    </div>}
  </>)
  return result
  })

import React, {Component} from 'react'
import styles from './companySettings.css'
import cssModules from "react-css-modules"
import Button from "../../../../components/button/Button"
import UserListItem from "../../components/createProjectDropDown/UserListItem"
import {isEmail} from "../../../../../common/validation"
import Checkbox from "../../../../components/checkbox/Checkbox"
import { transL, transS } from '../../../../services/helpers/lang'

@cssModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
class AddTeam extends Component {
    state = {
        isAccMenuOpen: false,
        team: {
            name: '',
            members: []
        },
        email: '',
        firstname: '',
        lastname: '',
        blockNameMessage: null,
        emailErrorMessage: null,
        candidates: [],
        isTeamMenuOpen: false,
        newParentTeamsArray: []
    }

    componentDidMount() {
        const {editableTeam} = this.props
        if (!editableTeam.new) {
            let members = [], parentTeams = editableTeam.parentTeams ? [...editableTeam.parentTeams] : null,
                newParentTeamsArray = editableTeam.parentTeams ? [...editableTeam.parentTeams] : [];
            editableTeam.members.forEach(m => members.push({...m, teams: [...m.teams]}) )

            this.setState({team: { ...editableTeam, members, parentTeams}, newParentTeamsArray})
            this.makeCandidates(editableTeam.name)
        } else {
            this.makeCandidates()
        }

        document.body.style.overflowY = 'hidden';
    }

    componentWillUnmount() {
        document.body.style.overflowY = 'auto'
    }

    changeTeam = () => {
        const {team} = this.state

        if (team.name === '') return this.checkTeamName(team.name)
        const deletedMembers = team.members.filter(m => m.unChecked)
        const addedMembers = team.members.filter(m => m.newMember)
        if (deletedMembers.length) team.deletedMembers = deletedMembers
        if (addedMembers.length) team.addedMembers = addedMembers
        if (this.props.editableTeam.new) {
            team.members = team.members.filter(u => !deletedMembers.find(m => m._id === u._id))
            team.members.forEach(m => {
                if (!m.teams.find(t => t === team.name)) m.teams.push(team.name)
            })
            delete team.deletedMembers
        }

        if (team.oldName) {
            team.members.forEach(m => m.teams = m.teams.filter(t => t !== team.oldName))
        }
        team.members.forEach(m => {
            if (!m.teams.find(t => t === team.name)) m.teams.push(team.name)
        })

        this.props.addTeam(team)
    }

    deleteTeam = () => {
        const {team} = this.state
        this.props.deleteTeam(team)
    }

    checkTeamName = (name) => {
        const {team} = this.state
        const {teamNames, editableTeam} = this.props

        if (name === '') return this.setState({blockNameMessage: 'Enter team name'})

        if (!!teamNames.find(t => t === name) && (editableTeam.new || team.oldName && team.oldName !== name) )
            return this.setState({blockNameMessage: 'This name is already in use'})

        return this.setState({blockNameMessage: null})
    }

    checkUserEmail = (email) => {
        if (email === '') {
            return this.setState({emailErrorMessage: transL('requiredEmail')})
        } else if (isEmail(email)) {
            return this.setState({emailErrorMessage: transL('errorEmail')})
        } else {
            this.setState({emailErrorMessage: null})
        }
    }

    inputChangeHandler = e => {
        const {team} = this.state

        if (e.target.name === 'teamName') {
            if (e.target.value.startsWith('_')) return

            if (!this.props.editableTeam.new) {
                const oldName = team.oldName ? team.oldName : team.name
                this.setState({team: {...team, oldName, name: e.target.value}})
            } else {
                this.setState({team: {...team, name: e.target.value}})
            }
            return this.checkTeamName(e.target.value)
        }

        this.setState({[e.target.name]: e.target.value})
    }

    addMember = () => {
        const {team, email, firstname, lastname} = this.state
        const {members} = this.props

        if (email === '' || isEmail(email)) return this.checkUserEmail(email)
        let member = {
            email,
            firstname,
            lastname,
            accessStatus: 0,
            title: '',
            teams: ['_general', team.name],
            _localId: Date.now(),
            newMember: true
        }

        const checkUser = members.find(m => m.email === email)
        if (checkUser) {
            checkUser.unChecked = false
            member = checkUser
        }

        if ( !team.members.find(m => (m._id || m._localId) === (member._id || members._localId)) ) team.members.push(member)

        this.setState({team: {...team, members: team.members}, email: '', firstname: '', lastname: ''})
    }

    makeCandidates = (name) => {
        const {team} = this.state
        const {members} = this.props

        const teamName = name || team.name
        const candidates = []
        members.forEach(m => {
            if (m.teams.find(t => t === teamName)) return

            candidates.push(m)
        })

        return this.setState({candidates})
    }

    teamsPopupSubmit = () => {
        const {newParentTeamsArray, team} = this.state

        this.setState({team: {...team, parentTeams: [...newParentTeamsArray]},isTeamMenuOpen: false})
    }

    teamsPopupClose = () => {
        const {editableTeam} = this.props
        const {team} = this.state
        if (!editableTeam.parentTeams) return this.setState({newParentTeamsArray: [], isTeamMenuOpen: false})
        this.setState({newParentTeamsArray: [...editableTeam.parentTeams], isTeamMenuOpen: false})
    }



    render() {
        const {email, firstname, lastname, team, blockNameMessage, candidates, emailErrorMessage, isTeamMenuOpen, newParentTeamsArray} = this.state
        const {editableTeam, getPopUpAction, popUpReset, getTeams} = this.props

        const candidatesList = candidates.map(c => {
            const memberIndex = team.members.findIndex(i => (i._id || i._localId) === (c._id || c._localId) )
            const isChecked = memberIndex > -1 ? !team.members[memberIndex].unChecked : false
            const callback = () => {
                if (isChecked) {
                    team.members[memberIndex].unChecked = !team.members[memberIndex].unChecked
                } else if (!isChecked && memberIndex !== -1){
                    team.members[memberIndex].unChecked = !team.members[memberIndex].unChecked
                } else {
                    if (c.deletedTeams) c.deletedTeams.filter(t => team.name !== t)
                    c.unChecked = false
                    team.members.push(c)
                }

                this.setState({team})
            }
            return <UserListItem maxNameWidth={240} _checkUser={callback} checked={isChecked} user={c} key={c._id || c._localId} />
        })

        const parentTeamsList = team.parentTeams && team.parentTeams.map((t, index) => {
            const callback = () => {
                const array = team.parentTeams.filter(i => i !== t)
                this.setState({team: {...team, parentTeams: array}, newParentTeamsArray: array})
            }
            return <span key={t + index}>{t}
                <span className='close-cross' onClick={callback}>+</span>
            </span>
        })

        const teams = getTeams()
        const teamsPopup = teams.map(t => {
            if (t === '_general') return

            const checked = !!newParentTeamsArray.find(i => i === t)
            const callback = () => {
                if (checked) {
                    let array = newParentTeamsArray.filter(i => i !== t)
                    return this.setState({newParentTeamsArray: array})
                } else {
                    newParentTeamsArray.push(t)
                    return this.setState({newParentTeamsArray})
                }
            }

            return <div styleName='addMember-team-popup-line' key={t} onClick={callback}>
                <div styleName='addMember-team-popup-line-checkbox'><Checkbox checked={checked} /></div>
                <div styleName='addMember-team-popup-line-teamName'>{t}</div>
            </div>
        })

        const popup = {
                name: 'confirm',
                text: `<div>
                          <div style="margin-bottom: 20px;font-size: var(--fontSize_h2); font-weight: bold">Delete Team?</div>
                          This will delete the team. It cannot be undone. All the team members will keep &#8195; their accounts and stay in other teams.</div>`,
                confirm: {
                    name: 'Delete Team',
                    danger: true,
                    event: () => { this.deleteTeam(); getPopUpAction(popUpReset) }
                },
                cancel: {
                    name: transS('No'),
                    event: () => { getPopUpAction(popUpReset); }
                }
        }

        if (editableTeam.membersOnly) {
            return (
                <div styleName='addTeam-wrapper'>
                    <div styleName='addTeam'>
                        <span className='close-cross' onClick={this.props.close}>+</span>
                        <div className='h3' style={{marginBottom: '40px'}}>Add Members to {team.name}</div>
                        <div styleName='listCandidates'>
                            {candidatesList}
                        </div>
                        <div styleName='addTeamsBtn-row' style={{marginTop: '30px'}}>
                            <Button text={transS('Save Changes')} callback={this.changeTeam} mod='blue fill'/></div>
                    </div>
                </div>
            )
        }

        return (
            <div styleName='addTeam-wrapper'>
                <div styleName='addTeam'>
                    <span className='close-cross' onClick={this.props.close}>+</span>

                    <div className="h1"
                         style={{marginBottom: '20px'}}>{editableTeam.new ? 'Create Team' : 'Edit Team'}</div>

                    <input type="text"
                           styleName='addTeam-input'
                           onChange={e => this.inputChangeHandler(e)}
                           value={team.name}
                           name='teamName'
                           placeholder='Team Name'
                           style={{fontSize: '20px', width: '320px', height: '44px'}}/>
                    {blockNameMessage && <div styleName='teamName-error'>{blockNameMessage}</div>}

                    { !team.isParentTeam &&
                    <div styleName='addTeam-parentTeams'>
                        <div className='h2' style={{margin: '40px 0 20px 0', lineHeight: 'normal'}}>Part of Another Team</div>
                        {parentTeamsList && <div styleName='addTeam-parentTeamsList'>
                            {parentTeamsList}
                        </div>}
                        {isTeamMenuOpen && <div styleName='addMember-team-popup-screen'></div>}
                        {isTeamMenuOpen && <div styleName='addMember-team-popup'>
                            <div onClick={this.teamsPopupClose} className='close-cross'>+</div>
                            <div className='h3' style={{paddingBottom: '40px'}}>Add Parent Team</div>
                            {teamsPopup}
                            <div style={{marginTop: '40px'}}>
                                <Button text={transS('Save Changes')} mod='blue fill' callback={this.teamsPopupSubmit}/></div>
                        </div>}
                        <div styleName='addMember-addTeamBtn' style={!teams.length ? {opacity: '0.3'} : null} onClick={() => {
                            if (!!teams.length) return this.setState({isTeamMenuOpen: true})
                        }}>Add Parent Team</div>
                    </div>}


                    <div styleName='addTeamsCont'>
                        <div styleName='addTeamsPart'>
                            <div className='h2' style={{margin: '0 0 20px 0'}}>People added to Team</div>
                            <div styleName='listUser'>
                                {team.members.map(m => {
                                    const memberIndex = team.members.findIndex(i => (i._id || i._localId) === (m._id || m._localId) )
                                    const checkUser = () => {
                                        team.members[memberIndex].unChecked = !team.members[memberIndex].unChecked
                                        this.setState({team})
                                    }
                                    const proveUser = () => {
                                        team.members[memberIndex].prove = !team.members[memberIndex].prove
                                        this.setState({team})
                                    }

                                    return <UserListItem _proved={proveUser} prove={m.prove || false} isYourFirm={true} rep={null}
                                                         maxNameWidth={155} _checkUser={checkUser} checked={!m.unChecked}
                                                         user={m} key={m._id || m._localId}/>
                                })}
                            </div>
                        </div>
                        <div styleName='addTeamsPart'>
                            <div className='h2' style={{marginBottom: '20px'}}>Invite New Participant</div>
                            <input type="text" styleName='addTeam-input' placeholder='First Name' name='firstname'
                                   value={firstname} onChange={e => this.inputChangeHandler(e)}
                                   style={{width: '154px', marginRight: '10px'}}/>
                            <input type="text" styleName='addTeam-input' placeholder='Last Name' name='lastname'
                                   value={lastname} onChange={e => this.inputChangeHandler(e)}
                                   style={{width: '155px'}}/>
                                <input type="text" styleName='addTeam-input' placeholder='Email' name='email'
                                       style={{marginBottom: '20px'}}
                                       onBlur={e => this.checkUserEmail(e.target.value)}
                                       onChange={e => this.inputChangeHandler(e)} value={email}/>
                                {emailErrorMessage && <div styleName='emailError'>{emailErrorMessage}</div>}
                            <Button text="Add a new person to the team"
                                    callback={this.addMember}
                                    mod='blue arch'/>

                            <div style={{lineHeight: '1', margin: '60px 0 20px'}} className='h2'>Or choose from people <br/>
                                in this company
                            </div>
                            <div styleName='listCandidates'>
                                {candidatesList}
                            </div>

                        </div>
                    </div>

                    <div styleName='addTeamsBtn-row'>
                    {!blockNameMessage ?
                        <Button text={editableTeam.new ? "Create Team" : transS('Save Changes')} callback={this.changeTeam}
                                   mod='blue fill'/>
                        :
                        <Button text={editableTeam.new ? "Create Team" : transS('Save Changes')} callback={() => null}
                                mod='blue arch disabled'/>}

                    {editableTeam.new ? '' : <Button text="Delete Team" callback={() => getPopUpAction(popup)} mod='red arch'/>}
                    </div>

                </div>
            </div>
        )
    }
}

export default AddTeam
import cx from 'classnames'
import CSSModules from 'react-css-modules'
import React from 'react'
import styles from './displayVersion.css'
import Changes from '../elements/Changes/Changes'
import Spinner from "../../../../../components/spinner/Spinner"
import Approvals from "../elements/Approvals/Approvals";
import { getDateString } from "../../../../../../common/getDateString"
import {connect} from "react-redux"
import { transS } from '../../../../../services/helpers/lang'

@connect(state => ({    
    dateData: state.userReducer.dateData
}), {})

@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
export class DisplayVersion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isScrollNeed: false,
            isScrolled: false,
            isWaitDownload: false,
            tryPopup: false
        }
    }

    componentDidMount() {
        this._calcBlockWidth()
        window.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.handleClickOutside)
    }

    _calcBlockWidth = () => {
        const headerWidth = this.refWrapper.parentElement.offsetWidth + 80 // 80 = 40padding left + 40padding right
        const clientWidth = document.documentElement.clientWidth

        if (headerWidth > clientWidth) {
            this.setState({isScrollNeed: true})
        } else if (headerWidth < clientWidth) {
            this.setState({isScrollNeed: false, isScrolled: false})
        }
    }

    download = async () => {
        this.setState({isWaitDownload: true})
        this.props.download('docx', null, null, null, 
          () => this.setState({isWaitDownload: false}));
    }

    render() {
        const {
            actualDocument, displayVersion, isExportAllowed, getHistoryDocumentAction, jumpChanges,
            stateMenu, frameProgress, setMenuAction, tasksClickHandler, approverUserList, approved,
            isExternal, isProjectAdmin, user, dateData, freezeFunc
        } = this.props
        const {isScrollNeed, isScrolled, isWaitDownload, tryPopup} = this.state

        const [ver, subVer] = displayVersion.split('.'),
         isActual = displayVersion === `${actualDocument.version}.${actualDocument.subVersion}`,
         justImported = actualDocument.orig == 50 && isActual,
          isFrozen = actualDocument.orig == 21 && isActual
            
        let version = {}
        actualDocument.history.forEach((item) =>
            (item.version === +ver && item.subVersion === +subVer) ? version = item : null)

        const redisplayVersion = () => {
            let a = displayVersion.split('.');
            return isFrozen ? 'frozen' : `${a[0] - 1}.${a[1]}`;
        }

        return (isFrozen ?
            <React.Fragment>
                <div className="scaffold"></div>
                <div styleName='buttonBlock' 
                    ref={e => this.refWrapper = e}>
                    <div styleName='versionBlock'>                        
                        <div styleName='versionButtons'>
                            <div styleName='version-cont frozen'>
                                <span>Exported for Sending via Email and Frozen</span>
                                <div styleName='version-cont_time'>
                                    {getDateString(version.createdAt, 4, dateData)}</div>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div styleName='version-button bold'
                     onClick={() => freezeFunc(true)}
                >Import as New Version
                </div> 
                <div styleName='version-button'
                     onClick={() => freezeFunc(false)}
                >Unfreeze without Import
                </div>
            </React.Fragment>
            :
            justImported ?
            <React.Fragment>
                <div className="scaffold"></div>
                <div styleName='buttonBlock' 
                    ref={e => this.refWrapper = e}>
                    <div styleName='versionBlock'>                        
                        <div styleName='versionButtons'>
                            <div styleName='version-cont just-imported'>
                                <span>Preview of Imported Version.  You cannot edit yet</span>
                                <div styleName='version-cont_time'>
                                Choose Continue or other options</div>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div styleName='version-button bold'
                     onClick={() => freezeFunc(true, true)}
                >Continue with This Version
                </div> 
                {tryPopup && <div styleName='screen' 
                   onClick={() => this.setState({tryPopup: false})} />}
                <div styleName='version-button'
                     onClick={() => this.setState({tryPopup: !tryPopup})} >
                    Try New or Return
                    {tryPopup &&  <div styleName='try-popup'
                      onClick={() => this.setState({tryPopup: false})} >
                        <div>Try New or Return</div>
                        <div className='space20'></div>
                        <div onClick={() => {
                            this.setState({tryPopup: false})
                            freezeFunc(true, true)
                        }}>                        
                        Try New Version
                        </div>
                        <div className='space20'></div>
                        <div onClick={() => {
                            this.setState({tryPopup: false})
                            freezeFunc(false, true)
                        }}>
                        Return to Previous Version
                        </div>
                    </div>}
                </div>  
                <span className={`arrow ${tryPopup ? 'arrowOpen' : ``}`}
                  onClick={() => this.setState({tryPopup: !tryPopup})}
                  style={{left:'-30px', top:'2px'}}
                >&nbsp;</span>
            </React.Fragment>
            :
            <React.Fragment>
                <div className="scaffold"></div>
                <div styleName='buttonBlock'
                     ref={e => this.refWrapper = e}>
                    <div style={isScrollNeed ? null : {width: '558px'}}
                         styleName={cx('versionBlock', {'versionBlock_open': isScrolled || !isScrollNeed})}>

                        
                        <div styleName='versionButtons'>
                            <div className="scaffold"></div>
                            <div styleName='version-cont'>
                                <span>{`${transS('Showing Version')} ${redisplayVersion()}`}</span>
                                <div styleName='version-cont_time'>
                                    {getDateString(version.createdAt, 4, dateData)}</div>
                            </div>
                            <div styleName='version-button bold'
                                 onClick={() => getHistoryDocumentAction(actualDocument._id, actualDocument.coreDocument, actualDocument.blocked, '')}
                            >{transS('Current Version')}
                            </div> 
                            <div styleName='version-button'
                                 onClick={tasksClickHandler}
                            >{transS('History & Versions')}
                            </div>
                            {!!isExportAllowed &&
                            isWaitDownload ?
                                <div styleName='expDocSpinner'><Spinner withoutLogo={true}/>{transS('Exporting')}...</div>
                                :
                                <div styleName='version-button' onClick={this.download}>Export to .docx</div>}
                        </div>
                        {isScrollNeed && <div styleName='versionButtons-grad' />}
                        {isScrollNeed &&                           
                       <div styleName={cx('arrow-right', {'arrow-left': isScrolled})}
                   onClick={() => this.setState({isScrolled: !isScrolled})}>
                         <span></span>
                       </div>}
                    </div>
                </div>

                {!isFrozen && user.userGroup === version.editorsGroup && <div style={{marginLeft: '40px'}}>
                    <Approvals approverUserList={approverUserList} approved={approved} isExternal={isExternal} displayVersion={displayVersion}
                               actualDocument={actualDocument} approveBtnCallback={() => null} isProjectAdmin={isProjectAdmin} />
                </div>}



                <Changes setStateMenu={arg => setMenuAction(arg)} jumpChanges={jumpChanges} stateMenu={stateMenu} frameProgress={frameProgress}/>


            </React.Fragment>
        )
    }
}

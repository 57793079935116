import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './button.css';

const Hint = props => {
  let { text } = props;

  return (
   
        <div styleName="hint">
         {text}
        </div>
        
  );
};

export default new CSSModules(Hint, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });

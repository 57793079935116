import React from 'react'
import CSSModules  from 'react-css-modules'
import styles from './SelectedRecipientsPopup.css'
import cx from 'classnames'
import Button from "../button/Button"
import {isEmail} from "../../../common/validation"
import SmallUser from "../../views/privateArea/components/projectPage/SmallUser"
import UserLogo from "../userLogo/UserLogo"
import Tip from "../tip/Tip"
import { transS } from '../../services/helpers/lang'

@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
class SelectedRecipientsPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            isShowAddBtn: false,
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const candidates = this.getCandidates()
        if (candidates.length === 1 && this.state.inputValue && !this.state.isShowAddBtn)
            this.setState({isShowAddBtn: true})
        else if (candidates.length !== 1 && this.state.isShowAddBtn)
            this.setState({isShowAddBtn: false})
    }

    inputOnChangeHandler = e => {
        this.setState({inputValue: e.target.value})
    }

    inputKeyDownHandler = e => {
        if (e.key === 'Enter') this.addRecipient()
    }

    handleClickOutside = e => {
        let check = e.target
        while (check && check !== document.body) check = check.parentElement
        if (!check) return

        if (this.refWrapper.parentElement && e.target !== this.refWrapper.parentElement && !this.refWrapper.parentElement.contains(e.target))
            this.props.hidePopup()
    }

    addRecipient = () => {
        this.props.selectRecipientCallback(1, this.getCandidates()[0]._id)
    }

    clearInput = () => this.setState({inputValue: '', isShowAddBtn: false})

    getCandidates = () => {
        const {inputValue} = this.state
        const {candidates, selectedRecipients} = this.props

        const isMatchUser = (user, str) => {
            const email = user.email.toLowerCase()
            const firstname = user.firstname && user.firstname.toLowerCase()
            const lastname = user.lastname && user.lastname.toLowerCase()
            str = str.toLowerCase()
            return email.includes(str) || firstname.includes(str) || lastname.includes(str)
        }
        return candidates
            .filter(el => !selectedRecipients.find(sr => el._id === sr._id))
            .filter(el => isMatchUser(el, inputValue))
    }

    makeUserList = (selected) => {
        const {selectRecipientCallback} = this.props
        const candidates = selected ? this.props.selectedRecipients : this.getCandidates()
        return candidates.map(el => <div styleName={cx('candidate', {'_sel': selected})} key={el._id + `${selected ? 'sel' : 'ccl'}`}>
                <div styleName='left'>
                    <UserLogo firstname={el.firstname} lastname={el.lastname} email={el.email} avatar={el.avatar} mod='_small' />
                    <div styleName='nameBlock' onClick={() => selected ? null : selectRecipientCallback(1, el._id)}>
                        {(el.firstname + el.lastname) && <div>{el.firstname} {el.lastname}</div>}
                        <div>{el.email}</div>
                    </div>
                </div>
                <div styleName='right'>
                    {selected ? <div className='close-cross' styleName='cross' onClick={() => selectRecipientCallback(0, el._id)}>+</div>
                        :
                        <Button text={transS('Add')} mod='blue margin0' callback={() => selectRecipientCallback(1, el._id)} />}
                </div>
        </div>)
    }

    render() {
        const {inputValue, isShowAddBtn} = this.state
        const {isShowSelRecipientsPopup, selectedRecipients, candidates, hidePopup} = this.props

        return (
            <div styleName={cx('wrapper', {'_vis': isShowSelRecipientsPopup})} ref={e => this.refWrapper = e}>
                <div styleName='input'>
                    <input type="text" placeholder={transS('Type name or email')} value={inputValue} onChange={this.inputOnChangeHandler} onKeyDown={this.inputKeyDownHandler} />
                    {isShowAddBtn && <Button mod='blue margin0' text={transS('Add')} callback={this.addMember} />}
                    {inputValue && <div data-close='1' className='close-cross' onClick={this.clearInput}>+</div>}
                </div>
                {selectedRecipients && !!selectedRecipients.length && <div styleName='selectedRecipients'>
                    <div className='h4' style={{marginBottom: '20px'}}>
                    {transS('Personal Message')}</div>
                    {this.makeUserList(1)}
                    <Button text={transS('Done')} mod='blue fill margin0' callback={hidePopup} />
                </div>}
                {!!this.getCandidates().length && <div styleName='candidates'>
                    <div className='h4' style={{marginBottom: '20px'}}>{transS('Select a person')}</div>
                    {this.makeUserList()}
                </div>}
            </div>
        )
    }
}

export default SelectedRecipientsPopup
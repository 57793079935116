import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import CSSModules from 'react-css-modules'
import styles from './DocumentListItemDropDown.css';
import { popUpReset, popUpWithInputReset } from '../../../../services/helpers';
import { getPopUpAction, getpopUpWithInputAction } from '../../../../services/actions/dataActions';
import { renameDocumentAction, deleteDocumentAction, folderAction } from '../../../../services/actions/documentActions';
import {downloader} from '../../../../services/helpers/download';
import UniversalListMenuDropDown from './UniversalListMenuDropDown';
import { transS } from '../../../../services/helpers/lang';

const DocumentListMenuDropDown = params => {
  const {item, top, left, close} = params;
  const dispatch = useDispatch();
  const [downloadParams, setDownloadParams] = useState(null);
  const isFolder = item?.contentType === 'folder'
  
  const openChangeNamePopup = (isNewFolder) => {
    close();
    const isRenameFolder = item.contentType === 'folder';
    const popup = {
      name: 'rename',
      text: !isNewFolder ? transS(isRenameFolder ? 'Folder name' : 'Document name') 
        : transS('New Folder'),
      inputText: isNewFolder ? '' :
        item.contentType && !isRenameFolder
        ? item.title.replace(/\.[^ .]+$/,'')
        : item.title,
      projectId: item.coreDocument || item._id, // actually document id
      fieldName: 'documentName',
      placeholder: transS(isNewFolder || isRenameFolder 
        ? 'Folder name' : 'Document name'),
      confirm: {
        name: transS(isNewFolder ? 'Create Folder': 'Rename'),
        event: (id, doc) => {
          return () => {
            let newName = doc.value;
            if (!isNewFolder && item.contentType && !isRenameFolder)
                newName += '.' + item.title.split('.').pop();
            if (doc.invalid) return;
            if (!isNewFolder)
              dispatch(renameDocumentAction(id, newName));
            else dispatch(folderAction(id, newName))
          };
        }
      },
      cancel: {
        name: transS('Cancel'),
        event: () => { dispatch(getpopUpWithInputAction(popUpWithInputReset)); }
      }
    };
    dispatch(getpopUpWithInputAction({ popup }));
  };
  
  const deletePopup = {
      name: 'confirm',
      text: transS(isFolder ? 'Do you want to remove the folder?' 
        : 'Do you want to remove the document?')
        .replace('?', item?.title ? ` (${item.title})?` : '?'),
      confirm: {
        name: transS('Delete'),
        danger: true,
        event: () => dispatch(deleteDocumentAction(item.coreDocument || item._id, item.projectId))
      },
      cancel: {
        name: transS('Cancel'),
        event: () => { dispatch(getPopUpAction(popUpReset)); }
      }
    };
  
  const download = () => { 
     if (downloadParams) return;
     setDownloadParams(true);
     downloader({format: 'docx', actualDocument: item, 
       callback: ()=> {setDownloadParams(null); close();} });
  };
  
   
  return !!item && ( 
    <UniversalListMenuDropDown
        item={item}
        top={top} 
        left={left} 
        close={close}
        menuItems = {[
         {
          hidden: isFolder || item.orig === 302, 
          name: downloadParams ? "Loading.." : transS('Download'),
          click: () => download() },
         {
          name: transS(isFolder ? 'Rename Folder' : 'Rename Document'),
          click: () => openChangeNamePopup() },
         {
          name: transS(isFolder ? 'Delete Folder' : 'Delete Document'),
          style: 'delete',
          click: () => { dispatch(getPopUpAction(deletePopup)); close(); } },
         {
          hidden: isFolder || item?.parent, 
          name: (<span><img src="/images/icons-folder.png" width="13"  height="13" />{transS('New Folder')}</span>),
          divided: true,
          click: () => openChangeNamePopup(true) },          
        ]}
    /> )
};
export default CSSModules(DocumentListMenuDropDown, styles, {allowMultiple: true});

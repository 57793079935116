import React from 'react';
import { colors, fontSize } from '../../../../components/colors';
import { signingTool, transS } from '../../../../services/helpers/lang';
import {Avatar} from './Avatar';
import { useSelector } from 'react-redux';
import { Icon_CheckBlank, Icon_Checked, Icon_x } from '../../../../components/svglib/svglib';
import { rights } from '../../../../../configs/appConfig';
import { StdToolTip } from '../../../../components/StdToolTip';

export const TeamMember = ({luserId, callback, right, directRight, 
  external, editable, removable}) => {    
  const {knownUsers, companyTree} = useSelector(state => state.unitReducer),
    user = knownUsers.get(luserId) || companyTree && 
      companyTree.find(t => luserId === t.loc_id),
    isUser = user?.userid,
    userName = isUser ? user.firstname + ' ' + user.lastname
      : user?.team_name,
    removableRefined = removable && right === directRight && !(right & rights.CREATOR)

  const styleCont = {
    position: 'relative',
    width: 320,
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: fontSize.standart,
    color: colors.darkGray,
  }, styleName = {
    paddingLeft: 15, // remains 280
    width: 280 - (editable ? 120 : removableRefined ? 15 : 0),
    overflow: 'hidden',
    position: 'relative',    
  }, styleUser = {
    fontSize: fontSize.readable,
    fontWeight: 'bold',
  }, styleMail = {
    color: colors.gray,
  }


  return !user ? false : <div style={styleCont}>
    <Avatar userId={luserId} external={external} />
    <div style={styleName}>
      {!!userName && 
      <div className='dev-fade-text' style={styleUser}>
        {userName}</div> }
      {!!user.email &&
      <div style={styleMail} className='dev-fade-text'>
        {user.email}</div> }
    </div>
    {!!editable && <DrawRights 
      right={right}
      directRight={directRight} 
      callback={(r) => callback(external, 'right', luserId, r)}
    />}
    {!!removableRefined &&
      <span style={{cursor: 'pointer',
        position: 'absolute', right: 0, top: 0
        }} onClick={() => callback(luserId, 'remove', luserId)}>
        <Icon_x />
      </span>
    }
  </div>
}

const DrawRights = ({right, directRight, callback}) => {
  const options = [
    {right: rights.ADMIN_UNIT | rights.CREATOR, 
      text: transS('Admin'),
      tip: transS('Project Admin can add and delete users and send the document without approvals.')
    },
    {right: rights.APPROVE, 
      text: transS('Approver'),
      tip: transS('Approval required for sending the document to the next team')
    },
    {right: rights.SIGN,
      text: transS('Signee'),
      tip: transS('After the document is finalised, a signee would need to sign the document. This requires logging in your DocuSign account.')
    },
  ], canSign = !!signingTool(),
  styleControls = {
    width: 120,
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  }

  const funcString = o => {
    if (!canSign && o.right === rights.SIGN) return false
    const current = o.right,
      uneditable = (current & rights.CREATOR) && (directRight & rights.CREATOR) 
        || (right & current),
      checked = (right || directRight) & current,
      styled = {display: 'flex', gap: 5},
      tipStyle = {width: 200, whiteSpace: 'normal'}

    return <span onClick={() => { const r = current & rights.TEAM_MASK
      if (uneditable) return
      callback(directRight & r ? -r : r) } }
      key={current} style={styled}> 
      <StdToolTip content={o.tip} arrowCenter="1"
        style={tipStyle}>
        {checked ? <Icon_Checked color={uneditable ? colors.gray : colors.primary}
          /> : <Icon_CheckBlank />}
      </StdToolTip>
      <span>{o.text}</span>
    </span>}
  
  return <div style={styleControls}>
    {options.map(o => funcString(o))}
  </div>
}
import React from 'react';
import { colors, fontSize } from "../colors"

const common = {
  display: 'flex',
  gap: '10px',
  cursor: 'pointer',
  position: 'relative',
}

export const ButtonLarge = (props) => {
  const styled = {
    width: "fit-content",
    alignItems: 'unset',
    whiteSpace: 'nowrap',
    padding: '10px 20px',
    fontSize: fontSize.readable,
    backgroundColor: colors.primary,
    borderRadius: '5px',
    color: colors.white,
    border: `solid 1px ${colors.primary}`,
  }  
  return <Button {...props} styled={styled} />
}

export const ButtonStd = props => {
  const styled = {
    padding: '2px 10px',
    fontSize: fontSize.standart,
    backgroundColor: colors.primary,
    borderRadius: '5px',
    color: colors.white,
    border: `solid 1px ${colors.primary}`,
  }
  return <Button {...props} styled={styled} />
}

const Button = ({styled, style, Picture, text, action, disabled}) => {
  const applied = {...common, ...styled, ...style}
  if (disabled) {
    applied.opacity = 0.3
    action = null
    applied.cursor='initial'
  }
  return <div style={applied} onClick={(e) => !!action && action(e)}>
    {!!Picture && <Picture />}
    {text}
  </div>
}
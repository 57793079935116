import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import PropTypes from 'prop-types';
import styles from './addUserByEmail.css';

const InputBox  = props => {
  let {placeholder, func, value, error} = props;
  return (
      <div styleName='input-area' style={{ width: props.inputWidth, display: props.display,
              margin: props.margin}}>
         {!!error && <div styleName='error-message'>{error}</div> }
         <input placeholder={placeholder} styleName='input' onChange={(e) => func(e)} type="text" value={value} />
      </div>
  );
};

InputBox.propTypes = {
  placeholder: PropTypes.string,
  func: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.string
};

export default new CSSModules(InputBox, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });


import React from 'react'
import styles from './helpPopup.css'
import CSSModules from 'react-css-modules'
import cx from 'classnames'
import UserManual from './UserManual'
import {Link, NavLink} from 'react-router-dom'
import {chromeCheck} from "../../../../components/browserSupport/browsersCheck";
import { transS } from '../../../../services/helpers/lang'

@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
export default class HelpMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            briefTutorialSelected: 1,
        }
    }

    componentDidMount() {
        document.body.style.overflow = 'hidden'
        window.addEventListener('click', this.clickOutsideHandler)
    }

    componentWillUnmount() {
        document.body.style.overflow = ''
        window.removeEventListener('click', this.clickOutsideHandler)
    }

    clickOutsideHandler = e => {
        if (this.refScreen && e.target === this.refScreen) {
            this.props.popupOpenHandler(false)
        }
    }

    briefTutorialSelectedChangeHandler = (e) => {
        if (!e.target.dataset.btn && !e.target.parentElement.dataset.btn && !e.target.parentElement.parentElement.dataset.btn) return

        const val = +e.target.dataset.btn || +e.target.parentElement.dataset.btn || +e.target.parentElement.parentElement.dataset.btn

        let leftCoords
        if (val === 1) {
            leftCoords = 0
        } else if (val === 2) {
            leftCoords = 660
        } else if (val === this.refSlider.children.length) {
            leftCoords = val * 840
        } else {
            leftCoords = (val - 2) * 840 + 660
        }
        this.refSlider.scrollTo({left: leftCoords, top: 0, behavior: 'smooth'})
        this.setState({briefTutorialSelected: val})
    }

    render() {
        const {popupOpen, popupOpenHandler} = this.props
        const {briefTutorialSelected} = this.state

        const sliderContent = () => {
            return new Array(15)
                .fill('')
                .map((_, i) => `/images/helpSlider_${i + 1}${chromeCheck() ? '_chrome' : ''}.png`)
                .map((url, i) => {
                    return (
                        <div key={url} styleName={cx('slider_item', {'_active': briefTutorialSelected === i + 1})} data-btn={i + 1}>
                            <div styleName='slider_item_image'>
                                <img src={url} alt={"screen" + i + 1} />
                            </div>
                            <div styleName='slider_item_text'>
                                {i + 1}
                            </div>
                        </div>
                    )
                })
        }

        return (
            <React.Fragment>
                <div styleName='screen'/>
                <div styleName='scroll' ref={e => this.refScreen = e}>
                    <div styleName={cx('wrapper', {'_CHROME_correction': chromeCheck()})}>
                        <div className='h1' style={{marginBottom: '44px', marginLeft: '40px'}}>{transS('Help')}</div>
                        <div className='close-cross' onClick={() => popupOpenHandler(false)}>+</div>

                        <div styleName='tabs'>
                            <div styleName={cx('tabs_tab', {'_sel': popupOpen === 1})}
                                 onClick={() => popupOpenHandler(1)}
                            >{transS('Brief Tutorial')}
                            </div>
                            <div styleName={cx('tabs_tab', {'_sel': popupOpen === 2})}
                                 onClick={() => popupOpenHandler(2)}
                            >{transS('5 min Video Overview')}
                            </div>
                            {/*<div styleName={cx('tabs_tab', {'_sel': popupOpen === 3})}*/}
                            {/*     onClick={() => popupOpenHandler(3)}*/}
                            {/*>Detailed FAQ*/}
                            {/*</div>*/}
                            {/*<div styleName={'tabs_tab tg'}*/}
                            {/*     onClick={() => popupOpenHandler(3)}*/}
                            {/*>Telegram Support */}
                            {/*</div>*/}
                            <div styleName={'tabs_tab tg'}>
                                <a href="mailto:support@contract.one">{transS('Email Support')} <img src={'/images/icon-link.svg'} alt='telegram icon' /></a>
                            </div>
                        </div>

                        {popupOpen === 1 && <div>
                            <div styleName='controls' onClick={this.briefTutorialSelectedChangeHandler}>
                                <button data-btn='1' styleName={cx({'_active': briefTutorialSelected === 1})}>{transS('Home page')}</button>
                                <button data-btn='2' styleName={cx({'_active': briefTutorialSelected === 2})}>{transS('Project Setup')}</button>
                                <button data-btn='3' styleName={cx({'_active': briefTutorialSelected === 3})}>{transS('Document View')}</button>
                                <button data-btn='4' styleName={cx({'_active': briefTutorialSelected >= 4 && briefTutorialSelected <= 8})}>{transS('Toolbar')}</button>
                                <button data-btn='9' styleName={cx({'_active': briefTutorialSelected >= 9 && briefTutorialSelected <= 11})}>{transS('Editing')}</button>
                                <button data-btn='12' styleName={cx({'_active': briefTutorialSelected >= 12 && briefTutorialSelected <= 15})}>{transS('Communication')}</button>
                            </div>

                            <div styleName='slider' ref={e => this.refSlider = e} onClick={this.briefTutorialSelectedChangeHandler}>
                                {sliderContent()}
                            </div>
                        </div>}

                        {popupOpen === 2 && <div styleName='video'>
                            <iframe width="1160" height="580px" src="https://www.youtube.com/embed/YD2UuDFm_Kc"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen />
                        </div>}

                        {/*{popupOpen === 3 && <UserManual />}*/}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

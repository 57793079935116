import React from 'react';
import cx from 'classnames';
import CSSModules from 'react-css-modules';
import styles from './DocumentListItemDropDown.css';

const DocumentListItemDropDown = props => {
  let {callback} = props;
  return  ( 
    <div styleName="wrapper">
      <button styleName='button' onClick={(e) => callback(e)} >
         <img src="/images/mouse.png" width="13" /> 
      </button> 
    </div>
);};

export default new CSSModules(DocumentListItemDropDown, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });

import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './progressAndTasks.css';
import ScrolledPart from './ScrolledPart';

const ProgressAndTasks = props => {
    const { dragHistory, dragTasks, jumpDoc, versionToggleBar,
         freezeFunc,prohibitNewVersion, toggleBar, download } = props;

    return dragHistory ? (
      <div styleName='wrapper'>
        <style>{`.at {font-weight:bold}`}</style>
        <div styleName='mainfield'>        
            <ScrolledPart isProgress={true}
                          download={download}
              toggleBar={toggleBar}
              dragHistory={dragHistory}
              jumpDoc={jumpDoc}
              freezeFunc={freezeFunc}
              prohibitNewVersion={prohibitNewVersion}
             />
            <div styleName='vertRule' />
            <ScrolledPart isProgress={false} 
              dragTasks={dragTasks}
              jumpDoc={jumpDoc}
            />
            <div
              onClick={() => versionToggleBar(false)}
              styleName='close-button'>
            </div>
        </div>
      </div>
    ) : false;
}
export default new CSSModules(ProgressAndTasks, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });

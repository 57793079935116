import React, { Component } from 'react';
import cx from 'classnames';
import CSSModules from 'react-css-modules';
import styles from './AddMembers.css';
import Button from "../../../../components/button/Button"
import {isEmail} from "../../../../../common/validation"
import {isEqual} from "lodash"
import { transS } from '../../../../services/helpers/lang';

@CSSModules(styles, {
    allowMultiple: true,
    handleNotFoundStyleName: 'throw' })
export default class AddMembers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            isShowAddBtn: false,
            candidates: [],
            alignTop: 0,

            isHiddenListPart: false,
            isShowAll: false,
        }
    }

    componentDidMount() {
        this._setCandidates()
        window.addEventListener('click', this.clickOutsideHandler)
        document.body.style.overflowY = 'hidden'
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.clickOutsideHandler)
        document.body.style.overflowY = ''
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this._setCandidates()

        if (this.refPopup && !this.state.alignTop) {
            const alignTop = (document.documentElement.clientHeight - this.refPopup.offsetHeight) / 2
            this.setState({alignTop: alignTop <= 40 ? 40 : alignTop})
        }

        if ( (!isEmail(this.state.inputValue) || this.state.candidates.length === 1 && this.state.inputValue) && !this.state.isShowAddBtn)
            this.setState({isShowAddBtn: true})
        else if ( !(!isEmail(this.state.inputValue) || this.state.candidates.length === 1) && this.state.isShowAddBtn)
            this.setState({isShowAddBtn: false})
    }

    _setCandidates = () => {
        const {number, func, flow} = this.props
        const {inputValue, isShowAll} = this.state

        let isInternal = flow.internals & (1 << number)
        let candidates = func(number, 'getCandidates', { list: true, isInternal, filter: inputValue.toLowerCase() })
        candidates = candidates.props ? candidates.props.children : ''

        const freeSpaceForUserList = document.documentElement.clientHeight - 275
        const usersCount = Math.floor(freeSpaceForUserList / 45)
        const isHiddenListPart = candidates.length > usersCount

        candidates = (isHiddenListPart && !isShowAll) ? candidates.slice(0, usersCount) : candidates

        if (!isEqual(candidates, this.state.candidates)) {
            this.setState({candidates, isHiddenListPart})
        }
    }

    inputChangeHandler = e => {
        this.setState({inputValue: e.target.value})
    }

    clearInput = () => this.setState({inputValue: '', isShowAddBtn: false})


    addMember = () => {
        if (!isEmail(this.state.inputValue)) {
            this.props.func(this.props.number, 'addMember', {mail: this.state.inputValue.toLowerCase()})
        } else if (this.state.candidates.length === 1) {
            this.props.func(this.props.number, 'addMember', this.state.candidates[0].props.user)
        }

        this.setState({inputValue: '', isShowAddBtn: false})
    }

    closeClickHandler = () => {
        const {func, number, isNewProject} = this.props

        func(number, 'closeAddMembers', {isNewProject})
    }

    clickOutsideHandler = e => {
        const {func, number, isNewProject} = this.props

        if (this.refPopup && e.target !== this.refPopup && !this.refPopup.contains(e.target) && e.target.type !== 'button' && !e.target.dataset.close) {
            func(number, 'closeAddMembers', {isNewProject})
        }
    }

    inputKeyDownHandler = e => {
        if (e.key === 'Enter') this.addMember()
    }

    render() {
        const {number, func, justAddedUsers} = this.props
        const {inputValue, isShowAddBtn, candidates, alignTop, isHiddenListPart, isShowAll} = this.state

        return (
            <div styleName="wrapper">
                <div styleName='popup-wrapper' ref={e => this.refPopup = e} style={{top: alignTop}}>
                    <div styleName='popup'>
                        {/*<div className='close-cross' onClick={this.closeClickHandler} style={{top: '15px', right: '20px'}}>+</div>*/}
                        <div styleName='input'>
                            <input type="text" placeholder={candidates.length ? 
                              transS('Search emails, names, or groups') 
                              : transS('Type Email')} value={inputValue}
                                   onChange={this.inputChangeHandler} onKeyDown={this.inputKeyDownHandler} />
                            {isShowAddBtn && <Button mod='blue margin0' text={transS('Add')} callback={this.addMember} />}
                            {inputValue && <div data-close='1' className='close-cross' onClick={this.clearInput}>+</div>}
                        </div>
                        {justAddedUsers && <div styleName={cx('justAdded', {'ext': number})}>
                            <div className='h4' style={{marginBottom: '20px'}}>{transS('Just Added')}</div>
                            {justAddedUsers}
                            <Button text={transS('Done')} mod='blue fill margin0' style={{marginTop: '15px'}} callback={this.closeClickHandler} />
                        </div>}
                        {!!candidates.length && <div styleName='candidates'>
                            <div className='h4' style={{marginBottom: '20px'}}>{transS('Select a person')}</div>
                            {candidates}
                            {isHiddenListPart &&
                                        <Button text={isShowAll ? transS('Show Less') : transS('Show More')} mod='blue'
                                                callback={() => this.setState({isShowAll: !isShowAll})} />}
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
}
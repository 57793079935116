import React from 'react'
import styles from './projectPage.css';
import cx from 'classnames';
import CSSModules from "react-css-modules";
import Button from "../../../../components/button/Button";

class InviteUserOptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAllInviteOptions: false,
        }
    }

    AllInviteOptionsClickHandler = () => {
        this.setState({showAllInviteOptions: true})
    }
    AllInviteOptionsCloseHandler = () => {
        this.setState({showAllInviteOptions: false});
        this.props.func(1, 'userShowInviteOptions', null)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.vis !== this.props.vis) {
            this.setState({showAllInviteOptions: false});
        }
    }

    render() {
        let {user, number, prove, rep, func} = this.props
        let {showAllInviteOptions} = this.state

        if (showAllInviteOptions) return <AllInviteOptions close={this.AllInviteOptionsCloseHandler}/>

        return (
            <div styleName='IUO_wrapper'>
                <div styleName='IUO_close' onClick={() => func(1, 'userShowInviteOptions', null)}>+</div>
                <div styleName='IUO_perm'>

                    <div styleName='IUO_perm-item' onClick={() => func(number, 'proveUser', user)}>
                        <img src={prove ? "../../../../images/approvalTrue.svg" : "../../../../images/approvalFalse.svg"}/>
                        <div styleName='IUO_perm-item-text'>Approval is {!prove && 'not'} required</div>
                    </div>

                    <div styleName='IUO_perm-item' onClick={() => func(number, 'repUser', user)}>
                        <img src={rep ? "../../../../images/representativeTrue.svg" : "../../../../images/representativeFalse.svg"}/>
                        <div styleName='IUO_perm-item-text'>
                            <span>Is {!rep && 'not'} a representative</span>
                            <div styleName='IUO_perm-item-text-small'>Can take part in external discussions</div>
                        </div>
                    </div>

                </div>
                <div styleName='IUO_invitations'>
                    <h3 className='h3' style={{marginTop: 0}}>Invitations to the Project</h3>
                    <div styleName='IUO_inv-timeJoin'>You invited to join the project via email on 26 Aug, 20:12</div>
                    <div styleName='IUO_inv-timeAccept'>Jackie Anna Clarkson accepted the invitation via email on 26
                        Aug,
                        20:12
                    </div>
                    <div>
                        <div styleName="IUO_send">Send Email Invitation Again</div>
                        <div styleName="IUO_send-desc">The user will get another invitation email.</div>
                    </div>
                    <Button
                        text={'All Invite Options'}
                        mod='blue arch' callback={this.AllInviteOptionsClickHandler}/>
                </div>
            </div>
        )
    }
}

let AllInviteOptions = props => {
    let {close} = props

    return (
        <div styleName='IUO_wrapper IUO_AllInvOpt'>
            <div styleName='IUO_close' onClick={close}>+</div>
            <h3 style={{marginTop: '0'}} className='h3'>All Invite Options</h3>
            <div styleName='IUO_options-item'>
                <div styleName="IUO_send">Send Email Invitation Again</div>
                <div styleName="IUO_send-desc">The user will get another invitation email.</div>
            </div>
            <div styleName='IUO_options-item'>
                <div styleName="IUO_send">Send Invitation to Jackie Anna Clarkson Now</div>
                <div styleName="IUO_send-desc">The user will get an invitation now. Usually they are invited
                    when the document is sent to the team.
                </div>
            </div>
            <div styleName='IUO_options-item'>
                <div styleName="IUO_send">Send Email Invitation to Acme Construction, Inc. Now</div>
                <div styleName="IUO_send-desc">The team will get an invitation now. Usually they are
                    invited when the document is sent to the team.
                </div>
            </div>
            <div styleName='IUO_options-item'>
                <div styleName="IUO_send">Create Invitation Link</div>
                <div styleName="IUO_send-desc">If a standard invite email doesn’t seem to get through, you
                    can create a link and send it. The user will have access to
                    the project, but their identity will not be confirmed.
                </div>
                <div styleName="IUO_createLink">
                    <div styleName="IUO_link">https://secure.contract.one/invite/Lkj62s6dfP7J8asdfnn</div>
                    <div styleName="IUO_link-valid">Valid for 30 minutes</div>
                    <Button
                        text={'Copy Link'}
                        mod='blue arch' callback={() => null}/>
                </div>
            </div>
            <div styleName='IUO_options-item'>
                <div styleName="IUO_send">Create From-Email Validation Code</div>
                <div styleName="IUO_send-desc">Ask the user to send an email from their address (jackie-
                    anna.clarkson@c1.com) to validate@contract.one with a
                    code. You can use this message:
                </div>
                <div styleName="IUO_createLink">
                    <div styleName="IUO_link">To validate your account on
                        Contract.one, please send an email
                        from your address (jackie-
                        anna.clarkson@c1.com) to
                        <span> validate@contract.one </span>
                        with a code anywhere in the email
                        <span> C1-VALIDATE-JX2F-5M99-7YO0 </span>
                    </div>
                    <div styleName="IUO_link-valid">The code is valid for 24 hours</div>
                    <Button
                        text={'Copy Message'}
                        mod='blue arch' callback={() => null}/>
                </div>
            </div>
        </div>
    )
}
AllInviteOptions = new CSSModules(AllInviteOptions, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});

export default new CSSModules(InviteUserOptions, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'});
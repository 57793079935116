import { rights, socketConstants } from "../../../configs/appConfig";
import { resetTeamMembers } from "../../views/privateArea/views/unitList/unitsFuncs";
import store from "../store";

export const changeUnit = (data) => {
    const {loc_id, title} = data,
      unit = store.getState().unitReducer.unitsList.get(loc_id)
    if (unit) {
      unit.title = title
      if (unit.refresh)
        unit.refresh()
      if (unit.pRefresh)
        unit.pRefresh()
      // send info to other reducers
      store.dispatch({ type: socketConstants.S_UNIT_CHANGE, data})
    }
  }

export const deleteUnit = data => {
  const {parent, ids} = data,
    red = store.getState().unitReducer,
    unitsList = red.unitsList,
    unitsTree = red.unitsTree,
    unitsStruc = red.unitsStruc,
    pinnedFolder = unitsList.get('_pinned'),
    deletedUnit = unitsList.get(parent),
    ancestor = deletedUnit.ancestor && unitsList.get(deletedUnit.ancestor)
  // remove visuals, remove from structures, don't forget descendants

  let pinnedRemove = false
  ids.forEach(id => {
    const index = unitsTree.findIndex(u => u.loc_id === id)
    if (index !== -1)
      unitsTree.splice(index,1)
    unitsList.delete(id)
    unitsStruc.openFolders.delete(id)
    const pinIndex = pinnedFolder.folders.findIndex(el => el === id)
    if (pinIndex !== -1) {
      pinnedFolder.folders.splice(index,1)
      pinnedRemove = true
      hideElement('p'+id)
    }
    hideElement(id)
  })
  if (deletedUnit.trefresh)
    deletedUnit.trefresh()
  if (ancestor) {
    ancestor.folders = ancestor.folders.filter(el => el !== parent)
    if (ancestor.refresh)
      ancestor.refresh()
  }
  if (pinnedRemove) {
    if (pinnedFolder.trefresh)
      pinnedFolder.trefresh()
    if (pinnedFolder.refresh)
      pinnedFolder.refresh()
  }
  // send info to other reducers
  store.dispatch({type: socketConstants.S_UNIT_DELETED, data})
}

export const unitFromLocation = () => 
  store.getState().unitReducer.unitsList.get(
    location.pathname.split('/')[2]
  )

export const addUnit = data => {
  const {loc_id, created, userId, integrated, folder, 
    parentId, newTeam, externals} = data,
    red = store.getState().unitReducer,
    unitsList = red.unitsList,
    unitsTree = red.unitsTree,
    unitsStruc = red.unitsStruc,
    parentUnit = parentId ? unitsList.get(parentId) : null,
    level = parentId ? parentUnit.level + 1 : 0,    
    newUnit = {ancestor: parentId,
      loc_id,
      title: folder,
      ucreated: created,
      integrated,      
      folders: [],
      level
    }

    if (parentId) {
      const firstIndex = unitsTree.findIndex(el => !!el.ancestor)
      if (firstIndex === -1)
        unitsTree.push(newUnit)
      else 
        unitsTree.splice(firstIndex, 0, newUnit)
    } else
      unitsTree.splice(2, 0, newUnit) // 2 - after pinned and externals
    unitsList.set(loc_id, newUnit)

    if (newTeam) {
      newUnit.members = [userId]
  	  newUnit.rights = [rights.CREATOR + (parentId ? 0 : rights.INTERNAL)]
    }

    if (parentId) {
      parentUnit.folders.unshift(loc_id)
      expandUnit(parentId,'')
      if (parentUnit.refresh)
        parentUnit.refresh()
      if (parentUnit.trefresh)
        parentUnit.trefresh()      
    } else {
      if (unitsStruc.listCallback)
        unitsStruc.listCallback(list => {
          const newList = [...list]
          newList.splice(2, 0, newUnit)  // 2 - after pinned and externals
          return newList
        })
    }
    if (level > unitsStruc.maxLevel)
        store.dispatch({type: socketConstants.S_UNIT_LEVEL, level})
  }


const hideElement = id => {
  const el = document.querySelector(`[data-lockid="${id}"]`)
  if (el)
        el.style.display = 'none'
}

export const expandUnit = (unitId, pinned) => {
    const unitsList = store.getState().unitReducer.unitsList,
      openFolders = store.getState().unitReducer.unitsStruc.openFolders,
      p = pinned ? 'p' : ''
    const makeOpen = id => {
      const unit = unitsList.get(id)
      if (!unit.folders?.length) return
      openFolders.add(p+id)
      document.querySelector(`[data-lockid="${p+id}"]`).dataset.closed = ""
      
      unit.folders.forEach(el => makeOpen(el))
    }
    makeOpen(unitId)
}

export const pinUnit = unit => {
  const red = store.getState().unitReducer,
    pinnedFolder = red.unitsTree[0],
    pinIndex = pinnedFolder.folders.findIndex(el => el === unit.loc_id)
    
    
  if (pinIndex !== -1) 
    pinnedFolder.folders.splice(pinIndex,1)
  else 
    pinnedFolder.folders.push(unit.loc_id)
  if (pinnedFolder.trefresh)
    pinnedFolder.trefresh()
  if (unit.refresh)
        unit.refresh()
}
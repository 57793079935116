import React from 'react'
import CSSModules from 'react-css-modules'
import Button from '../../../../../../components/button/Button'
import cx from 'classnames'
import styles from './RequestBackMenu.css'
import {connect} from 'react-redux'
import Comment from '../../../../../../components/comment/Comment'
import api from '../../../../../../services/api/api'
import {isEqual} from 'lodash'
import { transL, transS } from '../../../../../../services/helpers/lang'

@connect(state => ({
    messages: state.projectReducer.currentProject.messages,
    actualDocument: state.document.actualDocument,
    user: state.userReducer.user,
    internalUserList: state.userReducer.internalUserList,
    externalUserList: state.userReducer.externalUserList,
}), {})
@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
export default class RequestBackMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isRequestPopupOpen: false,
            addNote: null, // eventName
            sendMessages: [],
        }
    }

    componentDidMount() {
        window.addEventListener('mousedown', this.handleClickOutside)

        const {actualDocument} = this.props
        if (this.props.messages) {
            const sendMessages = this.props.messages
                .filter(m => m.type === 3)
                .filter(m => !m.delete)
                .filter(m => !!m.freeze)
                .filter(m => m.document === actualDocument.coreDocument)
                .filter(m => m.when > Date.parse(actualDocument.createdAt))
            this.setState({sendMessages})
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {actualDocument} = this.props
        if (this.props.messages) {
            const sendMessages = this.props.messages
                .filter(m => m.type === 3)
                .filter(m => !m.delete)
                .filter(m => !!m.freeze)
                .filter(m => m.document === actualDocument.coreDocument)
                .filter(m => m.when > Date.parse(actualDocument.createdAt))
            if (!isEqual(this.state.sendMessages, sendMessages)) {
                this.setState({sendMessages})
            }
        } else if (this.state.sendMessages && this.state.sendMessages.length) {
            this.setState({sendMessages: []})
        }

    }
    componentWillUnmount() {
        window.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside = (e) => {
        if (this.refRequestBlock && this.refRequestBlock !== e.target && !this.refRequestBlock.contains(e.target)) {
            this.setState({isRequestPopupOpen: false})
        }
    }

    messagesFunc = (action, params) => {
        const {addNote} = this.state
        const {actualDocument, user} = this.props
        switch (action) {
            case 'addMessage':
                api.addMessage(3, actualDocument.projectId, params.content, actualDocument.coreDocument, [], user.userGroup, true, addNote)
                break
            case 'addReply':
                api.replyMessage(params.messageId, params.content, user.userGroup)
                break
            case 'editMessage':
            case 'editReply':
                api.editMessage(params.messageId, params.content, params.reply)
                break
            case 'deleteMessage':
            case 'deleteReply':
                api.deleteMessage(params.messageId, params.reply)
                break
            case 'resolveMessage':
                api.resolveMessage(params.messageId, params.value)
                break
            case 'unreadCheck':
                api.unreadCheckMessage(params.messageId, params.reply)
                break
            case 'changeTitle':
                api.changeTitle(params.messageId, params.title)
                break
        }
    }

    render() {
        const {isProjectAdmin, getPopUpAction, popup, user, externalUserList, internalUserList} = this.props
        const {isRequestPopupOpen, addNote, sendMessages} = this.state
        const addNoteBlocked = sendMessages.length
        const canSend = !addNote;

        return (
            <React.Fragment>
                {isRequestPopupOpen && <div styleName='screen' />}

                <div styleName='requestBlock' ref={e => this.refRequestBlock = e} style={ {top: '-1px'} }>
                    <Button text={transS('Request Back')} 
                      mod={`blue nowrap margin0 leftArrow${isRequestPopupOpen ? ' leftArrow_open' : ''}`}
                      callback={() => this.setState({isRequestPopupOpen: !isRequestPopupOpen})}/>

                    <div styleName={cx('requestPopup', {'requestPopup_vis': isRequestPopupOpen})}>
                        {addNote === 'REQUEST_BACK' ? <div styleName='sendMenu-note'>
                            <Comment isCoverMessage={true} isNewComment={true} isEdit={true} me={user} blurHandler={() => this.setState({addNote: null})} sendBtnText={transS('Save')}
                                     side={1} func={this.messagesFunc} externalUserList={externalUserList} internalUserList={internalUserList} />
                        </div> : ''}
                        {sendMessages
                            .filter(el => el.event === 'REQUEST_BACK')
                            .map(el => <div styleName='sendMenu-note' key={el._id + 'smnc'}>
                                <Comment  isCoverMessage={true} {...el} me={user} sendBtnText={transS('Save')} isProjectAdmin={isProjectAdmin}
                                          recipients={el.recipients.length ? el.recipients : null}
                                          isNoResolve={true} isNoSendNow={true} isEditCreator={true} isNoReply={true}
                                          externalUserList={externalUserList} internalUserList={internalUserList}
                                          func={this.messagesFunc} />
                            </div>)}

                        <Button text={transS('Request Back')} 
                          mod={canSend ? 'blue arch' : 'blue arch disabled'} 
                          style={{marginRight: '10px'}}
                          callback={canSend ? () => getPopUpAction(popup.request) : null}/>
                        {!addNote && !sendMessages.filter(el => el.event === 'REQUEST_BACK').length &&
                            <Button text={transS('Add Message')} mod={`blue margin0${addNoteBlocked ? ' disabled' : ''}`}
                                callback={() => addNoteBlocked ? null : this.setState({addNote: 'REQUEST_BACK'})} />}
                        <div styleName='requestPopup-desc'>
                            {transS('The other team will get a request from you. They will send the document when they are ready.')}
                        </div>

                        {isProjectAdmin && <React.Fragment>
                            <div className='space40' />

                            {addNote === 'TAKE_OVER' ? <div styleName='sendMenu-note'>
                                <Comment isCoverMessage={true} isNewComment={true} isEdit={true} me={user} blurHandler={() => this.setState({addNote: null})} sendBtnText={transS('Save')}
                                         side={1} func={this.messagesFunc} externalUserList={externalUserList} internalUserList={internalUserList} />
                            </div> : ''}
                            {sendMessages
                                .filter(el => el.event === 'TAKE_OVER')
                                .map(el => <div styleName='sendMenu-note' key={el._id + 'smnc1'}>
                                    <Comment isCoverMessage={true} {...el} me={user} sendBtnText={transS('Save')} isProjectAdmin={isProjectAdmin}
                                              recipients={el.recipients.length ? el.recipients : null}
                                              isNoResolve={true} isNoSendNow={true} isEditCreator={true} isNoReply={true}
                                              externalUserList={externalUserList} internalUserList={internalUserList}
                                              func={this.messagesFunc} />
                                </div>)}

                            <Button text={transS('Take Over Editing')}
                              mod={canSend ? 'red arch' :'red arch disabled'}
                              style={{marginRight: '10px'}}
                              callback={canSend ? () => getPopUpAction(popup.overtake) : null}/>

                            {!addNote && !sendMessages.filter(el => el.event === 'TAKE_OVER').length &&
                            <Button text={transS('Add Message')} mod={`blue margin0${addNoteBlocked ? ' disabled' : ''}`}
                                    callback={() => addNoteBlocked ? null : this.setState({addNote: 'TAKE_OVER'})} />}

                            <div styleName='requestPopup-desc'
                              dangerouslySetInnerHTML={{__html: transL('over')}} />
                            
                        </React.Fragment>}

                    </div>
                </div>

            </React.Fragment>
        )
    }
}
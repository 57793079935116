import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, NavLink} from 'react-router-dom';
import cssModules from 'react-css-modules';
import styles from './login.css';

import {inputValidation, blurValidation, submitValidation} from '../../../../common/validation';

import {scheduleAction} from '../../../services/actions/userActions';

import Button from '../../../components/button/Button';
import Checkbox from "../../../components/checkbox/Checkbox"
import Logo from "../../../components/logo/Logo"
import { transEL, transLoc } from '../../../services/helpers/lang';

@connect(state => ({}), {
    scheduleAction
})
@cssModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
export default class Schedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: {
                value: '',
                dirty: false,
                invalid: false
            },
            lastname: {
                value: '',
                dirty: false,
                invalid: false
            },
            email: {
                value: '',
                dirty: false,
                invalid: false
            },
            phone: {
                value: '',
                dirty: false,
                invalid: false
            },
            company: {
                value: '',
                dirty: false,
                invalid: false
            },
            job: {
                value: '',
                dirty: false,
                invalid: false
            },
            team: {
                value: '',
                dirty: false,
                invalid: false
            },
            message: '',
            transferring: false,
            isAgree: false
        };
    }

    _input = e => inputValidation(this, e.currentTarget);

    _blur = e => blurValidation(this, e.currentTarget);

    _submit = e => {
        e.preventDefault();

        let validationList = ['firstname', 'lastname', 'email', 'company', 'job'];

        if (submitValidation(this, validationList)) {
            return false;
        }

        let {firstname, lastname, email, phone, company, job, team} = this.state;
        let {scheduleAction} = this.props;
        this.setState({transferring: true});

        scheduleAction({
            firstname: firstname.value,
            lastname: lastname.value,
            email: email.value,
            phone: phone.value,
            company: company.value,
            job: job.value,
            team: team.value
        })
            .then(() => {
                this.setState({
                    firstname: {...firstname, value: ''},
                    lastname: {...lastname, value: ''},
                    email: {...email, value: ''},
                    phone: {...phone, value: ''},
                    company: {...company, value: ''},
                    job: {...job, value: ''},
                    team: {...team, value: ''},
                    transferring: false
                });
            })
            .catch(resp => {
                let {data: {err}} = resp.response;
                this.setState({message: err, transferring: false});
            });
    };

    async componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        let {
            firstname, lastname, email, phone, company, job, team, isAgree,
            message, transferring
        } = this.state;
        return (
            <div styleName='wrapper'>
                <div styleName='quotes'>

                    <div styleName='quotes-quote'>
                        <div styleName='quotes-quote-text'>
                            “Contract.one is a great example of how contracting should be done in the modern world.
                            Simple, effective, efficient, intuitive and collaborative.”
                        </div>
                        <div styleName='quotes-quote-desc'>
                            Innovation Lead in a Global Law Firm
                        </div>
                    </div>

                    <div styleName='quotes-quote'>
                        <div styleName='quotes-quote-text'>
                            “Finally we have a product that understands how lawyers want to negotiate. Such a beautiful
                            experience. This is what legal tech should look like.”
                        </div>
                        <div styleName='quotes-quote-desc'>
                            Catherine Bamford <br/>
                            Top 10 ‘legal business <br/>
                            technologists’ (Financial Times)
                        </div>
                    </div>

                </div>
                <form onSubmit={this._submit} noValidate>
                    <div styleName='form-inner'>
                        <div styleName='logo'><Logo/></div>
                        <div styleName='link-row2'>
                            Already have an account?<NavLink styleName='link' to='/login'><Button text='Sign in'
                                                                                                  mod='blue arch'/></NavLink>
                        </div>
                        <div styleName='title'>Request Trial</div>
                        <label styleName='row border'>
                            <div styleName='error-message'>
                                {
                                    (() => {
                                        if (firstname.invalid === 'required') return transEL('requiredName');
                                        else if (firstname.invalid === 'minLength') return transEL('minLengthName');
                                        else if (firstname.invalid === 'maxLength') return transEL('maxLengthName');
                                    })()
                                }
                            </div>
                            <input styleName='input'
                                   type='text'
                                   name='firstname'
                                   value={firstname.value}
                                   onChange={this._input}
                                   onBlur={this._blur}
                                   autoComplete='off'
                                   placeholder='First Name*'
                                   required/>
                        </label>
                        <label styleName='row  border'>
                            <div styleName='error-message'>
                                {
                                    (() => {
                                        if (lastname.invalid === 'required') return transEL('requiredSurname');
                                        else if (lastname.invalid === 'minLength') return transEL('minLengthSurname');
                                        else if (lastname.invalid === 'maxLength') return transEL('maxLengthSurname');
                                    })()
                                }
                            </div>
                            <input styleName='input'
                                   type='text'
                                   name='lastname'
                                   value={lastname.value}
                                   onChange={this._input}
                                   onBlur={this._blur}
                                   autoComplete='off'
                                   placeholder='Last Name*'
                                   required/>
                        </label>
                        <label styleName='row  border'>
                            <div styleName='error-message'>
                                {
                                    (() => {
                                        if (email.invalid === 'required') return transEL('requiredEmail');
                                        else if (email.invalid === 'email') return transEL('errorEmail');
                                        else if (email.invalid === 'maxLength') return transEL('maxLengthEmail');
                                    })()
                                }
                            </div>
                            <input styleName='input'
                                   type='email'
                                   name='email'
                                   value={email.value}
                                   onChange={this._input}
                                   onBlur={this._blur}
                                   autoComplete='off'
                                   placeholder='Business Email*'
                                   required/>
                             <div styleName='mail-desc'>We’ll not be able to proceed requests from personal emails.</div>
                        </label>
                        <label styleName='row  border'>
                            <input styleName='input'
                                   type='tel'
                                   name='phone'
                                   onChange={this._input}
                                   value={phone.value}
                                   autoComplete='off'
                                   placeholder='Phone Number'
                            />
                        </label>
                        <label styleName='row  border'>
                            <div styleName='error-message'>
                                {
                                    (() => {
                                        if (company.invalid === 'required') return 'Company Name is required';
                                        else if (company.invalid === 'minLength') return 'Company name seems too short';
                                        else if (company.invalid === 'maxLength') return 'Company name seems too long';
                                    })()
                                }
                            </div>
                            <input styleName='input'
                                   type='text'
                                   name='company'
                                   value={company.value}
                                   onChange={this._input}
                                   onBlur={this._blur}
                                   autoComplete='off'
                                   placeholder='Company Name*'
                                   required/>
                        </label>
                        <label styleName='row border'>
                            <div styleName='error-message'>
                                {
                                    (() => {
                                        if (job.invalid === 'required') return 'Job title is required';
                                        else if (job.invalid === 'minLength') return 'Job title seems too short';
                                        else if (job.invalid === 'maxLength') return 'Job title seems too long';
                                    })()
                                }
                            </div>
                            <input styleName='input'
                                   type='text'
                                   name='job'
                                   value={job.value}
                                   onChange={this._input}
                                   onBlur={this._blur}
                                   autoComplete='off'
                                   placeholder='Job Title*'
                                   required/>
                        </label>
                        <label styleName='row' style={{paddingBottom: '30px'}}>
                            <input styleName='input'
                                   type='number' min='0'
                                   onChange={this._input}
                                   name='team'
                                   value={team.value}
                                   autoComplete='off'
                                   placeholder='How large is your Legal Team?'
                            />
                        </label>
                        <div styleName='row checkbox'>
                            <Checkbox checked={isAgree} callback={() => this.setState({isAgree: !isAgree})}
                                      text={`<div class="${styles['checkbox-text']}">I agree with Contract.one’s <a target='_blank' href='/legal/terms-of-use'>
                                          Terms of Use</a> and <a target='_blank' href='/legal/data-protection'>Data Protection Policy</a></div>`}/>
                        </div>
                        <div styleName='button-row'>
                            {isAgree ? <Button text='Request Trial' mod='promoMainBtn' type='submit'/>
                                :
                                <Button text='Request Trial' mod='promoMainBtn disabled' callback={() => null}/>
                            }
                        </div>
                    </div>
                    {
                        message && <div styleName='message-wrapper'>{message}</div>
                    }
                    <div styleName='link-row'>Already have an account?<NavLink styleName='link' to='/login'><Button
                        text={transLoc('Sign in')} mod='blue arch'/></NavLink></div>
                </form>
            </div>
        );
    }
}

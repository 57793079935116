import React, { useEffect, useRef, useState } from 'react'
import CSSModules from 'react-css-modules'
import styles from './editTemplatePopup.css'
import useInput from '../../services/hooks/useInput'
import Checkbox from '../checkbox/Checkbox'
import SmallUser from '../../views/privateArea/components/projectPage/SmallUser'
import Button from '../button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { TemplatesActionCreators } from '../../redux/template/action'
import cn from 'classnames'
import api from '../../services/api/api'
import { isEqual } from 'lodash'
import Spinner from '../spinner/Spinner'
import { transS } from '../../services/helpers/lang'



const AddMemberPopup = CSSModules(({func, team, closeHandler}) => {
    const text = useInput()
    const [isShowAddBtn, setIsShowAddBtn] = useState(false)
    const [isShowAll, setIsShowAll] = useState()
    const [isHiddenListPart, setIsHiddenListPart] = useState()
    const [candidates, setCandidates] = useState([])
    const [fetchedUsers, setFetchedUsers] = useState([])
    const [isFetching, setIsFetching] = useState(true)
    const wrapperRef = useRef(null)
    const closePopupHandler = ({target}) => {
        if (target !== wrapperRef.current) return
        closeHandler()
    }
    const clearInput = () => {
        text.onChange({target: {value: ''}})
    }
    const addMember = () => {

    }

    useEffect(() => {
        const value = text.value.toLowerCase()
        const candidates = fetchedUsers.filter(el => (
            `${el.firstname} ${el.lastname}`.toLowerCase().includes(value) || el.email.toLowerCase().includes(value)
        ))
        setCandidates(candidates)
    }, [text.value])
    useEffect(async () => {
        const res = await Promise.all([api.getCompanyMembers(), api.getCandidates()])
        const companyMembersRes = res[0]
        const candidatesRes = res[1]

        if (companyMembersRes.status !== 200 || candidatesRes.status !== 200) return

        const companyMembers = companyMembersRes.data.data._general.members
        const {result: candidates, groups} = candidatesRes.data.data
        let tempList = {}

        companyMembers.forEach(u => {
            tempList[u._id] = {... tempList[u._id], ...u}
            tempList[u._id].candidate = 1
        })
        candidates.forEach((u, i) => {
            if (!tempList[u._id]) tempList[u._id] = {... tempList[u._id], ...u}
            tempList[u._id].candidate = groups[i]
        })
        tempList = Object.keys(tempList).map(key => tempList[key])
          .filter(user => !team.find(u => u.user._id === user._id));
        setFetchedUsers(tempList)
        setCandidates(tempList)
        setIsFetching(false)
    }, [])

    return <div styleName='AMP_wrapper' ref={wrapperRef} onClick={closePopupHandler}>
        {isFetching ? <Spinner withoutLogo={true} /> :
        <div styleName='AMP_popup-wrapper'>
            <div styleName='AMP_popup'>
                <div styleName='AMP_input'>
                    <input type="text"
                           placeholder={candidates.length ? 
                            transS('Search emails, names, or groups') : transS('Type Email')}
                           {...text}
                    />
                    {isShowAddBtn && <Button mod='blue margin0' text={transS('Add')} callback={addMember} />}
                    {text.value && <div data-close='1' className='close-cross' onClick={clearInput}>+</div>}
                </div>
                
                {!!candidates.length && <div styleName='AMP_candidates'>
                    <div className='h4' style={{marginBottom: '20px'}}>{transS('Select a person')}</div>
                    {candidates.length && candidates.map(user => (
                        <SmallUser user={user}
                                   isAddListItem={true}
                                   noCheck={true}
                                   number={user.candidate}
                                   func={func}
                                   key={user._id}/>
                    ))}
                    {isHiddenListPart &&
                    <Button text={isShowAll ? transS('Show Less') : transS('Show More')} mod='blue'
                            callback={() => setIsShowAll(prev => !prev)} />}
                </div>}
            </div>
        </div> }
    </div>
}, styles, {allowMultiple: true})

const EditTemplatePopup = () => {
    if (!document.body.style.overflow) {
        document.body.style.paddingRight = window.innerWidth - document.body.clientWidth + 'px'
        document.body.style.overflow = 'hidden'
    }
    const {editTemplate, templatesList} = useSelector(state => state.templateReducer)

    let currentTemplate
    // if (editTemplate && !actualDoc._id) currentTemplate = templatesList.find(el => el._id === editTemplate)
    // else currentTemplate = actualDoc
    currentTemplate = templatesList.find(el => el._id === editTemplate)

    const dispatch = useDispatch()
    const overlayRef = useRef(null)
    const taRef = useRef(null); 
    const taRefDesc = useRef(null); 
    const [isTitleEdit, setIsTitleEdit] = useState(false)
    const [isDescEdit, setIsDescEdit] = useState(false)
    const [team, setTeam] = useState(currentTemplate.team)
    const [isAddMemberPopupOpen, setIsAddMemberPopupOpen] = useState(false)

    const saveHandler = () => {
        const shouldUpdate = () => {
            if (currentTemplate.title !== taRef.current.value.trim()) return true
            else if ((currentTemplate.description || '') !== taRefDesc.current.value.trim()) return true
            else if (!isEqual(currentTemplate.team, team)) return true
            else return false
        }

        if (shouldUpdate()) {
            dispatch(TemplatesActionCreators.editTemplate(currentTemplate._id, taRef.current.value.trim(), undefined, taRefDesc.current.value.trim(), team))
        }
    }
    const clickOutsideHandler = ({target}) => target === overlayRef.current ? closeHandler() : null
    const closeHandler = () => {
        saveHandler()
        dispatch(TemplatesActionCreators.setEditPopup(false, null))
    }
    const func = (i, action, param) => {
        const teamDeepCopy = JSON.parse(JSON.stringify(team))
        switch (action) {
            case 'addMember':
                delete param.accessExpires
                delete param.accessStatus
                delete param.candidate
                delete param.teams
                delete param.ticks
                delete param.title
                delete param.totalPaying
                setTeam(prev => [...prev, {user: param, approver: false, signer: false, admin: false}])
                setIsAddMemberPopupOpen(false)
                break
            case 'removeUser':
                setTeam(teamDeepCopy.filter(u => u.user._id !== param.user._id))
                break
            case 'proveUser':
                setTeam(teamDeepCopy.map(u => param._id === u.user._id ? {...u, approver: !u.approver} : u))
                break
            case 'signeeUser':
                setTeam(teamDeepCopy.map(u => param.signeeUser._id === u.user._id ? {...u, signer: !u.signer} : u))
                break
            case 'adminUser':
                setTeam(teamDeepCopy.map(u => param.adminUser._id === u.user._id ? {...u, admin: !u.admin} : u))
                break
        }
    }
    
    const checkValue = () => {
        if (taRef.current.value.trim() === "")
            taRef.current.value = currentTemplate.title;
    }
      

    useEffect(() => {
        document.body.style.paddingRight = window.innerWidth - document.body.clientWidth + 'px'
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.paddingRight = ''
            document.body.style.overflow = ''
        }
    }, [])
    useEffect(() => {
        window.addEventListener('click', clickOutsideHandler)
        return () => {
            window.removeEventListener('click', clickOutsideHandler)
        }
    }, [clickOutsideHandler])
     
    const checkSize = (isTitle) => {
     let ta = isTitle ? taRef : taRefDesc;
     ta.current.style.height = "1px";
     ta.current.style.height = (ta.current.scrollHeight + 1) + 'px'; 
    };
  
    useEffect(() => {checkSize(true); checkSize(false);}, []);

    return (
        <div styleName='popup_overlay' ref={overlayRef}>
            <div styleName='popup_wrapper' style={{width: '920px'}}>
                <div className='close-cross' onClick={closeHandler}>+</div>
                <textarea autoComplete="off" ref={taRef}
                    styleName='ETP_templateTitle_input'    
                    onInput={()=>checkSize(true)}
                    onBlur={checkValue}
                    defaultValue={currentTemplate.title}  />
                <textarea autoComplete="off" ref={taRefDesc}
                    styleName='ETP_templateDesc_input'    
                    onInput={()=>checkSize(false)}
                    onBlur={checkValue}
                    placeholder={transS('Add Template Description')}
                    defaultValue={currentTemplate.description}  />
                

                <div className='flex' style={{marginTop: '40px'}}>
                    <div styleName='ETP_standardTeam'>
                        <div className='h2' style={{lineHeight: 'normal', marginBottom: '40px'}}>{transS('Standard Team')}</div>
                        <div styleName='ETP_userList'>
                            {team.map(user => (
                                <SmallUser user={user.user} key={user.user._id}
                                           func={func}
                                           number={0}
                                           prove={user.approver}
                                           signee={user.signer}
                                           admin={user.admin}
                                           canEdit={true} />
                            ))}
                        </div>
                        <Button text={transS('Add Member')} mod='blue arch margin0' 
                                callback={() => setIsAddMemberPopupOpen(true)} />
                    </div>
                    <div styleName='ETP_relatedDocuments'>
                        <div className='h2' style={{lineHeight: 'normal', marginBottom: '40px'}}>{transS('Related Documents')}</div>
                        <Button text={transS('Add Related Documents')} mod='blue arch disabled' />
                        {/*<div styleName='ETP_documentList'>*/}
                        {/*    <div styleName='ETP_documentList_item'>*/}
                        {/*        <div className='flex' style={{position: 'relative', marginBottom: '20px'}}>*/}
                        {/*            <svg styleName='docIcon' width="11" height="13" viewBox="0 0 11 13">*/}
                        {/*                <path fill="none" fillRule="evenodd" stroke="#ACACAC" d="M1 1h9v11H1V1zm2 2h5M3 5h5M3 7h5"/>*/}
                        {/*            </svg>*/}
                        {/*            <span style={{display: 'inline-block', fontSize: '14px', color: 'var(--color_darkGray)', maxWidth: '230px'}}>*/}
                        {/*                Acme Construction SPA draft Seller’s-friendly version*/}
                        {/*            </span>*/}
                        {/*            <span className='close-cross' style={{top: '-4px', right: '0'}}>+</span>*/}
                        {/*        </div>*/}
                        {/*        <div styleName='ETP_documentList_item_controls'>*/}
                        {/*            <Checkbox text='Recommended for your Template' mod='small' checked={true} callback={() => null} />*/}
                        {/*            <Checkbox text='Auxiliary document for your Template' mod='small' checked={false} callback={() => null} />*/}
                        {/*            <Checkbox text='Master document to your Template' mod='small' checked={false} callback={() => null} />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div styleName='ETP_documentList_item'>*/}
                        {/*        <div className='flex' style={{position: 'relative', marginBottom: '20px'}}>*/}
                        {/*            <svg styleName='docIcon' width="11" height="13" viewBox="0 0 11 13">*/}
                        {/*                <path fill="none" fillRule="evenodd" stroke="#ACACAC" d="M1 1h9v11H1V1zm2 2h5M3 5h5M3 7h5"/>*/}
                        {/*            </svg>*/}
                        {/*            <span style={{display: 'inline-block', fontSize: '14px', color: 'var(--color_darkGray)', maxWidth: '230px'}}>*/}
                        {/*                Acme Construction SPA draft Seller’s-friendly version*/}
                        {/*            </span>*/}
                        {/*            <span className='close-cross' style={{top: '-4px', right: '0'}}>+</span>*/}
                        {/*        </div>*/}
                        {/*        <div styleName='ETP_documentList_item_controls'>*/}
                        {/*            <Checkbox text='Recommended for your Template' mod='small' checked={true} callback={() => null} />*/}
                        {/*            <Checkbox text='Auxiliary document for your Template' mod='small' checked={false} callback={() => null} />*/}
                        {/*            <Checkbox text='Master document to your Template' mod='small' checked={false} callback={() => null} />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
                {/*<div style={{marginTop: '30px'}}>*/}
                {/*    <Checkbox text='Hide Template in TemplatesView List' checked={false} callback={() => null} />*/}
                {/*</div>*/}
            </div>
            {isAddMemberPopupOpen && <AddMemberPopup func={func} team={team} closeHandler={() => setIsAddMemberPopupOpen(false)} />}
        </div>
    )
}

export default CSSModules(EditTemplatePopup, styles, {allowMultiple: true})
import React from 'react'
import { useSelector } from 'react-redux';
import CSSModules from 'react-css-modules';
import styles from './unitList.css'
import Tip from '../../../../components/tip/Tip';


const Avatar = ({userId}) => {
    const {knownUsers} = useSelector(state => state.unitReducer)
    const getFirstLetters = ({firstname, lastname, email}) => 
      (firstname) ? firstname.slice(0, 1) + lastname.slice(0, 1) : email.slice(0, 2)

    const user = knownUsers.get(userId)
    if (!user) return false
        
    return <span styleName='icons-cont'>
        {user.avatar ? <img src={user.avatar} styleName="circle-image" />
        : <span styleName='avatar-letters'>
            {getFirstLetters(user)}</span>}
        <Tip 
        text={`<div>${user.firstname + user.lastname ? 
            `${user.firstname} ${user.lastname}<br/>` 
        : ''}${user.email}</div>`} arrow='left bottom' />
    </span>      
}

export default CSSModules(Avatar, styles, {allowMultiple: true});
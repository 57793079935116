import cx from 'classnames'
import React from 'react'
import Checkbox from '../checkbox/Checkbox'
import CSSModules from 'react-css-modules'
import styles from './Tagging.css'
import UserLogo from '../userLogo/UserLogo'

@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
export class Tagging extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDueCheck: false,
            selectedDueTime: 3,
            positionDueTime: 3,

            position: {
                top: null,
                bottom: null
            },
            maxHeight: null
        }
    }

    componentDidMount() {
       window.addEventListener('click', this.handleClickOutside)
       window.addEventListener('scroll', this.scrollHandler)
       this.setTaggingPosition()
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleClickOutside)
        window.removeEventListener('scroll', this.scrollHandler)
    }

    scrollHandler = () => {
        this.setTaggingPosition()
    }

    setTaggingPosition = () => {
        const rect = this.props.textArea.getBoundingClientRect()

        const taBottomOffset = window.innerHeight - rect.top // расстояние от textarea до нижней видимой границы
        const taTopOffset = rect.bottom // расстояние от textarea до верхней видимой границы
        const position = {}
        let maxHeight
        if (taBottomOffset < taTopOffset) { // tagging будет над textarea
            position.top = null
            position.bottom = window.innerHeight - rect.top
            maxHeight = window.innerHeight - position.bottom - 105
        } else { // tagging будет под textarea
            position.top = rect.bottom
            position.bottom = null
            maxHeight = window.innerHeight - position.top - 105
        }
        this.setState({position, maxHeight})
    }

    handleClickOutside = e => {
        if (this.refWrapper && e.target !== this.refWrapper && !this.refWrapper.contains(e.target))
            this.props.setShowTaggingMenu(false)
    }

    makeTaggingUserList = () => {
        const {internalUserList, textareaValue, textArea} = this.props

        if (!textArea) return

        const userList = internalUserList
            .filter(el => {
                let ta = textArea,
                    start = ta.selectionStart,
                    end = ta.selectionEnd,
                    ind = textareaValue.lastIndexOf('@', start),
                    str = textareaValue.slice(ind + 1).toLowerCase()

                const isMatchName = `${el.firstname} ${el.lastname}`.toLowerCase().indexOf(str) !== -1
                const isMatchEmail = el.email.toLowerCase().indexOf(str) !== -1

                return isMatchName || isMatchEmail
            })
            .map(el => <div styleName='tagging_userList_item' key={el._id + 'tagUserList'} onClick={e => this.taChoice(e, el)}>
                <UserLogo mod='_small' firstname={el.firstname} lastname={el.lastname} avatar={el.avatar} email={el.email} />
                <div styleName='tagging_userList_item_nameBlock'>
                    {(el.firstname + el.lastname) && <div>{el.firstname} {el.lastname}</div>}
                    <div>{el.email}</div>
                </div>
            </div>)

        if (!userList.length) return this.props.setShowTaggingMenu(false)
        return userList
    }

    taChoice = (e, user) => {
        const {selectedDueTime, isDueCheck} = this.state
        const {textareaValue, textArea} = this.props
        let hr = 3600000, dueTime = ''
        if (isDueCheck) {
            switch (selectedDueTime - 1) {
                case 0: dueTime += `${hr/2}`; break; // 30min
                case 1: dueTime += `${hr}`; break; // 1hr
                case 2: dueTime += `${hr*2}`; break; // 2hrs
                case 3: dueTime += `${hr*4}`; break; // 4hrs
                case 4: dueTime += `${hr*24}`; break; // 1day
                case 5: dueTime += `${hr*48}`; break; // 2days
                case 6: dueTime += `${hr*72}`; break; // 3days
                case 7: dueTime += `${hr*168}`; break; // 1week
                default: dueTime = ''
            }
        }

        let val = `@[${(user.firstname + user.lastname) ? `${user.firstname} ${user.lastname}` : user.email}]`,
            taSel = textArea.selectionStart, shift = val.length,
            ind = textareaValue.lastIndexOf('@', taSel),
            str = textareaValue.slice(ind),
            newVal = textareaValue.slice(0, ind) + val + ' ' + textareaValue.slice(ind + str.length)
        const mentions = this.props.mentions.slice()
        mentions.push({...user, val, dueTime})
        this.props.setTextAreaValue(newVal, mentions)
        this.props.setShowTaggingMenu(false)
        textArea.setSelectionRange(taSel+shift, taSel+shift)
        textArea.focus()
    }

    toggleDueTimeHandler = e => {
        if (!e.target.dataset) return

        this.setState({selectedDueTime: +e.target.dataset.time})
    }
    toggleDueTimeArrowHandler = e => {
        const {selectedDueTime, positionDueTime} = this.state
        let newSelectedDueTime = e.target.dataset.direction === '1' ? selectedDueTime - 1 : selectedDueTime + 1
        if (newSelectedDueTime === 0 || newSelectedDueTime === 9) return

        if (newSelectedDueTime - positionDueTime === 3 || newSelectedDueTime - positionDueTime === -2) {
            let newSelectedPosDueTime = e.target.dataset.direction === '1' ? positionDueTime - 1 : positionDueTime + 1
            return this.setState({selectedDueTime: newSelectedDueTime, positionDueTime: newSelectedPosDueTime})
        }
        this.setState({selectedDueTime: newSelectedDueTime})
    }

    render() {
        const {selectedDueTime, positionDueTime, isDueCheck, position, maxHeight} = this.state

        return (
            <div styleName='tagging' ref={e => this.refWrapper = e} style={{top: position.top, bottom: position.bottom}}>
                    <div styleName='tagging_userList' style={{maxHeight}}>
                        {this.makeTaggingUserList()}
                    </div>
                    <div styleName={cx('tagging_dueBlock', {'_checked': isDueCheck})}>
                        <div styleName='checkboxDue' >
                            <Checkbox checked={isDueCheck} callback={e => {e.preventDefault();e.stopPropagation();this.setState({isDueCheck: !isDueCheck})}} />
                            <span onClick={() => this.setState({isDueCheck: !isDueCheck})}>Due <span>in</span></span>
                        </div>
                        <div styleName='choiceDueBlock'>
                            <span styleName={cx('left', {'_disabled': +selectedDueTime === 1})} data-direction='1' onClick={this.toggleDueTimeArrowHandler}/>
                            <div styleName='choiceDue' onClick={this.toggleDueTimeHandler}>
                                {positionDueTime < 3 && <span styleName={cx({'active': selectedDueTime === 1})} data-time='1'>30 min</span>}
                                {positionDueTime < 4 && <span styleName={cx({'active': selectedDueTime === 2})} data-time='2'>1 hr</span>}
                                {positionDueTime < 5 && <span styleName={cx({'active': selectedDueTime === 3})} data-time='3'>2 hrs</span>}
                                {positionDueTime < 6 && <span styleName={cx({'active': selectedDueTime === 4})} data-time='4'>4 hrs</span>}
                                {positionDueTime > 2 && <span styleName={cx({'active': selectedDueTime === 5})} data-time='5'>1 day</span>}
                                {positionDueTime > 3 && <span styleName={cx({'active': selectedDueTime === 6})} data-time='6'>2 days</span>}
                                {positionDueTime > 4 && <span styleName={cx({'active': selectedDueTime === 7})} data-time='7'>3 days</span>}
                                {positionDueTime > 5 && <span styleName={cx({'active': selectedDueTime === 8})} data-time='8'>1 week</span>}
                            </div>
                            <span styleName={cx('right', {'_disabled': +selectedDueTime === 8})} data-direction='2' onClick={this.toggleDueTimeArrowHandler}/>
                        </div>
                    </div>
            </div>
        )
    }
}
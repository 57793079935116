import React, {Component, Fragment} from 'react';
import CSSModules from "react-css-modules"
import styles from "./security.css"
import {Link, NavLink} from "react-router-dom"
import Footer from "../../../components/footer/Footer"

@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
class Security extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <Fragment>
                <div styleName='wrapper'>
                    <div styleName='title'>Security
                        <img src="../../../images/pad-lock.png" alt=""/>
                    </div>
                    <div styleName='title-desc'>In Contract.one, we keep your data safe. &nbsp;Here you can find out about how
                        we use trusted services provider, maintain reliable infrastructure and protect your data. <br/>
                        <br/>
                        If you have any security questions please contact us at <a styleName='link'
                                                                                   href="mailto:security@contract.one">security@contract.one</a>.
                    </div>

                    <div styleName='section'>
                        <div styleName='section-title'>Infrastructure</div>
                        <div>We achieve infrastructure's reliability by using trustworthy services and by maintaining
                            additional security arrangements. <br/>
                            <br/>
                            Firstly, we use <Link to='https://aws.amazon.com/' target='_blank' styleName='link'>AWS
                                platform</Link> that provides market-leading
                            defense against all known
                            infrastructure attacks. &nbsp;Besides, Amazon AWS EC2 infrastructure is
                            fully certified under the following standards: <br/>
                            <div styleName='cols-row' style={{marginTop: '20px', marginBottom: '40px'}}>
                                <div styleName='cols-row-col'>
                                    <div styleName='bold'>ISO/IEC 27001:2013</div>
                                    <div styleName="bold">ISO/IEC 27017:2015</div>
                                </div>
                                <div styleName='cols-row-col'>
                                    <div styleName="bold">ISO 9001:2015</div>
                                    <div styleName="bold">ISO/IEC 27018:2019</div>
                                </div>
                                <div styleName='cols-row-col'>
                                    <div styleName="bold">PCI DSS</div>
                                    <div styleName="bold">SOC</div>
                                </div>
                            </div>
                            Secondly, we implement technical security arrangements like Perimeter Firewalls, which
                            protect your device connected to private network from any possible attacks, while you use
                            our Software.
                        </div>
                    </div>

                    <div styleName='section'>
                        <div styleName='section-title'>Data Protection</div>
                        <div>
                            We store data on AWS and provide users with the same level of security and protection
                            regardless of subscription model and project role. &nbsp;For more information on what data we
                            collect and with whom we may share it, see our <NavLink to='/legal/data-protection'
                                                                                    styleName='link'>Data Protection
                            Policy.</NavLink> <br/>
                            <br/>
                            We use these techniques:
                        </div>
                        <div styleName='cols-row'>
                            <div styleName='cols-row-col'>
                                <div styleName='cols-row-col-title'>
                                    TLS
                                </div>
                                Transport Layer Security (TLS) is a protocol that is used to protect connection between
                                your device and our server while you enter username, password and other sensitive data.
                                So our communication is protected from malicious attacks.
                            </div>
                            <div styleName='cols-row-col'>
                                <div styleName='cols-row-col-title'>
                                    AES 256
                                </div>
                                Advanced Encryption Standard (AES 256) is a method of encryption when data turns into a
                                code. &nbsp;So your data is encrypted when you transfer it to our server.
                            </div>
                        </div>
                    </div>

                    <div styleName='section'>
                        <div styleName='section-title'>Projects and Contracts</div>
                        <div>
                            Your projects and contracts in Contract.one are encrypted and stored on protected <Link
                            to='https://aws.amazon.com/' target='_blank' styleName='link'>AWS</Link> servers. &nbsp;This
                            means that we or anybody else can't access your data, even physically. &nbsp;But
                            there is only one person who can reach and share your data — you. &nbsp;Only you can grant access
                            to other people by inviting them to Software and assigning with the role.
                        </div>
                    </div>

                    <div styleName='section'>
                        <div styleName='section-title'>Role Based Access Control</div>
                        <div styleName='cols-row' style={{marginTop: '40px'}}>
                            <div styleName='cols-row-col'>
                                <div styleName='cols-row-col-title'>
                                    Administrator
                                </div>
                                initiates and manages projects and contracts.
                            </div>
                            <div styleName='cols-row-col'>
                                <div styleName='cols-row-col-title'>
                                    Internal User
                                </div>
                                uploads a contract, proposes and accepts changes into it (if allowed to do so);
                                communicates internally and externally.
                            </div>
                            <div styleName='cols-row-col'>
                                <div styleName='cols-row-col-title'>
                                    External User
                                </div>
                                invites external users and may edit a contract and communicate with users (if allowed to
                                do so).
                            </div>
                        </div>
                        Contract.one allows Administrator to control communication between internal and external team. &nbsp;
                        Thanks to one of the Contract.one's core feature, external users won't access to any internal
                        comments until Administrator approve their publishing. &nbsp;Even if internal comment was published
                        accidentally, Administrator still has time to revoke approval.
                    </div>


                    <div styleName='section'>
                        <div styleName='section-title'>Compliance</div>
                        <div>
                            We process your data according to GDPR requirements and other local privacy laws. &nbsp;For more
                            information, please see our <NavLink to='/legal/data-protection' styleName='link'>Data
                            Protection Policy</NavLink>. <br/>
                            <br/>
                            We hold Cyber Essentials Certificate of Assurance. &nbsp;Currently we are in the process of
                            obtaining security certificates for ISO 27001 and SOC 2 type 1.
                        </div>
                    </div>

                    <div styleName='section'>
                        <div styleName='section-title'>Monitoring</div>
                        <div>
                            We monitor and evaluate our system for unexpected crashes, unauthorized access, suspicious
                            behavior, certain attack signatures, outages and other indicators of security incident. &nbsp;All
                            logs are retained for at least six months period.
                        </div>
                    </div>

                    <div styleName='section'>
                        <div styleName='section-title'>Backups</div>
                        <div>
                            In case of any emergency on a server side, it is guaranteed that completely no data will be
                            lost, because your data has been already backed up to our backup site. &nbsp;Furthermore, we
                            frequently test our backup procedure, including restoring entire system from backup.
                        </div>
                    </div>

                    <div styleName='section'>
                        <div styleName='section-title'>Internal Control</div>
                        <div>
                            We carry out our internal security management on a comprehensive level with internal
                            policies, regular information security training, strict NDA's and further security
                            development.
                        </div>
                    </div>

                </div>
                <Footer/>
            </Fragment>
        )
    }
}

export default Security
import React, { useEffect, useMemo, useState } from 'react'
import CSSModules from 'react-css-modules'
import styles from './templatesList.css'
import Button from '../../../../components/button/Button'
import Tip from '../../../../components/tip/Tip';
import DocumentLine from './DocumentLine'
import AddTemplatePopup from './AddTemplatePopup'
import Spinner from '../../../../components/spinner/Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { TemplatesActionCreators } from '../../../../redux/template/action'
import {showPaymentAction} from '../../../../services/actions/userActions';
import ListHeader from "../../components/ListHeader/ListHeader";
import { transS, transF } from '../../../../services/helpers/lang';
import UniversalListMenuDropDown from '../../components/DocumentListItemDropDown/UniversalListMenuDropDown';
import { RenamePopup } from './RenamePopup';
import { getPopUpAction } from '../../../../services/actions/dataActions';
import { popUpReset } from '../../../../services/helpers';

const sortFunc = (a,b) => {
    const ap = a.isPin ? -1 : 0,
      bp = b.isPin ? -1 : 0,
      af =  (a.contentType === 'folder') ? -1 : 0,
      bf = (b.contentType === 'folder') ? -1 : 0
      return (ap != bp) ? ap - bp 
      : (af !== bf) ? af - bf
      : af ? (a.title < b.title ? -1 : 1)
        : (a.createdAt < b.createdAt ? 1 : -1)        
  }
  const checkFolders = (arr, subs) => {
    for (let i=0; i < arr.length; i++) {
      if (arr[i].isPin) continue;
      if (arr[i].contentType !== 'folder') return
      const found = subs[arr[i]._id]
      if (!found) continue
      found.sort(sortFunc)
      found.forEach(a => a.parentName = arr[i].title)
      arr[i].subs = found
      delete subs[arr[i]._id]
      checkFolders(found, subs)
    }
  }
  
const hasFolders = a => a.findIndex(t => t.contentType === 'folder') !== -1

const TemplatesList = () => {
    const [isOpenAddTemplatePopup, setIsOpenAddTemplatePopup] = useState(false)
    const {templatesList, isLoading, openFolders} = useSelector(state => state.templateReducer)
    const {user} = useSelector(state => state.userReducer)
    const [pinnedTemplates, setPinnedTemplates] = useState(JSON.parse(localStorage.getItem('pinnedTemplates') || '[]'))
    const [isRenameOpen, setIsRenameOpen] = useState(false)
    const [doNewFolder, setDoNewFolder] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(TemplatesActionCreators.fetchTemplates())
    }, [])

    const openAddTemplatePopupHandler = () => setIsOpenAddTemplatePopup(prev => !prev)
    const addTemplateHandler = template => dispatch(TemplatesActionCreators.addTemplate(template))
    
    const pinTemplateClickHandler = id => {
        if (pinnedTemplates.indexOf(id) === -1) {
            setPinnedTemplates(prev => {
                const newArray = [...prev, id]
                localStorage.setItem('pinnedTemplates', JSON.stringify(newArray))
                return newArray
            })
        } else {
            setPinnedTemplates(prev => {
                const newArray = prev.filter(i => i !== id)
                localStorage.setItem('pinnedTemplates', JSON.stringify(newArray))
                return newArray
            })
        }
    }
    const [showDropDown, setShowDropDown] = useState(null);
    const lockContextMenu = e => {
       e.stopPropagation();
       e.preventDefault();
       return false;}
    
    const saveTitleHandler = (_id, newTitle, oldTitle, fold) => {
        if (newTitle.trim() !== oldTitle || fold && fold.newParent !== undefined) 
          dispatch(TemplatesActionCreators.editTemplate(_id, newTitle.trim(), fold?.newParent))
    }

    const deleteTemplate = (_id, isFolder) => {
        setShowDropDown(null)
        dispatch(getPopUpAction(
        {
          name: 'confirm',
          text: transF('delTemplate')(isFolder),
          confirm: {
              name: transS('Delete'),
              danger: true,
              event: isFolder ? 
               () => {
                   dispatch(TemplatesActionCreators.editTemplate(_id, 'delete folder'))
                   dispatch(getPopUpAction(popUpReset))
               } : () => dispatch(TemplatesActionCreators.deleteTemplate(_id))
          },
          cancel: {
              name: transS('Cancel'),
              event: () => dispatch(getPopUpAction(popUpReset))
          }
        }
        )) }
    
    
    const templates = useMemo( () => {
       if (isLoading) return false
       const subs = {}
       let tList = templatesList.filter(t => {
         delete t.subs
         if (pinnedTemplates.includes(t.id)) {
            t.isPin = 1;
            return true
         }
         t.isPin = 0
         if (!t.parent) return true
         if (subs[t.parent]) subs[t.parent].push(t)   
         else subs[t.parent] = [t]
         return false
       });
       tList.sort(sortFunc)
       checkFolders(tList, subs)
       for (const [key, value] of Object.entries(subs)) {
          value.sort(sortFunc)
          tList = [...tList, ... value]
       }
       return tList
    }, [isLoading, templatesList, pinnedTemplates])
    
    const sortedTemplates = useMemo( () => {  
      const sorted = []
      const iterateTree = (arr, lvl) => {
        arr.forEach(t => {
           sorted.push(t)
           t.lvl = lvl
           if (t.subs) iterateTree(t.subs, lvl + 1)
        })
      }
      if (templates)
        iterateTree(templates, 0)
      return sorted
    }, [templates])
    
    let currentLevel = 0

    return (
      <>
        <ListHeader currentOption={1} user={user} />
        <div styleName='wrapper'>
            <div className='h1' style={{marginBottom: '40px'}}>{transS('Templates')}</div>
            {user.ticks < 0 && <div styleName="orangeStop">
               {transS('Templates are available for paid accounts.')}
               <div styleName="orangeStop">
                 {transS('Upgrade to experience the easiest way to automate your documents.')}
               </div>
            </div>}
            {user.ticks < 0 && <Button text={transS('Upgrade Plan')} mod='orange fill' style={{marginRight: '10px'}}
                    callback={()=>dispatch(showPaymentAction(true))} />}
            {user.ticks < 0 ?
            <div styleName="hinted">
              <Button text={transS('New Template')} mod='blue fill disabled' />
              <Tip arrow='bottom left' text="Available in paid plans" />
            </div>
            : <Button text={transS('New Template')} mod='blue fill' style={{marginRight: '10px'}}
                    callback={openAddTemplatePopupHandler} />}
            {user.ticks > 0 && !!user.company &&
              <span>
                <Button text={transS('New Folder')} mod='blue arch' 
                  callback={() => setDoNewFolder(true)}
                />
                {doNewFolder && <RenamePopup 
                  title=""
                  saveHandler={(x, fold) => saveTitleHandler(null, x, '', fold)} 
                  closeHandler={() => setDoNewFolder(false)}
                  folderParams={{}}
                  templates={templates}
                />}
              </span>
            }

            {user.ticks < 0 ? false : isLoading
                ? <div>
                    <Spinner />
                </div>
                : <div styleName='templatesList' onContextMenu={e => lockContextMenu(e)} >
                    <div styleName='templatesList_head'>
                        <div>{transS('Templates')}</div>
                        <div>{transS('Status')}</div>
                        <div>{transS('Statistics')}</div>
                        <div>{transS('Created on')}</div>
                        <div>{transS('Created by')}</div>
                        <div />
                    </div>
                    {!!templates && sortedTemplates.map(t => {
                      const unvisible = currentLevel && t.lvl >= currentLevel
                      if (!unvisible) currentLevel = 0
                      if (!unvisible && t.contentType === 'folder' && !openFolders.has(t._id))
                          currentLevel = t.lvl +1
                      
                      return <DocumentLine key={t._id}
                        {...t}
                        pinHandler={() => pinTemplateClickHandler(t._id)}
                        onContextMenu={e => lockContextMenu(e)}
                        setShowDropDown={dd =>setShowDropDown(dd)}
                        lvl={t.lvl}
                        visible={!unvisible}
                    />})}
                    <UniversalListMenuDropDown 
                      item={showDropDown}
                      top={showDropDown?.top} 
                      left={showDropDown?.left} 
                      close={() => setShowDropDown(null)}
                      menuItems = {[
                       {
                        name: showDropDown?.orig !== 300 ? transS('Publish') : transS('Unpublish'),
                        hidden: showDropDown?.contentType === 'folder',
                        click: () => {showDropDown?.orig !== 300 ? 
                            dispatch(TemplatesActionCreators.publishTemplate(showDropDown?.coreDocument, showDropDown?._id,  ''))
                            : dispatch(TemplatesActionCreators.unpublishTemplate(showDropDown?.coreDocument))
                            setShowDropDown(null)
                            } },
                       {
                        name: transS('Settings'),
                        hidden: showDropDown?.contentType === 'folder',
                        click: () => {
                            dispatch(TemplatesActionCreators.setEditPopup(true, showDropDown._id)) 
                            setShowDropDown(null)
                        }},
                       {
                        name: transS('Rename or Move'),
                        click: (params) => {setIsRenameOpen(params) 
                            setShowDropDown(null)}},
                       {
                        name: transS(showDropDown?.contentType === 'folder' ?
                          `Delete Folder` : 'Delete Template'),
                        style: 'delete',
                        click: (params) => deleteTemplate(params._id, showDropDown?.contentType === 'folder')
                        },
                      ]}
                    />
                    {!!isRenameOpen &&
                        <div style={{position: 'absolute', 
                          top: (isRenameOpen.top -20)+"px", 
                          left: (isRenameOpen.left - (isRenameOpen.left < 300 ? 0 : 200)) +"px"}}>
                            <RenamePopup title={isRenameOpen.title} 
                                saveHandler={(x, f) => saveTitleHandler(isRenameOpen._id, x, isRenameOpen.title, f)} 
                                closeHandler={() => setIsRenameOpen(false)}
                                folderParams={isRenameOpen}
                                templates={templates}/>
                        </div>
                    }
                </div>}
            {isOpenAddTemplatePopup && <AddTemplatePopup addTemplateHandler={addTemplateHandler}
                                                         closeHandler={openAddTemplatePopupHandler} />}            
        </div>
      </>
    )
}

export default new CSSModules(TemplatesList, styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
import React, { useState } from 'react'
import { useDispatch, useSelector  } from 'react-redux'
import CSSModules from 'react-css-modules'
import styles from './templatesUnderMenu.css'
import Variables from './Variables';
import DropDownType from './DropdownType';
import { addVariableAction } from '../../../../../../redux/document/actions';
import { transS } from '../../../../../../services/helpers/lang';

const TemplatesUnderMenu = ({ hideVars }) => {
    const dispatch = useDispatch();
    const {insertOn, canPaste, frameProgress} = useSelector(state => state.paragraphReducer);
    const insertVar = () => {
      if (!insertOn || !canPaste) return;
      frameProgress.sendToFrame({c1:'variable', action: 'insert', id: 0});
    }
    const clickHandler = (e) => {
        const type = e.target.dataset?.type;
        if (!type) return;
        if (type === 'if') return insertVar();
        e.preventDefault();
        e.stopPropagation();
        dispatch(addVariableAction(type));
    }
    return (
      <div styleName='container'>
        <Variables />  
        {!hideVars && <div styleName='wrapper'>
            <div styleName='createVars'>
                <div styleName='createVars_title'>{transS('Variables')}:</div>
                <div styleName='createVars_btns' onClick={clickHandler}>
                   <DropDownType type="text" />
                   <DropDownType type="number" />
                   <DropDownType type="date" />
                   <DropDownType type="dropdown" />
                   <div styleName="divider"></div>
                   <DropDownType type="if" faded={!insertOn || !canPaste}/>                   
                </div>
            </div>
        </div> }
      </div>
    )
}

export default CSSModules(TemplatesUnderMenu, styles, {allowMultiple: true})
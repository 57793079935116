import React, { useState, useEffect, useRef } from 'react';
import CSSModules from 'react-css-modules'
import styles from './clauseLib.css'
import { transS } from '../../../../services/helpers/lang';
import Tip from '../../../../components/tip/Tip';
import Categories from './Categories';
import { useDispatch, useSelector } from 'react-redux';
import { getPopUpAction } from '../../../../services/actions/dataActions';
import { popUpReset } from '../../../../services/helpers';
import api from '../../../../services/api/api';
import { changeClause } from '../../../../services/actions/userActions';
import TextArea from '../../components/topMenu/elements/TemplatesUnderMenu/TextArea';
import userConstants from '../../../../services/constants/userConstants';


const Clause = params => {
  let {kind, clause,   
    callback, shift, rOnly} = params
  const MAGIC_SHIFT = 382
  const 
    {user, closedAddFolders, clauseLib} = useSelector(state => state.userReducer),
    dispatch = useDispatch(),
    ref = useRef(null)
  const isNew = !clause, 
    alwaysDraft = kind === 'clauseDraft',
    isClause = alwaysDraft || kind === 'clause',
    adLib = !clauseLib.clauses.includes(clause) && isClause || isNew,
    backCopy = JSON.stringify(clauseLib),
    isPublished = !!clause && !clause.isDraft,
    tipText = transS('Enter Name') 
        + (isClause ? (transS(' and Clause Text')) : ''),
    getCategories = (id, folders, s) => {
      folders.forEach((f) => {
        if (f.clauses.includes(id) && f.id !== 1)
          s.add(f.id)
        if (f.subs) getCategories(id, f.subs, s)
      })
      return s
    },
    getParent = (clause, folders, s) => {
      const found = folders.find(el => el.subs?.includes(clause))
      if (found) {
        s.add(found.id)
        return s
      }
      folders.forEach(f => f.subs?.length && !s.size && getParent(clause, f.subs, s))
      return s
    },
    categories = isNew ?  new Set() :
      isClause ? getCategories(clause.id, clauseLib.folders, new Set())
      : clause.lvl ? getParent(clause, clauseLib.folders, new Set()) : new Set(),
    [unchanged, setUnchanged] = useState(true),
    [showCategories, setShowCategories] = useState(false),
    checkGood = clause => clause ?
        clause.title.trim() && (!isClause || clause.content.trim())
        : false,
    [good, setGood] = useState(checkGood(clause)),
    [newCats, setNewCats] = useState(categories),
    [cont, setCont] = useState(isNew ? {title:'', hint:'', content:''}
      : {...clause}),
    [handlerActive, setHandlerActive] = useState(true),
    isCompanyAdmin = user.company?.knownCompanies[0].members._general.members.find(
        m => user._id === m._id)?.accessStatus > 0;
  
  const checkOut = () => {
    if (isUnchanged())
      callback(null);
    else {
      dispatch(getPopUpAction({
        name: 'confirm',
        header: transS('Save Changes')+'?',
        close: {
            event: () => {
              dispatch(getPopUpAction(popUpReset))
              setHandlerActive(true)
            }
        },
        confirm: {
          name: transS('Save'),
          mod: 'fill blue',
          event: () => {
            dispatch(getPopUpAction(popUpReset))
            saveClause()
        } },
        cancel: {
          name: transS('No'),
          mod: 'red arch',
          event: () => {
          dispatch(getPopUpAction(popUpReset))
            callback(null)
        }}
    }))
   }  
  }
    
  const clickOutsideHandler = (e) => {    
    if (handlerActive && !ref.current?.contains(e.target) && e.target.tagName !== 'HTML') {
      //e.stopPropagation();
      //e.preventDefault();
      //ref.current.blur()
      setHandlerActive(false)
      setTimeout(checkOut, 20)      
    }
  }

  const setOpenFolders = setOfFolders => dispatch(
    {type: userConstants.SET_CLOSED_FOLDERS, folders: setOfFolders})
  
  const stringifyCats = x => JSON.stringify(Array.from(x).sort())


  useEffect(() => {
    window.addEventListener('mousedown', clickOutsideHandler)
    return () => {
        window.removeEventListener('mousedown', clickOutsideHandler)
    }
  }, [clickOutsideHandler])
  
  const isUnchanged = () => clause 
      && clause.title === cont.title.trim()
      && stringifyCats(newCats) === stringifyCats(categories)
      && (!isClause ||
        clause.hint === cont.hint.trim()
        && clause.content === cont.content.trim())

  const changeField1 = () => {
    setGood(checkGood(cont))
    setUnchanged(isUnchanged())
    setCont({...cont})
  }

  const modifyCats = (clause, folders, cont) => {
    if (!newCats.size && !categories.size) return
  
    if (!cont) {
      Array.from(newCats).forEach(i => {
        if (categories.has(i)) {
          categories.delete(i)
          newCats.delete(i)
        } } )
      if (!isClause) {
       if (!newCats.size) folders.push(clause)
       if (!categories.size)
          clauseLib.folders = clauseLib.folders.filter(f => f !== clause)
      }
    }
    folders.forEach(folder => {
      if (newCats.has(folder.id)) {
        if (isClause) {
          if( !folder.clauses.includes(clause.id))
            folder.clauses.push(clause.id)
        } else if (folder.subs)
            folder.subs.push(clause)
          else folder.subs = [clause] 
        newCats.delete(folder.id)
      }
      if (categories.has(folder.id)) {
        if (isClause) {
          folder.clauses = folder.clauses.filter(i => i !== clause.id)
        } else if (folder.subs)
          folder.subs = folder.subs.filter(f => f !== clause)
        categories.delete(folder.id)
      }
      if (folder.subs)
        modifyCats(clause, folder.subs, 1)
    })
  }

  const traverseFolders = (folder, func) => {
    func(folder)
    if (folder.subs)
      folder.subs.forEach(f => traverseFolders(f, func))
  }

  const deleteClauseConfirmed = () => { 
    if (isClause && !clauseLib.clauses.find(c => c === clause)) 
      return      
    let parent = null
    const last = clauseLib.folders.pop()
    if (last && last.id !== 1) clauseLib.folders.push(last)
    const clauseFunc = f => {
      delete f.lvl
      f.clauses = f.clauses.filter(c => c !== clause.id)
    }
    const folderFunc = f => {
      delete f.lvl
      if (f.subs?.includes(clause))
        parent = f 
    }

    clauseLib.folders.forEach(f => traverseFolders(f,
      isClause ? clauseFunc : folderFunc))
    if (isClause) { 
       clauseLib.clauses = clauseLib.clauses.filter(c => c !== clause)
    } else {
      if (parent === null) {
        clauseLib.folders = clauseLib.folders.filter(f => f !== clause)
        parent = clauseLib.folders
      } else {
        clause.clauses.forEach(c => 
          !parent.clauses.includes(c) && parent.clauses.push(c))
        parent.subs = parent.subs.filter(f => f !== clause)
        parent = parent.subs
      }
      if (clause.subs)
        clause.subs.forEach(f => parent.push(f))      
    }    
    dispatch(getPopUpAction(popUpReset))
    serverSave()
  }

  const deleteClause = () => dispatch(getPopUpAction({
      name: 'confirm',
      text: transS('Are you sure, you want to delete the ')
        + transS(isClause ? 'clause' : 'category') + '?',
      confirm: {
        name: transS('Delete'),
        mod: 'red fill big',
        event: deleteClauseConfirmed        
        },
      cancel: {
        name: transS('Cancel'),
        event: () => dispatch(getPopUpAction(popUpReset))
        }
    }))

  const serverSave = async () => {
    clauseLib.stamp = Date.now()
    const isDraft = !clause.chgDraft && clause.isDraft
    delete clause.chgDraft
    api.setClauseLib(JSON.stringify(clauseLib), isDraft)
    .then(res => {
        clauseLib.stamp = 0
        dispatch(changeClause(res.data.data))
        callback(null)
    })
    .catch(err => {
        console.log('save clause error ', err)
        dispatch(getPopUpAction({
          name: 'confirm',
          text: transS('Error saving'),
          confirm: {
            name: transS('Got it'),
            mod: 'red fill big',
            event: () => {
              dispatch(changeClause(backCopy))
              callback(err)
              dispatch(getPopUpAction(popUpReset))
            }              
        }}))        
    })
  }

  const saveClause = (isDraft = undefined) => {
    // clear lvl and uncategorized
    // refresh categories if needed
    // go server
    const last = clauseLib.folders.pop()
    if (last && last.id !== 1) clauseLib.folders.push(last)
    if (isNew) {
      clause= {id: Date.now(),
      isDraft: isDraft === undefined ? true : isDraft}
      if (isClause)
        clauseLib.clauses.push(clause)
      else {
        clause.clauses = []
        if (!newCats.size)
         clauseLib.folders.push(clause)
      }
    } else if (isDraft !== undefined) {
      clause.isDraft = isDraft
      clause.chgDraft = true
    }
    clause.title = cont.title.trim()
    if (isClause) {
        clause.hint = cont.hint.trim()
        clause.content = cont.content.trim() 
        clause.edited = user._id
        clause.when = Date.now()
        if (adLib && !isNew)
            clauseLib.clauses.push(clause)
    }
    delete clause.lvl    
      
    if (stringifyCats(newCats) !== stringifyCats(categories))
      modifyCats(clause, clauseLib.folders)
    
    serverSave()
  }
  
  const canSelectCategory = clauseLib?.folders.reduce((a,f) => a ||
    (f.id !== 1 && f.id !== clause?.id), false)
   
  return <div styleName='editFixed' ref={ref}
    onClick={()=>false}
    style={shift ? {top: `${shift - MAGIC_SHIFT}px`} : {}}>
    
    <div styleName='upperSide'>
      <div styleName={isClause ? 'upperLeft' : 'upperFull'}>
        <div styleName='smallTitle'>
            {transS(isClause ? 'Clause Name' : 'Category Name')}
        </div>
        <TextArea name="title" 
           placeholder={isClause ? transS('Enter Clause Name')
            : transS('Enter Category Name')} 
           changed={()=>changeField1()}
           ronly={rOnly}
           big="1" container={cont} />
        
        {isClause && <>
          <div styleName='smallTitle'>
            {transS('Tip')}
          </div>
          <TextArea name="hint" placeholder={transS('Add Tip')} big="1"
           ronly={rOnly}
           changed={()=>changeField1()} container={cont} />
           </>}
      </div>
      {isClause && <div styleName='upperRight'>
        <div styleName='smallTitle'>
            {transS('Clause Text')}
        </div>
        <TextArea name="content" placeholder={transS('Enter Clause Text')} big="1"
           ronly={rOnly}
           changed={()=>changeField1()} container={cont} enterMode="1"/>        
      </div>}
    </div>
    {!rOnly && <div styleName='lowerSide'>
      <span styleName='orange tip'
         onClick={() => (unchanged || !good) ? null : saveClause()}>
            {!good && <Tip arrow='bottom left' text={tipText} />}
            {alwaysDraft ? transS('Save Draft') : transS('Save')}</span>      
      <span styleName={alwaysDraft ? "red" : ""}
          onClick={() => callback(null)}>
            {alwaysDraft ? transS('Cancel & Delete') : transS('Cancel') }</span>
      {canSelectCategory && <span styleName='distant'
          onClick={() => setShowCategories(true)}>
            {transS('Select Category')}
            {showCategories && 
            <Categories 
              cats={newCats}
              folders={clauseLib.folders}
              closedFolders={closedAddFolders}
              setOpenFolders={setOpenFolders}
              callback={res => {
                if (res)
                  setNewCats(new Set(res))
                if (stringifyCats(res) !== stringifyCats(categories) )     
                   setUnchanged(false)
                setTimeout(() => setShowCategories(false), 20)
              }}
              isClause={isClause}
              restriction={!isNew && !isClause ? clause.id : 0}
            />} 
        </span>}
      {isClause &&  !!isCompanyAdmin && !alwaysDraft &&
         <span styleName={'distant tip' + (!good ? ' inactive' : '')}
          onClick={() => saveClause(isPublished)}>
            {!good && <Tip arrow='bottom left' text={tipText} />}
            {transS(isPublished ? 'Unpublish' : 'Publish')}</span>}
      {!isNew && !adLib && <span styleName='red'
          onClick={deleteClause}>
            {transS('Delete')}</span>}
    </div>}

  </div>    
}
   
export default CSSModules(Clause, styles, {allowMultiple: true});


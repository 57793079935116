import React, {Component, Fragment} from 'react';
import CSSModules from "react-css-modules"
import styles from "./dataProtection.css"
import Footer from "../../../components/footer/Footer"

@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
class DataProtectionEU extends Component {

    scrollTo = (target) => {
        switch (target) {
            case '9.5.2':
                window.scrollTo({top: this.ref952.offsetTop - 106, left: 0, behavior: "smooth"})
                break
            case '9.2':
                window.scrollTo({top: this.ref92.offsetTop - 106, left: 0, behavior: "smooth"})
                break
            case '5.1':
                window.scrollTo({top: this.ref51.offsetTop - 106, left: 0, behavior: "smooth"})
                break
            case '5.2':
                window.scrollTo({top: this.ref52.offsetTop - 106, left: 0, behavior: "smooth"})
                break
            case '5':
                window.scrollTo({top: this.ref5.offsetTop + 14, left: 0, behavior: "smooth"})
                break
            case '6':
                window.scrollTo({top: this.ref6.offsetTop + 14, left: 0, behavior: "smooth"})
                break
            default:
                break
        }
    }

    render() {
        return (
            <Fragment>
                <div styleName='wrapper'>
                    <div styleName='title'>Data Protection Policy (Europe) <img src="../../../images/pad-lock.png"
                                                                                alt=""/></div>
                    <div styleName='version'>Version 1.0. <br/>
                        Effective as of January 1, 2020.
                    </div>

                    <div styleName='section'>
                        <div styleName='subTitle'><span>1</span>Purpose and scope</div>
                        <div styleName='paragraph'><span>1.1</span>This policy (the <b>“Policy”</b>) of Contract.one,
                            Inc
                            (the <b>“Company”</b>, <b>“we”</b>)
                            describes how we process personal data.
                        </div>
                        <div styleName='paragraph'><span>1.2</span>The Policy is applied when the user (<b>“you”</b>)
                            uses
                            our website (www.contract.one,
                            the <b>“Website”</b>) or our software (Contract.one platform, the <b>“Software”</b>).
                        </div>
                        <div styleName='paragraph'><span>1.3</span>The Policy is applied to European Economic Area and
                            European Union
                            citizens only and is made up in order to comply with GDPR.
                        </div>
                        <div styleName='paragraph'><span>1.4</span>We process personal data after you provide us with
                            your
                            consent.
                            You are free to refuse your consent at any time.
                            You will see consent options when you visit the Website for first time.
                        </div>
                        <div styleName='paragraph'><span>1.5</span>Since the CJEU invalidated EU-US Data Protection
                            Shield,
                            we supplement the
                            Policy with Standard Contractual Clauses which you join after you give consent.
                        </div>
                    </div>

                    <div styleName='section'>
                        <div styleName='subTitle'><span>2</span>Your rights</div>
                        <div styleName='paragraph'>
                            <div styleName='subTitle-desc'>Before giving a consent to process your personal data, we
                                must
                                provide you with information on rights with regards to data processing:
                            </div>
                            <div styleName='paragraph'><span>(1)</span><b>the right to be informed.</b> <br/>
                                You have the right to request an information on how we process your personal data and
                                with
                                whom
                                we share it (
                                <div onClick={e => this.scrollTo('5')} styleName='link'>Sections 5</div> and <div
                                    onClick={e => this.scrollTo('6')}
                                    styleName='link'>6</div>).
                            </div>
                            <div styleName='paragraph'><span>(2)</span><b>the right of access.</b> <br/>
                                You have the right to request (I) a copy of your personal data, (II) the purposes of
                                processing,
                                (III) an information on the
                                third parties or categories of third parties that receives your data.
                            </div>
                            <div styleName='paragraph'><span>(3)</span><b>the right to rectification.</b> <br/>
                                In case if there are some inaccuracies in your data, you have the right to rectify it (
                                <div onClick={e => this.scrollTo('9.5.2')}
                                     styleName='link'>Clause 9.5.2</div>).
                            </div>
                            <div styleName='paragraph'><span>(4)</span><b>the right to withdraw.</b> <br/>
                                You can request to erase and stop processing your personal data. Your consent withdrawal
                                means
                                immediate termination of our relationship.
                            </div>
                            <div styleName='paragraph'><span>(5)</span><b>the right to object.</b> <br/>
                                You may object to us using your personal data for our marketing purposes by contacting
                                us in
                                writing or by unsubscribing from our emailing list <br/>
                                (<div onClick={e => this.scrollTo('9.2')}
                                     styleName='link'>Clause 9.2</div>).
                            </div>
                        </div>
                    </div>

                    <div styleName='section'>
                        <div styleName='subTitle'><span>3</span>Our Principles</div>
                        <div styleName='paragraph'>
                            <div styleName='subTitle-desc'>The Company complies with the following principles:</div>
                            <div styleName='paragraph'><span>(1)</span>
                                Lawfulness, fairness, and transparency: we obey the law; only process personal data in a
                                way
                                that people would reasonably
                                expect; always are open about our data protection practices;
                            </div>
                            <div styleName='paragraph'><span>(2)</span>
                                Purpose limitation: we only process personal data for the specific reason we collected
                                it
                                and
                                nothing else;
                            </div>
                            <div styleName='paragraph'><span>(3)</span>
                                Data minimization: we don't process any more data than we need. <br/>
                                <br/>
                                The Company shall ensure that personal data are adequate, relevant and limited to what
                                is
                                necessary
                                in relation to the purposes for which they are processed.
                            </div>
                            <div styleName='paragraph'><span>(4)</span>
                                Accuracy: we make sure that any personal data we hold is adequate and accurate. <br/>
                                <br/>
                                The Company shall take reasonable steps to ensure personal data is accurate. <br/>
                                <br/>
                                Where necessary for the lawful basis on which data is processed, steps shall be put in
                                place
                                to
                                ensure that personal data is kept up to date.
                            </div>
                            <div styleName='paragraph'><span>(5)</span>
                                Storage limitation: we don't store personal data for longer than we need to;
                            </div>
                            <div styleName='paragraph'><span>(6)</span>
                                Integrity and confidentiality: we always process personal data securely.
                            </div>
                        </div>
                    </div>

                    <div styleName='section'>
                        <div styleName='subTitle'><span>4</span>Data Controller and Data Processor</div>
                        <div styleName='subTitle head-cols'>
                            <div>Information</div>
                            <div>Description and comments</div>
                        </div>
                        <div styleName='paragraph cols'>
                            <div>Data Controller</div>
                            <span>4.1</span>
                            <div>Contract.one, Inc. <br/>
                                2035 Sunset Lake Road, <br/>
                                Suite B-2 <br/>
                                Newark, DE 19702
                            </div>
                        </div>
                        <div styleName='paragraph cols'>
                            <div>Data Processor</div>
                            <span>4.2</span>
                            <div>Amazon Web Services, Inc. <br/>
                                410 Terry Avenue North, <br/>
                                Seattle, WA 98109-5210
                            </div>
                        </div>
                    </div>

                    <div styleName='section'>
                        <div styleName='subTitle' ref={e => this.ref5 = e}><span>5</span>Data we process</div>
                        <div styleName='subTitle head-cols'>
                            <div>Information Type</div>
                            <div>Details</div>
                        </div>
                        <div styleName='paragraph cols' ref={e => this.ref51 = e}>
                            <div>Account information</div>
                            <span>5.1</span>
                            <div>personal data, which you give us when register or manage the account — for example,
                                your
                                name, email, photo, payment information (if applicable).
                            </div>
                        </div>
                        <div styleName='paragraph cols' ref={e => this.ref52 = e}>
                            <div>Content</div>
                            <span>5.2</span>
                            <div>documents, messages, comments etc., which you upload.</div>
                        </div>
                        <div styleName='paragraph cols'>
                            <div>Data from your device and log files</div>
                            <span>5.3</span>
                            <div>the device and the browser you use, IP address, internet provider, the time you visited
                                the
                                Website, which pages you visited on
                                the Website, which Software features you used etc.
                            </div>
                        </div>
                        <div styleName='paragraph cols'>
                            <div>Cookies, pixel tags and other similar technologies data</div>
                            <span>5.4</span>
                            <div styleName='paragraph'>
                                <span>(1)</span>
                                <span style={{top: '329px'}}>(2)</span>
                                <div>
                                    cookies are small files, which identify you as a unique user by storing certain
                                    information about you, for example, login information or your preferences. <br/>
                                    <br/>
                                    You can set up your browser not to accept cookies, but this may limit your ability
                                    to
                                    use the Software or break it. <br/>
                                    <br/>
                                    Pixel tags are small pieces of code, which we may embed on the Website and to
                                    emails.
                                    They allow us to understand, for example,
                                    when you opened our email and if you are actually notified about personal data that
                                    is
                                    important to you.
                                </div>
                            </div>
                        </div>
                        <div styleName='paragraph cols'>
                            <div>Personal data from third parties</div>
                            <span>5.5</span>
                            <div styleName='paragraph'>
                                <span>(1)</span>
                                <span style={{top: '112px'}}>(2)</span>
                                <div>
                                    Another user may provide us with your email to invite you to use the Software.<br/>
                                    <br/>
                                    We may receive personal data about how you use the Website from third parties (for
                                    example, from providers of online marketing services).
                                    Such Information can be statistics of using the Website, the ways, how you get to
                                    the
                                    Website etc.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div styleName='section'>
                        <div styleName='subTitle' ref={e => this.ref6 = e}><span>6</span>With whom we may share
                            information
                        </div>
                        <div styleName='paragraph'>
                            <div styleName='subTitle-desc'>We may share personal data:</div>
                            <div styleName='paragraph'><span>(1)</span>with other users. <br/>
                                <br/>
                                It is necessary to arrange communication between the users. For example, your name and
                                photo
                                are
                                automatically displayed when you comment a contract;
                            </div>
                            <div styleName='paragraph'><span>(2)</span>
                                trusted services providers — for example, providers of the analytical software, which
                                allows
                                to
                                search vulnerabilities. <br/>
                                <br/>
                                When you use the Website, such software may collect personal data about your online
                                activities
                                on other websites and over time;
                            </div>
                            <div styleName='paragraph'><span>(3)</span>
                                integrated services providers. <br/>
                                <br/>
                                The Software feature may be available, which on demand allows to integrate the Software
                                with
                                third parties’ software.
                                We may share personal data with such third parties if you or other Software user ask for
                                such
                                integration. <br/>
                                <br/>
                                The Policy is not applied to any third parties’ software (if any). Please, study such
                                third
                                parties’ privacy policy;
                            </div>
                            <div styleName='paragraph'><span>(4)</span>
                                any third party if we reasonably consider the disclosure necessary —
                                <div styleName='subParagraph'><span>(i)</span>to comply with the law;</div>
                                <div styleName='subParagraph'><span>(ii)</span>prevent a fraud against users or us;
                                </div>
                                <div styleName='subParagraph'><span>(iii)</span>protect our rights;</div>
                            </div>
                            <div styleName='paragraph'><span>(5)</span>
                                any third party, which is specified by other Software user who has lawful access to your
                                personal data,
                                for example when another Software user has access to the same project as you do, and has
                                enough
                                rights to
                                invite a new user to that project;
                            </div>
                            <div styleName='paragraph'><span>(6)</span>
                                any third party, which is involved into change of our business (merger, acquisition
                                etc.);
                            </div>
                            <div styleName='paragraph'><span>(7)</span>
                                any member of our group (subsidiaries, corporate affiliates, if any).
                            </div>
                        </div>
                    </div>

                    <div styleName='section'>
                        <div styleName='subTitle'><span>7</span>Security</div>
                        <div styleName='paragraph'><span>7.1</span>
                            Security of personal data is important for us. We do our best to protect Information from
                            unauthorized access, destruction, use, modification or disclosure.
                            <div styleName='subParagraph'><span>(1)</span>We make sure that personal data is stored
                                securely
                                using modern technology that is kept-up-to-date. </div>
                            <div styleName='subParagraph'><span>(2)</span>Access to personal data is limited to
                                personnel
                                who need access and appropriate security should be in place to avoid unauthorised
                                sharing of
                                information.
                            </div>
                            <div styleName='subParagraph'><span>(3)</span>When personal data is deleted this should be
                                done
                                safely such that the data is irrecoverable.
                            </div>
                            <div styleName='subParagraph'><span>(4)</span>Appropriate back-up and disaster recovery
                                solutions shall be in place.
                            </div>
                        </div>
                        <div styleName='paragraph'><span>7.2</span>
                            However, any data transmission via the Internet is not 100 % secure. So, we do not warrant
                            the
                            security of personal data you transmit to or from the Software, and you do it at your own
                            risk.
                        </div>
                    </div>

                    <div styleName='section'>
                        <div styleName='subTitle'><span>8</span>Breach</div>
                        <div styleName='paragraph'><span>8.1</span>
                            In case of determination of the breach of security, the Company shall provide notice of any
                            breach of security to all the Software users that were affected by such breach. We will send
                            the
                            notice to the users’
                            email addresses that saved in the Software within 72 hours after we determine the breach.
                        </div>
                        <div styleName='paragraph'><span>8.2</span>
                            The Company shall also report on data breach to the relevant supervisory authority within 72
                            hours after breach detection.
                        </div>
                    </div>

                    <div styleName='section'>
                        <div styleName='subTitle'><span>9</span>Other provisions</div>
                        <div styleName='subTitle2'><span>9.1</span>Changes to Policy</div>
                        <div styleName='paragraph'><span>9.1.1</span>
                            We may change the Policy and in that case will make the changes available to you.
                        </div>
                        <div styleName='paragraph'><span>9.1.2</span>
                            If you do not accept the changes to the Policy, please, stop using the Software. If you use
                            the
                            Software subject to invitation from the Customer, please, instruct the Customer to ask us to
                            remove personal data it controls.
                        </div>
                        <div styleName='subTitle2' ref={e => this.ref92 = e}><span>9.2</span>Opt Out from Commercial
                            Emails
                        </div>
                        <div styleName='paragraph'>
                            We may from time to time send you emails, for example, about new Software features. You can
                            opt
                            out at any time by clicking the unsubscribe link in any email.
                        </div>
                        <div styleName='subTitle2'><span>9.3</span>Do Not Track</div>
                        <div styleName='paragraph'>
                            You may set up do not track feature for your browser, which allows to avoid tracking your
                            interactions with third parties’ websites over time. However, the Website currently does not
                            respond do not track signals from browsers.
                        </div>
                        <div styleName='subTitle2'><span>9.4</span>Governing Law</div>
                        <div styleName='paragraph'>
                            Storage, processing and transmission of personal data are governed by the law of the State
                            of
                            Delaware.
                        </div>
                        <div styleName='section'>
                            <div styleName='subTitle2'><span>9.5</span>Updating Information</div>
                            <div styleName='subTitle head-cols'>
                                <div>Personal data you can update</div>
                                <div>How to update</div>
                            </div>
                            <div styleName='paragraph cols'>
                                <div>Your comments to the documents (
                                    <div styleName='link' onClick={() => this.scrollTo('5.2')}>Clause 5.2</div>)
                                </div>
                                <span>9.5.1</span>
                                <div>just click the comment and change the text. Some changes may be limited to keep
                                    transparency and honesty of communication between the users.
                                </div>
                            </div>
                            <div styleName='paragraph cols' ref={e => this.ref952 = e}>
                                <div>Your account Information <br/>
                                    (<div styleName='link' onClick={() => this.scrollTo('5.1')}>Clause 5.1</div>)
                                </div>
                                <span>9.5.2</span>
                                <div>please, log in to your account and go to the settings page.</div>
                            </div>
                        </div>
                        <div styleName='subTitle2'><span>9.6</span>Retention</div>
                        <div styleName='paragraph'><span>9.6.1</span>
                            We will retain personal data for as long as:
                            <div styleName='subParagraph'><span>(1)</span>your account is active; or</div>
                            <div styleName='subParagraph'><span>(2)</span>we need personal data —</div>
                            <div styleName='subParagraph'>
                                <div styleName='subParagraph'><span>(i)</span>to provide you with the Software; or</div>
                                <div styleName='subParagraph'><span>(ii)</span>comply with our obligations, resolve
                                    disputes,
                                    enforce our agreements.
                                </div>
                            </div>
                        </div>
                        <div styleName='paragraph'><span>9.6.2</span>
                            We will delete your personal data after 36 months after your last logout from the Software.
                            We
                            may delete it earlier.
                        </div>
                        <div styleName='subTitle2'><span>9.7</span>Feedback</div>
                        <div styleName='paragraph'>
                            You may provide us with feedback about the Software. You grant us a perpetual, irrevocable,
                            worldwide, royalty-free license to use such feedback in any manner.
                        </div>

                        <div styleName='subTitle2'><span>9.8</span>Contact</div>
                        <div styleName='paragraph'>
                            Please contact our Data Protection Officer at <a styleName='link'
                                                                              href="mailto:security@contract.one">security@contract.one</a> if
                            you feel something is
                            not addressed in the Policy or have further questions. Or contact us at <a styleName='link'
                                                                                                       href="mailto:global@contract.one">global@contract.one</a>.
                        </div>
                    </div>


                    <div styleName='signature'>
                        Anton Vashkevich, <br/>
                        President and CEO
                    </div>

                </div>
                <Footer/>
            </Fragment>
        )
    }
}

export default DataProtectionEU
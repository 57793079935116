import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import styles from './unitList.css'
import { transF, transL, transS } from '../../../../services/helpers/lang';
import ShowDocStatus from './ShowDocStatus';
import ShowAvatars from './ShowAvatars';
import { showContext} from './Units';
import ShowActivity from './ShowActivity';
import { ContextMenu } from '../../../../components/contextMenu/ContextMenu';
import { getContextItems} from './UnitsHelper';
import { unitHelper } from './unitsFuncs';
import { getRelativePosition } from '../../../../services/helpers';
import { rights } from '../../../../../configs/appConfig';
import { UniPopup } from '../../../../components/popupWindows/UniPopup';
import { pinUnit } from '../../../../services/sockets/directStore';

const ShowElement = ({unit, mark}) => { 
    
    const {unitsStruc, unitsList, luserId, 
          } =  useSelector(state => state.unitReducer),
        {openTeamsCallback, contextMenuCallback, 
          firstColumnStyle, icons} =  showContext(),
        fakeUnit = {}

    if (!unit || !(unitsList.get(unit.loc_id))) 
          unit = fakeUnit
    const {isDoc, contentType, isFolder, hasChildren, iAmInternal, canEditTeams,
        isInternal, hasExternals, inGroup, hasParams, docProps} = unitHelper(unit, luserId),
      padStyle = {marginLeft: `${(!mark || unit.pinnedFolder ? unit.level : 1)*40}px`},
      [isOpen, setIsOpen] = useState(hasChildren && 
        unitsStruc.openFolders.has(unit.loc_id)),
      [showTeams, setShowTeams] = useState(null),
      [contextMenu, setContextMenu] = useState({ x: null, y: null, items: [] }),
      [showPopup, setShowPopup] = useState(null),
      [drawState, setDrawState] = useState(0),
      navigate = useHistory(),
      unitRef = useRef(),
      isExcludedFolder = ['_pinned','_externals'].includes(unit.loc_id),
      isUnitPinned = unitsList.get('_pinned').folders.includes(unit.loc_id)

    const externalCompany = transS('Unspecified Companies')
    
    const goUnit = () => {
      if (unit.pinnedFolder)
        return
      if (isFolder)
        navigate.push(`/units/${unit.loc_id}`)
      else {
        // find old project
        let parent = unitsList.get(unit.ancestor)
        while (parent.ancestor) 
          parent = unitsList.get(parent.ancestor)
        navigate.push(`/${parent.loc_id.slice(2)}/documents/${unit.loc_id.slice(2)}`)
      }
    }

    const editTeams = () => null // to do

    const toggleTeams = () => {    
      if (showTeams) {
        setShowTeams(false);
        openTeamsCallback.call = null
      } else {
        if (openTeamsCallback.call)
          openTeamsCallback.call()
        openTeamsCallback.call = () => setShowTeams(false)
        setShowTeams(true)
      }
    }

    const showContextMenu = event => {
      event.preventDefault();
      if (contextMenuCallback.call) 
        contextMenuCallback.call()
      if (isExcludedFolder) return
      contextMenuCallback.call = closeContextMenu
      const {x, y} = getRelativePosition(event, unitRef.current)
      setContextMenu({ x, y, 
        items: getContextItems(unit, x, y, 
          setShowPopup, isUnitPinned)})
    }
    const closeContextMenu = () => setContextMenu({ x: null, y: null, items:[] })

    const toggleArrow = (id) => {
        if (unitRef.current.dataset.closed != 1) {
          if (!mark || id === '_pinned')
            unitsStruc.openFolders.delete(id)
          unitRef.current.dataset.closed = "1"
        } else {
          if (!mark || id === '_pinned')
            unitsStruc.openFolders.add(id)
          unitRef.current.dataset.closed = ""
        }
      }
    
    let visibleTitle = unit.title, visibleExtension = ''
    if (contentType) {
      const name = visibleTitle.split('.');
      visibleExtension = name.pop().toUpperCase();
      visibleTitle = name.join('.');
    }

    useEffect(() => {
      if (mark && !unit.pinnedFolder)
        unit.pRefresh = () => setDrawState(d => d+1)
      else
        unit.refresh = () => setDrawState(d => d+1)
      return () => {
        if (mark && !unit.pinnedFolder)
          unit.pRefresh = null
        else
          unit.refresh = null
      }
    },[])

    if (isExcludedFolder && !unit.folders.length || unit === fakeUnit)
      return false
      
    const lockid = mark && !unit.pinnedFolder ? (mark + unit.loc_id) : unit.loc_id
    return (
      <div styleName='unit' data-closed={isOpen ? "" : "1"}
        data-lockid={lockid}
        data-filter-hide={mark ? "1" : ""}
        data-filter-approve={isDoc && docProps.approvalRequired ? "" : "1"}
        data-filter-progress={isDoc && unit.orig !== 12 ? "" : "1"}
        data-filter-fin={isDoc && unit.orig === 12 ? "" : "1"}
        onContextMenu={showContextMenu} ref={unitRef}>
        <div styleName='unit-child unit-flex' style={firstColumnStyle}>
          <div styleName='unit-child unit-tiny' style={padStyle}>
            {isFolder ? icons.folder
              : isDoc ? icons.doc
              : <span styleName='unit-extension'>{visibleExtension}</span>}
          </div>
          <div styleName='unit-child unit-title'
            onClick={goUnit}>
            {visibleTitle}
          </div>
          {(unit.pinnedFolder || hasChildren && !mark) 
          && <div styleName='unit-child unit-arrow'
            onClick={ e => toggleArrow(unit.loc_id)}          
          >
            <span data-on-open="1" data-filter-hide="1">{icons.arrow_up}</span>
            <span data-on-close="1" data-filter-hide="1">{icons.arrow_down}</span>
          </div>}
        </div>

        <div styleName='unit-child unit-teams' 
          onClick={() => (hasExternals || isInternal || canEditTeams) && 
            (unit.members ? toggleTeams() 
            : (canEditTeams && editTeams()))}
        >
          { isExcludedFolder ? false :
            showTeams ? <>
            {!!hasExternals && !!iAmInternal && <div className='dev-button-std'
              styleName="orig-external for-teams">
              <span>{externalCompany}</span>
              <ShowAvatars arr={unit.members.filter((_, i) => 
                  unit.rights[i] & rights.EXTERNAL)} />
            </div>}
            <div className='dev-button-std' 
              styleName="orig-internal for-teams">
              <span>{transL('orig-internal')}</span>
              <ShowAvatars arr={unit.members.filter((_, i) => 
                unit.rights[i] & (iAmInternal ? rights.INTERNAL: rights.EXTERNAL))} />              
            </div>
            {canEditTeams && <span className='dev-button-std' styleName='edit-teams'>
                {transS('Edit Teams')}
            </span>}
          </>
          : hasExternals ? <span className='pointable'>{externalCompany}</span> :
            isInternal ? 
              <span className='pointable'>{transS('Internal')}</span>
            : canEditTeams && 
            <span className='dev-button-std' styleName='add-teams'>
                {transS('Add Teams')}
            </span>
          }
        </div>

        <div styleName='unit-child unit-status'>
          {isDoc ? <ShowDocStatus docProps={docProps} /> 
          : isFolder ? '' : unit.shared ? 
            <div className='dev-button-std' styleName='orig-external'>
                {transS('Shared')}
            </div>
             : ''}
        </div>

        <div styleName='unit-child unit-narrow'>
           {isDoc && <ShowActivity
              id={unit.loc_id} 
              lastAuthor={docProps.lastAuthor}
              lastActivity={docProps.lastActivity}
              checkChanges={docProps.checkChanges}/>
            }
        </div>
      
        <div styleName='unit-child unit-wide'>
            
        </div>
        {!isExcludedFolder && <div styleName='unit-child unit-last'>
          {hasParams && icons.arrow_up}
          {isUnitPinned && <span onClick={() => pinUnit(unit)}>
             {icons.pinned}
          </span>}
            
          <span onClick={showContextMenu}>{icons.mouse}</span>
        </div> }   
        {contextMenu.x !== null && contextMenu.y !== null && (
          <ContextMenu
            x={contextMenu.x}
            y={contextMenu.y}
            items={contextMenu.items}
            onClose={closeContextMenu}
          />
        )}
        {!!showPopup && <UniPopup {...showPopup} />}
      </div>
    )
  }

  export default CSSModules(ShowElement, styles, {allowMultiple: true});

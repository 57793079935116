import React from 'react';
import Privacy from './Privacy';
import styles from './static.css';
import CSSModules from 'react-css-modules';

const Terms = () => {return (
    <Privacy isPrivacy={false} />
)}

export default new CSSModules(Terms, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });


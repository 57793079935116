import React from 'react';
import { NavLink } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import styles from './static.css';
import Footer from '../../../components/footer/Footer';

const Contacts = () => {
  window.scrollTo(0, 0)
  return (
      <React.Fragment>
    <div styleName="wrapper">  
      <div styleName="content">  
        <div styleName="header">Contacts</div>
        <div styleName="subheader">Customer support </div>
        <div styleName="normal"></div>
        <div styleName="normal womargin"> 
          <span styleName="link"> global@contract.one </span><br></br>
          +1 (929) 999-1891<br></br>
          <br></br>
            2035 Sunset Lake Road,<br></br>
            Suite B-2,<br></br>
            Newark, DE 19702<br></br> <br></br> 
              Please report any security issues to<br /> 
              <span styleName="link">security@contract.one</span>
            <br></br>
        </div>
        <div styleName="subheader">Join Our Team</div>
        <div styleName="normal"></div>
        <div styleName="normal womargin"> 
          <span styleName="link"> genius@contract.one</span>          
        </div>
        <div styleName="subheader"></div>
        <div styleName="placeholder"></div>
        <div styleName="normal"></div>
        <div styleName="normal"></div>
        <div styleName="normal"></div>
      </div>
      <div styleName="align"></div>
    </div>
        <Footer />
      </React.Fragment>
)};

export default new CSSModules(Contacts, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });



import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import CSSModules from 'react-css-modules';
import styles from './paymentComponent.css';
import { showPaymentAction, showExpiredAction } from '../../../../services/actions/userActions';
import Title from '../../../../components/promo/Title'
import Slogan from '../../../../components/promo/Slogan'
import Price from '../../../../components/promo/Price';
import PlanElement from '../../../../components/promo/PlanElement'
import Logo from '../../../../components/logo/Logo';
import UserLogo from '../../../../components/userLogo/UserLogo';
import { renewUserAction } from '../../../../services/actions/userActions';
import { getPopUpAction }  from '../../../../services/actions/dataActions';
import { popUpReset }      from '../../../../services/helpers';

import api from '../../../../services/api/api';
import Spinner from '../../../../components/spinner/Spinner'

import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm';
import Button from "../../../../components/button/Button"
import { canTariffed, transF, transS } from '../../../../services/helpers/lang';

@connect(state => ({
    showExpired: state.userReducer.showExpired,
    user: state.userReducer.user,
    projectList: state.projectReducer.projectList
}), {
    showPaymentAction, showExpiredAction, renewUserAction, getPopUpAction, popUpReset
})
@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
export default class Payment extends Component {
    state = {
        isSelected: false,
        months: 12,
        total: 504,
        proceed: false,
        congrats: false,
    }

    componentWillUnmount() {
        document.querySelector('body').style.overflow = ''
    }

    _close = () => {
        const {showPaymentAction} = this.props
        showPaymentAction(false)
    }

    _setMonth = (value) => {
        const {config} = this.state;
        if (value <= 0 || value > 60) return
        let total = ((value - (value % 12)) * config.smart_price) + (value % 12 * config.standart_price)
        this.setState({months: value, total})
    }

    _submit(e) {
        if (!this.state.proceed)
            this.setState({proceed: true});
    }

    _checkout = (result) => {
        if (result) {
            this.props.renewUserAction(result);
            this.setState({congrats: true});
            return;
        }
        this._close();
    }
    
    componentDidMount() {
      document.querySelector('body').style.overflow = 'hidden';
      if (!this.state.config)
      api.getConfig()
        .then( res => {
               this.setState({config: res.data, total: res.data.smart_price*12});
      })
    }


    render() {
        const {isSelected, months, total, config, congrats} = this.state
        const {user, projectList, getPopUpAction, popUpReset} = this.props

        let expDate = new Date(user.accessExpires);
        const plan = user.totalPaying ? transS('Basic') : ((user.ticks <= 0) ? transS('Free') : transS('Demo'))
        let msecs = user.ticks - ((new Date()) - user.loginTime);
        let daysLeft = Math.trunc(msecs/1000/60/60/24);
        let daysLeftString = daysLeft ? daysLeft.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : daysLeft
        let expString = expDate.toLocaleDateString('en-us',
            {year: 'numeric', month: 'long', day: 'numeric',});

        let docsLeft, docsCount = 0
        if (!user.totalPaying && user.ticks <= 0) {
            projectList.forEach(p => {
                if (p.docs) docsCount += p.docs.length
            })
            if (docsCount < 5) docsLeft = 5 - docsCount
        }
        
        if (congrats) getPopUpAction(
          {
            name: 'confirm',
            header: 'Succesfully Upgraded',
            text: `Paid for: ${months} Months<br><br>
                Until: ${expString}<br><br>
                Plan: Basic<br><br>
                Paid: $${total}<br><br>`,
            confirm: {
                name: 'Excellent',
                event: () => {
                    getPopUpAction(popUpReset);
                    this._close()
                }
            },
          }      
        );

        return !config ?
          <Spinner />
        : (
            <div styleName='wrapper'>
                <div styleName='card'>
                    <div styleName='title'>Manage Your Plan</div>
                    <div onClick={this._close} className='close-cross'>+</div>
                    <div styleName='current'>Your current plan: <span>{plan}</span>.</div>

                    <div styleName='desc'>

                            {(user.ticks >= 0 || user.totalPaying) ?

                                user.totalPaying ?

                                    user.ticks >= 0 ?

                                        daysLeft <= 14 ?
                                            <div styleName='red'>
                                                {transS('Paid until')} {expString}
                                                <div>{`(${daysLeftString} ${daysLeft === 1 ? 'day' : 'days'} left)`}</div>
                                            </div>
                                            :
                                            <React.Fragment>
                                                {transS('Paid until')} {expString}
                                                <div
                                                    styleName='gray'>{`(${daysLeftString} ${daysLeft === 1 ? 'day' : 'days'} left)`}</div>
                                            </React.Fragment>

                                        :
                                        <React.Fragment>
                                            <div styleName='red' style={{fontWeight: 'bold'}}>Expired!</div>
                                            <div styleName='gray'>(on {expString})</div>
                                        </React.Fragment>

                                    :
                                    <React.Fragment>
                                        {transS('Available until')} {expString}
                                        <div styleName='gray'>{`(${daysLeftString} ${daysLeft === 1 ? 'day' : 'days'} left)`}</div>
                                    </React.Fragment>

                                :
                                docsLeft ?
                                    <div>
                                        {transF('You can add')(docsLeft)}
                                        <div styleName='gray'>{transF('You already have')(docsCount)}</div>
                                    </div>
                                    :
                                    <div>
                                        <div styleName='gray'>You cannot add document for free.</div>
                                        <div styleName='gray'>{transF('You already have')(docsCount)}</div>
                                    </div>
                            }



                    </div>

                    {canTariffed() &&
                    <div className='h2' style={{margin: '50px 0 20px 0', lineHeight: 'normal'}}>{user.totalPaying ? transS('Renew Plan') : transS('Upgrade')}</div>
                    }
                    {!user.totalPaying &&
                    <div styleName={cx('plan-basic', {'selected': isSelected})} onClick={() => this.setState({isSelected: true})}>
                        <div styleName='plan-basic-title'>{transS('Basic')}</div>
                        <div styleName='plan-basic-desc'>Unlimited Projects</div>
                        <div styleName='price'>
                            <div>$</div>
                            <div>{config.smart_price}</div>
                            <div>per month billed annually</div>
                        </div>
                        <div styleName='price2'>
                            <div>$</div>
                            <div>{config.standart_price}</div>
                            <div>per month billed monthly</div>
                        </div>
                    </div>}

                    { (!user.totalPaying && isSelected) && <div className='space20'></div>}
                    { (user.totalPaying || isSelected) &&
                    <div styleName='plan-renew'>
                        <div styleName='plan-renew-months'>
                            <div styleName='plan-renew-head'>Paid for</div>
                            {`${months} ${months === 1 ? 'Month' : 'Months'}`}
                            <div styleName='monthsUp' onClick={() => this._setMonth(months + 1)}></div>
                            <div styleName='monthsDown' onClick={() => this._setMonth(months - 1)}></div>
                        </div>
                        <div styleName='plan-renew-total'>
                            <div styleName='plan-renew-head'>Total</div>
                            <div styleName='plan-renew-total-sum'><span>$</span>{total}</div>
                        </div>
                        <div styleName='plan-renew-checkBtn'>
                            <Button mod='promoMainBtn margin0' text='Checkout' callback={() => this._submit()}/>
                            <div styleName='paymentDesc'>Online payment by Stripe</div>
                        </div>
                    </div>}

                    { this.state.proceed && ( //apiKey="pk_test_d69flD9IMUPsteUOyOcxfoYy or pk_live_fHFTiOnZYrO3qH0HGRVyucG7" 
                        <div styleName='calc'>
                            <div styleName='calc-wrapper'>
                                <StripeProvider apiKey="pk_live_fHFTiOnZYrO3qH0HGRVyucG7">
                                    <Elements>
                                        <CheckoutForm
                                            returnFunc={this._checkout}
                                            clicked={()=>{return months}}
                                        />
                                    </Elements>
                                </StripeProvider>
                            </div>
                        </div>
                    ) }

                </div>
            </div>
        )
    }
}

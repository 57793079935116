export const ADD_FILE = 'ADD_FILE';
export const ADD_FILE_SUCCESS = 'ADD_FILE_SUCCESS';
export const ADD_FILE_FAILED = 'ADD_DOCUMENT_FAILED';
export const IMPORT_FILE = 'IMPORT_FILE';

export const FETCH_DOCUMENT = 'FETCH_DOCUMENT';
export const FETCH_DOCUMENT_SUCCESS = 'FETCH_DOCUMENT_SUCCESS';
export const FETCH_DOCUMENT_FAILED = 'ADD_DOCUMENT_FAILED';

export const UPDATE_DOCUMENT_LIST = 'UPDATE_DOCUMENT_LIST';
export const UPDATE_DOCUMENT_LIST_SUCCESS = 'UPDATE_DOCUMENT_LIST_SUCCESS';
export const UPDATE_DOCUMENT_LIST_FAILED = 'UPDATE_DOCUMENT_LIST_FAILED';

export const GET_DOCUMENT_LIST = 'GET_DOCUMENT_LIST';
export const GET_DOCUMENT_LIST_SUCCESS = 'GET_DOCUMENT_LIST_SUCCESS';
export const GET_DOCUMENT_LIST_FAILED = 'GET_DOCUMENT_LIST_FAILED';
export const CLEAR_DOCUMENT_LIST = 'CLEAR_DOCUMENT_LIST';

export const SET_CURRENT_DOCUMENT = 'SET_CURRENT_DOCUMENT';
export const SET_CURRENT_DOCUMENT_SUCCESS = 'SET_CURRENT_DOCUMENT_SUCCESS';
export const SET_CURRENT_DOCUMENT_FAILED = 'SET_CURRENT_DOCUMENT_LIST_FAILED';

export const GET_ACTUAL_DOCUMENT = 'GET_ACTUAL_DOCUMENT';
export const GO_ACTUAL_DOCUMENT = 'GO_ACTUAL_DOCUMENT';
export const SET_ACTUAL_DOCUMENT = 'SET_ACTUAL_DOCUMENT';
export const SET_DOCUMENT_ENVELOPE = 'SET_DOCUMENT_ENVELOPE';
export const ADD_VARIABLE = 'ADD_VARIABLE';
export const SET_VARIABLE = 'SET_VARIABLE';
export const SET_USED_VARS = 'SET_USED_VARS';
export const SET_FORMATABLE = 'SET_FORMATABLE';
export const SHOW_DATE_CONTROL= 'SHOW_DATE_CONTROL';
export const SET_VAR_CLICK = 'SET_VAR_CLICK';
export const SET_VAR_CHANGE = 'SET_VAR_CHANGE';

export const NEW_SUBVERSION = 'NEW_SUBVERSION';
export const RETURN_SUBVERSION = 'RETURN_SUBVERSION';
export const NEW_DEMO = 'NEW_DEMO';
export const TRANSFER_EDITING = 'TRANSFER_EDITING';
export const APPROVE_EDITING = 'APPROVE_EDITING';
export const DISAPPROVE_EDITING = 'DISAPPROVE_EDITING';
export const REQUEST_EDITING = 'REQUEST_EDITING';
export const OVERTAKE_EDITING = 'OVERTAKE_EDITING';
export const GET_HISTORY_DOCUMENT = 'GET_HISTORY_DOCUMENT';

export const DISPLAY_ONLY = 'DISPLAY_ONLY';
export const CHANGE_APPROVAL = 'CHANGE_APPROVAL';

export const NEW_DOCUMENT_SUBVERSION_SOCKET = 'NEW_DOCUMENT_SUBVERSION_SOCKET';
export const NEW_DOCUMENT_VERSION_SOCKET = 'NEW_DOCUMENT_VERSION_SOCKET';
export const GET_DOCUMENT_BY_REQUEST_SOCKET = 'GET_DOCUMENT_BY_REQUEST_SOCKET';
export const GET_DOCUMENT_BY_FORCE_SOCKET = 'GET_DOCUMENT_BY_FORCE_SOCKET';

export const SET_DOCUMENT_VIEWED = 'SET_DOCUMENT_VIEWED';
export const SET_DOCUMENT_VIEWED_SUCCESS = 'SET_DOCUMENT_VIEWED_SUCCESS';

export const RENAME_DOCUMENT_FROM_SIDEBAR = 'RENAME_DOCUMENT_FROM_SIDEBAR';
export const SET_DOCUMENT_STATS = 'SET_DOCUMENT_STATS';

export const SET_DOCUMENT_NAVIGATION = 'SET_DOCUMENT_NAVIGATION';
export const TOGGLE_DOCUMENT_NAVIGATION_VIS = 'TOGGLE_DOCUMENT_NAVIGATION_VIS';
export const CLEAR_BACK_NAVIGATION = 'CLEAR_BACK_NAVIGATION';

export const CLEAR_WRITER_COVER_MESSAGE = 'CLEAR_WRITER_COVER_MESSAGE';
export const SET_WRITER_COVER_MESSAGE = 'SET_WRITER_COVER_MESSAGE';


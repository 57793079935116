import React from 'react'
import { useSelector } from 'react-redux';
import { StdToolTip } from '../../../../components/StdToolTip';
import { colors, fontSize } from '../../../../components/colors';
import { getAllTeamMembers } from './unitsFuncs';


export const Avatar = ({userId, external}) => {
    const {knownUsers, companyTree} = useSelector(state => state.unitReducer)
    const getFirstLetters = ({firstname, lastname, email}) => 
      (firstname) ? firstname.slice(0, 1) + lastname.slice(0, 1) : email.slice(0, 2)

    const user = knownUsers.get(userId) || companyTree && 
      companyTree.find(t => userId === t.loc_id),
      isUser = user?.userid
    if (!user) return false

    const userName = u => `${u.firstname} ${u.lastname}`,
      getTeam = () => {
        const a = Array.from(getAllTeamMembers(companyTree, userId))
          .map(el => userName(knownUsers.get(el)))
        return a.join(`\n`) },
      tipContent = isUser 
      ? `${userName(user)}\n${user.email}`
      : getTeam()

    const styleCircle = {
      width: 25, 
      height: 25,
      borderRadius: '50%',
      objectFit: 'cover'
    }, styleLetters = {
        width: 25,
        height: 25,
        lineHeight: '25px',
        borderRadius: '50%',
        fontSize: fontSize.tip,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        color: colors.white,
        backgroundColor: external ? colors.externalIcon : colors.gray
    }
        
    return <StdToolTip 
      content={<div style={{whiteSpace: 'pre-line'}}>{tipContent}</div>}
      arrowCenter="1"> 

        {!isUser ? <span style={styleLetters}><Icon_Team /></span>
        : user.avatar 
        ? <img src={user.avatar} style={styleCircle} />
        : <span style={styleLetters}>
            {getFirstLetters(user)}</span>}

    </StdToolTip>
    
}

import React, {Component} from 'react'
import styles from './companySettings.css'
import cssModules from "react-css-modules"
import cx from "classnames"
import Button from "../../../../components/button/Button"
import {connect} from "react-redux"
import UserLine from "./userLine"
import {
    changeCompanyAction,
    deleteCompanyMemberAction,
    getCompanyMembersAction,
    inviteCompanyMembersAction,
    activateCompanyMemberAction,
    changeCompanyMemberPasswordAction
} from "../../../../services/actions/userActions"
import Spinner from "../../../../components/spinner/Spinner"
import AddMember from "./addMember"
import TeamLine from "./teamLine"
import AddTeam from "./addTeam"
import {popUpReset} from "../../../../services/helpers"
import {getPopUpAction} from "../../../../services/actions/dataActions"
import UserListItem from "../../components/createProjectDropDown/UserListItem"
import {Redirect} from "react-router-dom"
import { transS } from '../../../../services/helpers/lang'

@connect(state => ({
    user: state.userReducer.user,
    company: state.userReducer.user.company && state.userReducer.user.company.knownCompanies[0] || null,
    loader: state.userReducer.user.company && state.userReducer.user.company.loader || null
}), {
    changeCompanyAction,
    getCompanyMembersAction,
    inviteCompanyMembersAction,
    deleteCompanyMemberAction,
    getPopUpAction,
    popUpReset,
    activateCompanyMemberAction,
    changeCompanyMemberPasswordAction
    }
)

@cssModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
class CompanySettings extends Component {
    state = {
        selectedPage: 0,
        name: {
            value: '',
            invalid: false,
        },
        address: {
            country: '',
            state: '',
            zip: '',
            address1: '',
            address2: '',
        },
        role: '',
        members: {},
        editableMember: null,
        editableTeam: null,
        isModified: false,
        isShowDeactivated: false,
        teams: [],
        deletedTeams: []
    }

    componentDidMount = () => {
        const {members} = this.state
        const {company} = this.props

        if (company) {
            this.setState({...company, name: {value: company.name, invalid: false}})
        }

        this._getMembers()

        let tab = this.props.location.hash.split('/')[1];
        if (tab) this._page(tab);

        window.scrollTo(0, 0)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.company && this.state.members !== this.props.company.members) {
            this.setState({...this.props.company, name: {value: this.props.company.name, invalid: false}})
        }
    }

    _page = (page) => {
        switch (page) {
            case 0:
                this.setState({selectedPage: 0})
                this.props.history.push('/company')
                break;
            case 1:
            case 'members':
                this.props.history.push('/company/#/members')
                this.setState({selectedPage: 1})
                break;
            case 2:
            case 'billing':
                this.setState({selectedPage: 2})
                this.props.history.push('/company/#/billing')
                break;
            case 3:
            case 'setting':
                this.setState({selectedPage: 3})
                this.props.history.push('/company/#/setting')
                break;
            case 4:
            case 'support':
                this.setState({selectedPage: 4})
                this.props.history.push('/company/#/support')
                break;
            case 5:
            case 'trash':
                this.setState({selectedPage: 5})
                this.props.history.push('/company/#/trash')
                break;
        }
    }

    _getMembers = () => {
        const {company, user, getCompanyMembersAction} = this.props
        const {members} = this.state

        if (!company) {
            this.setState({teams: ['_general']})
            return this.setState({
                members: {
                    _general: {
                        members: [{ _id: user._id,
                            firstname: user.firstname,
                            lastname: user.lastname,
                            avatar: user.avatar,
                            email: user.email,
                            totalPaying: user.totalPaying,
                            accessExpires: user.accessExpires,
                            ticks: user.ticks,
                            isActivated: true,
                            accessStatus: 2,
                            title: '',
                            teams: ['_general'] }]
                    }
                }
            })
        }

        const teams = []
        for (let team in company.members) {
            if (!company.members.hasOwnProperty(team) || team.startsWith('_')) continue;
            teams.push(team)
        }
        this.setState({teams})

        getCompanyMembersAction()
    }

    changeCompany = () => {
        const {company, user, changeCompanyAction} = this.props
        const {name, address, role, members, deletedTeams} = this.state
        let data = {name: name.value, address, role, workflows: user.workflows, members, deletedTeams}
        if (!company && !name.value) {
            this._page(0)
            return this.setState({name: { value: name.value, invalid: true} } )
        }

        changeCompanyAction(data)
        this.setState({isModified: false, deletedTeams: []})
    }

    inputChangeHandler = (e) => {
        const {name} = this.state
        if (e.target.name.indexOf('address') + 1) {
            const name = e.target.name.split(' ')[1]
            this.setState({address: {...this.state.address, [name]: e.target.value}})
        }
        if (e.target.name === 'name') return this.setState({name: {value: e.target.value, invalid: false}, isModified: true})
        this.setState({[e.target.name]: e.target.value, isModified: true})
    }

    inviteMember = member => {
        let {members} = this.state
        const {company, inviteCompanyMembersAction} = this.props

        if (member.addedTeams && member.addedTeams.length) {
            member.addedTeams.forEach(t => {
                const user = {...member}
                delete user.addedTeams
                delete user.deletedTeams
                members[t].members.push(user)
            })
        }
        delete member.addedTeams
        delete member.deletedTeams

        inviteCompanyMembersAction(members)

        this.setState({editableMember: null, members})
    }

    acceptedUserRequest = member => {
        const {members} = this.state
        const {inviteCompanyMembersAction} = this.props

        const membersTest = {...members, _invited: [...members._invited]}

        member.teams = ['_general']
        member.deletedTeams = ['_invited']

        membersTest._general.members.push(member)

        this.setState({members: members})
        inviteCompanyMembersAction(membersTest)
    }

    deleteMember = (member) => {
        const {members} = this.state
        const {user, company, deleteCompanyMemberAction, getPopUpAction, popUpReset} = this.props

        if (member._id === user._id) return

        if (!company) {
            const memberId = member._id || member._localId

            let updMembers = {...members, _general: {...members._general} }
            member.teams.map(t => {
                updMembers[t].members = members[t].members.filter(m => memberId !== (m._id || m._localId) )
            })

            this.setState({members: updMembers})
            return this.setState({editableMember: null})
        }

        deleteCompanyMemberAction([member._id])
        getPopUpAction(popUpReset);
    }

    editMember = member => {
        let {members} = this.state
        const {company, inviteCompanyMembersAction} = this.props

        if (member.deletedTeams.length) {
            member.deletedTeams.forEach(t => {
                const filteredMembers = members[t].members.filter(i => (i._id || i._localId) !== (member._id || member._localId))
                members[t].members = filteredMembers
            })
        }
        if (member.addedTeams.length) {
            member.addedTeams.forEach(t => {
                const user = {...member}
                delete user.addedTeams
                delete user.deletedTeams
                members[t].members.push(user)
            })
        }

        member.teams.map(t => {
            members[t].members.map((m, index) => {
                if ((m._localId || m._id) === (member._localId || member._id)) return members[t].members[index] = member
                return m
            })
        })


        inviteCompanyMembersAction(members)

        this.setState({members, editableMember: null})
    }

    deactivateMember = (member) => {
        const {user, activateCompanyMemberAction, getPopUpAction, popUpReset} = this.props

        if (member._id === user._id) return getPopUpAction(popUpReset);

        activateCompanyMemberAction({deactivateMembers: [member._id]})
        getPopUpAction(popUpReset);
    }

    activateMember = (member) => {
        const {user, activateCompanyMemberAction} = this.props

        if (member._id === user._id) return

        activateCompanyMemberAction({activateMembers: [member._id]})
    }

    changeMemberPassword = (memberId, newPassword) => {
        const {changeCompanyMemberPasswordAction} = this.props
        changeCompanyMemberPasswordAction(memberId, newPassword)
    }

    addTeam = (team) => {
        let {members, teams, deletedTeams} = this.state
        const {company} = this.props

        if (team.oldName) {
            members[team.name] = {...members[team.oldName]}
            teams = teams.filter(t => t !== team.oldName)
            teams.push(team.name)
            members[team.name].members = members[team.name].members.map(m => {
                m.teams = teams
                return m
            })

            team.members.forEach(m => {
                const userIndex = members._general.members.findIndex(i => (i._id || i._localId) === (m._id || m._localId))
                members._general.members[userIndex] = m
            })

            if (deletedTeams.find(t => t === team.name)) {
                deletedTeams = deletedTeams.filter(t => t !== team.name)
            } else {
                deletedTeams.push(team.oldName)
            }

            delete members[team.oldName]
        }

        if (team.parentTeams && team.parentTeams.length === 0) {
            delete members[team.name].parentTeams
            delete team.parentTeams
        }

        if (team.deletedMembers) {
            team.deletedMembers.forEach(u => {
                team.members = team.members.filter(i => (i._id || i._localId) !== (u._id || u._localId) )

                const userIndex = members[team.name].members.findIndex(i => i._id || i._localId === u._id || u._localId)
                if (userIndex) members[team.name].members[userIndex].teams = members[team.name].members[userIndex].teams.filter(i => i !== team.name)

                u.teams = u.teams.filter(t => t !== team.name)

                if (team.oldName) u.deletedTeams = [team.oldName]
                else u.deletedTeams = [team.name]

                const userIndex_general = members._general.members.findIndex(i => (i._id || i._localId) === (u._id || u._localId) )
                members._general.members[userIndex_general] = u
            })
            delete team.deletedMembers
        }

        if (team.addedMembers) {
            team.addedMembers.forEach(u => {
                if (u.deletedTeams) u.deletedTeams.filter(t => t !== team.name)
                const checkUser = members._general.members.find(i => (i._id || i._localId) === (u._id || u._localId) )
                if (!checkUser) members._general.members.push(u)
                delete u.newMember
            })
            delete team.addedMembers
        }

        if (!members[team.name]) members[team.name] = {}
        if (team.parentTeams)  members[team.name].parentTeams = team.parentTeams
        team.members.forEach(m => {

        })
        members[team.name].members = team.members
        if (!team.oldName) {
            team.members.forEach(m => {
                const userIndex = members._general.members.findIndex(i => (i._id || i._localId) === (m._id || m._localId))
                members._general.members[userIndex] = m
            })
        }
        teams = teams.find(i => team.name === i) ? teams : [...teams, team.name]

        this.setState({members, teams, editableTeam: null, isModified: true, deletedTeams})
        this.changeCompany()
    }

    deleteTeam = (team) => {
        let {members, teams, deletedTeams} = this.state

        delete members[team.name]
        teams = teams.filter(t => team.name !== t)

        members._general.members.map(m => {
            m.teams = m.teams.filter(t => team.name !== t)
            return m
        })

        deletedTeams = [...deletedTeams, team.name]
        this.setState({members, teams, editableTeam: null, isModified: true, deletedTeams })
    }

    render() {
        const {selectedPage, name, address, role, members, editableMember, isModified, editableTeam, teams, isShowDeactivated} = this.state
        const {company, getPopUpAction, popUpReset, user} = this.props

        if (company && (!company.members._general.members.find(m => m._id === user._id) ||
            !company.members._general.members.find(m => m._id === user._id).accessStatus) ) return <Redirect to='/not-found' />

        const saveBtn = <div styleName='saveBtnRow'>{company ?
            isModified ? 
            <Button text={transS('Save Changes')} mod='blue fill' callback={() => this.changeCompany()}/> : ''
            :
            <Button text={transS('Create Company')} mod='blue fill' callback={() => this.changeCompany()}/>}</div>

        let membersList = []
        for (let team in members) {
            if (!team || team === '0' || team !== '_general' ) continue;
            let teamMembers = members[team].members.map(i => <UserLine
                openEdit={() => this.setState({editableMember: i})}
                getPopUpAction = {getPopUpAction}
                popUpReset = {popUpReset}
                deactivateMember={this.deactivateMember}
                changeMemberPassword={this.changeMemberPassword}
                deleteMember={this.deleteMember}
                user={i} key={i._id || i.email}/>)
            membersList = [...membersList, ...teamMembers]
        }

        let requestedUsersList
        if (members._invited && members._invited.length) {
            requestedUsersList = members._invited.map(i => {
                return <UserLine
                    openEdit={() => this.setState({editableMember: i})}
                    getPopUpAction = {getPopUpAction}
                    acceptRequest={() => this.acceptedUserRequest(i)}
                    popUpReset = {popUpReset}
                    deleteMember={this.deleteMember}
                    isRequested = {true}
                    user={i} key={i._id || i.email}/>
            })
        }


        let deactivatedMembersList = []
        if (members._deactivated && members._deactivated.length) {
            deactivatedMembersList = members._deactivated.map(i => {
                const popup = {
                    deleteMember: {
                        name: 'confirm',
                        text: `<div>
                          <div style="margin-bottom: 20px;font-size: var(--fontSize_h2); font-weight: bold">Delete Member?</div>
                          Are you sure you want to delete the member from the company?</div>`,
                        confirm: {
                            name: 'Delete Member',
                            danger: true,
                            event: () => {
                                this.deleteMember(i);
                            }
                        },
                        cancel: {
                            name: transS('No'),
                            event: () => {
                                getPopUpAction(popUpReset);
                            }
                        }
                    },
                }

                return <div key={i._id} styleName='deactivatedMembersList'>
                    <UserListItem maxNameWidth={180} noCheck={true} user={i}/>
                    <Button text='Activate Member' mod='blue' callback={() => this.activateMember(i)}/>
                    <Button text='Delete Member' mod='red' callback={() => getPopUpAction(popup.deleteMember)}/>
                    </div>
            })
        }

        let teamsList = []
        for (let team in members) {
            if (!team || team === '0' || team.startsWith('_') || team === '_general' ) continue;
            if (members[team].parentTeams) continue;

            let subTeams = {}, subTeamsList = []
            for (let checkTeam in members) {
                let isSubTeam = (members[checkTeam].parentTeams && members[checkTeam].parentTeams.length) ? members[checkTeam] : null
                if (isSubTeam && isSubTeam.parentTeams.find(i => i === team)) subTeams[checkTeam] = members[checkTeam]
            }
            for (let subTeam in subTeams) {
                subTeamsList.push(<TeamLine key={subTeam} team={subTeam} members={subTeams[subTeam].members} addTeam={this.addTeam} teams={teams}
                                            isSubTeam={true}
                                            openAddMembers={() => this.setState({editableTeam: {name: subTeam, members: subTeams[subTeam].members, membersOnly: true} })}
                                            openEdit={() => this.setState({editableTeam: {name: subTeam, members: subTeams[subTeam].members, parentTeams: subTeams[subTeam].parentTeams} })}/>)
            }

            teamsList.push(<TeamLine key={team} team={team} members={members[team].members} addTeam={this.addTeam} teams={teams}
                                     subTeamsList={subTeamsList}
                                     openAddMembers={() => this.setState({editableTeam: {name: team, members: members[team].members, membersOnly: true} })}
                                     openEdit={() => this.setState({editableTeam: {name: team, members: members[team].members, isParentTeam: !!subTeamsList.length} })}/>)
        }

        return (
          <>
            <div styleName='wrapper'>
                <div className='h1' style={{marginBottom: '40px'}}>{company ? transS('Company Administration') : 'Create New Company'}</div>
                <div styleName='pages-block'>
                    <div styleName={cx('pages', {'_isSelected': selectedPage === 0})}
                         onClick={() => this._page(0)}>Company
                    </div>
                    <div styleName={cx('pages', {'_isSelected': selectedPage === 1})}
                         onClick={() => this._page(1)}>Members & Teams
                    </div>
                    <div styleName={cx('pages', {'_isSelected': selectedPage === 2})}
                         onClick={() => this._page(2)}>Billing
                    </div>
                    {/*<div styleName={cx('pages', {'_isSelected': selectedPage === 3})}*/}
                    {/*     onClick={() => this._page(3)}>Settings*/}
                    {/*</div>*/}
                    {/*<div styleName={cx('pages', {'_isSelected': selectedPage === 4})}*/}
                    {/*     onClick={() => this._page(4)}>Support*/}
                    {/*    <div styleName='counter'>2</div>*/}
                    {/*</div>*/}
                    {/*<div styleName={cx('pages', {'_isSelected': selectedPage === 5})}*/}
                    {/*     onClick={() => this._page(5)}>Trash*/}
                    {/*</div>*/}
                </div>

                {selectedPage === 0 && <div styleName='page-wrapper'>
                    <div className='h2' style={{marginBottom: '10px'}}>Company</div>
                    <input styleName={'company-title_input'}
                           name='name'
                           placeholder='Company name'
                           onChange={e => this.inputChangeHandler(e) }
                           value={name.value} type='text' />
                    {name.invalid && <div styleName='companyName-error'>Enter the company name</div>}
                    <div styleName='company-details'>
                        <div styleName='company-details-column'>
                            <div className='h2' style={{marginBottom: '10px'}}>Public Details</div>
                            <input styleName={'company-details_input'}
                                   name='address country'
                                   placeholder='Country'
                                   onChange={e => this.inputChangeHandler(e) }
                                   value={address.country} type='text' />
                            <input styleName={'company-details_input'} style={{width: '215px', marginRight: '10px'}}
                                   name='address state'
                                   placeholder='State'
                                   onChange={e => this.inputChangeHandler(e) }
                                   value={address.state} type='text' />
                            <input styleName={'company-details_input'} style={{width: '120px'}}
                                   name='address zip'
                                   placeholder='ZIP'
                                   onChange={e => this.inputChangeHandler(e) }
                                   value={address.zip} type='text' />
                            <input styleName={'company-details_input'}
                                   name='address address1'
                                   placeholder='Address Line 1'
                                   onChange={e => this.inputChangeHandler(e) }
                                   value={address.address1} type='text' />
                            <input styleName={'company-details_input'}
                                   name='address address2'
                                   placeholder='Address Line 2'
                                   onChange={e => this.inputChangeHandler(e) }
                                   value={address.address2} type='text' />
                        </div>
                        <div styleName='company-details-column'>
                            <div className='h2' style={{marginBottom: '10px'}}>Standard Company Role</div>
                            <input styleName={'company-details_input'}
                                   name='role'
                                   placeholder='Legal Advisor'
                                   onChange={e => this.inputChangeHandler(e) }
                                   value={role} type='text' />
                        </div>
                    </div>
                    {saveBtn}
                </div>}
                {selectedPage === 1 && <div styleName='page-wrapper teams-wrapper'>
                    <div styleName='members-wrapper'>
                        <div className='h2' style={{marginBottom: '40px'}}>Members</div>
                        <Button text='New Member' mod='blue arch'
                                callback={() => this.setState({editableMember: {new: true}})}/>
                        <div styleName='members'>
                            <div styleName='members-head'>
                                <div styleName='members-head_col col1'>Members</div>
                                <div styleName='members-head_col col2'>Title</div>
                                <div styleName='members-head_col col3'>Teams</div>
                                <div styleName='members-head_col col4'>Access Level, Status</div>
                                <div styleName='members-head_col col5'>Plan</div>
                                <div styleName='members-head_col col6'>Edit</div>
                            </div>
                            <div styleName='members-list'>
                                {this.props.loader ?
                                    <div style={{position: 'relative', top: '60px'}}><Spinner withoutLogo={true}/>
                                    </div> : membersList}
                                {!this.props.loader && requestedUsersList}
                            </div>

                            {(members._deactivated && !!members._deactivated.length) &&
                            <React.Fragment>
                                <div styleName='showDeM-btn'
                                     style={{marginTop: '20px'}}
                                     onClick={() => this.setState({isShowDeactivated: !isShowDeactivated})}
                                >{isShowDeactivated ? 'Hide Deactivated Members' : 'Show Deactivated Members'}</div>
                                {isShowDeactivated && <div styleName='DeM-list'>{deactivatedMembersList}</div>}
                            </React.Fragment>}

                        </div>
                    </div>

                    <div styleName='teams'>
                        <div className='h2' style={{marginBottom: '40px', lineHeight: 'normal'}}>Teams</div>
                        <Button text='New Team' mod='blue arch' callback={() => this.setState({editableTeam: {new: true}})}/>
                        <div styleName='teamsList'>
                            {teamsList}
                        </div>
                    </div>



                    {saveBtn}
                </div>}
                {selectedPage === 2 && <div styleName='page-wrapper billing-wrapper'>
                    {/*<div className='h2' style={{marginBottom: '20px'}}>Paid Accounts</div>*/}

                    <div className='h2' style={{marginBottom: '20px'}}>{transS('Upgrade')}</div>
                    <div>To add more accounts please contact support@contract.one</div>
                    {saveBtn}
                </div>}
                {selectedPage === 3 && <div styleName='page-wrapper'>
                    <div className='h2' style={{marginBottom: '20px'}}>{transS('Settings')}</div>
                    {saveBtn}
                </div>}
                {selectedPage === 4 && <div styleName='page-wrapper'>
                    <div className='h2' style={{marginBottom: '20px'}}>Support</div>
                    {saveBtn}
                </div>}
                {selectedPage === 5 && <div styleName='page-wrapper'>
                    <div className='h2' style={{marginBottom: '20px'}}>Trash</div>
                    {saveBtn}
                </div>}

                {editableMember && <AddMember editableMember={editableMember}
                                              teams={teams}
                                              editMember={this.editMember}
                                              inviteMember={this.inviteMember}
                                              company={company}
                                              close={() => this.setState({editableMember: null})}/>}
                {editableTeam && <AddTeam close={() => this.setState({editableTeam: null})}
                                          teamNames={teams}
                                          getTeams={() => { // all teams without sub teams
                                              let teams = []
                                              for (let team in members) {
                                                  if (!members.hasOwnProperty(team) || editableTeam.name === team || team.startsWith('_') || team === '_general') continue;
                                                  let checkTeam = members[team].parentTeams && members[team].parentTeams.length
                                                  if (!checkTeam) teams.push(team)
                                              }
                                              return teams
                                          }}
                                          members={members._general.members}
                                          getPopUpAction={getPopUpAction}
                                          popUpReset={popUpReset}
                                          addTeam={this.addTeam}
                                          deleteTeam={this.deleteTeam}
                                          editableTeam={editableTeam} />}

              </div>
            </>
        )
    }
}

export default CompanySettings
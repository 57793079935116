import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector  } from 'react-redux'
import CSSModules from 'react-css-modules'
import styles from './overlineMenu.css'
import Tip from '../../../../components/tip/Tip'
import BIH from './BIH'
import { turnCommunicationVis } from '../../../../redux/paragraph/actions'
import { transL, transS } from '../../../../services/helpers/lang'

const OverlineMenu = ({params, sendToFrame}) => {
    const refPlace = useRef(null)
    const {visible, x, y, z, history, acceptable} = params
    const {formatting, communicationVis} = useSelector(state => state.paragraphReducer)
    const {formatable} = useSelector(state => state.document)
    const [posX, setPosX] = useState(0)
    const [posY, setPosY] = useState(-1000)
    const dispatch = useDispatch()

    const goHist = (hist, para) => sendToFrame({ c1: "overline", hist, para})

    const goAccept = (accept, content) => sendToFrame({ c1: "overline", 
        accept, content})

    const goComment = () => {
      if (communicationVis)
        dispatch(turnCommunicationVis(0))
      sendToFrame({ c1: "overline", comment:1})
    }

    const goClause = () => sendToFrame({ c1: "overline", clause:1, 
      shift: refPlace.current?.getBoundingClientRect().top})

    const goMenu = () => sendToFrame({ c1: "overline", menu:1})

    const acceptBtn = (acceptable, number) => {        
      const isActive = acceptable[number],
        btnStyles = ['btn-accept', 'btn-decline', 'btn-mute'],
        btnNames = [transS('Accept'), transS('Decline'), transS('Mute')],
        styleName = btnStyles[number] + (isActive ? '' : ' semi') + ' btn',
        tipText = !number && !isActive && acceptable[1] ?
        transL('ownChgs')
        : ((isActive && number === 2) ? transL('mute') : '')

        return <div styleName={styleName} onClick={() => isActive && goAccept(number, acceptable[number])}>
            {(acceptable[number] === 1 ? 'Undo ' : '') + btnNames[number]}
            {tipText && <Tip arrow='top left' text={tipText} />}
        </div>}
      
    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        return () => {
          window.removeEventListener('scroll', scrollHandler);
        };
    }, []);
    
    let lastKnownScrollPosition = 0, ticking = false;
    
    const scrollHandler = () => {
        lastKnownScrollPosition = window.scrollY;
        if (!refPlace.current?.pose || !refPlace.current.pose.visible) return;
        if (!ticking) {
            window.requestAnimationFrame(() => {
                ticking = false;
                checkPosition(lastKnownScrollPosition);
            });
            ticking = true;
        }
    }
    
    const checkPosition = (shiftY) => {
        const height = +getComputedStyle(refPlace.current).height.replace('px', '') + 1
        const {x,y,z, visible} = refPlace.current.pose
        if (y < shiftY || !visible) setPosY(-1100)
        else if (y - height < shiftY) {
            setPosX(z); setPosY(y)
        }
        else {
            setPosX(x); setPosY(y - height)
        } 
       }

    useEffect(() => {
        if (!visible || !refPlace.current) {
            setPosY(-1100); return;
        }
        refPlace.current.pose = {visible, x,y,z}
        checkPosition(window.scrollY)
    }, [x,y,z,visible]) 
    return <div styleName='wrapper' ref={refPlace}
      style={{top: (posY - ((visible && (communicationVis !== 2)) ? 0 : 1550)) + 'px', left: posX + 'px'}}>
        
      {history && <div styleName='history'>
        {history.map((el, i) => 
          <div styleName='historyLine' key={i} onClick={() => goHist(el[2], el[3])}>
            {el[0]}<span>{el[1]}</span></div>)}
          </div>}
      <div styleName='bottomPart'>
        {acceptable && acceptBtn(acceptable, 0)}
        {acceptable && acceptBtn(acceptable, 1)}
        {acceptable && acceptBtn(acceptable, 2)}
        {acceptable && <div styleName='vertical'></div>}
        <div styleName='btn btn-comment' onClick={goComment}>{transS('Comment')}</div>
        <div styleName='vertical'></div>
        <BIH sendToFrame={sendToFrame}/>
        <div styleName='vertical'></div>
        {formatable && 
        <div styleName='btn btn-format' 
            onClick={() => dispatch(turnCommunicationVis(2))}></div>}
        <div styleName='btn btn-menu' onClick={goMenu}></div>
        <div styleName='vertical'></div>
        <div styleName='btn btn-comment' onClick={goClause}>{transS('New Clause')}</div>
      </div>
    </div>
}
export default CSSModules(OverlineMenu, styles, {allowMultiple: true})

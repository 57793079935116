import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import CSSModules from 'react-css-modules'
import styles from './templatesUnderMenu.css'
import useEvent from '../../../../../../services/helpers/use-event';
import { transS } from '../../../../../../services/helpers/lang';

const syms = {text: ['T', 'Text'], number: ['#', 'Number']
   , date: ['', 'Date']
   , dropdown: ['','Dropdown'], 'if': ['<IF>', 'Condition'] }

const DropDownType = params => {
  let {type, container, changed, func, arrow, plus, faded} = params;
  const [list, setOpen] = useState(null);
  const _open = () => {
    if (!changed || list) {
        setOpen(null);
        return;
    }
    if (!func) return changed();
    let keys = Object.keys(syms), index = keys.findIndex(el => el === type);
    keys.splice(0,0, keys.splice(index,1)[0]);
    setOpen(keys);
  }
  const click = (el) => {
    setOpen(null);
    if (container.type === el) return;
    container.type = el;
    changed('', container);
  }
  const overlayRef = useRef(null);
  const clickOutsideHandler = ({target}) => overlayRef.current && (!target || !overlayRef.current.contains(target)) && setOpen(null);
  const clickOutsideHandler1 = () => setOpen(null);
  useEffect(() => {
        window.addEventListener('click', clickOutsideHandler)
        window.addEventListener('blur', clickOutsideHandler1)
        return () => {
            window.removeEventListener('click', clickOutsideHandler)
            window.removeEventListener('blur', clickOutsideHandler1)
        }
    }, []);
  return (
    <>
    <div styleName='createVars_btn' data-type={type} 
       ref={overlayRef}
       data-point={!!changed && (!!func || arrow)} 
       data-arrow={arrow} data-faded={faded}
       onClick={_open}>
        <span data-type={type}>{syms[type][0]}</span>
        {!container && transS(syms[type][1])}        
        {list && <div styleName="typeSelector">            
            {list.map(el => func(el, container.type === el, () => click(el)))}
        </div>}
    </div>
    {plus && <span styleName='createVars_btn_add'>{transS(syms[type][1])}</span>}
    </>
  )};
export default CSSModules(DropDownType, styles, {allowMultiple: true});

import React, { useState, useEffect, useRef } from 'react';
import CSSModules from 'react-css-modules'
import styles from './clauseLib.css'
import { transS } from '../../../../services/helpers/lang';
import Tip from '../../../../components/tip/Tip';


const ShowCategories = params => {
  let {clausesCallback, folders, closedFolders, setOpenFolders,drawFind, 
    selectedFolders, callback, restricted, hideLines} = params;

  const showFolder = (folder) => {
    //{title: "first category", id: 13, subs:[folder11, folder22], clauses:[10, 12,34]}
    const isOpen = closedFolders.has(folder.id), 
        lvl = folder.lvl
    return <div key={folder.id}>    
    <div  style={{paddingLeft: (40*lvl)+"px"}} styleName="preline">
      <div styleName={`line${hideLines && false ? ' lhidden' : ''}`} >
        <img src="/images/icons-folder.png" width="13" height="13" />
        <span  onClick={e => callback(folder, e.target.offsetTop)}
          className='pointable'
          style={{maxWidth: `calc(100% - ${40*lvl+40}px)`}}
          styleName={'show-folder' + 
            (selectedFolders?.has(folder.id) ? ' selected-folder' : '')}
            >{drawFind ? 
            <span dangerouslySetInnerHTML={{ __html:
                drawFind(folder.title)}}></span>
            : folder.title}</span>
        {!!(folder.subs?.length || 
            clausesCallback && folder.clauses.length) 
        && <span styleName="arrowShift"           
          className={`arrow${isOpen ? ' arrowOpen' : ''}`}
          onClick={() => {
          if (isOpen)
            closedFolders.delete(folder.id)
          else
            closedFolders.add(folder.id)
          setOpenFolders(new Set(closedFolders))
          }} ></span>}
    </div></div>
    {isOpen && !!folder.subs && (
       restricted ?
       folder.subs.filter(f => !restricted.has(f.id)).map(f => showFolder(f))
       : folder.subs.map(f => showFolder(f)))
    }
    {isOpen && clausesCallback &&
      folder.clauses
        .map(no => clausesCallback(no,folder.id, lvl+1))
    }
  </div>}
  
  
  return <div styleName='maskLine'> 
    {restricted ?
    folders.filter(f => !restricted.has(f.id))
     .map((folder) => showFolder(folder) )
    : folders.map((folder) => showFolder(folder) )}
    </div>
 }  

export default CSSModules(ShowCategories, styles, {allowMultiple: true});
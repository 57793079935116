import { all } from 'redux-saga/effects';
import documentSaga from '../../redux/document/saga';
import paragraphSaga from '../../redux/paragraph/saga';

function * rootSaga() {
  yield all([
    documentSaga(),
    paragraphSaga()
  ]);
}

export default rootSaga;

import keyMirror from 'keymirror';

export default keyMirror({
  GET_PROJECT_LIST: null,
  GET_PROJECT_DOCUMENTS_LIST: null,
  SET_CURRENT_PROJECT: null,
  ADD_PROJECT: null,
  RENAME_PROJECT: null,
  UPDATE_PROJECT: null,
  DELETE_PROJECT: null,
  GET_INVITE_PROJECT_POPUP: null,
  GET_PROJECT_EXPORT: null,
  RENAME_DOCUMENT: null,
  SHARE_DOCUMENT: null,
  DOC_TO_FOLDER: null,
  DELETE_DOCUMENT: null,
  SET_MESSAGE: null
});

import React from 'react'
import { connect } from 'react-redux';
import styles from './Approvals.css';
import cx from 'classnames';
import CSSModules from "react-css-modules";
import Button from "../../../../../../components/button/Button"
import Tip from "../../../../../../components/tip/Tip"
import Comment from "../../../../../../components/comment/Comment"
import Approval from "./Approval"
import api from "../../../../../../services/api/api"
import {isEqual} from "lodash"
import { transS } from '../../../../../../services/helpers/lang';

@connect(state => ({
    messages: state.projectReducer.currentProject.messages,
    internalUserList: state.userReducer.internalUserList,
    user: state.userReducer.user
}), {})
@CSSModules(styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' })
class Approvals extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            tip: null,
            showInputReqApprovals: false,
            approveMessages: [],
            isPendingReply: false
        }
    }

    componentDidMount() {
        const {actualDocument, displayVersion} = this.props
        window.addEventListener('click', this.outsideClickHandler)

        if (this.props.messages) {
            let documentCreatedAt, documentNextVerCreatedAt
            if (displayVersion) {
                const histIndex = actualDocument.history.findIndex(el => el.version + '.' + el.subVersion === displayVersion)
                documentCreatedAt = actualDocument.history[histIndex].createdAt
                if (histIndex !== 0) {
                    documentNextVerCreatedAt = actualDocument.history[histIndex - 1].createdAt
                }
            } else {
                documentCreatedAt = actualDocument.createdAt
            }
            const approveMessages = this.props.messages
                .filter(m => m.type === 1)
                .filter(m => m.document === actualDocument.coreDocument)
                .filter(el => el.when > Date.parse(documentCreatedAt))
                .filter(el => !displayVersion || el.when < Date.parse(documentNextVerCreatedAt))
                // .filter(m => m.when > Date.parse(actualDocument.createdAt))
            this.setState({approveMessages})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {actualDocument, displayVersion} = this.props

        if (this.props.messages) {
            let documentCreatedAt, documentNextVerCreatedAt
            if (displayVersion) {
                const histIndex = actualDocument.history.findIndex(el => el.version + '.' + el.subVersion === displayVersion)
                documentCreatedAt = actualDocument.history[histIndex].createdAt
                if (histIndex !== 0) {
                    documentNextVerCreatedAt = actualDocument.history[histIndex - 1].createdAt
                }
            } else {
                documentCreatedAt = actualDocument.createdAt
            }
            const approveMessages = this.props.messages
                .filter(m => m.type === 1)
                .filter(m => m.document === actualDocument.coreDocument)
                .filter(el => el.when > Date.parse(documentCreatedAt))
                .filter(el => !displayVersion || el.when < Date.parse(documentNextVerCreatedAt))
                // .filter(m => m.when > Date.parse(actualDocument.createdAt))
            if (!isEqual(this.state.approveMessages, approveMessages)) this.setState({approveMessages, isPendingReply: false})
        } else if (this.state.approveMessages && this.state.approveMessages.length) {
            this.setState({approveMessages: []})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.outsideClickHandler)
    }

    outsideClickHandler = e => {
        let isNodeWasDeletedFromDOM = e.target.parentElement
        while (isNodeWasDeletedFromDOM && isNodeWasDeletedFromDOM !== document.body) {
            isNodeWasDeletedFromDOM = isNodeWasDeletedFromDOM.parentElement
        }

        if (this.refWrapper && e.target !== this.refWrapper && !this.refWrapper.contains(e.target) && isNodeWasDeletedFromDOM)
            this.setState({isOpen: false, showInputReqApprovals: false})
    }

    openHandler = val => {
        this.setState({isOpen: val !== undefined ? val : !this.state.isOpen, showInputReqApprovals: false})
    }

    tipMouseHandler = (type, pos, content) => {
        if (type === 'enter' || type === 'over') {
            this.setState({tip: {pos: pos - 100, content}})
        } else if (type === 'leave') {
            this.setState({tip: null})
        }
    }

    makeApproversList = () => {
        const {approveMessages, isOpen} = this.state
        const {approverUserList, approved, isProjectAdmin, internalUserList} = this.props

        return approverUserList.map(user => <Approval user={user} me={this.props.user} isProjectAdmin={isProjectAdmin}
                                                      func={this.messagesFunc}
                                                      isApprovalsOpen={isOpen}
                                                      isPendingReply={this.state.isPendingReply}
                                                      internalUserList={internalUserList}
                                                      document={this.props.actualDocument}
                                                      displayVersion={this.props.displayVersion}
                                                      approveMessages={approveMessages && approveMessages.filter(el => el.recipients && !!el.recipients.find(u => u._id === user._id))}
                                                      tipMouseHandler={this.tipMouseHandler}
                                                      isApproved={approved.indexOf(user._id) !== -1} key={user._id + 'aps'} />)
    }

    makeApproveMessages = () => {
        if (!this.state.approveMessages || !this.state.approveMessages.length) return

        return this.state.approveMessages
            .filter(el => !el.recipients || !el.recipients.length)
            .map(el => <Comment _id={el._id} key={el._id + 'aam'} user={el.user} edit={el.edit} deleted={el.delete}
                                replies={el.replies} func={this.messagesFunc} resolve={el.resolve} readers={el.readers}
                                isApprovalsOpen={this.state.isOpen} isPendingReply={this.state.isPendingReply}
                                approverUserList={this.props.approverUserList} title={el.title} internalUserList={this.props.internalUserList}
                                me={this.props.user} content={el.content} when={el.when} tipMouseHandler={this.tipMouseHandler}/>)
    }

    messagesFunc = (action, params) => {
        const {actualDocument, user} = this.props
        switch (action) {
            case 'addMessage':
                api.addMessage(1, actualDocument.projectId, params.content, actualDocument.coreDocument, params.recipients, user.userGroup, false)
                break
            case 'addReply':
                api.replyMessage(params.messageId, params.content, user.userGroup)
                this.setState({isPendingReply: true})
                break
            case 'editMessage':
            case 'editReply':
                api.editMessage(params.messageId, params.content, params.reply)
                break
            case 'deleteMessage':
            case 'deleteReply':
                api.deleteMessage(params.messageId, params.reply)
                break
            case 'resolveMessage':
                api.resolveMessage(params.messageId, params.value)
                break
            case 'unreadCheck':
                api.unreadCheckMessage(params.messageId, params.reply)
                break
            case 'changeTitle':
                api.changeTitle(params.messageId, params.title)
                break
        }
    }

    render() {
        const {isOpen, tip, showInputReqApprovals, approveMessages} = this.state
        const {approved, user, approverUserList, func, isExternal, actualDocument, approveBtnCallback, isProjectAdmin, internalUserList, displayVersion} = this.props;

        if (!approved || !approverUserList || !approverUserList.length || isExternal) return


        const canApprove = !!approverUserList.find(item => item._id === user._id)
        const approvedByMe = canApprove && (approved.indexOf(user._id) !== -1)
        const isApproveAgain = actualDocument.delApproved && actualDocument.delApproved.length && !!actualDocument.delApproved.find(i => i === user._id)

        let approveButton = displayVersion ?
            <Button text={transS('Approvals History')} mod='blue margin0' callback={() => this.openHandler()} />
            :
            approvedByMe ?
            <Button text={transS('You Approved')} mod='approved' callback={() => this.openHandler()} />
            :
            (canApprove ?
                    <Button text={isApproveAgain ? transS('Approve Again') : transS('Approve')}
                            mod={isApproveAgain ? 'approveAgain' : 'approve'}
                            style={isApproveAgain ? {position: 'relative', top: '-6px'} : null}
                            callback={() => approveBtnCallback(approvedByMe)} />
                    :
                    (!!approverUserList.length ? <Button text={transS('Approvals')} mod='blue margin0' callback={() => this.openHandler()} /> : '')
            );

        const isCanSendApproveMessage = //isProjectAdmin &&
            !displayVersion &&
          (!approveMessages || approveMessages.filter(el => !el.recipients || !el.recipients.length).every(el => !!el.delete && (!el.replies || el.replies.every(el => !!el.delete)) ))

        return (
            <React.Fragment>
                {isOpen && <div styleName='screen' />}

                <div styleName={cx('wrapper', {'_open': isOpen, '_dispVer': displayVersion})} ref={e => this.refWrapper = e} >

                    <div styleName='approveBtn'>
                        {approveButton}
                        <div styleName='arrowBtn' onClick={() => this.openHandler()}>
                            <Button text='' mod={`leftArrow${isOpen ? ' leftArrow_open' : ''}`} />
                        </div>
                    </div>

                    <div styleName={cx('popup', {'_vis': isOpen})}>

                        {approvedByMe && !displayVersion &&
                        <Button text={transS('Cancel Approval')} mod='blue arch' style={{marginBottom: '40px'}} callback={() => approveBtnCallback(approvedByMe)}/>}

                        <div styleName='scrollBlock' style={{maxHeight: document.documentElement.clientHeight - 187 - (approvedByMe ? 60 : 0)}} ref={e => this.refScrollBlock = e}>
                            {isCanSendApproveMessage && (showInputReqApprovals ?
                                <Comment me={user} isNewComment={true} func={this.messagesFunc}
                                         internalUserList={internalUserList}
                                         initialValueTA={transS('Please approve the document')}
                                         blurHandler={() => this.setState({showInputReqApprovals: false})} />
                                :
                                (approverUserList.filter(el => el._id !== user._id).length) > 1 ?
                                        <Button text={transS(`Request Everyone's Approval`)} mod='blue' style={{marginBottom: '40px'}}
                                                callback={() => this.setState({showInputReqApprovals: true})}/>
                                        : '')}

                            {this.makeApproveMessages()}

                            <div styleName='approversList'>
                                {this.makeApproversList()}
                            </div>
                        </div>
                    </div>

                    <div styleName={cx('tip', {'_vis': tip})} style={{top: tip ? tip.pos : 0}}>
                        <Tip text={tip ? tip.content : ''} arrow='bottom left' />
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default Approvals
import React from 'react'
import styles from './BrowserSupportNotice.css'
import CSSModules from 'react-css-modules'
import cx from 'classnames'
import {getBrowserName, isNeedNotFullyBrowserSupportNotice, SupportedBrowsers} from './browsersCheck'

@CSSModules(styles, {allowMultiple: true, handleNotFoundStyleName: 'throw'})
export class BrowserSupportNotice extends React.Component {
    closeCrossClickHandler = () => {
        sessionStorage.setItem('nfbsn', '1')
        this.forceUpdate()
    }

    render() {
        if (!isNeedNotFullyBrowserSupportNotice()) return ''

        return (
            <div styleName='wrapper'>
                <div styleName={cx('alert')}>
                    <div className='close-cross' style={{top: '13px', right: '18px'}}
                         onClick={this.closeCrossClickHandler}>+
                    </div>
                    <div>
                        Unfortunately {getBrowserName()} does not fully support the needed functionality that is
                        required to run Contract.one safely. Some features may work incorrectly. <br/>
                        <br/>
                        {SupportedBrowsers}
                    </div>
                </div>
            </div>
        )
    }
}

import _ from 'lodash';
import * as actionTypes from './constants';
import store from '../../services/store';
import { transliterate } from '../../../common/transliterate';


const initialState = {
  file: {},
  fileName: '',
  currentDocument: {},
  actualDocument: {},
  error: '',
  displayOnly: false,
  displayVersion: '',
  waiting: 0,
  envelope: null,
  navigation: {current: null, paras: null, recent: [], back: null, open: false, fullMode: false},
  viewedNew: 0, // сигнал для добавления переменной
  usedVars: new Set(),
};

const document = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.IMPORT_FILE:
    return {
      ...state,
      fileName: action.name,
      fileParsed: false
    };

  case actionTypes.ADD_FILE:
    return {
      ...state,
      file: action.document,
      fileName: action.name,
      fileParsed: false
    };

 case actionTypes.SET_CURRENT_DOCUMENT:
    let stateStore = store.getState(),
      projectList = stateStore.projectReducer.projectList, doc;    
    if (action.documentId) {
        if (action.documentId._id)
          doc = action.documentId; // it is template
        else projectList.find(p => p.docs && (doc = p.docs.find(d => d.coreDocument === action.documentId))); 
    }
    if (!doc) 
        doc = {};
    return {
      ...state,
      currentDocument: doc,
      dateControl: null
    };

  case actionTypes.SET_ACTUAL_DOCUMENT:
    let i = 1, vvlist =  action.document?.variables ? JSON.parse(action.document.variables) : null;
    if (vvlist)
      vvlist.forEach(el => el.name = 
        transliterate(el.name).match(/\b\w+\b/g).join('_').substr(0,40))
    return {
      ...state,
      actualDocument: action.document ? action.document : {},
      currentDocument: {...state.currentDocument, showedChanges: 0},
      approved: action.document ? action.document.approved : [],
      envelope: action.document && action.document.dsEnvelope && JSON.parse(action.document.dsEnvelope),
      varlist: vvlist,
      usedVars: new Set(),
      formatable: false,
      //varlist: action.document?.variables ? JSON.parse(action.document.variables) : null,
    };
    
  case actionTypes.ADD_VARIABLE:
    let vlist, id = Date.now(), newEl = {id, type: action.vartype}
    if (action.vartype === 'dropdown')
      newEl.dropdown = [{id: id+1, option: '', tip: ''}];
    if (state.varlist) {
        vlist = [...[newEl], ...state.varlist];
    } else vlist = [newEl];
    return {
      ...state,      
      varlist: vlist,
      viewedNew: id,
    };

  case actionTypes.SET_VARIABLE:    
    return {
      ...state,      
      varlist:  JSON.parse(JSON.stringify(action.varlist)), // [...action.varlist],  // 
    };

  case actionTypes.SHOW_DATE_CONTROL:
    return {
      ...state,
      dateControl: action.dateVar ? action : null
    }

  case actionTypes.SET_FORMATABLE:
    return {
      ...state,      
      formatable: true,
      fonts: action.fonts.sort()
    };

  case actionTypes.SET_USED_VARS:    
    return {
      ...state,      
      usedVars: new Set(action.usedVars),
    };
  case actionTypes.SET_VAR_CLICK:
     if (action.click.option)
       return {
         ...state,
         clickPosition: action.click  
       }
     return {
       ...state,
       click: action.click,
     }
  case actionTypes.SET_VAR_CHANGE:
     return {
       ...state,
       varlist: action.variable,
     }

  case actionTypes.SET_DOCUMENT_ENVELOPE:
    return {
      ...state,
      envelope: action.envelope,
    };

  case actionTypes.DISPLAY_ONLY:
    return {
      ...state,
      displayOnly: action.displayOnly,
      displayVersion: action.displayVersion
    };

  case actionTypes.CHANGE_APPROVAL:
    return {
      ...state,
      approved: action.data.approved,
      actualDocument: {...state.actualDocument, approved: action.data.approved, delApproved: action.data.delApproved}
    };

  case actionTypes.RENAME_DOCUMENT_FROM_SIDEBAR:
      if (action.documentId && (state.actualDocument._id !== action.documentId 
          || state.actualDocument.coreDocument !== action.documentId))
        return state;
      return {
        ...state,
        actualDocument: {...state.actualDocument, ...{title: action.newName}}
      };
  case actionTypes.SET_DOCUMENT_STATS:
    return {
      ...state, docStats: action.docStats
   };

    case actionTypes.SET_DOCUMENT_NAVIGATION:
      if (!action.current && !action.paras)
        return {...state, navigation: {current: null, paras: null, recent: [], back: null, open: false, fullMode: false}}

      const paras = action.paras || state.navigation.paras
      const recent = state.navigation.recent.slice()
      const back = action.isNeedBack ? state.navigation.current.id : state.navigation.back
      if (action.isJump && state.navigation.current && !recent.find(el => el && el.id === state.navigation.current.id)) {
        recent.unshift(state.navigation.current)
        if (recent.length > 3) recent.pop()
      }
      const updRecent = recent.map(el => paras.find(e => e.id === el?.id))

      return {...state, navigation: {current: action.current || state.navigation.current, paras, recent: updRecent, back, open: state.navigation.open, fullMode: state.navigation.fullMode}}
    case actionTypes.CLEAR_BACK_NAVIGATION:
      return {...state, navigation: {...state.navigation, back: null}}
    case actionTypes.TOGGLE_DOCUMENT_NAVIGATION_VIS:
      return {
        ...state, navigation: {
          ...state.navigation,
          open: action.open !== undefined ? action.open : state.navigation.open,
          fullMode: action.fullMode !== undefined ? action.fullMode : state.navigation.fullMode
        }
      }

    case actionTypes.CLEAR_WRITER_COVER_MESSAGE:
      return {...state, actualDocument: {...state.actualDocument, writingCoverMessage: undefined}}
    case actionTypes.SET_WRITER_COVER_MESSAGE:
      return {...state, actualDocument: {...state.actualDocument, writingCoverMessage: action.userId}}

  default:
    return state;
  }
};

export default document;

import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import Spinner from './Spinner';

// Универсальная обертка для отображения спиннера
const WithSpinner = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [showChild, setShowChild] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    // Немедленный показ спиннера, задержка для рендеринга дочернего компонента
    const timer = setTimeout(() => setShowChild(true), 0);
    return () => clearTimeout(timer);
  }, []);


  useLayoutEffect(() => {
    const checkIfRendered = () => {
      if (containerRef.current) {
        // Проверяем, что компонент завершил рендеринг
        setLoading(false);
      } else {
        // Если не завершил, ждем следующий рендер
        requestAnimationFrame(checkIfRendered);
      }
    };

    requestAnimationFrame(checkIfRendered);
  }, []);

  return (
    <>
      {loading &&<div style={{ position: 'relative', height: '50px', width: '100vw' }}>
       <Spinner /> </div>}
      {showChild && <div ref={containerRef} style={{ opacity: loading ? 0 : 1 }}>
        {children}
      </div>}
    </>
  );
};

export default WithSpinner;

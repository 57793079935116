import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import CSSModules from 'react-css-modules';
import styles from './userLogo.css';
import { showSidebarAction } from '../../services/actions/userActions';

const UserLogo = props => {
  let { firstname = '', lastname = '', email = '', avatar, creatorID, externalUserList, mod, dataAttr = false, centered, deleted, showSidebarAction } = props;
  let externalUser = externalUserList && creatorID && externalUserList.filter(item => item._id === creatorID).length;

  const _getFirstLetters = (firstname, lastname, email) => (firstname) ? firstname.slice(0, 1) + lastname.slice(0, 1) : email.slice(0, 1)


  // TODO:: for dev
  const _click = e => {
    const { editable } = props;
    e.preventDefault();
    if (editable) {
      showSidebarAction(true);
    }
  };

  const styles = {
    backgroundImage: `url(${avatar})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  };

  if (!avatar) mod = mod.replace('_border', '')

  return (
    <a data-ignore styleName={cx('wrapper', { [mod]: mod }, { '_no-external': externalUser === 0 }, { '_external': externalUser }, { '_with-outline': avatar }, { '_centered': centered }, { '_deleted': deleted })}
      href='/'
      onClick={_click}
      data-user-logo={dataAttr}
      style={styles}
    >
      {avatar ? '' : _getFirstLetters(firstname, lastname, email)}
    </a>
  );
};

UserLogo.propTypes = {
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  avatar: PropTypes.string,
  creatorID: PropTypes.string,
  externalUserList: PropTypes.array,
  mod: PropTypes.string,
  dataAttr: PropTypes.string,
  deleted: PropTypes.bool,
  editable: PropTypes.bool,
  showSidebarAction: PropTypes.func
};

export default connect(state => ({
  externalUserList: state.userReducer.externalUserList
}),
{
  showSidebarAction
}
)(new CSSModules(UserLogo, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' }));

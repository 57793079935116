import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from '../publicArea/static/static.css';

class PageNotFound extends Component {
  render () {
  let descr, {error} = this.props.match.params;
  switch (error) {
    case '404': descr = 'Project not found'; break;
    case '403': descr = 'No rights to the document'; break;
    case '402': descr = 'No rights to the project'; break;
    case '401': descr = 'No rights to the project'; break;
    case '400': descr = 'Document not found'; break;
    default: descr = 'Page not found'; break;
  }
  return (
    <div styleName="wrapper">  
      <div styleName="content">  
        <div styleName="header">{descr}</div>
        <a styleName='to-main-page-button' href='/'>Go to the main page</a>
      </div>
    </div> 
    )
  };
}
export default new CSSModules(PageNotFound, styles, { allowMultiple: true, handleNotFoundStyleName: 'throw' });

